// AngularJS
export abstract class AjsState {
  [key: string]: any;
}
export const $stateProvider = {
  provide: AjsState,
  useFactory: function ($injector: any) {
    return $injector.get('$state');
  },
  deps: ['$injector']
};

export abstract class AjsTransitions {
  [key: string]: any;
}
export const $transitionsProvider = {
  provide: AjsTransitions,
  useFactory: function ($injector: any) {
    return $injector.get('$transitions');
  },
  deps: ['$injector']
};

// external
export abstract class LazyLoadService {
  [key: string]: any;
}
export const $lazyLoadProvider = {
  provide: LazyLoadService,
  useFactory: function ($injector: any) {
    return $injector.get('$ocLazyLoad');
  },
  deps: ['$injector']
};

// common-header/services
export abstract class StoreService {
  [key: string]: any;
}
export const storeServiceProvider = {
  provide: StoreService,
  useFactory: function ($injector: any) {
    return $injector.get('storeService');
  },
  deps: ['$injector']
};

export abstract class HelpWidgetFactory {
  [key: string]: any;
}
export const helpWidgetFactoryProvider = {
  provide: HelpWidgetFactory,
  useFactory: function ($injector: any) {
    return $injector.get('helpWidgetFactory');
  },
  deps: ['$injector']
};

export abstract class COUNTRIES {
  [key: string]: any;
}
export const countriesProvider = {
  provide: COUNTRIES,
  useFactory: function ($injector: any) {
    return $injector.get('COUNTRIES');
  },
  deps: ['$injector']
};

// common/services
export abstract class FileDownloader extends Function {
  [key: string]: any;
}
export const fileDownloaderProvider = {
  provide: FileDownloader,
  useFactory: function ($injector: any) {
    return $injector.get('fileDownloader');
  },
  deps: ['$injector']
};

export abstract class ProxyFileDownloader extends Function {
  [key: string]: any;
}
export const proxyFileDownloaderProvider = {
  provide: ProxyFileDownloader,
  useFactory: function ($injector: any) {
    return $injector.get('proxyFileDownloader');
  },
  deps: ['$injector']
};

export abstract class OutsideClickHandler extends Function {
  [key: string]: any;
}
export const outsideClickHandlerProvider = {
  provide: OutsideClickHandler,
  useFactory: function ($injector: any) {
    return $injector.get('outsideClickHandler');
  },
  deps: ['$injector']
};

export abstract class CompanyAssetsService {
  [key: string]: any;
}
export const companyAssetsServiceProvider = {
  provide: CompanyAssetsService,
  useFactory: function ($injector: any) {
    return $injector.get('companyAssetsFactory');
  },
  deps: ['$injector']
};

// components/core-api-client
export abstract class CompanyService {
  [key: string]: any;
}
export const companyServiceProvider = {
  provide: CompanyService,
  useFactory: function ($injector: any) {
    return $injector.get('companyService');
  },
  deps: ['$injector']
};

export abstract class EnableCompanyProduct extends Function {
  [key: string]: any;
}
export const enableCompanyProductProvider = {
  provide: EnableCompanyProduct,
  useFactory: function ($injector: any) {
    return $injector.get('enableCompanyProduct');
  },
  deps: ['$injector']
};

export abstract class UserService {
  [key: string]: any;
}
export const userServiceProvider = {
  provide: UserService,
  useFactory: function ($injector: any) {
    return $injector.get('userService');
  },
  deps: ['$injector']
};

export abstract class UserProfileService extends Function  {
  [key: string]: any;
}
export const userProfileProvider = {
  provide: UserProfileService,
  useFactory: function ($injector: any) {
    return $injector.get('getUserProfile');
  },
  deps: ['$injector']
};

export abstract class StoreAuthorizationService {
  [key: string]: any;
}
export const storeAuthorizationProvider = {
  provide: StoreAuthorizationService,
  useFactory: function ($injector: any) {
    return $injector.get('storeAuthorization');
  },
  deps: ['$injector']
};

export abstract class StoreProductService {
  [key: string]: any;
}
export const storeProductProvider = {
  provide: StoreProductService,
  useFactory: function ($injector: any) {
    return $injector.get('storeProduct');
  },
  deps: ['$injector']
};

// components/gapi
export abstract class GapiLoader extends Function {
}
export const gapiLoaderProvider = {
  provide: GapiLoader,
  useFactory: function ($injector: any) {
    return $injector.get('gapiLoader');
  },
  deps: ['$injector']
};

export abstract class StorageAPILoader extends Function {
}
export const storageAPILoaderProvider = {
  provide: StorageAPILoader,
  useFactory: function ($injector: any) {
    return $injector.get('storageAPILoader');
  },
  deps: ['$injector']
};

export abstract class StoreAPILoader extends Function {
}
export const storeAPILoaderProvider = {
  provide: StoreAPILoader,
  useFactory: function ($injector: any) {
    return $injector.get('storeAPILoader');
  },
  deps: ['$injector']
};

export abstract class CoreAPILoader extends Function {
}
export const coreAPILoaderProvider = {
  provide: CoreAPILoader,
  useFactory: function ($injector: any) {
    return $injector.get('coreAPILoader');
  },
  deps: ['$injector']
};

export abstract class RiseAPILoader extends Function {
}
export const riseAPILoaderProvider = {
  provide: RiseAPILoader,
  useFactory: function ($injector: any) {
    return $injector.get('riseAPILoader');
  },
  deps: ['$injector']
};

// components/logging
export abstract class ExceptionHandler extends Function  {
  [key: string]: any;
}
export const exceptionHandlerProvider = {
  provide: ExceptionHandler,
  useFactory: function ($injector: any) {
    return $injector.get('$exceptionHandler');
  },
  deps: ['$injector']
};

export abstract class AnalyticsFactory {
  [key: string]: any;
}
export const analyticsFactoryProvider = {
  provide: AnalyticsFactory,
  useFactory: function ($injector: any) {
    return $injector.get('analyticsFactory');
  },
  deps: ['$injector']
};

export abstract class BigQueryLogging {
  [key: string]: any;
}
export const bigQueryLoggingProvider = {
  provide: BigQueryLogging,
  useFactory: function ($injector: any) {
    return $injector.get('bigQueryLogging');
  },
  deps: ['$injector']
};

export abstract class PurchaseFlowTracker {
  [key: string]: any;
}
export const purchaseFlowTrackerProvider = {
  provide: PurchaseFlowTracker,
  useFactory: function ($injector: any) {
    return $injector.get('purchaseFlowTracker');
  },
  deps: ['$injector']
};

// components/scrolling-list
export abstract class ProcessErrorCode extends Function {
}
export const processErrorCodeProvider = {
  provide: ProcessErrorCode,
  useFactory: function ($injector: any) {
    return $injector.get('processErrorCode');
  },
  deps: ['$injector']
};

export abstract class ScrollingListService  extends Function{
}
export const scrollingListServiceProvider = {
  provide: ScrollingListService,
  useFactory: function ($injector: any) {
    return $injector.get('ScrollingListService');
  },
  deps: ['$injector']
};

// components/url-field
export abstract class InsecureUrl  extends Function{
}
export const insecureUrlProvider = {
  provide: InsecureUrl,
  useFactory: function ($injector: any) {
    return $injector.get('insecureUrl');
  },
  deps: ['$injector']
};

// components/userstate
export abstract class UrlStateService {
  [key: string]: any;
}
export const urlStateServiceProvider = {
  provide: UrlStateService,
  useFactory: function ($injector: any) {
    return $injector.get('urlStateService');
  },
  deps: ['$injector']
};

export abstract class UserState {
  [key: string]: any;
}
export const userStateProvider = {
  provide: UserState,
  useFactory: function ($injector: any) {
    return $injector.get('userState');
  },
  deps: ['$injector']
};

export abstract class UserAuthFactory {
  [key: string]: any;
}
export const userAuthFactoryProvider = {
  provide: UserAuthFactory,
  useFactory: function ($injector: any) {
    return $injector.get('userAuthFactory');
  },
  deps: ['$injector']
};

export abstract class GoogleAuthFactory {
  [key: string]: any;
}
export const googleAuthFactoryProvider = {
  provide: GoogleAuthFactory,
  useFactory: function ($injector: any) {
    return $injector.get('googleAuthFactory');
  },
  deps: ['$injector']
};

export abstract class CanAccessApps  extends Function{
}
export const canAccessAppsProvider = {
  provide: CanAccessApps,
  useFactory: function ($injector: any) {
    return $injector.get('canAccessApps');
  },
  deps: ['$injector']
};


// editor/services
export abstract class PresentationService {
  [key: string]: any;
}
export const presentationServiceProvider = {
  provide: PresentationService,
  useFactory: function ($injector: any) {
    return $injector.get('presentation');
  },
  deps: ['$injector']
};

export abstract class PresentationParser {
  [key: string]: any;
}
export const presentationParserFactory = {
  provide: PresentationParser,
  useFactory: function ($injector: any) {
    return $injector.get('presentationParser');
  },
  deps: ['$injector']
};

export abstract class WidgetRenderer {
  [key: string]: any;
}
export const widgetRendererProvider = {
  provide: WidgetRenderer,
  useFactory: function ($injector: any) {
    return $injector.get('widgetRenderer');
  },
  deps: ['$injector']
};

export abstract class GadgetsApi {
  [key: string]: any;
}
export const gadgetsApiProvider = {
  provide: GadgetsApi,
  useFactory: function ($injector: any) {
    return $injector.get('gadgetsApi');
  },
  deps: ['$injector']
};

export abstract class WidgetModalFactory {
  [key: string]: any;
}
export const widgetModalFactoryProvider = {
  provide: WidgetModalFactory,
  useFactory: function ($injector: any) {
    return $injector.get('widgetModalFactory');
  },
  deps: ['$injector']
};

// displays/services
export abstract class DisplayApiService {
  [key: string]: any;
}
export const displayApiServiceProvider = {
  provide: DisplayApiService,
  useFactory: function ($injector: any) {
    return $injector.get('display');
  },
  deps: ['$injector']
};

export abstract class DisplayFactory {
  [key: string]: any;
}
export const displayFactoryProvider = {
  provide: DisplayFactory,
  useFactory: function ($injector: any) {
    return $injector.get('displayFactory');
  },
  deps: ['$injector']
};

// storage/services
export abstract class FileUploaderFactory extends Function {
}
export const fileUploaderFactoryProvider = {
  provide: FileUploaderFactory,
  useFactory: function ($injector: any) {
    return $injector.get('fileUploaderFactory');
  },
  deps: ['$injector']
};

export abstract class StorageApiService {
  [key: string]: any;
}
export const storageApiServiceProvider = {
  provide: StorageApiService,
  useFactory: function ($injector: any) {
    return $injector.get('storage');
  },
  deps: ['$injector']
};

export abstract class StorageManagerFactory {
  [key: string]: any;
}
export const storageManagerFactoryProvider = {
  provide: StorageManagerFactory,
  useFactory: function ($injector: any) {
    return $injector.get('storageManagerFactory');
  },
  deps: ['$injector']
};

export abstract class UploadOverwriteWarning {
  [key: string]: any;
}
export const uploadOverwriteWarningProvider = {
  provide: UploadOverwriteWarning,
  useFactory: function ($injector: any) {
    return $injector.get('uploadOverwriteWarning');
  },
  deps: ['$injector']
};

export abstract class UploadURIService {
  [key: string]: any;
}
export const uploadURIServiceProvider = {
  provide: UploadURIService,
  useFactory: function ($injector: any) {
    return $injector.get('UploadURIService');
  },
  deps: ['$injector']
};

export abstract class DownloadService {
  [key: string]: any;
}
export const downloadServiceProvider = {
  provide: DownloadService,
  useFactory: function ($injector: any) {
    return $injector.get('downloadFactory');
  },
  deps: ['$injector']
};


//schedules/services
export abstract class CreateFirstScheduleService extends Function {
}
export const createFirstScheduleServiceProvider = {
  provide: CreateFirstScheduleService,
  useFactory: function ($injector: any) {
    return $injector.get('createFirstSchedule');
  },
  deps: ['$injector']
};

export abstract class ScheduleFactory {
  [key: string]: any;
}
export const scheduleFactoryProvider = {
  provide: ScheduleFactory,
  useFactory: function ($injector: any) {
    return $injector.get('scheduleFactory');
  },
  deps: ['$injector']
};

export abstract class ScheduleSelectorFactory {
  [key: string]: any;
}
export const scheduleSelectorFactoryProvider = {
  provide: ScheduleSelectorFactory,
  useFactory: function ($injector: any) {
    return $injector.get('scheduleSelectorFactory');
  },
  deps: ['$injector']
};


// widgets/services

