
<div class="modal-header">
	<button type="button" class="close" data-dismiss="modal"
	  aria-hidden="true" (click)="closeModal()">
	  <streamline-icon name="close" width="10" height="10"></streamline-icon>
	</button>
	<h4 id="switch-company" class="modal-title">
		Select Sub-Company
	</h4>
</div>
<div class="modal-body select-subcompany-modal text-left">
	<div id="errorBox" [shown]="companies.apiError" class="alert alert-danger" role="alert">
    <strong>{{companies.errorMessage}}</strong> {{companies.apiError}}
  </div>

  <!-- Search -->
  <div class="flex-row button-toolbar-md-folded">
    <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="companies.doSearch" iconSet="madero"></search-filter>
    <div class="flex-row row-wrap-xs ml-md-3">
      <madero-checkbox checkboxId="allCompaniesCheckbox" ngDefaultControl (ngModelChange)="toggleAllCompanies($event)" [ngModel]="allCompanies"></madero-checkbox>
      <label class="font-weight-normal u_nowrap mb-0 u_clickable" (click)="toggleAllCompanies(!allCompanies)">Show full sub-company network</label>
    </div>
  </div>

  <!-- List of Companies -->
  <div class="panel panel-default scrollable-list border-container u_margin-sm-top mb-2"
    scrollingList (scrollEndEvent)="companies.load()"
    rvSpinner="company-selector-modal-list" [rvShowSpinner]="companies.loadingItems">
	<table id="companySelectorListTable" class="table">
		<thead class="table-header">
		  <tr class="table-header__row">
			<th class="table-header__cell col-xs-8 u_clickable">
				Company Name
			</th>
			<th class="table-header__cell col-xs-4" *ngIf="allCompanies">
				Parent Company
			</th>
		  </tr>
		</thead>
		<tbody class="table-body">
		  <tr class="table-body__row p-0" [ngClass]="{ 'bg-lighter-gray' : selectedCompanyId !== company.parentId }" *ngFor="let company of companies.items.list" (click)="setCompany(company)">
			<td class="table-body__cell">
				<p class="list-group-item-text"><strong>{{company.name}}</strong><br/><small class="text-muted">{{company | fullAddress}}</small></p>
			</td>
			<td class="table-body__cell" *ngIf="allCompanies">
				<span>{{company.parentName}}</span>
			</td>
		  </tr>
		</tbody>
	  </table>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-default btn-toolbar" data-dismiss="modal" aria-hidden="true" (click)="closeModal()">
		Cancel
	</button>
</div>
