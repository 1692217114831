<ng-template #overridesPopover><div [innerHtml]="scheduleOverrides"></div></ng-template>
<div>
  <div class="app-header u_margin-md-top mb-5">
    <!-- App Title -->
    <div class="app-header-title">
      <h1 class="m-0" id="title">Displays</h1>
      <display-license-counts showOnlineCount="true"></display-license-counts>
    </div>
  </div>

  <div [shown]="displays.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <p><strong>{{displays.errorMessage}}</strong></p>
    {{displays.apiError}}
  </div>

  <div class="flex-row button-toolbar-md-folded">
    <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="displays.doSearch" iconSet="madero"></search-filter>
    <div class="ml-md-3" *ngIf="userState.hasRole('da') && !(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)">
      <a id="displayAddButton" class="btn btn-primary btn-toolbar-wide" uiSref="apps.displays.add">
        Add Display
      </a>
    </div>
    <div class="flex-row row-wrap-xs ml-md-3">
      <madero-checkbox checkboxId="allCompaniesCheckbox" ngDefaultControl (ngModelChange)="toggleAllCompanies($event)" [ngModel]="allCompanies"></madero-checkbox>
      <label class="font-weight-normal u_nowrap mb-0 u_clickable" (click)="toggleAllCompanies(!allCompanies)">Show displays from all companies</label>
    </div>
  </div>

  <batch-operations [listObject]="displays"></batch-operations>

  <div *ngIf="!(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)">
    <div class="scrollable-list horizontal-scroll border-container u_margin-md-top u_margin-md-bottom"
      scrollingList (scrollEndEvent)="displays.load()" (scroll)="refresh()"
      rvSpinner="displays-list-loader" [rvShowSpinner]="displays.loadingItems">

      <table id="displaysListTable" class="table">
        <thead class="table-header">
          <tr class="table-header__row">
            <th class="table-header__cell col-xs-4">
              <div class="flex-row">
                <madero-checkbox (click)="displays.selectAll()" [ngValue]="search.selectAll"></madero-checkbox>
                <div class="u_clickable" id="tableHeaderName" (click)="displays.sortBy('name')">
                  Name
                  <i *ngIf="search.sortBy === 'name'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                </div>
              </div>
            </th>
            <th id="tableHeaderCompanyName" class="table-header__cell" *ngIf="allCompanies">
              Company
            </th>
            <th class="table-header__cell col-xs-1 u_clickable" (click)="displays.sortBy('licensed')">
              Licensed
              <i *ngIf="search.sortBy === 'licensed'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
            </th>
            <th id="tableHeaderStatus" class="table-header__cell col-xs-1 u_clickable" (click)="displays.sortBy('onlineStatus')">
              Status
              <i *ngIf="search.sortBy === 'onlineStatus'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
            </th>
            <th id="tableHeaderLastConnection" class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('lastActivityDate')">
              Last Connection
              <i *ngIf="search.sortBy === 'lastActivityDate'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
            </th>
            <th class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('scheduleName')">
              Schedule
              <i *ngIf="search.sortBy === 'scheduleName'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
            </th>
            <th class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('changeDate')">
              Last Modified
              <i *ngIf="search.sortBy === 'changeDate'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="table-body__row" [ngClass]="{ 'bg-lighter-gray' : selectedCompayId !== display.companyId }" *ngFor="let display of displays.items.list">
            <td class="table-body__cell display-name">
              <div class="flex-row">
                <madero-checkbox (click)="displays.select(display)" [ngValue]="display.selected"></madero-checkbox>
                <a class="madero-link u_ellipsis-md" uiSref="apps.displays.change" [uiParams]="{displayId: display.id, companyId: display.companyId}">
                  <strong>{{display.name}}</strong>
                </a>
              </div>
            </td>
            <td id="companyName-{{display.id}}" class="table-body__cell" *ngIf="allCompanies"><span>{{display.companyName}}</span></td>
            <td class="table-body__cell">
              {{ getLicenseStatus(display) }}
            </td>
            <td class="table-body__cell display-status">
              <span *ngIf="displayFactory.showLicenseRequired(display) && display.onlineStatus !== 'not_activated'">
                License Required
              </span>
              <span [ngClass]="{'text-success': display.onlineStatus === 'online', 'text-danger': display.onlineStatus === 'offline'}" *ngIf="!(displayFactory.showLicenseRequired(display) && display.onlineStatus !== 'not_activated')">
                {{display.onlineStatus | displayStatus}}
              </span>
            </td>
            <td class="table-body__cell">
              <span *ngIf="displayFactory.showLicenseRequired(display)">
                License Required
              </span>
              <span *ngIf="!displayFactory.showLicenseRequired(display)">
                {{display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
              </span>
            </td>
            <td class="table-body__cell display-schedule">
              <div class="u_nowrap flex-row">
                <a class="madero-link u_ellipsis-md schedule-view" *ngIf="displayFactory.hasSchedule(display)" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: display.scheduleId, cid: display.companyId }">
                  {{display.scheduleName}}
                </a>
                <span class="u_ellipsis-md" *ngIf="!displayFactory.hasSchedule(display)">
                  {{ display.overrideScheduleNames ? 'No Default Schedule' : 'No Schedule Selected' }}
                </span>
                <span class="badge badge-primary u_clickable ml-2"
                  *ngIf="display.overrideScheduleNames"
                  [popover]="overridesPopover"
                  (onShown)="showPopover(display.overrideScheduleNames)"
                  containerClass="madero-style"
                  outsideClick="true"
                  placement="bottom right">

                  +{{display.overrideScheduleNames.length}}
                </span>
              </div>
            </td>
            <td class="table-body__cell">
              <span class="u_ellipsis-md">
                {{display.changeDate | date:'d-MMM-yyyy h:mm a'}} by {{display.changedBy | username}}
              </span>
            </td>
          </tr>
          <!-- If no search results -->
          <tr [shown]="displays.items.list.length === 0 && search.query !== undefined">
            <td colspan="6" class="text-center">Your search didn't return any results.</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

  <div class="border-container text-center u_margin-md-top u_margin-md-bottom" *ngIf="!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined">
    <div class="panel-body">
      <div class="form-group my-4">
        Add a display to start communicating better with your audience!
      </div>
      <a id="displayAddButton" class="btn btn-primary btn-toolbar-wide mb-2" uiSref="apps.displays.add">
        Add Display
      </a>
    </div>
  </div>

</div>

