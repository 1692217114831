<div class="pl-0 file-component-list-file-row">
  <div class="rv-sortable-handle" *ngIf="showGripHandle">
    <streamline-icon class="streamline-component-icon" name="navigation-menu-vertical" width="8" height="24"></streamline-icon>    
  </div>

  <div class="file-thumbnail">
    <div class="file-helper"></div>
    <img [src]="getThumbnailUrl()"
      *ngIf="entry.exists && !isStreamlineThumbnail()"
      width="80">
    <div class="broken-link" [hidden]="entry.exists && !isStreamlineThumbnail()">
      <streamline-icon width="61" height="60"
        name="{{ entry.exists ? getStreamlineIcon() : 'forbidden' }}"
      ></streamline-icon>
    </div>
  </div>
  <div class="file-entry" [ngClass]="{'is-error' : !entry.exists}">
    <div class="file-text">
      <div class="file-name" title="{{ getStoragePath() }}">{{getFileName()}}</div>
      <div class="file-error" [hidden]="entry.exists">
        Your {{fileType}} has been deleted, renamed, or moved.
      </div>
    </div>
    <div class="file-remove">
      <a href="#" (click)="removeFileFromList()">
        <streamline-icon class="ml-3 streamline-component-icon" name="trash" width="16" height="18"></streamline-icon>
      </a>
    </div>
  </div>
</div>
