<div class="attribute-editor-component">
  <div class="attribute-editor-row counter-container">
    <label [shown]="counterType === 'down'" class="u_margin-sm-top">Countdown to:</label>
    <label [shown]="counterType === 'up'" class="u_margin-sm-top">Count up from:</label>
    <div class="madero-radio" id="dateRadio">
      <input type="radio" [ngModel]="targetUnit" value="targetDate" id="specificDate" name="targetUnitSelector" (change)="save()" (click)="targetUnit = 'targetDate'" aria-required="true" tabindex="1" required>
      <label for="specificDate" id="specificDateLabel">
        A specific date and time:
      </label>
      <popup-date-picker id="targetDate" [(ngModel)]="targetDate" ngDefaultControl (ngChange)="save()" [shown]="targetUnit === 'targetDate'"></popup-date-picker>
      <popup-time-picker id="targetDateTime" [(ngModel)]="targetDateTime" ngDefaultControl (ngChange)="save()" [shown]="targetUnit === 'targetDate'"></popup-time-picker>
    </div>

    <div class="madero-radio" id="timeRadio">
      <input type="radio" [ngModel]="targetUnit" value="targetTime" id="specificTime" name="targetUnitSelector" (change)="save()" (click)="targetUnit = 'targetTime'" aria-required="true" tabindex="1" required>
      <label for="specificTime" id="specificTimeLabel">
        A specific time, every day:
      </label>
      <popup-time-picker id="targetTime" [(ngModel)]="targetTime" ngDefaultControl (ngChange)="save()" [shown]="targetUnit === 'targetTime'"></popup-time-picker>
    </div>
    <div class="form-group mt-4" [shown]="counterType === 'down' && !nonCompletion">
      <label class="control-label" for="completionMessage">Enter countdown completion message:</label>
      <input type="text" id="completionMessage" class="form-control u_ellipsis" [(ngModel)]="completionMessage" (change)="save()" placeholder="Eg. &quot;It's time for...&quot;">
    </div>
  </div>
</div>
