import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

import * as angular from 'angular';
import * as _ from 'lodash';

import { StoreService, UserState, CompanyService, UserService } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';
import { AddressUtilsService } from './address-utils.service';
import { ContactUtilsService } from './contact-utils.service';
import { PromiseUtilsService } from './promise-utils.service';


@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private promiseUtils: PromiseUtilsService,
    private modalService: ModalService,
    private userState: UserState,
    private storeService: StoreService,
    private companyService: CompanyService,
    private userService: UserService,
    private addressUtils: AddressUtilsService,
    private contactUtils: ContactUtilsService
  ) { }

  _addressesAreIdentical(src, result) {
    var dest = {
      // Use Current Name for Comparison
      name: src.name,
      street: result.line1,
      unit: result.line2 && result.line2.length ? result.line2 : '',
      city: result.city,
      postalCode: result.postalCode,
      province: result.region,
      country: result.country
    };

    return this.addressUtils.addressesAreIdentical(src, dest);
  }

  validateAddress(addressObject) {
    addressObject.validationError = false;

    if (addressObject.country !== 'CA' && addressObject.country !== 'US') {
      console.debug('Address Validation skipped for country: ', addressObject.country);

      return Promise.resolve();
    } else {
      return this.storeService.validateAddress(addressObject)
        .then((result) => {
          if (!this._addressesAreIdentical(addressObject, result)) {
            console.error('Validated address differs from entered address: ', addressObject, result);
          }
        })
        .catch((result) => {
          addressObject.validationError = result.message ? result.message : 'Unknown Error';
        });
    }
  }

  isValidOrEmptyAddress(addressObject) {
    if (this.addressUtils.isEmptyAddress(addressObject)) {
      console.debug('Address is empty, skipped validation');
      return Promise.resolve();
    }
    if (addressObject.country !== 'CA' && addressObject.country !== 'US' && addressObject.country !== '') {
      console.debug('Address Validation skipped for country: ', addressObject.country);
      return Promise.resolve();
    }
    return this.storeService.validateAddress(addressObject);
  }

  validateAddressIfChanged(addressForm, addressObject) {
    if (this.addressUtils.isAddressFormDirty(addressForm)) {
      return this.isValidOrEmptyAddress(addressObject);
    } else {
      return Promise.resolve();
    }
  }

  _updateCompanySettings(company) {
    // update Selected company saved in userState
    var selectedCompany = this.userState.getCopyOfSelectedCompany(true);
    angular.extend(selectedCompany, company);

    // this will fire 'risevision.company.updated' event
    this.userState.updateCompanySettings(selectedCompany);
  }

  updateAddress(addressObject?, contact?) {
    var deferred = this.promiseUtils.generateDeferredPromise()
    var currentAddress = this.userState.getCopyOfSelectedCompany();

    var addressFields : any = {};
    var requiresUpdate = false;

    var billingContactEmails = currentAddress.billingContactEmails || [];
    var email = contact && contact.email;

    if (email && billingContactEmails.indexOf(email) === -1) {
      billingContactEmails.unshift(email);

      addressFields.billingContactEmails = billingContactEmails;

      requiresUpdate = true;
    }

    if (addressObject && !this.addressUtils.addressesAreIdentical(addressObject, currentAddress) ||
      requiresUpdate) {
      this.addressUtils.copyAddress(addressObject, addressFields);
      // Update shipping address for consistency
      this.addressUtils.copyAddressToShipTo(addressObject, addressFields);

      console.info('Company Fields changed. Saving...');

      this.companyService.updateCompany(addressFields.id, addressFields)
        .then(() => {
          this._updateCompanySettings(addressFields);

          console.info('Company Fields saved.');

          deferred.resolve();
        })
        .catch(() => {
          console.info('Error saving Company Fields.');
          deferred.reject('Error saving Company Fields.');
        });
    } else {
      deferred.resolve();
    }

    return deferred.promise;
  }

  updateContact(contact?) {
    var deferred = this.promiseUtils.generateDeferredPromise()
    var currentContact = this.userState.getCopyOfProfile();

    if (contact && !this.contactUtils.contactsAreIdentical(contact, currentContact)) {
      console.info('Contact information changed. Saving...');

      this.userService.updateUser(this.userState.getUsername(), contact)
        .then(() => {
          var profileCopyNoFollow = this.userState.getCopyOfProfile(true);
          this.contactUtils.copyContactObj(contact, profileCopyNoFollow);

          // this fires 'risevision.company.updated' event
          this.userState.updateUserProfile(profileCopyNoFollow);

          console.info('Contact information saved.');
          deferred.resolve();
        })
        .catch(() => {
          console.info('Error saving Contact information.');
          deferred.reject('Error saving Contact information.');
        });
    } else {
      deferred.resolve();
    }

    return deferred.promise;
  }

  confirmAndSetGeneralDelivery(addressObject?) {
    return this.modalService.confirm('Address Information',
        'The address you provided couldn\'t be validated. This can happen if the address does not exist in the USPS records. If you\'re sure the address is correct you can specify this address as General Delivery and we\'ll only validate the City, State and Zip Code.<br/>Would you like to specify this address as General Delivery?',
        'Yes',
        'Cancel')
      .then(() => {
        addressObject.unit = addressObject.unit ? addressObject.unit + ' - General Delivery' :
          'General Delivery';
      });
  }

}

angular.module('risevision.apps.services')
  .factory('addressFactory', downgradeInjectable(AddressService));
