import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss']
})
export class AuthFormComponent implements OnInit {

  @Input() credentials: any = {};
  @Input() isSignUp = false;
  @Input() ssoChecked = false;
  @Input() ssoEnabled = false;
  @Input() joinAccount = false;
  @Input() invalidRequest = false;
  @Input() usePassword = false;
  @Output() usePasswordChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() checkSso: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleInvalidRequest: EventEmitter<string> = new EventEmitter<string>();
  @Output() googleLogin: EventEmitter<void> = new EventEmitter<void>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  disableUsername = false;

  get passwordRequired() {
    return this.ssoChecked && (!this.ssoEnabled || this.usePassword);
  }

  @ViewChild('loginForm') loginForm: NgForm;

  EMAIL_REGEX = ValidationService.EMAIL_REGEX;

  ngOnInit() {
    this.disableUsername = this.credentials.username;
  }

  alternateLogin(): void {
    if (this.usePassword) {
      this.usePasswordChange.emit(false);
      this.loading.emit(true);
      setTimeout(() => {
        this.submitForm.emit(this.loginForm);
      });
    } else {
      this.usePasswordChange.emit(true);
    }
  }

  triggerGoogleLogin(): void {
    this.googleLogin.emit();
    this.loginForm.resetForm();
  }

  checkUsername(): void {
    if (this.passwordRequired) {
      this.checkSso.emit();
    }
  }

}
