<mat-calendar
  *ngIf="displayed"
  [headerComponent]="calendarMonthYearHeader"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [dateClass]="filterDate"
  [(selected)]="selectedDate"
  (selectedChange)="selectedDateChange.emit(selectedDate)"
  (pointerdown)="deselectDateDown($event)"
  (pointerup)="deselectDateUp($event)">
</mat-calendar>
