<div class="attribute-editor-component">
  <div class="attribute-editor-row playlist-item-selector pt-3">
    <ul class="flex-row flex-wrap">
      <li *ngFor="let component of playlistComponents" class="align-center">
        <a class="u_clickable pt-4 pb-3 u_lh-1" id="add-{{ component.type }}-button" (click)="addItem(component.type, component.options)" *requireRole="component.requireRole">
          <streamline-icon [name]="component.icon"></streamline-icon><br>
          <div class="pt-3 pb-1">{{ component.title }}</div>
          <small>{{ component.subtitle || '&nbsp;' }}</small>
        </a>
      </li>
    </ul>
  </div>
</div>
