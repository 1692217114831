import { Component, ElementRef } from '@angular/core';

import * as _ from 'lodash';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

import { ComponentsService } from '../../services/components.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';

@Component({
  selector: 'component-storage-selector',
  templateUrl: './storage-selector.component.html',
  styleUrls: ['./storage-selector.component.scss']
})
export class StorageSelectorComponent {

  public componentId: string;

  selectedItems = [];
  filterConfig = {
    placeholder: 'Search Rise Storage',
    id: 'componentStorageSearchInput'
  };
  search: any = {
    reverse: false
  };
  list = {
    folderItems: [],
    setFolderItems: (items?) => {
      items = items || [];

      this.list.folderItems = items;

      this.list.doSearch();
    },
    sortedItems: [],
    _filteredItems: [],
    doSearch: () => {
      if (!this.search.query) {
        this.list._filteredItems = this.list.folderItems;
      } else {
        this.list._filteredItems = _.filter(this.list.folderItems, (item) => {
          return item.name.toUpperCase().includes(this.search.query.toUpperCase());
        });
      }

      this.list.doSort();
    },
    doSort: () => {
      this.list.sortedItems = _.sortBy(this.list._filteredItems, this.search.sortBy);
      if (this.search.reverse) {
        this.list.sortedItems.reverse();
      }
    }

  };

  storageSelectorId = 'storage-selector';
  storageUploadManager;
  isUploading;
  fileType;
  currentFolder;

  folderType = StorageManagerService.FILE_TYPE.FOLDER;

  isFolder = this.templateEditorUtils.isFolder.bind(this.templateEditorUtils);
  fileNameOf = this.templateEditorUtils.fileNameOf.bind(this.templateEditorUtils);
  hasRegularFileItems() {
    return this.templateEditorUtils.hasRegularFileItems(this.list.folderItems, this.isFileType(StorageManagerService.FILE_TYPE.FOLDER));
  }

  isFileType(type) : boolean {
    return !!this.fileType && this.fileType.indexOf(type) !== -1;
  }

  get fileTypeName(): string {
    if (this.fileType === StorageManagerService.FILE_TYPE.IMAGE) {
      return 'Images';
    } else if (this.fileType === StorageManagerService.FILE_TYPE.VIDEO) {
      return 'Videos';
    } else if (this.isFileType(StorageManagerService.FILE_TYPE.IMAGE_VIDEO)) {
      return 'Images & Videos'
    } else {
      return 'Files';
    }
  }

  constructor(private elementRef: ElementRef,
    private componentsService: ComponentsService,
    private templateEditorUtils: TemplateEditorUtilsService,
    public storageManagerService: StorageManagerService,
    public currentPlanService: CurrentPlanService) {

    this.componentsService.registerDirective({
      type: 'rise-storage-selector',
      element: this.elementRef.nativeElement,
      show: () => {
        this.storageManagerService.isListView = true;

        this.fileType = this.storageManagerService.fileType;

        this.loadItems(this.storageUploadManager.folderPath);
      },
      onBackHandler: () => {
        var parts = this.storageUploadManager.folderPath.split('/');

        if (parts.length === 1) {
          return false;
        } else {
          // Since paths are of the 'folder/' form, the last item is the empty string
          parts.splice(parts.length - 2, 2);
          this.storageUploadManager.folderPath = parts.length > 0 ? parts.join('/') + '/' : '';
          this.loadItems(this.storageUploadManager.folderPath);

          return true;
        }
      }
    });

    this.storageUploadManager = {
      folderPath: '',
      onUploadStatus: (isUploading) => {
        this.isUploading = isUploading;
      },
      addFile: (file) => {
        templateEditorUtils.addOrReplaceAll(this.list.folderItems, {
          name: file.name
        }, file);

        this.list.doSearch();
      }
    };

    this.search.sortBy = this.fileNameOrderFunction;

  }

  _reset() {
    this.componentsService.resetPanelHeader();

    this.list.setFolderItems();
    this.selectedItems = [];
    this.search.selectAll = false;
    this.storageUploadManager.folderPath = '';
  }

  thumbnailFor(item) {
    if (item.metadata && item.metadata.thumbnail) {
      return item.metadata.thumbnail + '?_=' + (item.timeCreated && item.timeCreated.value);
    } else {
      return item.mediaLink;
    }
  }

  _handleNavigation(folderPath) {
    var folderName = this.templateEditorUtils.fileNameOf(folderPath);

    if (folderName) {
      this.componentsService.setPanelIcon('folder');
      this.componentsService.setPanelTitle(folderName);
    } else {
      this.componentsService.resetPanelHeader();
    }
  }

  loadItems(newFolderPath?) {
    this.currentFolder = this.templateEditorUtils.fileNameOf(newFolderPath);
    this._handleNavigation(newFolderPath);

    return this.storageManagerService.loadFiles(newFolderPath)
      .then((folderItems) => {
        this.list.setFolderItems(folderItems);

        this.selectedItems = [];
        this.search.selectAll = false;
        this.storageUploadManager.folderPath = newFolderPath;
      });
  }

  selectItem(item) {
    if (this.storageManagerService.isSingleFileSelector && this.storageManagerService.isSingleFileSelector()) {
      if (this.isSelected(item)) {
        this.selectedItems = [];
      } else {
        this.selectedItems = [item];
      }
    } else {
      this.templateEditorUtils.addOrRemove(this.selectedItems, {
        name: item.name
      }, item);
    }
  }

  selectAllItems = () => {
    this.search.selectAll = !this.search.selectAll;

    for (var i = 0; i < this.list.folderItems.length; ++i) {
      var item = this.list.folderItems[i];

      if (this.templateEditorUtils.isFolder(item.name)) {
        continue;
      }

      var idx = _.findIndex(this.selectedItems, {
        name: item.name
      });

      if (this.search.selectAll && this.list.sortedItems.indexOf(item) >= 0 && idx === -1) {
        this.selectedItems.push(item);
      } else if (!this.search.selectAll && idx >= 0) {
        this.selectedItems.splice(idx, 1);
      }
    }
  };

  isSelected(item) {
    return _.findIndex(this.selectedItems, {
      name: item.name
    }) >= 0;
  }

  addSelected = () => {
    if (this.storageManagerService.onSelectHandler) {
      this.componentsService.showPreviousPage();
      this.storageManagerService.onSelectHandler(this.selectedItems);

      this._reset();
    }
  };

  sortBy(cat) {
    if (cat !== this.search.sortBy) {
      this.search.sortBy = cat;
    } else {
      this.search.reverse = !this.search.reverse;
    }

    this.list.doSort();
  }

  dateModifiedOrderFunction(file) {
    return file.updated ? file.updated.value : '';
  }

  fileNameOrderFunction(file) {
    return file.name.toLowerCase().split(' (').join('/(');
  }

}
