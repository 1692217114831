import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserState, UserService, ProcessErrorCode, AjsState } from 'src/app/ajs-upgraded-providers';

import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent {

  user: any = {};
  loading = false;
  formError = null;
  apiError = null;

  isUserAdmin = this.userState.isUserAdmin();

  @ViewChild('userForm')
  public userForm: NgForm;

  EMAIL_REGEX = ValidationService.EMAIL_REGEX;

  constructor(private $state: AjsState,
    private userState: UserState,
    private userService: UserService,
    private tracker: TrackerService,
    private processErrorCode: ProcessErrorCode
  ) { }

  save() {
    if (!this.userForm.invalid) {
      let companyId = this.userState.getSelectedCompanyId();
      this.loading = true;
      this.userService.addUser(companyId, this.user.username, this.user)
        .then(() => {
          this.tracker.userEvent('User Created', this.userState.getUsername(), false, {
            invitedEmail: this.user.username
          });

          this.$state.go('apps.user.details', {
            username: this.user.username
          });
        })
        .catch((error) => {
          this.formError = 'User could not be added.';
          this.apiError = this.processErrorCode(error);
          if (error.code === 409) {
            this.apiError = `A User with the Username '${this.user.username}' already belongs
              to another Company. To add them to this Company, they must sign in
              and delete themselves from their current Company by opening their
              User Settings and clicking the Delete button.`;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }


}
