import { Injectable } from '@angular/core';

import { RiseAPILoader, UserService } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  constructor(private riseAPILoader: RiseAPILoader,
    private userService: UserService) { }

  _agreeToTerms() {
    console.debug('agreeToTerms called.');

    return this.riseAPILoader().then((riseApi) => {
      return riseApi.account.agreeToTerms();
    })
    .then((resp) => {
      console.debug('agreeToTerms resp', resp);

      return resp;
    })
    .catch((err) => {
      console.error('agreeToTerms failed', err);

      throw err;
    });
  }

  agreeToTermsAndUpdateUser(username, basicProfile) {
    console.debug('registerAccount called.', username, basicProfile);

    return this._agreeToTerms()
      .finally(() => {
        return this.userService.updateUser(username, basicProfile)
          .then((resp) => {
            if (resp.result) {
              return;
            } else {
              return Promise.reject();
            }
          })
          .finally('registerAccount ended');
      });
  }

  addAccount(userFirst, userLast, companyName, companyIndustry, mailSyncEnabled, refSource) {
    console.debug('addAccount called.');

    return this.riseAPILoader().then((riseApi) => {
      return riseApi.account.addWithDetails({
        userFirst: userFirst,
        userLast: userLast,
        companyName: companyName,
        companyIndustry: companyIndustry,
        mailSyncEnabled: mailSyncEnabled,
        refSource: refSource
      });
    })
    .then((resp) => {
      console.debug('addAccount resp', resp);

      return resp;
    })
    .catch((err) => {
      console.error('addAccount failed', err);

      throw err;
    });
  }

  getAccount() {
    console.debug('getAccount called.');

    return this.riseAPILoader().then((riseApi) => {
      return riseApi.account.get();
    })
    .then((resp) => {
      console.debug('getAccount resp', resp);

      return resp.result.item;
    })
    .catch((err) => {
      console.error('getAccount failed', err);

      throw err;
    });
  }

}