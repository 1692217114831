<div class="button-row text-right">
  <span class="visible-sm visible-xs text-right" [shown]="editorService.presentation.id">
    <last-revised [revisionStatusName]="editorService.presentation.revisionStatusName" [changeDate]="editorService.presentation.changeDate" [changedBy]="editorService.presentation.changedBy"></last-revised>
  </span>
  <div class="pull-left hidden-xs">
    <button id="designButton" class="btn btn-link pl-0" *ngIf="!artboardService.designMode" (click)="artboardService.showArtboard()">
      Design View
    </button>
    <button id="htmlButton" class="btn btn-link pl-0" *ngIf="artboardService.designMode" (click)="artboardService.showHtmlEditor()">
      HTML View
    </button>
  </div>

  <span class="hidden-sm hidden-xs save-status" [shown]="editorService.presentation.id">
    <last-revised [revisionStatusName]="editorService.presentation.revisionStatusName" [changeDate]="editorService.presentation.changeDate" [changedBy]="editorService.presentation.changedBy"></last-revised>
  </span>
  <button id="restoreButton" *requireRole="'cp ap'" class="btn btn-default btn-toolbar" [disabled]="!editorService.presentation.id || (!editorService.isRevised() && !editorService.hasUnsavedChanges) || editorService.savingPresentation" (click)="editorService.confirmRestorePresentation()">Restore</button>

  <!-- Indicates a successful or positive action -->
  <button id="saveButton" *requireRole="'ce cp ap'" type="submit" class="btn btn-default btn-toolbar" (click)="editorService.save()" [disabled]="editorService.savingPresentation">
    {{ editorService.savingPresentation ? 'Saving' : 'Save' }}
  </button>

  <button id="publishButton" *requireRole="'cp ap'" class="btn btn-primary btn-toolbar" [disabled]="editorService.hasUnsavedChanges || !editorService.isRevised() || editorService.savingPresentation" (click)="editorService.publishPresentation()">Publish</button>
</div>
