import { Component, Input, Output, EventEmitter } from '@angular/core';

import { UserState } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { GoogleApiOAuthService } from 'src/app/components/content-oauth/services/google-api-oauth.service';
import { GooglePhotosService } from '../services/google-photos.service';
import { TemplateEditorService } from '../../services/template-editor.service';

const GOOGLE_PHOTOS_SCOPE = 'https://www.googleapis.com/auth/photoslibrary.readonly';

@Component({
  selector: 'google-photos',
  templateUrl: './google-photos.component.html',
  styleUrls: ['./google-photos.component.scss']
})
export class GooglePhotosComponent {

  @Input() values: any;
  @Output() valuesChange: EventEmitter<any> = new EventEmitter<any>();

  componentId;

  userAccount = null;
  componentAccount = null;
  componentAccountUsername = null;

  get sameAccount(): boolean {
    return !!this.userAccount && this.userAccount === this.componentAccount;
  }

  spinner = false;
  revokeFailed = false;
  albumName = '';

  albums = [];

  constructor(
    private modalService: ModalService,
    private userState: UserState,
    private attributeDataService: AttributeDataService,
    private googleApiOAuthService: GoogleApiOAuthService,
    private googlePhotosService: GooglePhotosService,
    private templateEditorService: TemplateEditorService
  ) { }

  _reset() {
    this.userAccount = null;
    this.componentAccount = null;

    this.spinner = false;
    this.revokeFailed = false;

    this.albums = [];
  }

  init(componentId): void {
    this._reset();

    this.componentId = componentId;

    if (this.values.type === 'google-photos-album') {
      this.componentAccount = this.attributeDataService.getAttributeData(this.componentId, 'account');

      this._validateGoogleCredentials();
      this.albumName = this.attributeDataService.getAttributeData(this.componentId, 'name') || this.values.source || 'No Album Selected';
    }
  }

  saveSource() {
    this.attributeDataService.setAttributeData(this.componentId, 'source', this.values.source);

    let name = '';
    if (this.values.source === 'all-photos') {
      name = 'All Photos';
    } else if (this.values.source) {
      const selected = this.albums.find(album => album.id === this.values.source);
      if (selected) {
        name = selected.title;
      }
    }
    this.attributeDataService.setAttributeData(this.componentId, 'name', name);
    this.albumName = name;

    this.valuesChange.emit(this.values);
  }

  _resetSource() {
    this.values.source = '';
    this.saveSource();
  }

  saveAccount(account) {
    this.componentAccount = account;

    this.attributeDataService.setAttributeData(this.componentId, 'account', account);
  }

  private _validateGoogleCredentials() {
    this.spinner = true;

    this._getcomponentAccountUsername()
    .then((result:any) => {
      this.componentAccountUsername = result.username;
      return this.googleApiOAuthService.getConnectionStatus(GOOGLE_PHOTOS_SCOPE);
    })
    .then(() => {
        this.userAccount = this.googleApiOAuthService.getUserIdentifier();

        if (!this.componentAccount) {
          this.saveAccount(this.userAccount);
        }

        return this._populateAlbumsList();
      })
      .catch(() => {
        console.log('Photos Account not connected');
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  private _populateAlbumsList() {
    if (!this.sameAccount) {
      return;
    }

    this.googlePhotosService.getAlbums(this.userAccount)
      .then((albums: any) => {
        this.albums = albums;
        console.log("Album list", this.albums);
      })
      .catch((err) => {
        console.log('Failed to get album list', err);
      });
  }

  _changeAccount() {
    this._resetAccount(false);

    this.userAccount = this.googleApiOAuthService.getUserIdentifier();

    this.saveAccount(this.userAccount);

    this._getcomponentAccountUsername()
    .then((result:any) => {
      this.componentAccountUsername = result.username;
    })
    .catch( err => {
      console.log(err);
    })

    this._populateAlbumsList();
  }

  _connectAccount() {
    this.spinner = true;

    return this.googleApiOAuthService.authenticate(GOOGLE_PHOTOS_SCOPE)
      .then((result) => {
        this.userAccount = this.googleApiOAuthService.getUserIdentifier();
        this.componentAccountUsername = result.username;

        this._resetSource();
        this.saveAccount(this.userAccount);

        return this._populateAlbumsList();
      })
      .catch((err) => {
        console.log('Failed to connect to photos', err);
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  confirmConnect() {
    this.modalService.confirm('Connect to Google Photos', 'Allow Rise Vision to View your Google Photos library')
      .then(() => {
        this._connectAccount();
      })
      .catch(() => {});
  }

  _revokeAccount() {
    return this.googlePhotosService.revoke(this.googleApiOAuthService.getUserIdentifier())
      .then((revoked: boolean) => {
        this.userAccount = null;

        if (!revoked) {
          console.log('Token could not be revoked');

          this.revokeFailed = true;
        } else if (!this.userState.isRiseAuthUser()) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log('Failed to revoke account', err.message);
      });
  }

  _resetAccount(revoke) {
    this.albums = [];

    this._resetSource();

    this.saveAccount(null);

    if (!revoke) {
      return;
    }

    this.spinner = true;

    this.templateEditorService.hasUnsavedChanges = true;

    this.templateEditorService.save()
      .then(() => {
        return this._revokeAccount();
      })
      .catch((err) => {
        console.log('Failed to save presentation', err.message);
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  _getcomponentAccountUsername() {
    if (!this.componentAccount) {
      return Promise.resolve({username: ''});
    }

    return this.googlePhotosService.getUsername(this.componentAccount)
      .catch((err) => {
        return Promise.resolve({username: ''});
      });
  }

  confirmDisconnect() {
    let disconnectMessage = 'Any content that is using this Google Photos account will stop working.';

    if (!this.userState.isRiseAuthUser()) {
      disconnectMessage += ' If this account is the same as the one you use to Sign In to Rise Vision,'
        + ' it will also log you out of the application. You will be prompted to Sign In again if that happens.';
    }

    this.modalService.confirm('Disconnect from Google Photos', disconnectMessage)
      .then(() => {
        this._resetAccount(true);
      })
      .catch(() => {});
  }

  confirmChange() {
    this.modalService.confirm('Connect to Google Photos', 'Allow Rise Vision to View your Google Photos library')
    .then(() => {
      this._changeAccount();
    })
    .catch(() => {});
  }

}
