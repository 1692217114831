import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent extends ModalComponent {

  title: string;
  message: string;
  cancelButton: string;
  confirmButton: string;
  confirmButtonClass = 'btn-primary';
  
  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  confirm() {
    super.resolve();
  }

  close() {
    super.reject()
  }

}
