import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserState, CompanyService, ScrollingListService } from 'src/app/ajs-upgraded-providers';

import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent extends ModalComponent {

  filterConfig = {
    placeholder: 'Search Companies'
  };

  allCompanies = true;
  search = {
    companyId: this.userState.getSelectedCompanyId(),
    sortBy: 'name',
    reverse: false,
    name: 'Companies',
    includeSubcompanies: this.allCompanies
  }

  selectedCompanyId = this.userState.getSelectedCompanyId();

  companies = this.ScrollingListService(this.companyService.getCompanies, this.search);

  constructor(public modalRef: BsModalRef,
    private userState: UserState,
    private ScrollingListService: ScrollingListService,
    private companyService: CompanyService
  ) {
    super(modalRef);
  }

  closeModal() {
    super.resolve();
  }

  setCompany(company) {
    this.userState.switchCompany(company.id);

    this.closeModal();
  }

  toggleAllCompanies(value) {
    this.allCompanies = value;

    this.search.includeSubcompanies = value;
    this.companies.doSearch();
  }

}
