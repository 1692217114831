import { Component, OnInit, NgZone } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { StorageUtilsService } from '../../services/storage-utils.service';

@Component({
  selector: 'rename-file-modal',
  templateUrl: './rename-file-modal.component.html',
  styleUrls: ['./rename-file-modal.component.scss']
})
export class RenameFileModalComponent extends ModalComponent implements OnInit {

  selectedFile!: any;
  renameObject!: any;

  renameName: string = '';
  parentPath: string = '';
  extension: string = '';
  isProcessing: boolean = false;
  errorMessage: string = '';

  get validDestination() {
    return this.renameName && this.renameName.indexOf('/') === -1;
  }

  constructor (
    public modalRef: BsModalRef,
    private storageUtilsService: StorageUtilsService,
    private ngZone: NgZone
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.renameName = this.storageUtilsService.fileName(this.selectedFile).replace('/', '');
    this.parentPath = this.storageUtilsService.fileParent(this.selectedFile);
    const extensionIndex = this.renameName.lastIndexOf('.');
    if (extensionIndex >= 0) {
      this.extension = this.renameName.substring(extensionIndex);
      this.renameName = this.renameName.substring(0, extensionIndex);
    }
  }

  update () {
    this.ngZone.run(() => {});
  }

  ok () {
    this.errorMessage = '';
    this.isProcessing = true;

    let renameTo = this.renameName;
    if (this.extension) {
      renameTo += this.extension;
    }

    return this.renameObject(this.selectedFile, this.parentPath + renameTo)
      .then((resp) => {
        console.debug('Storage rename processed succesfully');

        super.resolve(resp);
      }, (e) => {
        if (e == 'cancel') {
          super.reject('cancel');
        } else {
          console.error('Error renaming \'' + this.selectedFile.name + '\' to \'' +
            renameTo + '\'', e);

          this.errorMessage = StorageUtilsService.STORAGE_CLIENT_ERROR.rename[
            (e && e.result && e.result.error && e.result.error.message) || 'unknown'
          ];
        }
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  cancel () {
    super.reject('cancel');
  }
}
