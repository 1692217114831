<div [shown]="taxExemption.show && !taxExemption.sent">
  <form #taxExemptionForm="ngForm" (ngSubmit)="submit()" id="form.taxExemptionForm" role="form" name="form.taxExemptionForm" novalidate>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group" [ngClass]="{ 'has-error': isFieldInvalid('number') }">
          <label for="number" class="control-label">Tax Exemption Number: *</label>
          <input #number name="number" id="number" type="text" class="form-control" [(ngModel)]="taxExemption.number" tabindex="1" maxlength="25" required>
          <span class="text-danger" [shown]="isFieldInvalid('number')">
            Choose a Tax Exemption Number.
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="form-group mb-0">
          <label for="fileName" class="control-label">Tax Exemption Document (Image or PDF only): *</label>
          <input #inputExemption id="inputExemption" name="inputExemption" type="file" accept="application/pdf,image/*" (change)="setFile()" style="display:none">
          <div class="flex-row" [shown]="taxExemption.file?.name">
            <div class="u_ellipsis">{{taxExemption.file?.name}}</div>
            <div class="ml-auto"><a aria-label="Remove Tax Exemption File" class="madero-link u_clickable text-danger font-weight-bold" (click)="removeFile()" tabindex="1">Remove</a></div>
          </div>
          <button name="selectFile" id="selectFile" type="button" (click)="selectFile()" aria-label="Attach Tax Exemption File" tabindex="1" class="btn btn-default btn-block" [shown]="!taxExemption.file?.name">Attach File</button>
        </div>
      </div>
    </div>

    <div class="madero-style alert alert-danger u_margin-md-top" [shown]="taxExemption.error">
      {{taxExemption.error}}
    </div>

    <div *ngIf="!showCancel">
      <button id="submitButton" type="submit" form="form.taxExemptionForm" aria-label="Submit Tax Exemption" tabindex="1" class="btn btn-default btn-block u_margin-md-top" [shown]="taxExemption.file?.name">Submit Tax Exemption</button>
    </div>

    <div class="flex-row u_margin-md-top" *ngIf="showCancel">
      <button id="cancelButton" type="button" (click)="taxExemption.show = false" aria-label="Cancel" tabindex="2" class="btn btn-default flex-1 mr-3">Cancel</button>
      <button id="applyButton" type="submit" form="form.taxExemptionForm" aria-label="Apply Tax Exemption" tabindex="1" class="btn btn-primary flex-1" [disabled]="!taxExemption.file?.name">Apply</button>
    </div>

  </form>
</div>
