<ng-template #treeNode let-level let-levels="levels", let-last="last", let-visible="visible">
  <div>
    <div>
      <div [ngClass]="{ 'tree-node-branch': level === levels, 'tree-node-skip': level !== levels && visible }">&nbsp;</div>
      <div [ngClass]="{ 'tree-node-skip': !last && level === levels || level !== levels && visible }">&nbsp;</div>
    </div>
  </div>
</ng-template>

<td [ngClass]="{ 'tree-node-cell': !root, 'table-body__cell align-center': root }">
  <div *ngIf="root">
    <streamline-icon name="hierarchy" width="16" height="16"></streamline-icon>
  </div>
  <div *ngIf="!root">
    <div>&nbsp;</div>
    <div>
      <div [ngClass]="{ 'tree-node-branch': company.level < 1, 'tree-node-skip': company.level > 0, 'tree-node-last': last && company.level > 0 }">&nbsp;</div>
      <div class="tree-node-skip" [ngClass]="{ 'tree-node-last': last }">&nbsp;</div>
    </div>
  </div>
</td>
<td class="table-body__cell pl-1">
  <div class="flex-row">
    <div *ngIf="company.inset" class="tree-node-spacer"></div>
    <div *ngFor="let visible of company.lines; let i = index" class="tree-node-inner">
      <ng-container *ngTemplateOutlet="treeNode; context: { $implicit: i + 1, levels: company.level, last: company.last, visible: visible }"></ng-container>
    </div>
    <div class="flex-row">
      <streamline-icon *ngIf="company.level < 0" class="mr-2 flex-row" name="ellipsis" width="14" height="14"></streamline-icon>
      <i *ngIf="company.hasSubs" class="tree-node-toggle mr-1 u_clickable fa" [ngClass]="company.showSubs ? 'fa-caret-down' : 'fa-caret-right'" (click)="company.showSubs = !company.showSubs"></i>
      <p *ngIf="root" class="list-group-item-text">{{ company.name }}</p>
      <a *ngIf="!root" class="madero-link font-weight-bold u_clickable flex-grow u_ellipsis" (click)="selectCompany.emit(company.id)">{{company.name}}</a>
    </div>
  </div>
</td>
<td class="table-body__cell">
  <mat-form-field color="accent" appearance="outline" subscriptSizing="dynamic" style="width: 150px;">
    <mat-select
      [name]="'license-type-' + company.id"
      [ngModel]="companyAllocationService.getLicenseType(company, licenseAllocationEnabled)"
      (selectionChange)="companyAllocationService.allocateType(company, $event.value)"
      [disabled]="companyAllocationService.getLicenseType(company, licenseAllocationEnabled) === 'managed' || !licenseAllocationEnabled">
      <mat-option value="">None</mat-option>
      <mat-option value="enterprise">Per-Display</mat-option>
      <mat-option value="unlimited">Unlimited</mat-option>
      <mat-option value="managed" *ngIf="companyAllocationService.getLicenseType(company, licenseAllocationEnabled) === 'managed'">Self-Managed</mat-option>
      <mat-option value="shared" *ngIf="!licenseAllocationEnabled">Shared</mat-option>
    </mat-select>
  </mat-form-field>
</td>
<td class="table-body__cell align-center">
  <number-input
    [value]="companyAllocationService.getLicenseType(company, licenseAllocationEnabled) === 'shared' ? 0 : company.allocatedLicenseCount"
    [name]="'license-count-' + company.id"
    (updateValue)="updateCount($event)"
    [disabled]="companyAllocationService.getLicenseType(company, licenseAllocationEnabled) !== 'enterprise'"
    [min]="companyAllocationService.getLicenseType(company, licenseAllocationEnabled) === 'enterprise' ? 1 : 0"
    step="1">
  </number-input>
</td>
<td class="table-body__cell align-center">{{company.displayCount || 0}}</td>