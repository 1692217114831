<div class="border-container mx-auto">
  <div class="panel-body">

    <h4>Password Confirmation</h4>

    <!-- CUSTOM LOGIN -->
    <div>
      <!-- ERROR PANEL 1 -->
      <div class="panel-body bg-info u_margin-lg-top" [shown]="emailResetSent">
        <p class="u_remove-bottom">
          <i class="fa fa-warning icon-left"></i>
          <span>An email with password reset instructions has been sent to your email inbox.</span>
        </p>
      </div>
      <!-- ERROR PANEL 2 -->
      <div class="panel-body bg-danger u_margin-lg-top" [shown]="invalidToken">
        <p class="u_remove-bottom"><i class="fa fa-warning icon-left"></i>
          <span>The password reset token is not valid. <a href="#" (click)="requestPasswordReset()">Request Password Reset</a></span>
        </p>
      </div>
    </div>

    <form #resetPasswordForm="ngForm" id="forms.resetPasswordForm" role="form" name="forms.resetPasswordForm" novalidate [shown]="!emailResetSent">
      <div class="u_margin-md-top">

        <password-input
          #newPassword="ngModel"
          name="newPassword"
          label="Create a new password:"
          [(ngModel)]="credentials.newPassword"
          [showPasswordMeter]="true" minlength="8" required>
        </password-input>

        <button id="resetPasswordButton" type="submit" [disabled]="resetPasswordForm.invalid" form="forms.resetPasswordForm" class="btn btn-primary btn-hg btn-block" (click)="resetPassword()">
          Update Password
        </button>
      </div>
    </form>

  </div>
</div><!--panel-->
