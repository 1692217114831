import { Injectable } from '@angular/core';

import { StorageApiService } from 'src/app/ajs-upgraded-providers';
import { StorageUtilsService } from './storage-utils.service';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {
  public static readonly FILE_TYPE = {
    IMAGE: 'image',
    VIDEO: 'video',
    FOLDER: 'folder',
    IMAGE_VIDEO: 'image,video',
    IMAGE_VIDEO_FOLDER: 'image,video,folder',
    DOCUMENT: 'document'
  }

  public static readonly SUPPORTED_IMAGE_TYPES = '.bmp, .gif, .jpeg, .jpg, .png, .svg, .webp';
  public static readonly SUPPORTED_VIDEO_TYPES = '.mp4, .webm';
  public static readonly SUPPORTED_IMAGE_VIDEO_TYPES = '.bmp, .gif, .jpeg, .jpg, .png, .svg, .webp, .mp4, .webm';
  public static readonly SUPPORTED_DOCUMENT_TYPES = '.docx, .pdf, .pptx, .xlsx';

  public static readonly CANVA_FOLDER = 'canva/';

  public onSelectHandler: Function;
  public isSingleFileSelector: Function;

  public fileType;
  public loadingFiles;
  isListView = true;
  folderItems = [];
  constructor(
    private storageApiService: StorageApiService,
    private storageUtils: StorageUtilsService) { }

    fileHasValidExtension(file: string, extensions?: string[]): boolean {
      return !extensions || extensions.length === 0 || extensions.some((extension) => {
        return file.toLowerCase().endsWith(extension.trim().toLowerCase());
      });
    }

    getValidExtensions(fileType: string): string {
      if (fileType === StorageManagerService.FILE_TYPE.IMAGE) {
        return StorageManagerService.SUPPORTED_IMAGE_TYPES;
      } else if (fileType === StorageManagerService.FILE_TYPE.VIDEO) {
        return StorageManagerService.SUPPORTED_VIDEO_TYPES;
      } else if (fileType && fileType.indexOf(StorageManagerService.FILE_TYPE.IMAGE_VIDEO) !== -1) {
        return StorageManagerService.SUPPORTED_IMAGE_VIDEO_TYPES;
      } else if (fileType === StorageManagerService.FILE_TYPE.DOCUMENT) {
        return StorageManagerService.SUPPORTED_DOCUMENT_TYPES;
      } else {
        return '';
      }
    }

    getValidExtensionsList(fileType: string): string[] {
      const validExtensions = this.getValidExtensions(fileType);

      return validExtensions ? validExtensions
        .split(',')
        .map(extension => {
          return extension.trim();
        }) : [];
    }

    getFileType(file: string): string {
      if (file && this.fileHasValidExtension(file, this.getValidExtensionsList(StorageManagerService.FILE_TYPE.IMAGE))) {
        return StorageManagerService.FILE_TYPE.IMAGE;
      } else if (file && this.fileHasValidExtension(file, this.getValidExtensionsList(StorageManagerService.FILE_TYPE.VIDEO))) {
        return StorageManagerService.FILE_TYPE.VIDEO;
      } else if (file && this.fileHasValidExtension(file, this.getValidExtensionsList(StorageManagerService.FILE_TYPE.DOCUMENT))) {
        return StorageManagerService.FILE_TYPE.DOCUMENT;
      } else {
        return '';
      }
    }

    loadFiles(folderPath: string): Promise<any> {
      this.loadingFiles = true;

      return this.storageApiService.files.get({
          folderPath: folderPath
        })
        .then((items) => {
          if (items.files) {
            let validExtensions = this.getValidExtensionsList(this.fileType);

            this.folderItems = items.files.filter((item) => {
              let isValid = false;

              if (this.fileType !== StorageManagerService.FILE_TYPE.FOLDER) {
                isValid = this.fileHasValidExtension(item.name, validExtensions);
              }

              return item.name !== folderPath && (this.storageUtils.fileIsFolder(item) || isValid);
            });
          } else {
            this.folderItems = [];
          }

          return this.folderItems;
        })
        .catch( (err) => {
          console.log('Failed to load files', err);
        })
        .finally( () => {
          this.loadingFiles = false;
        });
    }
}
