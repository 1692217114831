

<div rvSpinner="request-password-reset" [rvShowSpinner]="loading">
  <div class="border-container mx-auto">
    <div class="panel-body">

      <h4>Password Reset</h4>
      <!-- CUSTOM LOGIN -->

      <!-- ERROR PANEL -->
      <div class="panel-body bg-info u_margin-lg-top" [shown]="emailSent">
        <p class="u_remove-bottom">
          <i class="fa fa-warning icon-left"></i>
          <span>An email with password reset instructions has been sent to your email inbox (if it exists in our system).</span>
        </p>
      </div>
      <div class="panel-body bg-info u_margin-lg-top" [shown]="termsNotAccepted">
        <p class="u_remove-bottom">
          <i class="fa fa-warning icon-left"></i>
          <span>Your account has not been activated yet.<br><br>The activation email has been resent. Please use the link in the email to activate your account and create a password.</span>
        </p>
      </div>
      <div class="panel-body bg-danger u_margin-lg-top" [shown]="isGoogleAccount">
        <p class="u_remove-bottom">
          <i class="fa fa-warning icon-left"></i>
          <span>This account is authenticated by Google.<br/>
          <a href="https://myaccount.google.com/security#signin" target="_blank">Change your password on your Google account.</a></span>
        </p>
      </div>
      <div class="panel-body bg-danger u_margin-lg-top" [shown]="isSsoAccount">
        <p class="u_remove-bottom">
          <i class="fa fa-warning icon-left"></i>
          <span>Single Sign-On is enabled on your account.<br><br>Contact your system administrator to change your password.</span>
        </p>
      </div>

      <form #requestResetForm="ngForm" id="forms.requestResetForm" role="form" name="forms.requestResetForm" novalidate [shown]="!emailSent && !termsNotAccepted">
        <div class="u_margin-md-top">
          <div class="form-group" [ngClass]="{'has-error': (requestResetForm.submitted && username.invalid)}" show-errors>
            <label class="control-label">Email:</label>
            <input #username="ngModel" type="text" class="form-control" name="username" [(ngModel)]="credentials.username" placeholder="example@email.com" required focus-me="true">
            <p class="text-danger" [shown]="requestResetForm.submitted && username.invalid">
              Please enter an Email
            </p>
          </div>

          <button class="btn btn-primary btn-hg btn-block" [disabled]="requestResetForm.invalid" (click)="requestPasswordReset()">Email Me Reset Instructions</button>
        </div>
      </form>

    </div>
  </div><!--panel-->

  <div class="align-center u_margin-md-top">
    <a class="madero-link" id="sign-in-link" uiSref="common.auth.unauthorized">Go back to the sign in page</a>.
  </div>
</div>