import { Component, OnDestroy, NgZone } from '@angular/core';

import { UserState, ScrollingListService, DisplayFactory } from 'src/app/ajs-upgraded-providers';
import { DisplayListOperationsService } from 'src/app/displays/services/display-list-operations.service';

import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { DisplayApiService } from 'src/app/components/distribution/services/display-api.service';

@Component({
  selector: 'display-list',
  templateUrl: './display-list.component.html',
  styleUrls: ['./display-list.component.scss']
})
export class DisplayListComponent implements OnDestroy {

  search: any = {
    sortBy: 'name',
    reverse: false,
    name: 'Displays'
  };

  listOperations = this.displayListOperationsService.listOperations;
  displays = this.ScrollingListService(this.displayApiService.list.bind(this.displayApiService), this.search, this.listOperations);

  allCompanies;

  selectedCompayId = this.userState.getSelectedCompanyId();
  scheduleOverrides = '';

  filterConfig = {
    placeholder: 'Search Displays'
  };

  private subscription: any;

  constructor(private broadcaster: BroadcasterService,
    private ScrollingListService: ScrollingListService,
    public userState: UserState,
    private displayApiService: DisplayApiService,
    public displayFactory: DisplayFactory,
    private displayListOperationsService: DisplayListOperationsService,
    private ngZone: NgZone)
  {

    this.subscription = this.broadcaster.subscribe({
      next: (event: String) => {
        switch (event) {
        case 'risevision.company.planStarted':
          // use doSearch because it clears the list
          this.displays.doSearch();
          break;
        default:
          return;
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleAllCompanies(value) {
    this.allCompanies = value;

    this.search.includeSubcompanies = value;
    this.displays.doSearch();
  }

  getLicenseStatus(display) {
    if (display && display.playerProAuthorized) {
      return 'Yes';
    } else if (display && !display.playerProAuthorized && display.isFreeForURLs) {
      return 'Free';
    } else {
      return 'No';
    }
  }

  refresh () {
    setTimeout(() => {
      this.ngZone.run(() => {});
    });
  }

  showPopover (overrideNames) {
    this.scheduleOverrides = overrideNames.join('<br>');
    this.refresh();
  }

}