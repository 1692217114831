import { Component, ElementRef } from '@angular/core';

import { ScheduleSelectorFactory } from 'src/app/ajs-upgraded-providers';

import { ComponentsService } from '../../services/components.service';

@Component({
  selector: 'template-component-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss']
})
export class SchedulesComponent {

  public componentId: string;

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    public scheduleSelectorFactory: ScheduleSelectorFactory) {

    this.componentsFactory.registerDirective({
      type: 'rise-schedules',
      element: this.elementRef.nativeElement
    });
  }

}
