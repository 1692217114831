<div rvSpinner="move-company-modal" [rvShowSpinner]="loading">
<div class="modal-header">
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()">
    <streamline-icon name="close" width="10" height="10"></streamline-icon>
  </button>
  <h4 id="move-company-label" class="modal-title">Move Company</h4>
</div>
<div class="modal-body move-company-modal text-left">
  <form role="form">
    <div class="form-group">
      <label for="auth-key">
        Enter the Authentication Key of the Company that you want to move.
      </label>
      <div class="row">
        <div class="col-sm-6">
          <input id="auth-key" name="authKeyInput" type="text" class="form-control"
          [(ngModel)]="company.authKey" />
        </div>
        <div class="col-sm-6">
          <button class="btn btn-default btn-block retrieve-company-details-button mt-3 mt-md-0"
          type="submit"
          [attr.disabled]="company.authKey ? null : true"
          (click)="getCompany()">Retrieve Company Details</button>
        </div>
      </div>
    </div>
  </form>
  <div [shown]="company.name" class="company-details-info">
    <h5>Move <b>{{company.name}}</b> to <b>{{selectedCompany.name}}</b></h5>
    <div class="flex-row">
      <div class="border-container w-50 p-3">
        {{company.name}}<br>
        {{company.address}}
        {{company.city}}, {{company.province}}, 
        {{company.country}} {{company.postalCode}}
      </div>
      <div class="mx-2">→</div>
      <div class="to-company border-container w-50 p-3">
        {{selectedCompany.name}}<br>
        {{selectedCompany.address}}
        {{selectedCompany.city}}, {{selectedCompany.province}},
        {{selectedCompany.country}} {{selectedCompany.postalCode}}
      </div>
    </div>
  </div>
  <div [shown]="errors.length > 0">
    <div class="alert alert-danger mt-4 mb-1" *ngFor="let error of errors">
      {{error}}
    </div>
  </div>
  <div [shown]="messages.length > 0">
    <div class="alert alert-success mt-4 mb-1" *ngFor="let message of messages">
      {{message}}
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-toolbar close-move-company-button" data-dismiss="modal" (click)="closeModal()">
    {{dismissButtonText}}
  </button>
  <button type="button"
    class="btn btn-success btn-toolbar move-company-button"
    [shown]="company.name && !moveSuccess" (click)="moveCompany()">
    Move Company
  </button>
</div>
