import { Component } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { UserState } from 'src/app/ajs-upgraded-providers';

@Component({
  selector: 'change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent {

  isUnlimitedPlan = this.userState.isK12Customer();
  subscriptionId = '';
  planType = '';
  selectedProductCode = '';
  periodMonthly = false;
  displayCount = 1;

  constructor(
    private transition: Transition,
    private userState: UserState
  ) {
    this.subscriptionId = this.transition.params().subscriptionId || '';
    this.planType = this.transition.params().planType || '';
    this.displayCount = this.transition.params().displayCount;
  }

  updateProductCode(newCode: string) {
    this.selectedProductCode = newCode;
  }

  updatePlan() {
    this.transition.router.stateService.go('apps.purchase.update-plan', {
      subscriptionId: this.subscriptionId,
      productCode: this.selectedProductCode,
      monthly: this.periodMonthly
    });
  }

  cancel () {
    this.transition.router.stateService.go(
      this.transition.from(),
      this.transition.params('from')
    );
  }

}
