import { Injectable } from '@angular/core';
import { CompanyService } from 'src/app/ajs-upgraded-providers';
import type { CompanyTreeItem } from './company';

@Injectable({
  providedIn: 'root'
})
export class CompanyManagementService {

  constructor(
    private companyService: CompanyService
  ) { }

  private findParentCompany(companyTree: CompanyTreeItem[], subcompany: CompanyTreeItem): number {
    for (const [i, company] of companyTree.entries()) {
      if (company.id === subcompany.parentId) {
        return i;
      }
    }
    return -1;
  }

  private findNextCompany(companyTree: CompanyTreeItem[], parentIndex: number): number {
    const level = companyTree[parentIndex].level;
    for (let i = parentIndex + 1; i < companyTree.length; i++) {
      if (companyTree[i].level <= level) {
        return i;
      }
    }
    return companyTree.length;
  }

  createCompanyTree(tree: CompanyTreeItem[], companies: CompanyTreeItem[]): void {
    for (const company of companies) {
      company.level = 0;

      tree.push(company);
    }
  }

  updateCompanyTree(companyId: string, companyTree: CompanyTreeItem[], subcompanies: CompanyTreeItem[]): void {
    const remaining = subcompanies.length;
    subcompanies = subcompanies.filter(subcompany => {
      const parent = this.findParentCompany(companyTree, subcompany);
      if (parent >= 0) {
        const next = this.findNextCompany(companyTree, parent);
        companyTree[parent].hasSubs = true;
        companyTree[parent].showSubs = false;
        subcompany.parent = companyTree[parent];
        subcompany.level = Math.max(companyTree[parent].level, 0) + 1;

        // Each array item indicates whether the branch continuation line is visible at that level.
        subcompany.lines = Array(subcompany.level).fill(true);
        subcompany.inset = companyTree[parent].level < 0;
        subcompany.last = true;

        for (let i = next - 1; i > parent; i--) {
          if (companyTree[i].level === subcompany.level) {
            if (companyTree[i].last) {
              if (i < next - 1) {
                for (let j = i + 1; j < next; j++) {
                  // Restore the branch continuation line as the parent is no longer last at this level
                  companyTree[j].lines[subcompany.level - 1] = true;
                }
              }
              companyTree[i].last = false;
            }
            break;
          }
        }

        for (let i = subcompany.parent.level - 1, p = parent; i >= 0; i--) {
          if (p < 0 || companyTree[p].last) {
            // Hide the branch continuation line since the parent is the last item at this level
            subcompany.lines[i] = false;
          }
          p = this.findParentCompany(companyTree, companyTree[p]);
        }

        companyTree.splice(next, 0, subcompany);
        return false;
      }
      return true;
    });

    if (subcompanies.length !== remaining) {
      // Additional subcompanies that couldn't be added (perhaps they were returned ahead of their parent)
      this.updateCompanyTree(companyId, companyTree, subcompanies);

    } else if (remaining > 0) {
      // Orphaned items (parent may have been filtered out due to search)
      subcompanies = subcompanies.filter(subcompany => {
        const subparent = subcompanies.find(sub => sub.id === subcompany.parentId);
        if (subparent === undefined) {
          subcompany.level = -1;
          companyTree.push(subcompany);
          return false;
        }
        return true;
      });

      if (subcompanies.length !== remaining) {
        // Continue processing (e.g. subcompanies of orphaned companies)
        this.updateCompanyTree(companyId, companyTree, subcompanies);
      }
    }

  }

  loadSubcompanyTree(companyId: string, subcompanyIds: string, companyTree: CompanyTreeItem[], cursor?: string): Promise<void> {
    return this.companyService.getCompanies({
      filter: `hierarchy:= [${subcompanyIds}]`,
      includeSubcompanies: true,
      sortBy: 'name',
      name: 'Companies'
    }, cursor).then((result) => {
      const subtree = [];
      this.createCompanyTree(subtree, result.items);
      this.updateCompanyTree(companyId, companyTree, subtree);
      if (result.cursor) {
        return this.loadSubcompanyTree(companyId, subcompanyIds, companyTree, result.cursor);
      }
    });
  }
}
