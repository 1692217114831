import { Injectable, NgZone } from '@angular/core';

import * as _ from 'lodash';

import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { CardDescriptionPipe } from 'src/app/purchase/pipes/card-description.pipe';
import { BillingApiService } from './billing-api.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  item: any = {};

  loading;
  apiError;

  constructor(private ngZone: NgZone,
    private modalService: ModalService,
    private billingApiService: BillingApiService,
    private currentPlanService: CurrentPlanService,
    private processErrorCode: ProcessErrorCode,
    private cardDescriptionPipe: CardDescriptionPipe) { }

  _clearMessages() {
    this.loading = false;

    this.apiError = '';
  }

  getItemSubscription() {
    return this.item && this.item.subscription || {};
  }

  getItemCustomer() {
    return this.item && this.item.customer || {};
  }

  isInvoiced() {
    if (this.getItemSubscription().auto_collection) {
      return this.getItemSubscription().auto_collection === 'off';
    } else if (this.getItemCustomer().auto_collection) {
      return this.getItemCustomer().auto_collection === 'off';
    } else {
      return false;
    }
  }

  getPaymentSourceId() {
    if (this.getItemSubscription().payment_source_id) {
      return this.getItemSubscription().payment_source_id;
    } else if (this.getItemCustomer().primary_payment_source_id) {
      return this.getItemCustomer().primary_payment_source_id;
    } else {
      return null;
    }
  }

  _updatePaymentSourceId() {
    if (this.isInvoiced()) {
      this.item.paymentSourceId = 'invoice';
    } else {
      this.item.paymentSourceId = this.getPaymentSourceId();
    }
  }

  getSubscription(subscriptionId?, loadChanges?) {
    if (!subscriptionId) {
      subscriptionId = this.currentPlanService.currentPlan.subscriptionId;
    }

    this._clearMessages();

    this.item = {};
    this.loading = true;

    return this.billingApiService.getSubscription(subscriptionId)
      .then((resp) => {
        this.item = resp.item;

        this._updatePaymentSourceId();

        if (loadChanges && resp.item?.subscription?.has_scheduled_changes) {
          return this.billingApiService.getSubscription(subscriptionId, true)
            .then((withChanges) => {
              if (withChanges.item.subscription.plan_id !== this.item.subscription.plan_id) {
                this.item.subscription.changed_plan_id = withChanges.item.subscription.plan_id;
              }
              this.item.subscription.changed_plan_quantity = this.item.subscription.plan_quantity - withChanges.item.subscription.plan_quantity;
            });
        }
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;

        this.ngZone.run(() => {});
      });
  }

  changePoNumber() {
    this.loading = true;

    return this.billingApiService.changePoNumber(this.getItemSubscription().id, this.getItemSubscription().poNumber)
      .then((resp) => {
         _.extend(this.item, resp.item);
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  _changePaymentSource(subscriptionId, paymentSourceId) {
    this.loading = true;

    return this.billingApiService.changePaymentSource(subscriptionId, paymentSourceId)
      .then((resp) => {
        _.extend(this.item, resp.item);

        this._updatePaymentSourceId();
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changePaymentMethod(event, card) {
    // prevent the radio ng-model from being updated
    event.preventDefault();

    if (!this.isInvoiced() && this.getPaymentSourceId() === card.payment_source.id) {
      return;
    }

    var description = this.cardDescriptionPipe.transform(card.payment_source.card);

    this.modalService.confirm('Change Payment Method',
      'Are you sure you want to change the payment method? The <strong>' +
      description +
      '</strong> will be used for this subscription.',
      'Yes, Change', 'Cancel'
    ).then(() => {
      this._changePaymentSource(this.getItemSubscription().id, card.payment_source.id);
    });
  }

  _showErrorMessage(e) {
    this.apiError = this.processErrorCode(e);

    console.error(this.apiError, e);
  }

}
