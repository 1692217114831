<div class="input-group">
  <span *ngIf="iconSet !== 'madero'" class="input-group-addon" (click)="doSearch()"><i class="fa fa-search"></i></span>
  <span *ngIf="iconSet === 'madero'" class="input-group-addon" (click)="doSearch()">
	<streamline-icon name="magnifier" width="16" height="16"></streamline-icon>
  </span>
  <input id="{{ filterConfig.id }}" type="text" class="form-control" placeholder="{{ filterConfig.placeholder }}"
    [(ngModel)]="search.query" (keyup.enter)="searchAction(0)" (ngModelChange)="searchAction(1000)" [focusMe]="focus">
  <span *ngIf="iconSet !== 'madero'" class="input-group-addon" (click)="reset()"><i class="fa fa-times"></i></span>
  <span *ngIf="iconSet === 'madero'" class="input-group-addon" (click)="reset()">
  	<streamline-icon name="close" width="12" height="12"></streamline-icon>
  </span>
</div>
