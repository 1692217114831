import { StatesModule, Transition } from '@uirouter/angular';

import { CanAccessApps, UserState } from '../ajs-upgraded-providers';

import { CompanyAddComponent } from './components/company-add/company-add.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { UserAddComponent } from './components/user-add/user-add.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { ViewportComponent } from './viewport/viewport.component';
import { CompanyLicensesComponent } from './components/company-licenses/company-licenses.component';
import { CompanyAccessService } from './services/company-access.service';

export const routes: StatesModule = {
  states: [

    {
      name: 'apps.company',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.company.add',
      url: '/company/add',
      component: CompanyAddComponent,
      resolve: [{
        token: 'canAccessAps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.company.details',
      url: '/company/details',
      component: CompanyDetailsComponent,
      resolve: [{
        token: 'companyId',
        deps: [CanAccessApps, UserState],
        resolveFn: (canAccessApps, userState) => {
          return canAccessApps()
            .then(() => {
              return userState.getSelectedCompanyId();
            });
        }
      }]
    },
    {
      name: 'apps.company.licenses',
      url: '/company/licenses',
      component: CompanyLicensesComponent,
      resolve: [{
        token: 'canAccessApps',
        deps: [Transition, CanAccessApps, CompanyAccessService],
        resolveFn: (transition, canAccessApps, companyAccessService) => {
          return canAccessApps()
            .then(() => {
              if (!companyAccessService.accessCompanyLicenses) {
                transition.abort();
                return transition.router.stateService.go('apps.billing.home', transition.params());
              }
            });
        }
      }]
    },

    {
      name: 'apps.user',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.user.add2',
      url: '/users/add',
      redirectTo: 'apps.user.add'
    },
    {
      name: 'apps.user.list',
      url: '/user/list',
      params: {
        state: ''
      },
      component: UserListComponent,
      resolve: [{
        token: 'canAccessApps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    },
    {
      name: 'apps.user.add',
      url: '/user/add',
      params: {
        state: ''
      },
      component: UserAddComponent,
      resolve: [{
        token: 'companyId',
        deps: [CanAccessApps, UserState],
        resolveFn: (canAccessApps, userState) => {
          return canAccessApps()
            .then(() => {
              return userState.getSelectedCompanyId();
            });
        }
      }]
    },
    {
      name: 'apps.user.details',
      url: '/user/details/:username',
      component: UserDetailsComponent,
      params: {
        username: { squash: true, value: '' }
      },
      resolve: [{
        token: 'canAccessApps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    }

  ]
};