import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class WidgetSettingsService {

  widgetSettingsModal = () => {};

}

angular.module('risevision.editor.services')
  .factory('widgetSettingsService', downgradeInjectable(WidgetSettingsService));
