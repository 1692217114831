import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DisplayFactory, DisplayApiService } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';

import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';

import { DisplayControlComponent } from '../components/display-control/display-control.component';

@Injectable({
  providedIn: 'root'
})
export class DisplayControlService {

  constructor(private httpClient: HttpClient,
    private modalService: ModalService,
    private displayFactory: DisplayFactory,
    private displayApiService: DisplayApiService
  ) { }

  SCREEN_CONTROL_BUCKET = 'risevision-display-notifications';
  SCREEN_CONTROL_FILENAME = 'screen-control.txt';

  getConfiguration() {
    var display = this.displayFactory.display;
    var bucketName = this.SCREEN_CONTROL_BUCKET;
    var configUrl = StorageUtilsService.STORAGE_FILE_URL + bucketName + '/' + display.id + '/' + this.SCREEN_CONTROL_FILENAME;

    return this.httpClient.get(configUrl, {responseType: 'text'})
      .toPromise()
      .then((resp: any) => {
        display.screenControlConfiguration = resp;

        return resp;
      });
  }

  updateConfiguration(config) {
    var display = this.displayFactory.display;

    return this.updateConfigurationByObject(display, config);
  }

  _updateConfigurationByObject(display, config) {
    return this.displayApiService.uploadControlFile(display.id, config);
  }

  updateConfigurationByObject = this._updateConfigurationByObject.bind(this);

  getDefaultConfiguration() {
    return 'interface=\n' +
      'serial-port=\n' +
      'serial-baud-rate=\n' +
      'serial-data-bits=\n' +
      'serial-parity=\n' +
      'serial-stop-bits=\n' +
      'serial-flow-control=\n' +
      'serial-screen-on-cmd=\n' +
      'serial-screen-off-cmd=';
  }

  openDisplayControlModal(multiple?) {
    return this.modalService.showMediumModal(DisplayControlComponent, {
      initialState: {
        multiple
      }
    });
  }

}
