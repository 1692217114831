import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';

import { routes } from './style-guide.routes';
import { StyleGuideComponent } from './components/style-guide/style-guide.component';
import { ViewportComponent } from './viewport/viewport.component';

@NgModule({
  declarations: [
    StyleGuideComponent,
    ViewportComponent
  ],
  imports: [
    UIRouterModule.forChild(routes),
    CommonModule,
    ComponentsModule,
    SharedModule,
    PopoverModule.forRoot(),
    MatSlideToggleModule,
    MatCheckboxModule
  ]
})
export class StyleGuideModule {
  static entryComponents = []
  static providers = []
}
