<div class="file-empty-state u_margin-md-top">
  <p class="u_margin-md-top">You haven't uploaded any files yet!</p>
  <span>Select <b>Upload Files</b>, <b>Upload Folder</b>, or <b>Create Folder</b>.</span>
  <br/><br/>
  <div *ngIf="isMultipleFilesFoldersSelector">
    <div>
      <streamline-icon name="riseWidgetFolder"></streamline-icon>
      Select a Folder to show and update Images and Videos as you add or remove them from the Folder.
    </div>
    <div>
      <streamline-icon name="riseWidgetImageMulti"></streamline-icon>
      Or just select single Images and Videos to add and order them in your Playlist.
    </div>
  </div>
</div>
