<div (mouseout)="componentsService.highlightComponent(null)">
  <div (rvSortable)="sortItem($event)" appendTo=".attribute-editor" class="sortable-list">
    <div class="attribute-row attribute-row-hover rv-sortable-item" *ngFor="let comp of components; let key = index">
      <div *ngIf="isUserComponents" class="rv-sortable-handle">
        <streamline-icon class="streamline-component-icon" name="navigation-menu-vertical" width="8" height="24"></streamline-icon>
      </div>
      <div class="attribute-desc" (mouseover)="componentsService.highlightComponent(comp.id)">
        <streamline-icon class="streamline-component-icon" name="{{ getComponentIcon(comp) }}" width="24" height="18"></streamline-icon>
        <a [hidden]="comp.editing" class="madero-link flex-grow" [ngClass]="{'strikethrough' : !getComponentVisibility(comp)}" (click)="getComponentVisibility(comp) && editComponent(comp, key);">{{ getDisplayName(comp) }}</a>
        <div [shown]="comp.editing" class="mr-2 flex-grow">
          <input #ComponentName id="input-component-name-{{ key }}" class="w-100 form-control p-2 h-auto" type="text" [ngModel]="getDisplayName(comp)" (blur)="saveItemName(key, $event)" (keyup)="itemNameKeyup(key, $event)">
        </div>
      </div>
      <button class="btn btn-icon" mat-menu [matMenuTriggerFor]="playlistOptions">
        <streamline-icon class="streamline-component-icon" name="ellipsis" width="16" height="16"></streamline-icon>
      </button>
      <mat-menu #playlistOptions="matMenu" xPosition="before">
        <button class="playlist-item-rename" mat-menu-item (click)="renameComponent(key)">
          <streamline-icon class="mr-3 streamline-component-icon" name="pencil" width="16" height="16"></streamline-icon>
          <span>Edit Label</span>
        </button>
        <div [hidden]="isDataComponent(comp)">
          <button [shown]="getComponentVisibility(comp)" class="playlist-item-hide" mat-menu-item (click)="setComponentVisibility(comp, false)">
            <streamline-icon class="mr-3 streamline-component-icon" name="hide" width="16" height="16"></streamline-icon>
            <span>Hide</span>
          </button>
          <button [hidden]="getComponentVisibility(comp)" class="playlist-item-hide" mat-menu-item (click)="setComponentVisibility(comp, true)">
            <streamline-icon class="mr-3 streamline-component-icon" name="show" width="16" height="16"></streamline-icon>
            <span>Show</span>
          </button>
          <button *ngIf="isUserComponents"  class="playlist-item-delete" mat-menu-item (click)="removeComponent(key)">
            <streamline-icon class="mr-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
            <span>Delete</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>

</div>
