import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserState } from 'src/app/ajs-upgraded-providers';

import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'bulk-delete-confirmation',
  templateUrl: './bulk-delete-confirmation.component.html',
  styleUrls: ['./bulk-delete-confirmation.component.scss']
})
export class BulkDeleteConfirmationComponent extends ModalComponent implements OnInit {
  companyItems = 0;
  subCompanyItems = 0;
  inputText = null;
  expectedText = '0';

  itemName;
  selectedItems;

  constructor(public modalRef: BsModalRef,
    private userState: UserState) {
    super(modalRef);
  }

  ngOnInit(): void {
    _.each(this.selectedItems, (display) => {
      if (display.companyId === this.userState.getSelectedCompanyId()) {
        this.companyItems++;
      } else {
        this.subCompanyItems++;
      }
    });

    this.expectedText = this.selectedItems.length.toString();
  }

  delete() {
    if (this.inputText === this.expectedText) {
      super.resolve();
    }
  }

  dismiss() {
    super.reject();
  }

}
