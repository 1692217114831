<ng-template #assignedToPopover><div [innerHtml]="assignedToUsers"></div></ng-template>
<div>
  <div class="app-header u_margin-md-top mb-5">
    <!-- App Title -->
    <h1 class="app-header-title" id="title">Schedules</h1>
  </div>

  <div id="errorBox" [shown]="schedules.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <p><strong>{{schedules.errorMessage}}</strong></p>
    {{schedules.apiError}}
  </div>

  <div *ngIf="!(!schedules.loadingItems && schedules.items.list.length === 0 && search.query === undefined)">

    <div class="flex-row button-toolbar-md-folded">
      <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="schedules.doSearch" iconSet="madero"></search-filter>
      <div class="ml-md-3" *requireRole="'cp'">
        <a id="scheduleAddButton" class="btn btn-primary btn-toolbar-wide" uiSref="apps.schedules.add">
          Add Schedule
        </a>
      </div>
    </div>

    <batch-operations [listObject]="schedules"></batch-operations>

    <div class="scrollable-list horizontal-scroll border-container u_margin-md-top u_margin-md-bottom"
      scrollingList (scrollEndEvent)="schedules.load()"
      rvSpinner="schedules-list-loader" [rvShowSpinner]="schedules.loadingItems">

      <table id="schedulesListTable" class="table table--schedule-list">
        <thead class="table-header">
          <tr class="table-header__row u_clickable">
            <th class="table-header__cell col-sm-2">
              <div class="flex-row">
                <madero-checkbox (click)="schedules.selectAll()" [ngValue]="search.selectAll" *requireRole="'!ap'"></madero-checkbox>
                <div class="u_clickable" id="tableHeaderName" (click)="schedules.sortBy('name')">
                  Name
                  <i *ngIf="search.sortBy === 'name'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
                </div>
              </div>
            </th>
            <th id="tableHeaderTimeline" class="table-header__cell col-sm-2" (click)="schedules.sortBy('scheduleType', true)">
              Override
              <i *ngIf="search.sortBy === 'scheduleType'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            </th>
            <th id="tableHeaderTimeline" class="table-header__cell col-sm-2" (click)="schedules.sortBy('timeline')">
              Timeline
              <i *ngIf="search.sortBy === 'timeline'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            </th>
            <th id="tableHeaderDistribution" class="table-header__cell col-sm-2" (click)="schedules.sortBy('distributionSummary')">
              Distribution
              <i *ngIf="search.sortBy === 'distributionSummary'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            </th>
            <th id="tableHeaderChangeDate" class="table-header__cell col-sm-2" (click)="schedules.sortBy('changeDate')">
              Last Modified
              <i *ngIf="search.sortBy === 'changeDate'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            </th>
            <th *ngIf="userState.isK12Customer() || userState.isHigherEdCustomer()" id="tableHeaderAssignedTo" class="table-header__cell col-sm-2" (click)="schedules.sortBy('assignedUsersValue')">
              Assigned To
              <i *ngIf="search.sortBy === 'assignedUsersValue'" class="fa" [ngClass]="{'fa-caret-up': !search.reverse, 'fa-caret-down': search.reverse}"></i>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="table-body__row" *ngFor="let schedule of schedules.items.list">
            <td class="table-body__cell">
              <div class="flex-row">
                <madero-checkbox (click)="schedules.select(schedule)" [ngValue]="schedule.selected" *requireRole="'!ap'"></madero-checkbox>
                <a class="madero-link u_ellipsis-md" uiSref="apps.schedules.details" [uiParams]="{scheduleId: schedule.id}">
                  <strong>{{schedule.name}}</strong>
                </a>
              </div>
            </td>
            <td class="table-body__cell">{{schedule.scheduleTypeName}}</td>
            <td class="table-body__cell u_ellipsis-lg" [innerHtml]="schedule.timeline"></td>
            <td class="table-body__cell">{{schedule.distributionSummary}}</td>
            <td class="table-body__cell u_ellipsis-md">{{schedule.changeDate | date:'d-MMM-yyyy h:mm a'}} by {{schedule.changedBy | username}}</td>
            <td *ngIf="userState.isK12Customer() || userState.isHigherEdCustomer()" class="table-body__cell">
              <span class="u_ellipsis-md">
                {{ schedule.assignedUsers && schedule.assignedUsers.length > 0 ? (schedule.assignedUsers[0] | username) : '' }}
              </span>
              <span class="badge badge-primary u_clickable ml-2"
                  *ngIf="schedule.assignedUsers && schedule.assignedUsers.length > 1"
                  [popover]="assignedToPopover"
                  (onShown)="popoverText(schedule.assignedUsers)"
                  containerClass="madero-style"
                  outsideClick="true"
                  placement="bottom right">

                  +{{ schedule.assignedUsers.length - 1 }}
                </span>
            </td>
          </tr>
          <!-- If no search results -->
          <tr class="table-body__row" [shown]="schedules.items.list.length === 0 && search.query !== undefined">
            <td colspan="4" class="table-body__cell text-center">Your search didn't return any results.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="border-container text-center u_margin-md-bottom" *ngIf="!schedules.loadingItems && schedules.items.list.length === 0 && search.query === undefined">
    <div class="panel-body" *requireRole="'cp'">
      <div class="form-group my-4">
        <label>Add a schedule to start communicating better with your audience!</label>
      </div>
      <a id="scheduleAddButton" class="btn btn-primary btn-toolbar-wide mb-2" uiSref="apps.schedules.add">
        Add Schedule
      </a>
    </div>
    <div class="panel-body" *requireRole="'ap'">
      <div class="form-group my-4">
        <label>Your account is still being set up, you'll receive an email notice when it's ready. Thanks for your patience!</label>
      </div>
    </div>
  </div>

</div>
