<div id="companyIcpModal">
  <div class="modal-header">
    <h2 id="icpModalTitle" class="modal-title">Help us personalize your experience</h2>
  </div>
  <div class="modal-body">
    <form #companyIcpForm="ngForm" id="forms.companyIcpForm" role="form" name="forms.companyIcpForm" autocomplete="on" novalidate>
      <div class="form-group">
        <label for="company-industry" class="control-label">
          Tell us your Industry to help us make your Display look great *
        </label>
        <select #companyIndustry="ngModel" id="company-industry" class="form-control selectpicker" [(ngModel)]="company.companyIndustry" name="companyIndustry" autofocus required>
          <option [value]="undefined" hidden>&lt; Select Industry &gt;</option>
          <option *ngFor="let industry of COMPANY_INDUSTRY_FIELDS" value="{{industry[1]}}">{{industry[0]}}</option>
        </select>
      </div>

    </form>

  </div> <!-- //Modal Body -->

  <div class="modal-footer">
    <button id="saveButton" type="submit" class="btn btn-primary btn-lg btn-block" aria-label="Apply" tabindex="1" form="forms.companyIcpForm" (click)="save()" [disabled]="companyIcpForm.invalid">
      Apply
      <i class="fa fa-check icon-right"></i>
    </button>
  </div>

</div>
