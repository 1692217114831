<div class="btn-group-checkbox">
  <input type="checkbox" id="{{buttonId}}" name="{{buttonId}}" [(ngModel)]="value" (change)="onChange()" [disabled]="disabled">

  <div class="btn-group btn-group-justified">
    <button type="button" class="btn" [disabled]="disabled"
      [ngClass]="{'btn-toggle-blue-off' : !value, 'btn-toggle-blue-on' : value}">
      Yes
      <streamline-icon name="checkmark" [shown]="value"></streamline-icon>
    </button>
    <button type="button" class="btn" [disabled]="disabled"
      [ngClass]="{'btn-toggle-blue-off' : value, 'btn-toggle-blue-on' : !value}">
      No
      <streamline-icon name="checkmark" [shown]="!value"></streamline-icon>
    </button>
  </div>
</div>
