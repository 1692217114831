<div id="renameFileFolderModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="cancel()">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Rename File or Folder</h4>
  </div>
  <div class="modal-body align-left" autofocus="autofocus">
    <div class="form-group">
      <label for="name">File or Folder Name:</label>
      <div class="input-group">
        <input #renameInput type="text" class="form-control" value="" required id="name" name="name" [(ngModel)]="renameName" (keyup)="update()" (focus)="renameInput.select()" [focusMe]="true">
        <span class="input-group-addon">{{ extension }}</span>
      </div>
      <br>
      <div class="alert alert-danger" role="alert" [shown]="!validDestination">The new name cannot have slashes.</div>
      <div [shown]="errorMessage">
        <div class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button id="renameFileFolderModalCancelButton" type="button" class="btn btn-default btn-toolbar" (click)="cancel()" [disabled]="isProcessing">
      Cancel
    </button>
    <button id="renameFileFolderModalSaveButton" type="submit" class="btn btn-primary btn-toolbar" (click)="ok()" [disabled]="isProcessing || !validDestination">
      {{isProcessing ? 'Saving' : 'Save' }}
    </button>
  </div>
</div>
