import { Injectable } from '@angular/core';
import { AnalyticsFactory, UserService, UserState } from 'src/app/ajs-upgraded-providers';
import { TrackerService } from 'src/app/components/logging/tracker.service';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class DisplayActivationService {

  constructor(
    private userState: UserState,
    private userService: UserService,
    private analyticsFactory: AnalyticsFactory,
    private trackerService: TrackerService
  ) { }

  private _checkActiveDisplay (displays: any[]): any {
    return displays.reduce((result, display) => {
      if (!display.lastActivityDate) {
        return result;
      } else if (!result) {
        return display;
      } else {
        const newTime = new Date(display.lastActivityDate);
        const resultTime = new Date(result.lastActivityDate);
        return newTime > resultTime ? result : display;
      }
    }, null);
  }

  private _updateUserSettings (firstDisplayActivationDate: string): Promise<any> {
    const settings = {} as any;
    settings.firstDisplayActivationDate = firstDisplayActivationDate;

    return this.userService.updateUser(this.userState.getUsername(), {
        settings: settings
      })
      .then((resp) => {
        this.userState.updateUserProfile(resp.item);
      });
  }

  track (displays?: any[]): void {
    if (!displays || this.userState.isSubcompanySelected()) {
      return;
    }

    const profile = this.userState.getCopyOfProfile();

    if (profile && profile.settings && profile.settings.firstDisplayActivationDate) {
      return;
    }

    const activeDisplay = this._checkActiveDisplay(displays);

    if (activeDisplay && activeDisplay.lastActivityDate) {
      console.info('Active display found', activeDisplay);

      const activationDate = activeDisplay.lastActivityDate.toISOString();

      this.analyticsFactory.identify(this.userState.getUsername(), {
        firstDisplayActivationDate: activationDate
      });

      this.trackerService.displayEvent('first display activated', activeDisplay.id, activeDisplay.name, {
        firstDisplayActivationDate: activationDate
      });

      this._updateUserSettings(activationDate);
    }
  }
}

angular.module('risevision.displays.services')
  .factory('displayActivationTracker', downgradeInjectable(DisplayActivationService));
