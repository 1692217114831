<main class="container madero-style" *ngIf="userState.isRootCompany()">
  <h1>Style Guide</h1>

  <h2>Buttons</h2>
  <section class="border-round px-4 pb-5">
    <h3>Auto-Width</h3>
    <button class="btn btn-default">Default</button>
    <button class="btn btn-primary ml-5">Primary</button>

    <h3>Toolbar</h3>
    <button class="btn btn-default btn-toolbar">Default</button>
    <button class="btn btn-primary btn-toolbar ml-5">Primary</button>
  </section>

  <h2>Material UI Components</h2>
  <section class="border-round px-4 pb-5">
    <h3>Checkbox</h3>
    <mat-checkbox id="style-guide-checkbox-checked" [checked]="true"></mat-checkbox>
    <label for="style-guide-checkbox-checked-input" class="u_clickable">Checked</label>
    <mat-checkbox id="style-guide-checkbox-unchecked" class="ml-5" [checked]="false"></mat-checkbox>
    <label for="style-guide-checkbox-unchecked-input" class="u_clickable">Unchecked</label>
    <mat-checkbox id="style-guide-checkbox-disabled" class="ml-5" [disabled]="true"></mat-checkbox>
    <label for="style-guide-checkbox-disabled-input">Disabled</label>

    <h3>Slide Toggle</h3>
    <mat-slide-toggle></mat-slide-toggle>
  </section>
</main>
