import { Component, Input, NgZone, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'yes-no-toggle',
  templateUrl: './yes-no-toggle.component.html',
  styleUrls: ['./yes-no-toggle.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => YesNoToggleComponent), multi: true }
  ]
})
export class YesNoToggleComponent implements ControlValueAccessor {

  @Input() buttonId: string = '';
  disabled: boolean;
  value: boolean;

  constructor(private ngZone: NgZone) { }

  onChange() {
    this._onChange(this.value);
    this.ngZone.run(() => {});
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  private _onChange = (value: boolean) => undefined;
  registerOnChange(fn: (value: boolean) => void): void {
    this._onChange = fn;
  }

  private _onTouched = () => undefined;
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
