import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalComponent } from 'src/app/components/modals/modal.component';

import { DisplayControlService } from '../../services/display-control.service';

@Component({
  selector: 'display-control',
  templateUrl: './display-control.component.html',
  styleUrls: ['./display-control.component.scss']
})
export class DisplayControlComponent extends ModalComponent implements OnInit {

  multiple: boolean;

  loading = false;
  formData: any = {};

  constructor(public modalRef: BsModalRef,
    private displayControlService: DisplayControlService) {
    super(modalRef);
  }

  ngOnInit(): void {
    if (this.multiple) {
      this.formData.displayControlContents = this.displayControlService.getDefaultConfiguration();
    } else {
      this._loadConfiguration();
    }
  }

  _loadConfiguration() {
    this.loading = true;

    this.displayControlService.getConfiguration()
      .then((config) => {
        this.formData.displayControlContents = config;
      })
      .catch((err) => {
        console.log('Failed to load config; showing default', err);
        this.resetForm();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  saveConfiguration() {
    if (this.multiple) {
      super.resolve(this.formData.displayControlContents);

      return;
    }

    this.loading = true;

    this.displayControlService.updateConfiguration(this.formData.displayControlContents)
      .then(() => {
        super.resolve();
      })
      .catch((err) => {
        console.log('Failed to save configuration file', err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  dismiss() {
    super.reject();
  }

  resetForm() {
    this.formData.displayControlContents = this.displayControlService.getDefaultConfiguration();
  }

}
