import { Component, ChangeDetectorRef } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { PresentationService, ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'presentation-selector',
  templateUrl: './presentation-selector.component.html',
  styleUrls: ['./presentation-selector.component.scss']
})
export class PresentationSelectorComponent extends ModalComponent {
  public static PRESENTATION_SEARCH = {
    filter: ''
  };

  search: any = {
    sortBy: 'changeDate',
    reverse: true,
    name: 'Presentations',
    filter: PresentationSelectorComponent.PRESENTATION_SEARCH.filter,
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  };

  presentations = this.ScrollingListService(this.presentation.list, this.search);

  filterConfig = {
    placeholder: 'Search Presentations',
    id: 'presentationSearchInput'
  };

  constructor(modalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private ScrollingListService: ScrollingListService,
    private presentation: PresentationService) {
      super(modalRef);
    }

  select(presentation) {
    super.resolve([presentation.id, presentation.name, presentation.presentationType]);
  }

  dismiss() {
    super.reject();
  }

}