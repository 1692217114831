import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import * as _ from 'lodash';

import { AjsState, AjsTransitions, UserState } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';

import { BulkDeleteConfirmationComponent } from '../bulk-delete-confirmation/bulk-delete-confirmation.component';

@Component({
  selector: 'batch-operations',
  templateUrl: './batch-operations.component.html',
  styleUrls: ['./batch-operations.component.scss']
})
export class BatchOperationsComponent implements OnInit, OnDestroy {
  @Input() listObject!: any;

  transitionHook: any;

  constructor(private $state: AjsState,
    private $transitions: AjsTransitions,
    private modalService: ModalService,
    private userState: UserState) {
  }

  ngOnInit(): void {
    if (this.listObject && this.listObject.batchOperations &&
      this.listObject.batchOperations.operations) {
      this._filterByRole();
      this._updateDeleteAction();
    }

    let _bypass = false;
    this.transitionHook = this.$transitions.onStart({}, (trans: any) => {
      if (_bypass) {
        _bypass = false;
        return;
      }
      if (this.listObject.batchOperations.activeOperation) {
        var operationName = this.listObject.batchOperations.activeOperation.name.toLowerCase();

        trans.abort();

        this.modalService.confirm('Cancel bulk ' + operationName + '?',
          'A bulk ' + operationName +
          ' is in progress. Navigating away from this page will cancel this action. Are you sure you want to cancel?',
          'Yes, Cancel',
          'No')
          .then(() => {
            this.listObject.batchOperations.cancel();

            _bypass = true;
            this.$state.go(trans.to().name, trans.to().params);
          });
      }
    });

    window.onbeforeunload = (e: Event) => {
      if (this.listObject.batchOperations.activeOperation) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = true;
      }
    };
  }

  ngOnDestroy(): void {
    window.onbeforeunload = undefined;

    this.transitionHook();
  }

  _filterByRole() {
    _.remove(this.listObject.batchOperations.operations, (operation: any) => {
      return !this.userState.hasPermission(operation.requireRole);
    });
  }

  _updateDeleteAction() {
    _.each(this.listObject.batchOperations.operations, (operation) => {
      if (operation.isDelete) {
        operation.beforeBatchAction = () => {
          return this.modalService.showModal(BulkDeleteConfirmationComponent, {
            initialState: {
              selectedItems: this.listObject.getSelected(),
              itemName: this.listObject.batchOperations.name
            },
            class: 'madero-style modal-sm'
          });
        };
      }
    });
  }

}

angular.module('risevision.common.components')
  .directive(
    'batchOperations',
    downgradeComponent({
      component: BatchOperationsComponent
    }) as angular.IDirectiveFactory
  );
