import { Input, Component, ElementRef, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FileUploaderFactory, StorageApiService, UploadOverwriteWarning, UploadURIService } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';
import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';

import * as _ from 'lodash';

@Component({
  selector: 'basic-uploader',
  templateUrl: './basic-uploader.component.html',
  styleUrls: ['./basic-uploader.component.scss']
})
export class BasicUploaderComponent implements OnChanges {

  static readonly STORAGE_UPLOAD_CHUNK_SIZE = 256 * 1024 * 100; // Multiple of Google required chunk size (256 * 1024)

  @Input() uploaderId!: string;
  @Input() uploadManager!: any;

  validExtensions = [];
  private _validType = '';
  @Input() set validType(value: string) {
    this._validType = value || '';

    this.validExtensions = this.storageManagerService.getValidExtensionsList(this._validType);
  }
  get validType(): string {
    return this._validType;
  }

  @Input() fileList!: any;

  @ViewChild('inputField') inputField: ElementRef;

  uploader;
  status: any = {};
  warnings = [];
  fileTypeWarned;
  accept;
  files;
  folderNames = [];

  constructor(private storageApiService: StorageApiService,
    private modalService: ModalService,
    private fileUploaderFactory: FileUploaderFactory,
    private UploadURIService: UploadURIService,
    private templateEditorUtils: TemplateEditorUtilsService,
    private presentationUtils: PresentationUtilsService,
    private storageManagerService: StorageManagerService,
    private uploadOverwriteWarning: UploadOverwriteWarning) {

    this.initFileUploader();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileList && changes.fileList.currentValue) {
      this.uploadSelectedFiles(changes.fileList.currentValue);
    }
  }

  fileInputChangeEvent(files) {
    var selectedFiles = Array.from(files);
    this.uploadSelectedFiles(selectedFiles)
      .finally(() => {
        this.inputField.nativeElement.value = null;
      });
  }

  _addFile(file) {
    if (this.validType !== StorageManagerService.FILE_TYPE.FOLDER) {
      console.log('Add file to the list', file);
      this.uploadManager.addFile(file);
    } else {
      let folderName = this.templateEditorUtils.getUploadedFolder(file.name, this.uploadManager.folderPath);
      let folder = {
        name: folderName,
        kind: 'folder'
      }

      if (this.folderNames.indexOf(folderName) === -1) {
        this.folderNames.push(folderName);

        console.log('Add folder to the list', folder);
        this.uploadManager.addFile(folder);
      }
    }
  }

  initFileUploader() {
    var FileUploader = this.fileUploaderFactory();
    this.uploader = FileUploader;

    FileUploader.onAddingFiles = () => {
      this.uploadOverwriteWarning.resetConfirmation();
    };

    FileUploader.onAfterAddingFile = (fileItem) => {
      console.info('onAfterAddingFile', fileItem.file.name);

      if (!fileItem.isRetrying) {
        fileItem.file.name = (this.uploadManager.folderPath || '') + fileItem.file.name;
      }

      this.uploadManager.onUploadStatus(this._isUploading());

      var uploadFile = (resp) => {
        fileItem.url = resp.message;
        fileItem.taskToken = resp.taskToken;
        fileItem.encodingFileName = resp.newFileName;
        fileItem.chunkSize = BasicUploaderComponent.STORAGE_UPLOAD_CHUNK_SIZE;

        if (this.checkFileType(fileItem)) {
          FileUploader.removeFromQueue(fileItem);
          this.uploadManager.onUploadStatus(this._isUploading());

          return;
        }

        return this.uploadOverwriteWarning.checkOverwrite(resp)
          .then(() => {
            FileUploader.uploadItem(fileItem);
          }).catch(() => {
            FileUploader.removeFromQueue(fileItem);
            this.uploadManager.onUploadStatus(this._isUploading());
          });
      }

      return this.UploadURIService.getURI(fileItem.file)
        .then(uploadFile)
        .catch((resp) => {
          console.log('getURI error', resp);

          if (resp.message === 'Unencodable overwrite') {
            return this.UploadURIService.getURI(fileItem.file, true)
              .then(uploadFile);
          }

          FileUploader.notifyErrorItem(fileItem, resp.status);
          this.status.message = resp.message;
        });

    };

    FileUploader.currentFilePath = () => {
      return this.uploadManager.folderPath;
    };

    FileUploader.onBeforeUploadItem = (item) => {
      console.log('Attempting to upload', item.file.name);
    };

    FileUploader.onCancelItem = (item) => {
      FileUploader.removeFromQueue(item);
    };

    FileUploader.onCompleteItem = (item) => {
      if (item.isCancel || !item.isSuccess) {
        this.uploadManager.onUploadStatus(this._isUploading());

        if (!item.isSuccess) {
          console.log('Failed to upload: ', item.file);
        }

        return;
      }

      var baseFile = {
        'name': item.encodingFileName || item.file.name,
        'updated': {
          'value': new Date().valueOf().toString()
        },
        'size': item.file.size,
        'type': item.file.type
      };

      // Retrieve to force thumbnail creation
      this.storageApiService.refreshFileMetadata(item.encodingFileName || item.file.name)
        .then((file) => {
          this._addFile(file);
        }, (err) => {
          console.log('Error refreshing metadata', item.file.name, err);

          this._addFile(baseFile);
        })
        .finally(() => {
          FileUploader.removeFromQueue(item);
          this.uploadManager.onUploadStatus(this._isUploading());
        });
    };

  }

  fileNameOf(path) {
    return this.templateEditorUtils.fileNameOf(path);
  }

  _isUploading() {
    return this.activeUploadCount() > 0;
  }

  removeItem(item) {
    this.uploader.removeFromQueue(item);
    this.uploadManager.onUploadStatus(this._isUploading());
  }

  activeUploadCount() {
    return this.uploader.queue.length;
  }

  retryFailedUpload(file) {
    if (file.isError) {
      this.uploader.retryItem(file);
    }
  }

  uploadSelectedFiles(selectedFiles) {
    this.folderNames = [];

    return this.uploader.compress(selectedFiles)
      .then((fileItems) => {
        return this.uploader.addToQueue(fileItems);
      });
  }

  checkFileType(fileItem) {
    if (!this.validExtensions.length) {
      return;
    }

    if (this.storageManagerService.fileHasValidExtension(fileItem.name || fileItem.file.name, this.validExtensions)) {
      return;
    }

    if (fileItem.file && fileItem.file.type && fileItem.file.type.indexOf('video') === 0 &&
      fileItem.encodingFileName) {
      return;
    }

    if (this.fileTypeWarned) {
      return true;
    }
    this.fileTypeWarned = true;
    this.showInvalidExtensionsMessage();
    return true;
  }

  getAcceptedTypes() {
    if (this.presentationUtils.isMobileBrowser()) {
      // set accept parameters for mobile
      // https://github.com/Rise-Vision/rise-vision-apps/pull/1241
      if (this.validType === StorageManagerService.FILE_TYPE.IMAGE || this.validType === StorageManagerService.FILE_TYPE.VIDEO) {
        return this.validType + '/*';
      } else if (this.validType === StorageManagerService.FILE_TYPE.IMAGE_VIDEO) {
        return 'image/*, video/*';
      } else {
        return '';
      }
    } else if (this.validType.indexOf(StorageManagerService.FILE_TYPE.VIDEO) !== -1) {
      // if videos are allowed, check for valid type after passing files to encoding
      // https://github.com/Rise-Vision/rise-vision-apps/pull/1685
      return '*';
    } else {
      // return a csv string of the specific extensions or blank for folder
      return this.storageManagerService.getValidExtensions(this.validType);
    }
  }

  showInvalidExtensionsMessage() {
    var title = 'This file type is not supported';
    var message = this.getValidExtensionsMessage(this.validExtensions);

    this.modalService.showMessage(title, message);
  }

  getValidExtensionsMessage(validExtensions) {
    var prefix = validExtensions;
    var suffix = '';

    if (validExtensions.length > 1) {
      prefix = validExtensions.slice(0, validExtensions.length - 1);
      suffix = ' and ' + validExtensions[validExtensions.length - 1].toUpperCase();
    }

    return 'Rise Vision supports ' + prefix.join(', ').toUpperCase() + suffix + '.';
  }
}
