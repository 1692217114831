import { Injectable } from '@angular/core';

import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';

import { CardDescriptionPipe } from '../pipes/card-description.pipe';
import { BillingApiService } from 'src/app/billing/services/billing-api.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentSourcesService {

  public loading;
  public apiError;
  public existingCreditCards;
  public selectedCard;

  constructor(private modalService: ModalService,
    private billingApiService: BillingApiService,
    private processErrorCode: ProcessErrorCode) { }

  _clearMessages() {
    this.loading = false;

    this.apiError = '';
  }

  _loadCreditCards() {
    this.loading = true;

    return this.billingApiService.getCreditCards({
        count: 40
      })
      .then((result) => {
        this.existingCreditCards = result.items;

        if (result.items[0]) {
          this.selectedCard = result.items[0];
        }
      })
      .finally( () => {
        this.loading = false;
      });
  }

  init() {
    this._clearMessages();

    this.existingCreditCards = [];
    delete this.selectedCard;

    return this._loadCreditCards();
  }

  _deletePaymentSource(paymentSourceId) {
    this.loading = true;

    return this.billingApiService.deletePaymentSource(paymentSourceId)
      .then( () => {
        this.init();
      })
      .catch( (e) => {
        this.loading = false;

        this._showErrorMessage(e);
      });
  }

  removePaymentMethod(card) {
    var description = new CardDescriptionPipe().transform(card.payment_source.card);

    this.modalService.confirm('Remove Payment Method',
      'Are you sure you want to remove this payment method? The <strong>' +
      description +
      '</strong> will be removed from your company.',
      'Yes, Remove', 'Cancel'
    ).then( () => {
      this._deletePaymentSource(card.payment_source.id);
    });
  }

  _showErrorMessage(e) {
    this.apiError = this.processErrorCode(e);

    console.error(this.apiError, e);
  }
}