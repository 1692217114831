<div id="checkout-success" class="row">
  <div class="col-md-8 col-md-offset-2">
    <h4 class="u_margin-md-bottom">
      <span *ngIf="purchaseAction === 'add' || purchaseAction === 'unlimited'">A great big thank you!</span>
      <span *ngIf="purchaseAction === 'remove'">Display license removal initiated.</span>
      <span *ngIf="purchaseAction === 'annual'">Billing frequency has been adjusted.</span>
    </h4>

    <pending-change-alert *ngIf="subscriptionService.getItemSubscription().has_scheduled_changes"></pending-change-alert>

    <div class="border-container p-4">
      <p class="u_margin-md-bottom">
        We’ve sent a confirmation email to <b>{{updateSubscriptionService.userEmail}}</b>.
      </p>
      <p>
        <b>Details:</b><br />
        <span *ngIf="purchaseAction === 'add'">
          You’ve added <b>{{updateSubscriptionService.purchase.licensesToAdd}} display license{{ updateSubscriptionService.purchase.licensesToAdd > 1 ? 's' : '' }}</b> to your subscription.<br/>
        </span>
        <span *ngIf="purchaseAction === 'remove'">
          <b>{{updateSubscriptionService.purchase.licensesToRemove}} display license{{ updateSubscriptionService.purchase.licensesToRemove > 1 ? 's' : '' }}</b> will be removed from your subscription on the next billing cycle.<br/>
        </span>
        <span *ngIf="purchaseAction === 'annual'">
          You’ve changed your billing frequency to <b>Annual</b>.
        </span>
        <span *ngIf="purchaseAction === 'unlimited'">
          You’ve added <b>unlimited display licenses</b> to your subscription.<br/>
        </span>
        <span *ngIf="purchaseAction === 'change'">
          You’ve updated your plan to <b>{{ planName }}</b>.
        </span>
        <span *ngIf="updateSubscriptionService.estimate.invoice_estimate?.amount_due && !updateSubscriptionService.getCreditTotal()">
          You will be charged <b>{{updateSubscriptionService.estimate.invoice_estimate?.amount_due/100 | currency }}</b>.
        </span>
        <span *ngIf="updateSubscriptionService.getCreditTotal() && !updateSubscriptionService.estimate.invoice_estimate?.amount_due">
          You will be credited <b>{{updateSubscriptionService.getCreditTotal() | currency }}</b>.
        </span>
        <span *ngIf="updateSubscriptionService.estimate.invoice_estimate?.amount_due && updateSubscriptionService.getCreditTotal()">
          You will be credited <b>{{updateSubscriptionService.getCreditTotal() | currency }}</b> and charged <b>{{updateSubscriptionService.estimate.invoice_estimate?.amount_due/100 | currency }}</b>.
        </span>
      </p>
      <p id="next-invoice-row" class="left-right-aligner mb-0 border-top pt-5 pb-2" *ngIf="subscriptionService.getItemSubscription().has_scheduled_changes && updateSubscriptionService.estimate.next_invoice_estimate">
        <span class="font-weight-bold">Next invoice on {{updateSubscriptionService.estimate.subscription_estimate?.next_billing_at * 1000 | date:'d-MMM-yyyy'}}:</span>
        <span>
          <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimate.next_invoice_estimate?.currency_code}}</span>
          <span class="purchase-total">{{updateSubscriptionService.estimate.next_invoice_estimate?.total/100 | currency }}</span>
        </span>
      </p>
    </div>
    <div class="button-row u_margin-md-top">
      <button id="doneButton" class="btn btn-primary btn-block" (click)="closeEvent.emit()" aria-label="Done" tabindex="1">Continue</button>
    </div>
  </div>
</div>
