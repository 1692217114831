<div class="form-group timelineWeekdays">
  <div class="flex-row">
    <madero-checkbox name="weeklyRecurrenceMonday" [ngModel]="days.monday" ngDefaultControl (ngModelChange)="update('monday', $event)"></madero-checkbox>
    <span class="u_clickable" (click)="update('monday', !days.monday)">Monday</span>
  </div>
  <div class="flex-row">
    <madero-checkbox name="weeklyRecurrenceTuesday" [ngModel]="days.tuesday" ngDefaultControl (ngModelChange)="update('tuesday', $event)"></madero-checkbox>
    <span class="u_clickable" (click)="update('tuesday', !days.tuesday)">Tuesday</span>
  </div>
  <div class="flex-row">
    <madero-checkbox name="weeklyRecurrenceWednesday" [ngModel]="days.wednesday" ngDefaultControl (ngModelChange)="update('wednesday', $event)"></madero-checkbox>
    <span class="u_clickable" (click)="update('wednesday', !days.wednesday)">Wednesday</span>
  </div>
  <div class="flex-row">
    <madero-checkbox name="weeklyRecurrenceThursday" [ngModel]="days.thursday" ngDefaultControl (ngModelChange)="update('thursday', $event)"></madero-checkbox>
    <span class="u_clickable" (click)="update('thursday', !days.thursday)">Thursday</span>
  </div>
  <div class="flex-row">
    <madero-checkbox name="weeklyRecurrenceFriday" [ngModel]="days.friday" ngDefaultControl (ngModelChange)="update('friday', $event)"></madero-checkbox>
    <span class="u_clickable" (click)="update('friday', !days.friday)">Friday</span>
  </div>
  <div class="flex-row">
    <madero-checkbox name="weeklyRecurrenceSaturday" [ngModel]="days.saturday" ngDefaultControl (ngModelChange)="update('saturday', $event)"></madero-checkbox>
    <span class="u_clickable" (click)="update('saturday', !days.saturday)">Saturday</span>
  </div>
  <div class="flex-row">
    <madero-checkbox name="weeklyRecurrenceSunday" [ngModel]="days.sunday" ngDefaultControl (ngModelChange)="update('sunday', $event)"></madero-checkbox>
    <span class="u_clickable" (click)="update('sunday', !days.sunday)">Sunday</span>
  </div>
</div>