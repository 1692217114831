import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DisplayFactory } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';
import { Transition } from '@uirouter/angular';
import { ScreenshotService } from '../../services/screenshot.service';

@Component({
  selector: 'display-details',
  templateUrl: './display-details.component.html',
  styleUrls: ['./display-details.component.scss']
})
export class DisplayDetailsComponent implements OnInit {

  selectedSchedule: any = null;

  @ViewChild('displayForm') displayForm: NgForm;

  constructor(private ngZone: NgZone,
    public displayFactory: DisplayFactory,
    private screenshotService: ScreenshotService,
    private modalService: ModalService,
    private transition: Transition
  ) { }

  ngOnInit(): void {
    const displayId = this.transition.params().displayId;
    this.displayFactory.init();
    this.displayFactory.getDisplay(displayId).then(() => {
      if (this.displayFactory.hasSchedule(this.displayFactory.display)) {
        this.selectedSchedule = {
          id: this.displayFactory.display.scheduleId,
          name: this.displayFactory.display.scheduleName,
          companyId: this.displayFactory.display.companyId
        };
      }

      if (!this.displayFactory.display.playerProAuthorized) {
        this.displayFactory.display.monitoringEnabled = false;
      }

      this.screenshotService.loadScreenshot();

      setTimeout(() => {
        this.ngZone.run(() => {});
      })
    });
  }

  addDisplay() {
    if (!this.displayForm.dirty) {
      this.transition.router.stateService.go('apps.displays.add');
    } else {
      this.modalService.confirm('Unsaved Changes',
          'You have unsaved changes. Do you want to Save or Discard them?',
          'Save', 'Discard')
        .then(() => {
          // do what you need if user presses ok
          this.save().then(() => {
              this.transition.router.stateService.go('apps.displays.add');
            });
        }, () => {
          // do what you need to do if user cancels
            this.transition.router.stateService.go('apps.displays.add');
        });

    }
  }

  save() {
    if (!this.displayForm.valid) {
      console.info('form not valid: ', this.displayForm.errors);

      return Promise.reject();
    } else {
      return this.displayFactory.updateDisplay(this.selectedSchedule);
    }
  }

}
