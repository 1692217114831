<div class="attribute-editor-component" rvSpinner="web-page-editor-loader" [rvShowSpinner]="spinner">
  <div class="attribute-editor-row">
    <div class="form-group has-feedback" [ngClass]="{ 'has-error': validationResult && validationResult !== 'VALID', 'has-success': validationResult === 'VALID' && src !== '' }">
      <label class="control-label" for="te-web-page-src">Web Page URL:</label>
      <input type="text" id="te-web-page-src" name="te-web-page-src" class="form-control u_ellipsis" [(ngModel)]="src" placeholder="Enter URL" (debounceEvent)="srcChanged()" >
      <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{validationResult && validationResult !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
      <p class="help-block" [ngSwitch]="validationResult">
        <span *ngSwitchCase="'INVALID_URL'">Provide a valid and secure URL (https://).</span>
        <span *ngSwitchCase="'LOAD_FAILURE'">The Web Page at the URL provided could not be loaded.</span>
        <span *ngSwitchCase="'FRAME_RESTRICTED'">The owner of the Web Page at the URL provided does not allow the page to be embedded within an iFrame. If possible, please contact the Web Page owner to discuss the below restrictions:</span>
      </p>
      <ul [shown]="validationResult === 'FRAME_RESTRICTED'">
        <li><a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/X-Frame-Options">X-Frame-Options</a></li>
        <li><a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/frame-ancestors">Content-Security-Policy: frame-ancestors</a></li>
      </ul>
    </div>
  </div>
  <div class="attribute-editor-row">
    <div class="form-group">
      <label class="control-label" for="te-web-page-refresh-interval">Refresh Interval:</label>
      <select id="te-web-page-refresh-interval" [(ngModel)]="refresh" class="form-control" (change)="onAttributeChanged()">
        <option value="0">Never Refresh</option>
        <option value="1">1 min</option>
        <option value="5">5 min</option>
        <option value="30">30 min</option>
        <option value="60">60 min</option>
      </select>
    </div>
  </div>
  <div class="attribute-editor-row" [hidden]="true">
    <span class="flex-row">
      <madero-checkbox id="te-web-page-unload" name="te-web-page-unload" ngDefaultControl [(ngModel)]="unload" (ngModelChange)="onAttributeChanged()"></madero-checkbox>
      <label for="te-web-page-unload">Unload Web Page when not visible in Presentation (recommended)</label>
    </span>
  </div>
  <div class="attribute-editor-row">
    <div class="form-group">
      <label class="control-label" for="te-web-page-zoom">Zoom:</label>
      <select id="te-web-page-zoom" [(ngModel)]="zoom" class="form-control" (change)="onAttributeChanged()">
        <option value="50">50%</option>
        <option value="75">75%</option>
        <option value="90">90%</option>
        <option value="100">100%</option>
        <option value="125">125%</option>
        <option value="150">150%</option>
        <option value="200">200%</option>
      </select>
    </div>
  </div>
  <div class="attribute-editor-row">
    <div class="form-group">
      <label class="control-label" for="te-web-page-interactive">Interactivity:</label>
      <span class="flex-row">
        <madero-checkbox id="te-web-page-interactive" name="te-web-page-interactive" ngDefaultControl [(ngModel)]="interactive" (ngModelChange)="onAttributeChanged()"></madero-checkbox>
        <label for="te-web-page-interactive">Allow Interaction</label>
      </span>
    </div>
  </div>
  <div class="attribute-editor-row" [shown]="interactive && zoom <= 100">
    <span class="flex-row">
      <madero-checkbox id="te-web-page-scrollbars" name="te-web-page-scrollbars" ngDefaultControl [(ngModel)]="scrollbars" (ngModelChange)="onAttributeChanged()"></madero-checkbox>
      <label for="te-web-page-scrollbars">Scrollbars Enabled</label>
    </span>
  </div>
</div>
