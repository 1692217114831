<div id="companyRoleModal">
  <div class="modal-header">
    <h2 id="companyRoleTitle" class="modal-title">Help us personalize your experience</h2>
  </div>
  <div class="modal-body">
    <form #companyRoleForm="ngForm" id="forms.companyRoleForm" role="form" name="forms.companyRoleForm" autocomplete="on" novalidate>
      <div class="form-group">
        <label for="company-role" class="control-label">
          Tell us your Company Role to help us make your experience great *
        </label>
        <select name="companyRole" id="company-role" class="form-control selectpicker" [(ngModel)]="user.companyRole" autofocus required>
          <option value="" [shown]="false">&lt; Select Role &gt;</option>
          <option *ngFor="let role of COMPANY_ROLE_FIELDS" value="{{role[1]}}">{{role[0]}}</option>
        </select>
      </div>
    </form>

  </div> <!-- //Modal Body -->

  <div class="modal-footer">
    <button id="saveButton" type="submit" class="btn btn-primary btn-lg btn-block" aria-label="Apply" tabindex="1" form="forms.companyRoleForm" (click)="save()" [disabled]="companyRoleForm.invalid">
      Apply
      <i class="fa fa-check icon-right"></i>
    </button>
  </div>

</div>
