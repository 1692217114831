<div>
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">
    <!-- App Title -->
    <div class="app-header-title">
      <h1 class="m-0" id="title">Displays</h1>
      <div>
        <span [shown]="playerLicenseService.hasProfessionalLicenses()">
          {{ playerLicenseService.getUsedLicenseString() }}
        </span>
      </div>
    </div>
  </div>

  <div rvSpinner="display-loader" [rvShowSpinner]="displayFactory.loadingDisplay">

  <div>
    <form #displayForm="ngForm" id="forms.displayForm" role="form" name="forms.displayForm" (ngSubmit)="save()" autocomplete="on" novalidate>
      <div id="infoBox" class="madero-style alert alert-info u_margin-md-top" role="alert">
        <strong [shown]="isActivating">Activate your Display</strong>
        <strong [shown]="!isActivating">Create a Display</strong>
        <p>Give your display a unique name, like “Hallway Display”. <span [shown]="!hasActivationKey">If you have an Activation Code, you can enter it here.</span></p>
      </div>

      <div class="form-group required" [ngClass]="{'has-error': (displayName.dirty || displayForm?.submitted) && displayName.invalid }">
        <label class="control-label" for="displayName">
          Display Name:
        </label>
        <input #displayName="ngModel" name="displayName" id="display-name" type="text" class="form-control" [(ngModel)]="displayFactory.display.name" placeholder="Name your new display" required>
        <p [shown]="(displayName.dirty || displayForm?.submitted) && displayName.invalid" class="help-block validation-error-message-name text-danger">Please enter a name for your display.</p>
      </div>

      <div class="form-group">
        <label class="control-label" for="activationKey">
          Activation Code:
        </label>
        <input #activationKey name="activationKey" autocomplete="off" maxlength="5" type="text" class="form-control" [ngClass]="{ 'u_uppercase': displayFactory.display.activationKey }" [(ngModel)]="displayFactory.display.activationKey" (ngModelChange)="detectChanges()" placeholder="Enter the code shown on your display">
      </div>

      <div id="errorBox" [shown]="displayFactory.apiError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
        <strong>{{displayFactory.errorMessage}}</strong> {{displayFactory.apiError}}
      </div>

      <div class="button-row text-right mt-5 pt-5">
        <!-- Indicates cancel or non-destructive action -->
        <button id="cancelButton" type="button" uiSref="apps.displays.list" class="btn btn-default btn-toolbar">
          Cancel
        </button>
        <!-- Indicates a successful or positive action -->
        <button id="saveButton" type="submit" class="btn btn-primary btn-toolbar" form="forms.displayForm" [disabled]="displayForm.invalid || displayFactory.savingDisplay" *requireRole="'da'">
          {{ displayFactory.savingDisplay ? 'Saving' : 'Save' }}
        </button>
      </div><!--button-row-->

    </form><!-- displayForm -->
  </div><!--container-->
</div>
