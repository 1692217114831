import { Injectable } from '@angular/core';

import type { Company } from './company';
import type { CompanyLicenseInfo } from './company-license-info';
import { PlansService } from 'src/app/components/plans/plans.service';
import { CompanyApiService } from './company-api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyAllocationService {

  licenseInfo: CompanyLicenseInfo = {};
  allocationMap: { [key: string]: Company } = {};

  assignablePlan = {
    unlimited: [
      this.plansService.getUnlimitedPlan().productCode,
			this.plansService.getPlanByType('unlimited').productCode
    ],
	  enterprise: [
      this.plansService.getEnterprisePlan(false).productCode,
			this.plansService.getEnterprisePlan(true).productCode
    ]
  };

  availablePlan = {};

  constructor(
    private companyApiService: CompanyApiService,
    private plansService: PlansService
  ) { }

  init(): void {
    this.allocationMap = {};
    this.licenseInfo = {};

    this.companyApiService.getLicenseAllocationInfo()
      .then((result) => {
        if (result && result.item && result.item.licenseInfo) {
          this.licenseInfo = result.item.licenseInfo;
        }
      });
  }

  getProductCode(type?: string): string {
    if (type === 'unlimited') {
      return this.plansService.getUnlimitedPlan().productCode;
    } else if (type === 'enterprise') {
      return this.plansService.getEnterprisePlan(false).productCode;
    } else {
      return '';
    }
  }

  getAssignablePlan(productCode: string): string {
    if (productCode === this.plansService.getUnlimitedPlan().productCode ||
      productCode === this.plansService.getPlanByType('unlimited').productCode) {
      return 'unlimited';
    } else if (productCode === this.plansService.getEnterprisePlan(false).productCode ||
      productCode === this.plansService.getEnterprisePlan(true).productCode) {
      return 'enterprise';
    } else {
      return '';
    }
  }

  getLicenseType(company: Company, licenseAllocationEnabled?: boolean): string {
    if (!licenseAllocationEnabled) {
      return 'shared';
    }
    if (company.hasActivePlan && !company.allocatedFromCompanyId) {
      return 'managed';
    }
    return this.getAssignablePlan(company.allocatedProductCode);
  }

  getPurchasedLicenses(type: string): number {
    const info = this.licenseInfo[type.toUpperCase()];
    return info ? info.purchasedLicenseCount : 0;
  }

  getAllocatedLicenses(type: string): number {
    const info = this.licenseInfo[type.toUpperCase()];
    return info ? info.allocatedLicenseCount : 0;
  }

  getRemainingLicenses(type: string): number {
    const info = this.licenseInfo[type.toUpperCase()];
    return info ? Math.max(info.purchasedLicenseCount - info.allocatedLicenseCount, 0) : 0;
  }

  getRequiredLicenses(type?: string): number {
    if (type) {
      const info = this.licenseInfo[type.toUpperCase()];
      return info ? Math.max(info.allocatedLicenseCount - info.purchasedLicenseCount, 0) : 0;
    }
    return this.getRequiredLicenses('enterprise') + this.getRequiredLicenses('unlimited');
  }

  getRequiredLicensesText(type: string): string {
    const count = this.getRequiredLicenses(type);
    return count ? `${count} ${type.charAt(0).toUpperCase()}${type.slice(1).toLowerCase()} license${count > 1 ? 's' : ''}` : '';
  }

  private updateTotals(type: string, count: number): void {
    const info = this.licenseInfo[type.toUpperCase()];
    if (info) {
      info.allocatedLicenseCount = Math.max(info.allocatedLicenseCount + count, 0);
      info.usedLicenseCount = Math.min(info.purchasedLicenseCount, info.allocatedLicenseCount);
    } else {
      this.licenseInfo[type.toUpperCase()] = {
        purchasedLicenseCount: 0,
        allocatedLicenseCount: count,
        usedLicenseCount: 0
      };
    }
  }

  allocateType(company: Company, type?: string): void {
    if (this.getLicenseType(company, company.licenseAllocationEnabled) !== type) {
      const oldPlan = this.getAssignablePlan(company.allocatedProductCode);
      const count = company.allocatedLicenseCount;

      company.allocatedProductCode = this.getProductCode(type);

      if (type === 'unlimited' || type === 'enterprise') {
        company.allocatedLicenseCount = 1;
        this.updateTotals(oldPlan, -count);
        this.updateTotals(type, 1);

      } else if (type === 'none' || !type) {
        company.allocatedLicenseCount = 0;
        this.updateTotals(oldPlan, -count);
      }

      this.allocationMap[company.id] = company;
    }
  }

  allocateCount(company: Company, count: number): void {
    const plan = this.getAssignablePlan(company.allocatedProductCode);
    if (plan) {
      const change = count - (company.allocatedLicenseCount || 0);
      company.allocatedLicenseCount = count;
      this.allocationMap[company.id] = company;
      this.updateTotals(plan, change);
    }
  }

  saveAllocations(licenseAllocationEnabled: boolean): Promise<any> {
    return this.companyApiService.allocateLicenses(this.allocationMap, licenseAllocationEnabled);
  }

}
