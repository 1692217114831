import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { UserState } from 'src/app/ajs-upgraded-providers';

import { PlansService } from 'src/app/components/plans/plans.service';

@Injectable({
  providedIn: 'root'
})
export class ReloadSelectedCompanyService {

  constructor(private userState: UserState,
    private plansService: PlansService) { }

  reload() {
    return this.userState.reloadSelectedCompany();
  }

  _wait(delay) {
    return new Promise(function(resolve) {
      setTimeout(resolve, delay);
    });
  }

  _reloadAsync() {
    return this._wait(10000)
      .then(() => {
        return this.reload();
      });
  }

  reloadWithExpectation(expectedResult, attempt = 0) {
    return this._reloadAsync()
      .then(() => {
        if (attempt >= 3) {
          return false;
        }

        const selectedCompany = this.userState.getCopyOfSelectedCompany(true);
        const expectedCompany = {};

        _.defaults(expectedCompany, expectedResult, selectedCompany);

        if (_.isEqual(expectedCompany, selectedCompany)) {
          return true;
        } else {
          return this.reloadWithExpectation(expectedResult, attempt + 1);
        }
      });
  }

  reloadPlan(plan, displayCount) {
    const unlimitedPlanProductCode = this.plansService.getUnlimitedPlan().productCode;
    let expectedResult : any = {
      playerProTotalLicenseCount: displayCount
    };

    if (plan && plan.indexOf(unlimitedPlanProductCode) !== -1) {
      expectedResult = {
        planProductCode: unlimitedPlanProductCode
      };
    }

    return this.reloadWithExpectation(expectedResult);
  }

}
