import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { GadgetApiService } from './gadget-api.service';
import { BaseList } from 'src/app/components/scrolling-list/base-list';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GadgetService {

  static readonly EMBEDDED_PRESENTATIONS_CODE = 'd3a418f1a3acaed42cf452fefb1eaed198a1c620';

  private _gadgets = [
    {
      gadgetType: 'presentation',
      id: 'presentation',
      name: 'Embedded Presentation',
      productCode: GadgetService.EMBEDDED_PRESENTATIONS_CODE
    }
  ];

  loadingGadget = false;
  apiError = '';

  constructor(private gadgetApiService: GadgetApiService) { }

  private _getGadgetByIdCached (gadgetId) {
    var cachedGadget = _.find(this._gadgets, {
      id: gadgetId
    });

    return cachedGadget;
  }

  private _updateGadgetCache (newGadget) {
    if (!this._getGadgetByIdCached(newGadget.id)) {
      this._gadgets.push(newGadget);
    }
  }

  getGadgetById (gadgetId?) {
    var cachedGadget = this._getGadgetByIdCached(gadgetId);

    if (cachedGadget) {
      return Promise.resolve(cachedGadget);
    } else {
      //show loading spinner
      this.loadingGadget = true;

      return this.gadgetApiService.get(gadgetId)
        .then((result) => {
          this._updateGadgetCache(result.item);

          return result.item;
        })
        .catch((e) => {
          this.apiError = e.message ? e.message : e.toString();
          throw e;
        })
        .finally(() => {
          this.loadingGadget = false;
        });
    }
  }

  private _getGadgetByProductCached (productCode) {
    var cachedGadget = _.find(this._gadgets, {
      'productCode': productCode
    });

    return cachedGadget;
  }

  getGadgetByProduct (productCode) {
    var cachedGadget = this._getGadgetByProductCached(productCode);

    if (cachedGadget) {
      return Promise.resolve(cachedGadget);
    } else {
      //show loading spinner
      this.loadingGadget = true;

      var search = new BaseList();
      search.productCodes = [productCode];

      return this.gadgetApiService.list(search)
        .then((result) => {
          if (result.items && result.items[0]) {
            this._updateGadgetCache(result.items[0]);

            return result.items[0];
          } else {
            this.apiError =
              'No Gadgets found via the Product Code:' + productCode;
            throw new Error();
          }
        })
        .catch((e) => {
          this.apiError = e.message ? e.message : e.toString();

          throw e;
        })
        .finally(() => {
          this.loadingGadget = false;
        });
    }
  }
}

angular.module('risevision.editor.services')
  .factory('gadgetFactory', downgradeInjectable(GadgetService));