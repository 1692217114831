import { Component } from '@angular/core';
import { CompanyAllocationService } from '../../services/company-allocation.service';

@Component({
  selector: 'company-license-totals',
  templateUrl: './company-license-totals.component.html',
  styleUrls: ['./company-license-totals.component.scss']
})
export class CompanyLicenseTotalsComponent {

  constructor(
    public companyAllocationService: CompanyAllocationService
  ) {
  }

}
