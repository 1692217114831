import { Injectable } from '@angular/core';
import { ComponentsService, PlaylistComponentItem, PlaylistComponentInfo } from '../../services/components.service';
import { BasePlaylistService, BasePlaylistTemplateItem } from './base-playlist.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class UserComponentsService extends BasePlaylistService {

  playlistComponents: PlaylistComponentItem[] = ComponentsService.USER_COMPONENTS_ARRAY;

  private _getBaseAttributes(type: string) {
    return {
      'id': 'uc-' + type + '-' + Date.now(),
      'tagName': type,
      'userComponent': true
    }
  }

  private _addUserComponentsItem(item, editItem: boolean = true) {
    this.playlistItems.unshift(item);

    // save the added component to attribute data
    this.attributeDataService.addUserComponent(cloneDeep(item));

    this.analyticsFactory.track('User Component Added', {
      componentType: item.tagName,
    });

    if (editItem) {
      this.editComponent(item);
    }
  }

  protected getComponentObject(item: BasePlaylistTemplateItem): PlaylistComponentInfo {
    return {
      type: item.tagName,
      id: item.id
    };
  }

  addItem(type: string, options?: any): void {
    if (type === 'rise-folder') {
      return this.showMediaSelector(options);
    }

    const component = this.getComponentByType(type);
    const baseAttributes = this._getBaseAttributes(type);
    const defaultAttributes = cloneDeep(component.defaultAttributes) || {}

    const item = {...baseAttributes,...defaultAttributes};

    this._addUserComponentsItem(item);
  }

  addUserComponentsMediaItems(itemsToAdd: any[], editItem?: boolean) {
    // we're only supporting single file, so the item to add is always first index
    const itemToAdd = itemsToAdd[0];

    const baseAttributes = this._getBaseAttributes('rise-folder');
    const itemAttributes = itemToAdd.attributes ? cloneDeep(itemToAdd.attributes) : {};

    const item = {...baseAttributes,...itemAttributes};

    this._addUserComponentsItem(item, editItem);
  }

  showMediaSelector(options?: any) {
    super.showMediaSelector(options);

    this.mediaSelectorService.onUserComponentAdd = this.addUserComponentsMediaItems.bind(this);
  }
}
