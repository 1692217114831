<div class="pt-3 mb-5">
  <div id="pricing-plan-grid" [ngClass]="{ 'with-unlimited': canAccessUnlimitedPlan }">
    <div id="plan-1-title" class="u_clickable top-grid-row pb-2" [ngClass]="{ 'selected' : isBasicPlan && !isUnlimitedPlan, 'disabled' : planType === 'basic' }" (click)="selectBasic()">
      <div class="text-center u_position-relative px-xl-4" *ngIf="canAccessUnlimitedPlan">
        <div *ngIf="planType === 'basic'" class="badge badge-current-plan">Current Plan</div>
        <h2>Basic</h2>
        <p>A few displays to get started</p>
      </div>
      <div class="text-center px-xl-4" *ngIf="!canAccessUnlimitedPlan">
        <div *ngIf="planType === 'basic'" class="badge badge-current-plan">Current Plan</div>
        <h2>Basic</h2>
        <p>Getting started with a few displays</p>
      </div>
    </div>
    <div id="plan-2-title" class="u_clickable top-grid-row pb-2" [ngClass]="{ 'selected' : isAdvancedPlan && !isUnlimitedPlan, 'disabled' : planType === 'advanced' }" (click)="selectAdvanced()">
      <div class="text-center u_position-relative px-xl-4" *ngIf="canAccessUnlimitedPlan">
        <div *ngIf="planType === 'advanced'" class="badge badge-current-plan">Current Plan</div>
        <h2>Advanced</h2>
        <p>Displays throughout the school, in the hallways and common areas</p>
      </div>
      <div class="text-center px-xl-4" *ngIf="!canAccessUnlimitedPlan">
        <div *ngIf="planType === 'advanced'" class="badge badge-current-plan">Current Plan</div>
        <h2>Advanced</h2>
        <p>Growing display network and content management needs</p>
      </div>
    </div>
    <div id="plan-3-title" class="u_clickable top-grid-row pb-2" [ngClass]="{ 'selected' : isUnlimitedPlan || isEnterprisePlan, 'disabled' : planType === 'enterprise' && !canAccessUnlimitedPlan }" (click)="selectEnterpriseOrUnlimited()">
      <div class="text-center u_position-relative px-xl-4">
        <div *ngIf="planType === 'enterprise'" class="badge badge-current-plan">Current Plan</div>
        <h2>Enterprise</h2>
        <p>
          Display network across multiple locations{{ canAccessUnlimitedPlan ? ',' : ' and' }} network management
          tools{{ canAccessUnlimitedPlan ? ' and a fixed price per school for unlimited displays' : '' }}.
        </p>
      </div>
    </div>
    <div id="plan-1-price" class="u_align-middle text-center text-xl font-weight-bold u_clickable" [ngClass]="{ 'selected' : isBasicPlan && !isUnlimitedPlan, 'disabled' : planType === 'basic' }" (click)="selectBasic()">
      <div class="px-4">{{ basicPricePerDisplay | currency }} per display<wbr> per&nbsp;month</div>
    </div>
    <div id="plan-2-price" class="u_align-middle text-center text-xl font-weight-bold u_clickable" [ngClass]="{ 'selected' : isAdvancedPlan && !isUnlimitedPlan, 'disabled' : planType === 'advanced' }" (click)="selectAdvanced()">
      <div class="px-4">{{ advancedPricePerDisplay | currency }} per display<wbr> per&nbsp;month</div>
    </div>
    <div id="plan-3-price" class="flex-row px-0 px-lg-2 px-xl-4" [ngClass]="{ 'selected' : isUnlimitedPlan || isEnterprisePlan, 'disabled' : planType === 'enterprise' && !canAccessUnlimitedPlan, 'u_align-middle': !canAccessUnlimitedPlan }" >
      <div *ngIf="canAccessUnlimitedPlan" class="flex-50 u_align-middle text-center text-xl font-weight-bold u_clickable" (click)="selectEnterpriseOrUnlimited()">
        <div class="px-4">Unlimited Displays<br>{{ unlimitedMonthlyPrice | currency }} per school<wbr> per&nbsp;month</div>
      </div>
      <div [ngClass]="{ 'flex-50': canAccessUnlimitedPlan }" class="u_align-middle text-center text-xl font-weight-bold u_clickable" (click)="selectEnterpriseOrUnlimited()">
        <div class="px-4">{{ enterprisePricePerDisplay | currency }} per display<wbr> per&nbsp;month</div>
      </div>
    </div>

    <div id="plan-1-frequency" class="py-4 u_align-middle u_clickable" [ngClass]="{ 'selected' : isBasicPlan && !isUnlimitedPlan, 'disabled' : planType === 'basic' }" (click)="selectBasic()">
      <div class="text-center" *ngIf="!subscriptionId">
        <div class="btn-group btn-group-justified btn-group-period mb-0">
          <button type="button" class="btn w-50" [ngClass]="{'btn-toggle-blue-off' : !periodMonthly, 'btn-toggle-blue-on' : periodMonthly}" (click)="updatePeriodMonthly(true)">
            Monthly
            <streamline-icon name="checkmark" [shown]="periodMonthly"></streamline-icon>
          </button>
          <button type="button" class="btn w-50" [ngClass]="{'btn-toggle-blue-off' : periodMonthly, 'btn-toggle-blue-on' : !periodMonthly}" (click)="updatePeriodMonthly(false)">
            Annually
            <streamline-icon name="checkmark" [shown]="!periodMonthly"></streamline-icon>
          </button>
        </div>
      </div>
      <div class="text-center" *ngIf="!!subscriptionId">
        <div>Paid Annually</div>
      </div>
    </div>
    <div id="plan-2-frequency" class="py-4 u_align-middle u_clickable" [ngClass]="{ 'selected' : isAdvancedPlan && !isUnlimitedPlan, 'disabled' : planType === 'advanced' }" (click)="selectAdvanced()">
      <div class="text-center">
        <div>Paid Annually</div>
      </div>
    </div>
    <div id="plan-3-frequency" class="py-4 u_clickable u_align-middle px-0 px-lg-2 px-xl-4" [ngClass]="{ 'selected' : isUnlimitedPlan || isEnterprisePlan, 'disabled' : planType === 'enterprise' && !canAccessUnlimitedPlan }" (click)="selectEnterpriseOrUnlimited()">
      <div class="flex-row text-center" [ngClass]="{ 'u_align-middle': !canAccessUnlimitedPlan }">
        <div class="flex-50" *ngIf="canAccessUnlimitedPlan">Paid Annually</div>
        <div [ngClass]="{ 'flex-50': canAccessUnlimitedPlan }">Paid Annually</div>
      </div>
    </div>
    <div id="plan-1-select" class="py-4 text-center border-top border-bottom u_nowrap u_clickable" [ngClass]="{ 'selected': isBasicPlan && !isUnlimitedPlan, 'disabled': planType === 'basic' || allowMultiple }" (click)="selectBasic()">
      <madero-checkbox name="selectBasic" checkboxId="selectBasic" [ngValue]="isBasicPlan && !isUnlimitedPlan"></madero-checkbox>
      <label class="text-blue u_clickable">Select this plan</label>
      <div [shown]="!planType && isBasicPlan">
        <div class="quantity input-group pt-3 px-5">
          <span class="input-group-btn">
            <button class="btn btn-secondary mr-0" type="button" (click)="updateDisplayCount(-1)">-</button>
          </span>
          <input class="form-control font-weight-bold text-center" type="number" name="displayCount" [(ngModel)]="displayCount" min="1">
          <span class="input-group-btn">
            <button class="btn btn-secondary" type="button" (click)="updateDisplayCount(1)">+</button>
          </span>
        </div>
        <div class="pt-3">Basic Licenses</div>
      </div>
    </div>
    <div id="plan-2-select" class="py-4 text-center border-top border-bottom u_nowrap u_clickable" [ngClass]="{ 'selected': isAdvancedPlan && !isUnlimitedPlan, 'disabled': planType === 'advanced' || allowMultiple }" (click)="selectAdvanced()">
      <madero-checkbox name="selectAdvanced" checkboxId="selectAdvanced" [ngValue]="isAdvancedPlan && !isUnlimitedPlan"></madero-checkbox>
      <label class="text-blue u_clickable">Select this plan</label>
      <div [shown]="!planType && isAdvancedPlan">
        <div class="quantity input-group pt-3 px-5">
          <span class="input-group-btn">
            <button class="btn btn-secondary mr-0" type="button" (click)="updateDisplayCount(-1)">-</button>
          </span>
          <input class="form-control font-weight-bold text-center" type="number" name="displayCount" [(ngModel)]="displayCount" min="1">
          <span class="input-group-btn">
            <button class="btn btn-secondary" type="button" (click)="updateDisplayCount(1)">+</button>
          </span>
        </div>
        <div class="pt-3">Advanced Licenses</div>
      </div>
    </div>
    <div id="plan-3-select" class="flex-row justify-center align-start py-4 text-center border-top border-bottom u_nowrap u_clickable px-0 px-md-2 px-xl-4" [ngClass]="{ 'selected' : isUnlimitedPlan || isEnterprisePlan }">
      <div class="flex-50" *ngIf="canAccessUnlimitedPlan" [ngClass]="{ 'disabled': planType === 'unlimited' }">
        <div (click)="selectUnlimited()">
          <madero-checkbox name="selectUnlimited" checkboxId="selectUnlimited" [ngValue]="isUnlimitedPlan"></madero-checkbox>
          <label class="text-blue u_clickable">Select this plan</label>
        </div>
        <div [shown]="isUnlimitedPlan && allowMultiple">
          <div class="quantity input-group pt-3 px-2 px-lg-5">
            <span class="input-group-btn">
              <button class="btn btn-secondary mr-0" type="button" (click)="updateUnlimitedCount(-1)">-</button>
            </span>
            <input class="form-control font-weight-bold text-center" type="number" name="displayCount" [(ngModel)]="unlimitedCount" min="1">
            <span class="input-group-btn">
              <button class="btn btn-secondary" type="button" (click)="updateUnlimitedCount(1)">+</button>
            </span>
          </div>
          <div class="pt-3">Unlimited Licenses</div>
        </div>
      </div>
      <div [ngClass]="{ 'flex-50': canAccessUnlimitedPlan, 'disabled': planType === 'enterprise' }">
        <div (click)="selectEnterprise()">
          <madero-checkbox name="selectEnterprise" checkboxId="selectEnterprise" [ngValue]="isEnterprisePlan"></madero-checkbox>
          <label class="text-blue u_clickable">Select this plan</label>
        </div>
        <div [shown]="!planType && isEnterprisePlan">
          <div class="quantity input-group pt-3 px-2 px-lg-5">
            <span class="input-group-btn">
              <button class="btn btn-secondary mr-0" type="button" (click)="updateDisplayCount(-1)">-</button>
            </span>
            <input class="form-control font-weight-bold text-center" type="number" name="displayCount" [(ngModel)]="displayCount" min="1">
            <span class="input-group-btn">
              <button class="btn btn-secondary" type="button" (click)="updateDisplayCount(1)">+</button>
            </span>
          </div>
          <div class="pt-3">Enterprise Licenses</div>
        </div>
      </div>
    </div>
    <div id="plan-1-features" class="feature-list bottom-grid-row" [ngClass]="{ 'selected' : isBasicPlan && !isUnlimitedPlan }">
      <ul>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          500+ Professionally Designed <span class="u_nowrap">Templates
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'No design experience required! Customize professionally designed templates for any use such as events, weather, sports, and many more.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          New Templates Added <span class="u_nowrap">Weekly
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'New templates added based on recommendations and suggestions of customers like yourself.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          {{ canAccessUnlimitedPlan ? 'School Specific Content' : 'Special Content' }} (Random Act of Kindness, Good News Network, &amp; <span class="u_nowrap">more)
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            popover="Templates with content you can trust from reputable known partners like Random Acts of Kindness CASEL.org, and more to help promote learning and positive {{ canAccessUnlimitedPlan ? 'school' : '' }} culture.">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Runs On Any Device Any <span class="u_nowrap">OS
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Rise Vision runs on a wide range of digital signage media players, wireless presentation systems, and interactive flat panels to fit your needs and budget.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Unlimited <span class="u_nowrap">Users
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Create content collaboratively with any number of users in your account.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Offline <span class="u_nowrap">Play
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Stores content locally so that your display is always running regardless of connection status.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Unlimited Content Storage (photos, videos, <span class="u_nowrap">presentations)
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Save all your photos, videos and presentations in Rise Vision with unlimited storage.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Content Integrations (Google Slides, YouTube, Weather &amp; many <span class="u_nowrap">more)
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Automatically pull content from the services you already know and love.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Full Editor To Design Your Own <span class="u_nowrap">Presentations
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Don&rsquo;t want to use our templates? No problem, you can create your own presentations with our editor.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Scheduling &amp; <span class="u_nowrap">Playlists
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Show the content you want at the time you want with easy customizable schedules and playlists.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Support Replies Within A Business <span class="u_nowrap">Hour
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Fast and helpful customer support within a business hour.'">
          </streamline-icon></span>
        </li>
      </ul>
    </div>
    <div id="plan-2-features" class="feature-list bottom-grid-row" [ngClass]="{ 'selected' : isAdvancedPlan && !isUnlimitedPlan }">
      <ul>
        <li class="mt-3 mb-2"><strong>Everything In Basic, Plus:</strong></li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Emergency Alerts From A Service That Supports CAP (Common Alerting <span class="u_nowrap">Protocol)
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Receive emergency alerts from any service that supports CAP (Common Alerting Protocol), such as Singlewire, Punch Alert, E2Campus, Rave, Alertus, CrisisGo, and configure those alerts to immediately show on any or all of your active displays.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Get Notified When Your Display Goes <span class="u_nowrap">Offline
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Continuously monitor the status of your display and receive an email notification if your display goes offline.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          {{ canAccessUnlimitedPlan ? 'School Wide Scheduling' : 'Scheduling Overrides In' }} <span class="u_nowrap">
          {{ canAccessUnlimitedPlan ? 'Overrides' : 'Company' }}
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            popover="Show priority messages on all displays in your {{ canAccessUnlimitedPlan ? 'school' : 'company' }} either immediately or as part of your regularly scheduled content.">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Set Brand Logo &amp; Colors Once, Apply To All <span class="u_nowrap">Content
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Automatically add your logo and colors to all of your Templates saving you time and helping you maintain a consistent look.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Display <span class="u_nowrap">Control
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Automatically turn your display on or off according to the schedule it is assigned to allowing you to conserve electrical usage and extend the life of your hardware.'">
          </streamline-icon></span>
        </li>
      </ul>
    </div>
    <div id="plan-3-features" class="feature-list bottom-grid-row" [ngClass]="{ 'selected' : isUnlimitedPlan || isEnterprisePlan }">
      <ul>
        <li class="mt-3 mb-2"><strong>Everything In Advanced, Plus:</strong></li>
        <li [shown]="canAccessUnlimitedPlan">
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Unlimited Displays (with Unlimited <span class="u_nowrap">plan)
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Show content on every display in your school for a fixed price.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          {{ canAccessUnlimitedPlan ? 'Classroom' : 'Automatic Alerts' }} <span class="u_nowrap">
          {{ canAccessUnlimitedPlan ? 'Alerts' : 'Overrides' }}
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Show emergency alerts or announcements on any display in your network regardless of whether it is currently showing a Rise Vision presentation.'">
          </streamline-icon></span>
        </li>
        <li [shown]="canAccessUnlimitedPlan">
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Teacher Specific User <span class="u_nowrap">Role
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            [popover]="'Assign displays to your teachers allowing them to manage their content in a simple interface.'">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          {{ canAccessUnlimitedPlan ? 'District Wide Scheduling' : 'Scheduling Overrides In' }} <span class="u_nowrap">
          {{ canAccessUnlimitedPlan ? 'Overrides' : 'Sub-Companies' }}
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            popover="Show priority messages on all displays in all {{ canAccessUnlimitedPlan ? 'schools' : 'companies' }} throughout your {{ canAccessUnlimitedPlan ? 'district' : 'network' }} either immediately or as part of your regularly scheduled content.">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Share Custom Templates {{ canAccessUnlimitedPlan ? 'Throughout' : 'With' }} Your <span class="u_nowrap">
          {{ canAccessUnlimitedPlan ? 'District' : 'Organization' }}
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            popover="Save custom presentations as templates and share them throughout your {{ canAccessUnlimitedPlan ? 'district' : 'network' }}.">
          </streamline-icon></span>
        </li>
        <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Single Sign On
          <span class="u_nowrap">(SSO)
            <streamline-icon
              name="help"
              class="help"
              [outsideClick]="true"
              [popover]="'User authentication with Google, Microsoft or ClassLink for added security.'">
            </streamline-icon>
          </span>
        </li>
        <!-- <li>
          <streamline-icon name="checkmark" class="checkmark"></streamline-icon>
          Bulk Actions For Presentations, Users, Displays, Schedules, &amp; <span class="u_nowrap">{{ canAccessUnlimitedPlan ? 'Schools' : 'Companies' }}
          <streamline-icon
            name="help"
            class="help"
            [outsideClick]="true"
            popover="Move, import and export multiple presentations, schedules, displays, users or {{ canAccessUnlimitedPlan ? 'schools' : 'companies' }} with ease.">
          </streamline-icon></span>
        </li> -->
      </ul>
    </div>
    <div id="plan-spacer-1" class="spacer">&nbsp;</div>
    <div id="plan-spacer-2" class="spacer">&nbsp;</div>
    <div id="plan-spacer-3" class="spacer">&nbsp;</div>
  </div>
</div>
<div class="border-container madero-style w-100 mx-0 p-5 mb-5">
  <div class="text-center mb-5 mx-2 px-0 mx-md-3 px-md-3 mx-lg-5 px-lg-5" [shown]="isUnlimitedPlan">
    <p>
      <span class="font-weight-bold">Note:</span> An unlimited license is a site license per school building. If purchasing for a school district or network of schools, you need to purchase a separate license for each school. If you have any questions, our <a href="https://www.risevision.com/contact-us?form_selected=sales&content_hide=true" target="_blank">Sales Team</a> is happy to help!
    </p>
  </div>
  <div class="row">
    <div [ngClass]="{ 'col-sm-offset-1': !(isPerDisplayPlan && isUnlimitedPlan) }">
      <div class="row pb-2">
        <div class="col-sm-12 pl-4">
          <p class="text-xl font-weight-bold mb-3">Pricing</p>
        </div>
      </div>
      <div class="pricing-calculator text-left text-lg" [ngClass]="{ 'simple-calculator': !(isPerDisplayPlan && isUnlimitedPlan) }">

        <!-- Top row (license counts) -->

        <div id="total-unlimited-count" *ngIf="isUnlimitedPlan" class="number px-2 align-right">{{ unlimitedCount }}</div>
        <div id="total-unlimited-label" *ngIf="isUnlimitedPlan" class="pl-2 pt-2 mt-1">Unlimited Licenses</div>

        <div id="total-spacer-1-1" *ngIf="isPerDisplayPlan && isUnlimitedPlan">&nbsp;</div>

        <div id="total-display-count" *ngIf="isPerDisplayPlan" class="number px-2 align-right">{{ displayCount }}</div>
        <div id="total-display-label" *ngIf="isPerDisplayPlan" class="pl-2 pt-2 mt-1">Display Licenses</div>

        <div id="total-spacer-1-2" *ngIf="isPerDisplayPlan && isUnlimitedPlan">&nbsp;</div>
        <div id="total-spacer-1-3" *ngIf="isPerDisplayPlan && isUnlimitedPlan">&nbsp;</div>

        <!-- Middle row (subtotals) -->

        <div id="total-unlimited-times" *ngIf="isUnlimitedPlan" class="number px-2 align-right">&times;</div>
        <div id="total-unlimited-price" *ngIf="isUnlimitedPlan" class="price-text pb-2">
          <div class="number px-2">{{ unlimitedPrice | currency }}</div>
          <div class="aligner-item--bottom pl-2 pb-1">Per year, billed annually</div>
        </div>

        <div id="total-spacer-2-1" *ngIf="isPerDisplayPlan && isUnlimitedPlan">&nbsp;</div>

        <div id="total-display-times" *ngIf="isPerDisplayPlan" class="number px-2 align-right">&times;</div>
        <div id="total-display-price" *ngIf="isPerDisplayPlan" class="price-text pb-2">
          <div class="number px-2" *ngIf="pricePerDisplay === baseDisplayPrice">{{ baseDisplayPrice | currency }}</div>
          <div class="number px-2" *ngIf="pricePerDisplay !== baseDisplayPrice">
            <del>{{ baseDisplayPrice | currency }}</del>
            <span> {{ pricePerDisplay | currency }}</span>
          </div>
          <div class="aligner-item--bottom pl-2 pb-1">Per display, per month</div>
        </div>

        <div id="total-spacer-2-2" *ngIf="isPerDisplayPlan && isUnlimitedPlan">&nbsp;</div>
        <div id="total-spacer-2-3" *ngIf="isPerDisplayPlan && isUnlimitedPlan">&nbsp;</div>

        <!-- Bottom row (totals) -->

        <div id="total-spacer-3-1" *ngIf="isUnlimitedPlan" class="border-top">&nbsp;</div>
        <div id="total-unlimited-total" *ngIf="isUnlimitedPlan" class="pl-2 pt-2 border-top price-text">
          <div class="number mr-3">{{ unlimitedTotal | currency }}</div>
          <div class="aligner-item--bottom pb-1">Billed annually</div>
        </div>

        <div id="total-addition" *ngIf="isPerDisplayPlan && isUnlimitedPlan" class="number pt-2 border-top">+</div>

        <div id="total-spacer-3-2" *ngIf="isPerDisplayPlan" class="border-top">&nbsp;</div>
        <div id="total-display-total" *ngIf="isPerDisplayPlan" class="pl-2 pt-2 border-top price-text">
          <div class="number mr-3">{{ displayTotal | currency }}</div>
          <div class="aligner-item--bottom pb-1">
            <span *ngIf="periodMonthly && isBasicPlan">Billed monthly</span>
            <span *ngIf="!periodMonthly || !isBasicPlan">Billed annually</span>
            <span *ngIf="yearlySavings > 0"><span class="label label-primary ml-2">save {{ yearlySavings | currency }}!</span></span>
          </div>
        </div>

        <div id="total-equals" *ngIf="isPerDisplayPlan && isUnlimitedPlan" class="number border-top">=</div>

        <div id="total-grand-total" *ngIf="isPerDisplayPlan && isUnlimitedPlan" class="pl-2 border-top price-text">
          <div class="number mr-3">{{ totalPrice | currency }}</div>
          <div class="aligner-item--bottom pb-1">
            <span *ngIf="periodMonthly && isBasicPlan">Billed monthly</span>
            <span *ngIf="!periodMonthly || !isBasicPlan">Billed annually</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>