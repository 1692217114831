import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { StoreProductService, StoreAuthorizationService } from 'src/app/ajs-upgraded-providers';

import { WidgetUtilsService } from './widget-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  static readonly TEMPLATES_TYPE = 'Templates';
  static readonly UNLISTED_STORE_PRODUCT = {
    'productId': '111',
    'productOrderWeight': 5,
    'name': 'U.S. Stocks - Streaming Watchlist',
    'descriptionShort': 'Create a streaming watchlist of up to 30 U.S. equities.',
    'imageUrl': 'https://s3.amazonaws.com/Store-Products/StockTrak/widget_918_image_edit1.png',
    'paymentTerms': 'Subscription',
    'trialPeriod': 7,
    'productCode': '7949cc2b1ab2b77f2ce48d23c5aae55b9d4d27d6'
  };

  private _professionalWidgets;

  constructor(
    private widgetUtils: WidgetUtilsService,
    private storeProduct: StoreProductService,
    private storeAuthorization: StoreAuthorizationService
  ) {
    this._professionalWidgets = this.widgetUtils.getProfessionalWidgets();
  }

  private _getUnlistedProducts () {
    var productCode = ProductsService.UNLISTED_STORE_PRODUCT.productCode;

    return this.storeAuthorization.check(productCode)
      .then(() => {
        return [ProductsService.UNLISTED_STORE_PRODUCT];
      })
      .catch(() => {
        return [];
      });
  }

  private _filter (results, search) {
    if (search && search.query) {
      return results.filter(result => result && result.name && result.name.includes(search.query));
    } else {
      return results;
    }
  }

  private _loadProducts (search, cursor?) {
    var unlistedProducts = [];
    var filteredProfessionalWidgets = [];
    if (search && search.category !== ProductsService.TEMPLATES_TYPE) {
      unlistedProducts = this._getUnlistedProducts();
      filteredProfessionalWidgets = this._filter(this._professionalWidgets, search);
    }

    return Promise.all([this.storeProduct.list(search, cursor), unlistedProducts])
      .then((results) => {
        var dummyProductCode = 1;
        var filteredStoreProducts = results[0].items || [];
        var filteredUnlistedProducts = this._filter(results[1], search);
        var result = {
          items: []
        };

        result.items = _.union(filteredProfessionalWidgets, filteredUnlistedProducts, filteredStoreProducts);
        result.items = _.uniqBy(result.items, (product) => {
          return product.productCode || dummyProductCode++;
        });

        return result;
      });
  }

  loadProducts = this._loadProducts.bind(this);
}