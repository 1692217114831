<div class="presentation-selector-container attribute-editor-component" rvSpinner="presentation-selector-loader" [rvShowSpinner]="playlistComponentFactory.templates.loadingItems">

  <div class="playlist-templates-title active-search">
    <label>Choose A Presentation</label>
  </div>
  <search-filter class="templates-selector-search-box" [filterConfig]="filterConfig" [search]="playlistComponentFactory.search" [doSearch]="playlistComponentFactory.templates.doSearch" iconSet="madero"></search-filter>

  <div class="templates-selector te-scrollable-container active-search"
       scrollingList (scrollEndEvent)="playlistComponentFactory.templates.load()">
    <div id="te-playlist-no-results" class="templates-selector-no-results" [shown]="!(playlistComponentFactory.templates.items.list.length > 0)">
      No Presentations found. To create a new Presentation <a (click)="createNewTemplate()">click here</a>.
    </div>

    <div class="row templates-row" [shown]="playlistComponentFactory.templates.items.list.length > 0"
        *ngFor="let template of playlistComponentFactory.templates.items.list"
        (click)="playlistComponentFactory.templates.select(template)">
      <madero-checkbox id="{{template.id}}" [ngValue]="template.selected"></madero-checkbox>
      <label class="templates-selector-name u_text-ellipsis" for="{{template.id}}">
        <span class="templates-name">{{ template.name }}</span>
        <div class="templates-status font-weight-normal" [ngClass]="{'templates-status-revised' : template.revisionStatusName === 'Revised'}">{{ template.revisionStatusName }}</div>
      </label>
    </div>
  </div>

  <div class="playlist-action-button-bar">
    <div class="add-templates">
      <button id="te-playlist-add-template" class="btn btn-primary btn-block" (click)="addTemplates()" [disabled]="!playlistComponentFactory.templates.getSelected().length">
        Add Presentation
      </button>
    </div>
  </div>

</div>
