<div class="form-group required" [ngClass]="{'has-error': (name.dirty || companyForm?.submitted) && name.invalid }" *ngIf="!company.id">
  <label for="company-settings-name" class="control-label">
    Company Name:
  </label>
  <input #name="ngModel" required id="company-settings-name" type="text" class="form-control"
    [(ngModel)]="company.name" name="name">
  <p [shown]="(name.dirty || companyForm?.submitted) && name.invalid" class="help-block validation-error-message-name text-danger">Company Name is required.</p>
</div>

<div class="row">
  <div class="{{showManagedSections ? 'col-md-8' : 'col-md-12'}} mb-4">
    <div class="pl-4 pr-4 pt-1 border-container">
      <div class="row">
        <div class="col-md-12 mb-2">
          <h5>Contact Information</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group required" [ngClass]="{'has-error': (companyIndustry.dirty || companyForm?.submitted) && companyIndustry.invalid }">
            <label for="company-industry" class="control-label">
              Industry:
            </label>
            <select #companyIndustry="ngModel" id="company-industry" class="form-control selectpicker" [disabled]="disableIndustry" [(ngModel)]="company.companyIndustry" name="companyIndustry" required>
              <option [value]="undefined" hidden>&lt; Select Industry &gt;</option>
              <option *ngFor="let industry of COMPANY_INDUSTRY_FIELDS" value="{{industry[1]}}">{{industry[0]}}</option>
            </select>
            <p [shown]="(companyIndustry.dirty || companyForm?.submitted) && companyIndustry.invalid" class="help-block validation-error-message-name text-danger">Please select an Industry.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="company-size" class="control-label">
              Company Size:
            </label>
            <select #companySize name="companySize" id="company-size" class="form-control" [(ngModel)]="company.companySize">
              <option [value]="undefined" hidden>&lt; Select Size &gt;</option>
              <option *ngFor="let size of COMPANY_SIZE_FIELDS" value="{{size[1]}}">{{size[0]}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="company-settings-street" class="control-label">
              Street:
            </label>
            <input #street id="company-settings-street" type="text" class="form-control" [(ngModel)]="company.street" name="street" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="company-settings-unit" class="control-label">
              Unit:
            </label>
            <input #unit id="company-settings-unit" type="text" class="form-control" [(ngModel)]="company.unit" name="unit" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="company-settings-city" class="control-label">
              City:
            </label>
            <input #city id="company-settings-city" type="text" class="form-control" [(ngModel)]="company.city" name="city" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="company-settings-country" class="control-label">
              Country:
            </label>
            <select #country id="company-settings-country" class="form-control selectpicker"
              [(ngModel)]="company.country" (change)="detectChanges()" name="country">
              <option [value]="undefined" hidden>&lt; Select Country &gt;</option>
              <option *ngFor="let c of countries" [value]="c.code">{{c.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="company-settings-state" class="control-label u_ellipsis u_align-top">
              State/Province/Region:
            </label>
            <input #region id="company-settings-state" type="text" class="form-control" [(ngModel)]="company.province" name="province" *ngIf="company.country !== 'US' && company.country !== 'CA'" />
            <select #province class="form-control selectpicker" [(ngModel)]="company.province" name="province" *ngIf="company.country === 'CA'">
              <option [value]="undefined" hidden>&lt; Select Province &gt;</option>
              <option *ngFor="let c of regionsCA" [value]="c[1]">{{c[0]}}</option>
            </select>
            <select #state class="form-control selectpicker" [(ngModel)]="company.province" name="province" *ngIf="company.country === 'US'">
              <option [value]="undefined" hidden>&lt; Select State &gt;</option>
              <option *ngFor="let c of regionsUS" [value]="c[1]">{{c[0]}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="company-settings-zip" class="control-label">
              ZIP/Postal Code:
            </label>
            <input #postalCode id="company-settings-zip" type="text" class="form-control" [(ngModel)]="company.postalCode" name="postalCode" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" [ngClass]="{'has-error': website.errors?.pattern}">
            <label for="company-settings-website" class="control-label">
              Website:
            </label>
            <input #website="ngModel" id="company-settings-website" name="website" type="text" placeholder="https://" class="form-control" [(ngModel)]="company.website"
            [pattern]="WEBSITE_REGEX" />
            <p [shown]="website.errors?.pattern" class="help-block validation-error-message-website text-danger">Please provide a valid URL.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="company-settings-phone" class="control-label">
              Phone Number:
            </label>
            <input #telephone name="telephone" id="company-settings-phone" type="tel" class="form-control" [(ngModel)]="company.telephone"/>
          </div>
        </div>
      </div>

      <div class="form-group" [ngClass]="{'has-error': billingContactEmails.invalid}">
        <label class="control-label">Billing Notifications Email:</label>
        <emails-field #billingContactEmails="ngModel" name="billingContactEmails" [(ngModel)]="company.billingContactEmails" require-emails-on-change="true"></emails-field>
        <p class="help-block text-danger" [shown]="billingContactEmails.errors?.emails">Please provide a valid email.</p>
        <p class="help-block text-danger" [shown]="billingContactEmails.errors?.requireEmails">Please enter at least one email.</p>
      </div>
    </div>
  </div>

  <div class="col-md-4" *ngIf="showManagedSections">
    <div class="pl-4 pr-4 pt-1 mb-4 border-container">
      <div class="row">
        <div class="col-md-12 mb-2">
          <h5>SSO Settings</h5>
        </div>
      </div>
      <div class="row mb-3 border-bottom">
        <div class="col-md-12 mb-2">
          <div class="flex-row left-right-aligner">
            <label id="company-settings-ssoenabled-label" for="company-settings-ssoenabled" class="control-label">
              Enable SAML Single Sign On
            </label>
            <mat-slide-toggle class="mb-2" name="ssoEnabled" id="company-settings-ssoenabled" ariaLabelledby="company-settings-ssoenabled-label"
              [(ngModel)]="company.settings.ssoEnabled">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div *ngIf="!company.settings.ssoEnabled">
        <div class="row mb-3">
          <div class="col-md-12">
            <span class="sso-instructions">
              Use the following information to create an application within your Identity Provider.
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">
                Assertion Consumer Service URL:
              </label>
              <div class="flex-row space-between">
                <div>{{company.settings.ssoSpUrl}}</div>
                <a class="u_clickable madero-link" (click)="copyToClipboard(company.settings.ssoSpUrl)">
                  <streamline-icon name="copy" width="15" height="15"></streamline-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="control-label">
                Service Provider Entity ID:
              </label>
              <div class="flex-row space-between">
                <div>{{company.settings.ssoSpId}}</div>
                <a class="u_clickable madero-link" (click)="copyToClipboard(company.settings.ssoSpId)">
                  <streamline-icon name="copy" width="15" height="15"></streamline-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="company.settings.ssoEnabled">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-ssoidpid" class="control-label">
                Single Sign-on Provider ID:
              </label>
              <input id="company-settings-ssoidpid" name="company-settings-ssoidpid"
                #ssoIdpId="ngModel" required type="text" class="form-control"
                [(ngModel)]="company.settings.ssoIdpId">
              <p [shown]="(ssoIdpId.dirty || companyForm?.submitted) && ssoIdpId.errors?.required" class="help-block validation-error-message-name text-danger">Identity Provider ID is required.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-ssoidpurl" class="control-label">
                Single Sign-on Provider URL:
              </label>
              <input id="company-settings-ssoidpurl" name="company-settings-ssoidpurl"
                #ssoIdpUrl="ngModel" required type="text" placeholder="https://" class="form-control"
                [(ngModel)]="company.settings.ssoIdpUrl" [pattern]="WEBSITE_REGEX" />
              <p [shown]="(ssoIdpUrl.dirty || companyForm?.submitted) && ssoIdpUrl.errors?.required" class="help-block validation-error-message-name text-danger">Identity Provider URL is required.</p>
              <p [shown]="ssoIdpUrl.errors?.pattern" class="help-block validation-error-message-ssoidpurl text-danger">Please provide a valid URL.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company-settings-ssoidpcertificate" class="control-label">
                X.509 Certificate:
              </label>
              <div *ngIf="certificateEditable">
                <textarea id="company-settings-ssoidpcertificate" name="company-settings-ssoidpcertificate"
                  #ssoIdpCertificate="ngModel" required type="text" rows="4" class="form-control"
                  [(ngModel)]="company.settings.ssoIdpCertificate">
                </textarea>
                <p [shown]="(ssoIdpCertificate.dirty || companyForm?.submitted) && ssoIdpCertificate.errors?.required"
                  class="help-block validation-error-message-name text-danger">
                  Identity Provider x509 Certificate is required.
                </p>
              </div>
              <div class="flex-row left-right-aligner" *ngIf="!certificateEditable">
                <input id="company-settings-ssoidpcertificate-label" name="company-settings-ssoidpcertificate-label"
                  #ssoIdpCertificate="ngModel" type="text" class="form-control"
                  [(ngModel)]="company.settings.ssoIdpCertificate">
                <button class="btn btn-default-icon pl-3 pr-3 ml-2" *ngIf="!certificateEditable" (click)="editCertificate()">
                  <streamline-icon name="pencil" width="12" height="12"></streamline-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="flex-row left-right-aligner">
              <label id="company-settings-ssorequired-label" for="company-settings-ssorequired" class="control-label">
                Force SSO Sign-on
              </label>
              <mat-slide-toggle class="mb-2" name="ssoRequired" id="company-settings-ssorequired" ariaLabelledby="company-settings-ssorequired-label"
                [(ngModel)]="company.settings.ssoRequired">
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showManagedSections">
      <div class="pl-4 pr-4 pt-4 mb-4 border-container">
        <div class="form-group">
          <label>
            Authentication Key:
          </label>
          <a class="action-link ps-reset-auth-key ml-2 madero-link pull-right" href="#" (click)="resetAuthKey()">Reset</a>
          <div class="ps-auth-key">
            {{company.authKey}}
          </div>
        </div>
        <div class="form-group">
          <label>
            Claim ID:
          </label>
          <a class="action-link ps-reset-claim-id ml-2 madero-link pull-right" href="#" (click)="resetClaimId()">Reset</a>
          <div class="ps-claim-id">
            {{company.claimId}}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isRiseStoreAdmin && showManagedSections">
      <div class="pl-4 pr-4 pt-1 pb-4 mb-4 border-container">
        <div class="row">
          <div class="col-md-12">
            <h5>Rise Admin Only</h5>
          </div>
        </div>
        <div class="flex-row left-right-aligner mt-3">
          <label id="is-test-label" for="is-test">Test Company</label>
          <mat-slide-toggle name="isTest" id="is-test" ariaLabelledby="is-test-label" class="mb-2"
            [(ngModel)]="company.isTest">
          </mat-slide-toggle>
        </div>
        <div class="flex-row left-right-aligner mt-3">
          <label id="share-company-plan-label" for="share-company-plan">Share Company Plan</label>
          <mat-slide-toggle name="shareCompanyPlan" id="share-company-plan" ariaLabelledby="share-company-plan-label" class="mb-2"
            [(ngModel)]="company.shareCompanyPlan" [disabled]="disableShareCompanyPlan()">
          </mat-slide-toggle>
        </div>
        <div class="flex-row left-right-aligner mt-3" *ngIf="!(currentPlanService.isUnlimitedPlan() && currentPlanService.isSubscribed())">
          <label id="unlock-all-features-label" for="unlock-all-features" class="rise-admin-only">Unlock All Features</label>
          <mat-slide-toggle name="unlockAllFeatures" id="unlock-all-features" ariaLabelledby="unlock-all-features-label" class="mb-2"
            [(ngModel)]="company.unlockAllFeatures">
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>
