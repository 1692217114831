import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, NgZone } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

import * as _ from 'lodash';

import { COUNTRIES, UserState } from 'src/app/ajs-upgraded-providers';

import { GeodataService } from 'src/app/shared/services/geodata.service';
import { CompanyIcpService } from '../../services/company-icp.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

@Component({
  selector: 'company-fields',
  templateUrl: './company-fields.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  styleUrls: ['./company-fields.component.scss']
})
export class CompanyFieldsComponent implements OnInit, OnChanges {

  @Input() company: any = {};
  @Input() showManagedSections: boolean = false;
  @Output() resetAuthKeyEvent = new EventEmitter<string>();
  @Output() resetClaimIdEvent = new EventEmitter<string>();

  disableIndustry: boolean;
  isRiseStoreAdmin = this.userState.isRiseStoreAdmin();
  certificateEditable: boolean = false;

  public companyForm: NgForm;

  regionsCA = GeodataService.REGIONS_CA;
  regionsUS = GeodataService.REGIONS_US;
  COMPANY_INDUSTRY_FIELDS = this.sort(CompanyIcpService.COMPANY_INDUSTRY_FIELDS);
  COMPANY_SIZE_FIELDS = CompanyIcpService.COMPANY_SIZE_FIELDS;

  WEBSITE_REGEX = ValidationService.WEBSITE_REGEX;

  constructor(
    private controlContainer: ControlContainer,
    private ngZone: NgZone,
    public countries: COUNTRIES,
    private userState: UserState,
    public currentPlanService: CurrentPlanService
  ) { }

  ngOnInit() {
    if (!this.company.settings) {
      this.company.settings = {};
    }

    this.companyForm = <NgForm>this.controlContainer;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.company.settings && typeof this.company.settings.ssoEnabled === "string") {
      this.company.settings.ssoEnabled = this.company.settings.ssoEnabled === "true";
      this.company.settings.ssoRequired = this.company.settings.ssoRequired === "true";

      this.certificateEditable = !this.company.settings.ssoIdpCertificate;
    }

    if (changes.company) {
      this.disableIndustry = !!changes.company.currentValue.companyIndustry && !this.userState.isRiseAdmin();
    }

    this.detectChanges();
  }

  sort(items) {
    return _.sortBy(items, item => {
      return item[0];
    });
  }

  resetAuthKey() {
    this.resetAuthKeyEvent.emit();
  }

  resetClaimId() {
    this.resetClaimIdEvent.emit();
  }

  copyToClipboard (text) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text);
    }
  }

  editCertificate () {
    this.certificateEditable = true;
  }

  disableShareCompanyPlan() {
    return this.userState.isRootCompanySelected() ||
      this.currentPlanService.isUnlimitedPlan() && !this.currentPlanService.isOnTrial() &&
      !this.company.shareCompanyPlan;
  }

  detectChanges() {
    setTimeout(() => {
      this.ngZone.run(() => {});
    })
  }
}