<div class="text-center text-muted u_margin-md-top u_margin-md-bottom" *ngIf="files.length === 0 && filesDetails?.code!==202 && filesDetails?.code!==404 && fileListVisible">You haven't uploaded any files yet!</div>

<div id="storageFilesGrid" class="grid-selector-list grid-selector-list_storage" *ngIf="files.length > 0 && filesDetails?.code!==202 && filesDetails?.code!==404 && fileListVisible">

  <div
    *ngFor="let file of storageUtilsService.filterFiles(files, search, storageService.storageFull)"
    (click)="triggerFileClick(file)"
    class="grid-selector-list-item"
    container="body"
    containerClass="madero-style thumbnail-tooltip"
    [tooltipAnimation]="false"
    [adaptivePosition]="false"
    [delay]="100"
    placement="bottom"
    (onShown)="onTooltipShown()"
    tooltip="{{file.name | storageFileName:folderPath}}{{ file.isThrottled? ' - This file is being requested too many times and is blocked.' : '' }}">

    <thumbnail-image [file]="file" [folderPath]="folderPath"></thumbnail-image>

  </div><!--end grid-selector-list-item-->
  <div class="grid-selector-list-item list-item-placeholder"></div>
  <div class="grid-selector-list-item list-item-placeholder"></div>
  <div class="grid-selector-list-item list-item-placeholder"></div>
  <div class="grid-selector-list-item list-item-placeholder"></div>
  <div class="grid-selector-list-item list-item-placeholder"></div>
  <div class="grid-selector-list-item list-item-placeholder"></div>
  <div class="grid-selector-list-item list-item-placeholder"></div>
  <div class="grid-selector-list-item list-item-placeholder"></div>

</div>