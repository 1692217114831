<ng-template #licenseRequired>
  <span class="mr-auto" *ngIf="displayFactory.showLicenseRequired()">
    <a href="" (click)="confirmLicensing()" [shown]="userState.hasRole('da')">License Required</a>
    <span [shown]="!userState.hasRole('da')">License Required</span>
  </span>
</ng-template>

<div class="fields-container">
  <div class="row">

    <div class="col-sm-12 col-md-7 pb-2 pb-lg-0">
      <div class="flex-row">
        <stretchy-input name="displayNameInput" ngDefaultControl class="mb-0" [(ngModel)]="displayFactory.display.name"></stretchy-input>
        <button type="button" id="deleteButton" *requireRole="'da'" [disabled]="!displayFactory.display.id" (click)="confirmDelete()" class="btn-icon pl-0">
          <streamline-icon class="u_margin-left streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
        </button>
      </div>
    </div>

    <div class="col-sm-12 col-md-5 pl-lg-0">
      <div class="pull-right" [hidden]="!displayFactory.display || !displayFactory.display.id">
        <label class="mr-2">Media Player Actions:</label>
        <div class="btn-group dropdown" dropdown>
          <button id="displayActionsButton" type="button" dropdownToggle class="form-control btn-select btn-schedule-selector btn-toolbar flex-row">
            <div class="row-entry">
              <span class="text-gray mr-auto">Choose an action</span>
              <span class="text-gray pl-2"><i class="fa fa-sort"></i></span>
            </div>
          </button>
          <div *dropdownMenu class="dropdown-menu playlist-menu" role="menu" aria-labelledby="displayActionsButton">
            <ul>
              <li>
                <button type="button" class="btn-dropdown u_clickable" [hidden]="displayFactory.display.onlineStatus === 'not_activated' || !playerProService.isRestartCompatiblePlayer(displayFactory.display)" [disabled]="!displayFactory.display.playerVersion" (click)="playerActionsService.confirm(displayFactory.display.id, displayFactory.display.name, 'restart')" *requireRole="'da'">
                  Restart Rise Player
                </button>
              </li>
              <li>
                <button type="button" class="btn-dropdown u_clickable"
                  [hidden]="displayFactory.display.onlineStatus === 'not_activated' || !playerProService.isRebootCompatiblePlayer(displayFactory.display)" [disabled]="!displayFactory.display.playerVersion" (click)="playerActionsService.confirm(displayFactory.display.id, displayFactory.display.name, 'reboot')" *requireRole="'da'">
                  Reboot Media Player
                </button>
              </li>
              <li>
                <a id="installPlayerAction" class="u_clickable" (click)="showInstallPanel = true">
                  <span>Install Display</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
<!-- DISPLAY ERRORS AND ALERTS -->

<div [shown]="playerActionsService.controlsError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>{{playerActionsService.controlsError}}</strong>
</div>

<div class="border-container u_margin-md-top" [shown]="!displayFactory.loadingDisplay && displayFactory.display?.id && showInstallPanel || displayFactory.display.onlineStatus === 'not_activated'">
  <div class="panel-body display-instructions-panel">
    <button id="dismissButton" type="button" class="close" [shown]="displayFactory.display.onlineStatus !== 'not_activated'" (click)="showInstallPanel = false">
      <i class="fa fa-times"></i>
    </button>
    <div class="form-group my-2">
      <label>You Have Several Options To Activate This Display</label>
    </div>

    <ol>
      <li>
        <div class="form-group">
          <b>Recommended:</b> Purchase a Rise Vision Media Player. Leave the management and support to us with our subscription hardware as a service (HaaS) digital signage media player. $144.00 per year per display or $324.00 per display per three years. Only available in the United States.
          <a class="madero-link" href="https://www.risevision.com/contact-us?form_selected=sales&content_hide=true">Contact Sales</a> or <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/18468794812180">Learn More</a>.
        </div>
      </li>
      <li>
        <div class="form-group">
          Use a dedicated media player connected to your display and install the Rise Player software for your operating system:
          <p>
            <a class="madero-link" id="installWindows" href="https://help.risevision.com/hc/en-us/articles/115001519126" target="_blank">
              Windows
            </a>
            <br/>
            <a class="madero-link" id="installLinux" href="https://help.risevision.com/hc/en-us/articles/115001872283" target="_blank">
              Linux
            </a>
            <br/>
            <a class="madero-link" id="installChrome" href="https://help.risevision.com/hc/en-us/articles/360001252066" target="_blank">
              Chrome OS
            </a>
            <br/>
            <a class="madero-link" id="installRaspberry" href="https://help.risevision.com/hc/en-us/articles/115001872303" target="_blank">
              Raspberry Pi
            </a>
            <br/>
            <a class="madero-link" id="installAndroid" href="https://help.risevision.com/hc/en-us/articles/4402330525716" target="_blank">
              Android
            </a>
            <br/>
            <a class="madero-link" id="installFireOs" href="https://help.risevision.com/hc/en-us/articles/4410858095508" target="_blank">
              Fire OS
            </a>
            <br/>
            <a class="madero-link" id="installAppleTv" href="https://help.risevision.com/hc/en-us/articles/14876884664596" target="_blank">
              Apple TV</a>
            <beta-badge></beta-badge>
          </p>
        </div>

        <div class="form-group">
          When prompted, enter the following Display ID:<br />
          <span class="highlight-text">{{displayFactory.display?.id}}</span>
        </div>
      </li>

      <li>
        <div class="form-group">
          You can use any display that can show a web page full screen such as collaboration displays used in a class or meeting room
          powered by <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360061086311-How-do-I-use-Rise-Vision-with-Airtame-" target="_blank">Airtame</a>,
          <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360062378091-How-do-I-use-Rise-Vision-with-Zoom-Room-digital-signage-" target="_blank">Zoom Room</a>,
          <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360062124192-How-do-I-use-Rise-Vision-with-Mersive-s-Solstice-Digital-Signage-" target="_blank">Mersive's Solstice</a>,
          <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/4402127464852-How-do-I-use-Rise-Vision-with-ScreenBeam-" target="_blank">ScreenBeam</a>,
          <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/4402127647508-How-do-I-use-Rise-Vision-with-Vivi-" target="_blank">Vivi</a>,
          or <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Player-on-a-thin-client-" target="_blank">Thin clients</a>.
          To do this, configure your display to show the following URL as per the instructions for the system you’re using; <br /><br />

          <span class="highlight-text">{{ getWebPlayerUrl() }}</span>
        </div>
      </li>
      <li>
        Deploy Rise Vision through a <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/4402330525716">mobile device management platform</a> to your SMART, Promethean, Clear Touch, ViewSonic, BenQ, Clevertouch, Boxlight, Newline, Avocor, or TouchView display.
      </li>
    </ol>

    <br />

    <div class="form-group">
      Your Display ID and the URL above are unique for your display and they will only work on one device at a time.
    </div>

    <div class="form-group">
      Need help? Send us an email to <a class="madero-link" href="mailto:support@risevision.com">support@risevision.com</a>.
    </div>

  </div>
</div>

<div class="fields-preview-panels display-fields-body mt-4">
  <div class="fields-container">

    <div class="scrollable-list border-container">

      <div class="list-group-item">
        <activation-notice></activation-notice>

        <div class="flex-row form-group">
          <div class="row-entry space-between align-end">
            <label class="pull-left mb-3" [shown]="!userState.hasRole('cp')">Default Schedule: <span class="font-weight-normal">{{displayFactory.display.scheduleName || 'None'}}</span></label>
            <preview-selector class="flex-grow" [(selectedItem)]="selectedSchedule" (selectedItemChange)="scheduleSelected()" label="Default Schedule:" additionalClass="mr-auto mb-0" placement="bottom left" [shown]="userState.hasRole('cp')"></preview-selector>
            <span class="button-toolbar">
              <a id="viewSchedule" class="madero-link u_inline-block my-3 ml-4" *ngIf="selectedSchedule" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: selectedSchedule.id, cid: selectedSchedule.companyId }">Edit</a>
            </span>
          </div>
        </div>

        <div class="form-group mb-0" *ngIf="displayFactory.hasOverrideSchedule(displayFactory.display)">
          <label>Override Schedule{{ displayFactory.display.overrideScheduleIds.length > 1 ? 's' : '' }}:</label>
          <div class="flex-row mb-2" *ngFor="let override of displayFactory.display.overrideSchedules">
            <div *ngIf="override" class="row-entry space-between align-end">
              <div class="flex-grow u_ellipsis">
                {{ override.name }}
              </div>
              <div class="text-danger mx-4">
                {{ override.scheduleTypeName }}
              </div>
              <div>
                <span class="u_link-disabled" *ngIf="override.companyId !== userState.getSelectedCompanyId()">Edit</span>
                <a class="madero-link u_clickable" *ngIf="override.companyId === userState.getSelectedCompanyId()" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: override.id, cid: userState.getSelectedCompanyId() }">Edit</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list-group-item" *ngIf="playerProService.isAndroidPlayer(displayFactory.display)">
        <div class="flex-row form-group mb-0">
          <div class="row-entry">
            <label class="control-label pull-left mb-0">Override Alerts:</label>

            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>

            <yes-no-toggle name="toggleOverrideTakeover" [(ngModel)]="displayFactory.display.overrideTakeoverAllowed" [disabled]="displayFactory.showLicenseRequired()" buttonId="overrideTakeoverAllowed" *ngIf="!displayFactory.showLicenseRequired() && isClassroomAlertsAvailable"></yes-no-toggle>
            <a href="#" class="madero-link" (click)="promptUpgrade('classroom_alerts')" *ngIf="!displayFactory.showLicenseRequired() && !isClassroomAlertsAvailable">Plan Upgrade Required</a>
          </div>
        </div>
        <div class="mt-3">Allow Rise Player to take over the screen with overrides replace type schedules.</div>
      </div>

      <div class="list-group-item">
        <div class="flex-row form-group mb-0">
          <div class="row-entry">
            <label class="control-label pull-left mb-0">License:</label>
            <yes-no-toggle name="toggleLicense" [(ngModel)]="displayFactory.display.playerProAuthorized" (change)="toggleProAuthorized()" [disabled]="!playerLicenseService.isProToggleEnabled(displayFactory.display)"></yes-no-toggle>
          </div>
        </div>

        <div class="flex-row form-group mt-2 mb-0" *ngIf="displayFactory.display.isFreeForURLs">
          <div class="row-entry">
            <label class="control-label pull-left mb-0"></label>
            This is a free display.
          </div>
        </div>
      </div>

      <div class="list-group-item form-group mb-0">
        <label>Display ID:</label>
        <div>
          For a dedicated <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/115001817143-What-is-a-Media-Player-get-one-pre-configured-build-your-own-?_ga=2.13275188.2099187533.1636965414-1754393580.1635242387" target="_blank">media player</a> connected to your display:<br>
        </div>
        <div class="flex-row space-between mb-3">
          <div class="highlight-text">{{ displayFactory.display?.id }}</div>
          <a class="u_clickable madero-link" (click)="copyToClipboard(displayFactory.display.id)">Copy</a>
        </div>
        <div>
          Or, configure a display that can show a <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Vision-Player-in-a-web-browser-" target="_blank">full screen webpage</a>:
          <a class="u_clickable madero-link" (click)="showWebPlayer=true" [hidden]="showWebPlayer">Show details.</a>
        </div>
        <span [shown]="showWebPlayer">
          <div class="flex-row space-between mt-2">
            <div class="highlight-text">{{ getWebPlayerUrl() }}</div>
            <a class="u_clickable madero-link u_margin-left mb-auto" (click)="copyToClipboard(getWebPlayerUrl())">Copy</a>
          </div>
        </span>
        <div class="text-muted mt-3">
          Each individual device needs to have a unique Display ID.
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Last Connection:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          <span class="mr-auto" *ngIf="!displayFactory.showLicenseRequired()">
            {{displayFactory.display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
          </span>
        </div>
      </div>

      <!-- SCREENSHOT  -->
      <screenshot (licenseRequired)="confirmLicensing()" *ngIf="playerProService.isScreenshotCompatiblePlayer(displayFactory.display)"></screenshot>

      <div class="list-group-item flex-row form-group mb-0" [hidden]="displayFactory.display.onlineStatus === 'not_activated'">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Status:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          <span class="mr-auto" *ngIf="!displayFactory.showLicenseRequired()">
            <span class="text-danger" [shown]="displayFactory.display.onlineStatus === 'offline'">Offline</span>
            <span class="text-success" [shown]="displayFactory.display.onlineStatus === 'online'">Online</span>
          </span>
        </div>
      </div>

      <div *ngIf="!playerProService.isAvocorDisplay(displayFactory.display)" class="border-top">
        <div class="list-group-item flex-row form-group mb-0">
          <div class="row-entry">

            <label class="control-label pull-left mb-0">
              Monitoring:
            </label>

            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>

            <yes-no-toggle name="toggleMonitoring" [(ngModel)]="displayFactory.display.monitoringEnabled" (change)="trackDisplayMonitoring()" [disabled]="displayFactory.showLicenseRequired()" *ngIf="!displayFactory.showLicenseRequired() && isMonitoringAvailable"></yes-no-toggle>
            <a href="#" class="madero-link" (click)="promptUpgrade('monitoring')" *ngIf="!displayFactory.showLicenseRequired() && !isMonitoringAvailable">Plan Upgrade Required</a>
          </div>
        </div>

        <div class="list-group-item" [shown]="!displayFactory.showLicenseRequired() && displayFactory.display.monitoringEnabled && isMonitoringAvailable">
          <timeline-basic-textbox [(timelineString)]="displayFactory.display.monitoringSchedule" [disabled]="!displayFactory.display.monitoringEnabled"></timeline-basic-textbox>
        </div>

        <div class="list-group-item" [shown]="!displayFactory.showLicenseRequired() && displayFactory.display.monitoringEnabled && isMonitoringAvailable">
          <div class="flex-row form-group mb-0">
            <div class="row-entry">
              <label class="control-label pull-left mb-0">Recipients:</label>
              <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
            </div>
          </div>

          <div class="mt-2" *ngIf="!displayFactory.showLicenseRequired()">Separate multiple recipients by a comma.</div>
          <div class="mt-3" [ngClass]="{ 'has-error': monitoringEmails.errors?.emails }" *ngIf="!displayFactory.showLicenseRequired()">
            <emails-field #monitoringEmails="ngModel" name="monitoringEmails" ngDefaultControl [(ngModel)]="displayFactory.display.monitoringEmails"></emails-field>
            <p class="help-block text-danger" [shown]="monitoringEmails.errors?.emails">
              A valid email address is required.
            </p>
            <p class="help-block text-danger" [shown]="monitoringEmails.errors?.duplicateEmails">
              The email already exists.
            </p>
          </div>
        </div>
      </div>

      <div class="list-group-item" *ngIf="playerProService.isRebootOrRestartDailyCompatiblePlayer(displayFactory.display)">
        <div class="flex-row form-group mb-0">
          <div class="row-entry">
            <label class="control-label pull-left
              mb-0">{{playerProService.isRebootCompatiblePlayer(displayFactory.display)
              ? "Reboot" : "Restart"}} Daily:</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>

            <yes-no-toggle name="toggleRestart" [(ngModel)]="displayFactory.display.restartEnabled" [disabled]="displayFactory.showLicenseRequired()" buttonId="restartEnabled" *ngIf="!displayFactory.showLicenseRequired()"></yes-no-toggle>
          </div>
        </div>

        <popup-time-picker id="restartTime" name="restartTime" ngDefaultControl [(ngModel)]="displayFactory.display.restartTime" [shown]="!displayFactory.showLicenseRequired() && displayFactory.display.restartEnabled"></popup-time-picker>
      </div>

      <div class="list-group-item" *ngIf="playerProService.isAutostartFlagEnabledPlayer(displayFactory.display)">
        <div class="flex-row form-group mb-0">
          <div class="row-entry">
            <label class="control-label pull-left mb-0">Autostart After Boot:</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>

            <yes-no-toggle name="toggleAutostartOnBoot" [(ngModel)]="displayFactory.display.autostartOnBoot" [disabled]="displayFactory.showLicenseRequired()" buttonId="autostartOnBoot" *ngIf="!displayFactory.showLicenseRequired()"></yes-no-toggle>
          </div>
        </div>
      </div>

      <div class="list-group-item" *ngIf="playerProService.isDisplayControlCompatiblePlayer(displayFactory.display)">
        <div class="flex-row form-group mb-0">
          <div class="row-entry">
            <label class="control-label pull-left mb-0">Display Control:</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>

            <span class="mr-auto" *ngIf="!displayFactory.showLicenseRequired() && isDisplayControlAvailable">
              <a class="madero-link u_clickable" (click)="configureDisplayControl()" *requireRole="'da'">
                Edit
              </a>
            </span>
            <a href="#" class="madero-link" (click)="promptUpgrade('display_control')" *ngIf="!displayFactory.showLicenseRequired() && !isDisplayControlAvailable">Plan Upgrade Required</a>
          </div>
        </div>

        <div class="mt-2" [shown]="!displayFactory.showLicenseRequired()">
          Turn screen ON/OFF based on schedule.
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0" [shown]="(displayFactory.display.playerVersion || displayFactory.display.onlineStatus === 'online') && playerProService.isDeviceOrientationCompatiblePlayer(displayFactory.display)">
        <div class="row-entry">
          <label class="control-label pull-left mb-0" for="orientation">Rotation:</label>
          <span class="mr-auto">
            <select #orientation name="orientation" class="form-control" [(ngModel)]="displayFactory.display.orientation">
              <option [ngValue]="0">Landscape</option>
              <option [ngValue]="90">Portrait</option>
              <option [ngValue]="180">Landscape (flipped)</option>
              <option [ngValue]="270">Portrait (flipped)</option>
            </select>
          </span>
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Use Company Address:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>

          <yes-no-toggle name="toggleUseCompanyAddress" [(ngModel)]="displayFactory.display.useCompanyAddress" [disabled]="displayFactory.showLicenseRequired()" buttonId="useCompanyAddress" *ngIf="!displayFactory.showLicenseRequired()"></yes-no-toggle>
        </div>
      </div>

      <display-address [(display)]="displayFactory.display"></display-address>

      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.playerLocalIpAddress">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">IP Address:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          <span class="mr-auto" *ngIf="!displayFactory.showLicenseRequired()">
            {{displayFactory.display.playerLocalIpAddress}}
          </span>
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.macAddress">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">MAC Address:</label>
          <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
          <span class="mr-auto" *ngIf="!displayFactory.showLicenseRequired()">
            {{displayFactory.display.macAddress}}
          </span>
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.os">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Operating System:</label>
          <span class="mr-auto">
            {{displayFactory.display.os}}
            {{playerProService.isFireOSPlayer(displayFactory.display) ? " (Fire OS)" : ""}}
          </span>
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Resolution:</label>
          <span class="mr-auto">
            <span [shown]="displayFactory.display.playerVersion">{{displayFactory.display.width | resolution: displayFactory.display.height}}</span>
          </span>
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.playerName || displayFactory.display.playerVersion">
        <div class="row-entry">
          <label class="control-label pull-left mb-0 aligner-item--top">Player:</label>
          <span class="mr-auto">
            <div [shown]="displayFactory.display.playerName">{{displayFactory.display.playerName}}</div>
            <div [shown]="displayFactory.display.playerVersion">{{displayFactory.display.playerVersion}}</div>
          </span>
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.viewerVersion && displayFactory.display.viewerVersion !== displayFactory.display.playerVersion">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Viewer:</label>
          <span class="mr-auto">
            {{displayFactory.display.viewerVersion}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.browserVersion">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Browser:</label>
          <span class="mr-auto">
            {{(displayFactory.display.browserName ? displayFactory.display.browserName + " " : "") + (displayFactory.display.browserVersion ? displayFactory.display.browserVersion : "")}}
          </span>
        </div>
      </div>

      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.serialNumber">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Serial Number:</label>
          <span class="mr-auto">
            {{displayFactory.display.serialNumber}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.cpu">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">CPU:</label>
          <span class="mr-auto">
            {{displayFactory.display.cpu}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.hostName">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Host Name:</label>
          <span class="mr-auto">
            {{displayFactory.display.hostName}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.showProductName()">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Product Name:</label>
          <span class="mr-auto">
            {{displayFactory.display.productName}}
          </span>
        </div>
      </div>
      <div class="list-group-item flex-row form-group mb-0" [shown]="displayFactory.display.deviceManagementId">
        <div class="row-entry">
          <label class="control-label pull-left mb-0">Device Management ID:</label>
          <span class="mr-auto">
            {{displayFactory.display.deviceManagementId}}
          </span>
        </div>
      </div>

    </div>
  </div>

  <div class="preview-container" [shown]="selectedSchedule">
    <div class="align-right mb-4" *ngIf="isMobile">
      <button class="btn btn-default btn-toolbar" (click)="isMobile = false">Show Preview</button>
    </div>
    <div class="preview-embed" *ngIf="!isMobile && selectedSchedule?.id">
      <iframe frameborder="0" [src]="isMobile ? '' : santitizedPreviewUrl">
      </iframe>
    </div>
  </div>

  <div class="preview-container" [hidden]="selectedSchedule">
    <div class="border-container empty-preview text-center">
      <div class="form-group" [hidden]="userState.hasRole('cp')">
        <label>Nothing is currently showing on your display.</label>
      </div>
      <preview-selector [(selectedItem)]="selectedSchedule" (selectedItemChange)="scheduleSelected()" label="Nothing is currently showing on your display." placement="bottom left" [shown]="userState.hasRole('cp')"></preview-selector>
    </div>
  </div>
</div>

<div id="errorBox" [shown]="displayFactory.apiError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>{{displayFactory.errorMessage}}</strong> {{displayFactory.apiError}}
</div>
