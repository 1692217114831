import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import * as _ from 'lodash';

import { ScheduleFactory, InsecureUrl } from 'src/app/ajs-upgraded-providers';

import { TrackerService } from 'src/app/components/logging/tracker.service';
import { BlueprintService } from 'src/app/template-editor/services/blueprint.service';
import { TemplateEditorService } from 'src/app/template-editor/services/template-editor.service';
import { PresentationsService } from 'src/app/editor/services/presentations.service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  static TYPE_URL = 'url';
  static TYPE_PRESENTATION = 'presentation';
  static TRANSITION_TYPES = [
    ['normal', 'No transition'],
    ['fadeIn', 'Fade in'],
    ['slideFromLeft', 'Slide from left'],
    ['slideFromRight', 'Slide from right'],
    ['slideFromTop', 'Slide from top'],
    ['slideFromBottom', 'Slide from bottom'],
    ['stripesHorizontal', 'Stripes horizontal'],
    ['stripesVertical', 'Stripes vertical'],
    ['zoomIn', 'Zoom in']
  ];

  private DEFAULT_DURATION = 10;

  constructor(private scheduleService: ScheduleFactory,
    private trackerService: TrackerService,
    private presentationsService: PresentationsService,
    private blueprintService: BlueprintService,
    private insecureUrl: InsecureUrl) { }

  newPresentationItem (presentation) {
    this.trackerService.scheduleEvent('Add Presentation to Schedule',
      this.scheduleService.schedule.id, this.scheduleService.schedule.name, {
        presentationId: presentation.id,
        presentationName: presentation.name
      }
    );

    // Cache presentation to avoid API call for the name
    this.presentationsService.setPresentation(presentation);

    return {
      duration: this.DEFAULT_DURATION,
      type: PlaylistService.TYPE_PRESENTATION,
      objectReference: presentation.id,
      name: presentation.name,
      presentationType: presentation.presentationType
    };
  }

  initPlayUntilDone (item, presentation, isNew) {
    if (presentation.presentationType === TemplateEditorService.HTML_PRESENTATION_TYPE) {
      return this.blueprintService.isPlayUntilDone(presentation.productCode)
        .then((playUntilDone) => {
          if (playUntilDone && isNew) {
            //When user schedules a PUD template, then set schedule item to PUD by default.
            item.playUntilDone = true;
          }
          if (!playUntilDone) {
            item.playUntilDone = false;
          }

          return playUntilDone;
        })
        .catch((e) => {
          console.error('Failed to check HTML Template Play Until Done', e);

          item.playUntilDone = false;

          return false;
        });
    } else {
      return Promise.resolve(true);
    }
  }

  addPresentationItem (presentation) {
    var playlistItem = this.newPresentationItem(presentation);

    return this.initPlayUntilDone(playlistItem, presentation, true)
      .then(() => {
        this.updatePlaylistItem(playlistItem);
      });
  }

  addPresentationItems (presentations) {
    for (var i in presentations) {
      this.addPresentationItem(presentations[i]);
    }
  }

  getNewUrlItem () {
    this.trackerService.scheduleEvent('Add URL Item to Schedule',
      this.scheduleService.schedule.id, this.scheduleService.schedule.name
    );

    return {
      duration: this.DEFAULT_DURATION,
      type: PlaylistService.TYPE_URL,
      name: 'URL Item'
    };
  }

  getPlaylist () {
    return this.scheduleService.schedule.content ? this.scheduleService.schedule.content :
      this.scheduleService.schedule.content = [];
  }

  _getItemIndex (playlistItem) {
    return this.getPlaylist() ?
      this.getPlaylist().indexOf(playlistItem) : -1;
  }

  isNew (playlistItem) {
    return this._getItemIndex(playlistItem) === -1;
  }

  updatePlaylistItem (playlistItem) {
    if (this._getItemIndex(playlistItem) === -1) {
      this.getPlaylist().push(playlistItem);
    }
  }

  removePlaylistItem (playlistItem) {
    var index = this._getItemIndex(playlistItem);
    if (index !== -1) {
      this.getPlaylist().splice(index, 1);
    }
  }

  duplicatePlaylistItem (playlistItem) {
    var index = this._getItemIndex(playlistItem);
    if (index !== -1) {
      var newPlaylistItem = angular.copy(playlistItem);
      newPlaylistItem.name = 'Copy of ' + newPlaylistItem.name;

      this.getPlaylist().splice(index + 1, 0, newPlaylistItem);
    }
  }

  moveItem (currIndex, newIndex) {
    var playlist = this.getPlaylist();

    playlist.splice(newIndex, 0, playlist.splice(currIndex, 1)[0]);
  }

  getItemTransition (playlistItem) {
    var transition = _.find(PlaylistService.TRANSITION_TYPES, (t) => {
      return playlistItem.transitionType === t[0];
    });

    return transition ? transition[1] : PlaylistService.TRANSITION_TYPES[0][1];
  }

  hasInsecureUrl (playlistItem) {
    return !!(playlistItem && playlistItem.type === 'url' && this.insecureUrl(playlistItem.objectReference));
  }

}

angular.module('risevision.schedules.services')
  .factory('playlistFactory', downgradeInjectable(PlaylistService))
  .constant('TRANSITION_TYPES', PlaylistService.TRANSITION_TYPES);
