<div>
  <div class="app-header u_padding-20-vertical mb-4 border-bottom flex-row space-between">
    <h1 class="app-header-title" id="title">Companies &amp; Licenses</h1>
    <a class="btn btn-default btn-toolbar-wide" uiSref="apps.company.add">Add Sub-Company</a>
  </div>
  <form #allocationForm="ngForm" (ngSubmit)="save(allocationForm)" class="pt-2">
    <div class="border-round flex-row space-between p-4 mb-4">
      <label for="assign-licenses-toggle" class="mb-0 pr-3">Assign Licenses Manually</label>
      <mat-slide-toggle
        id="assign-licenses-toggle"
        name="assignLicensesToggle"
        [(ngModel)]="selectedCompany.licenseAllocationEnabled">
      </mat-slide-toggle>
    </div>
    <div class="company-licenses-layout">
      <div class="company-licenses-column">
        <!-- <div class="flex-row pb-2">
          <search-filter class="flex-grow" [filterConfig]="filterConfig" [search]="search" [doSearch]="boundSearch" iconSet="madero"></search-filter>
          <a class="btn btn-default btn-toolbar-wide ml-4" uiSref="apps.company.add">Add Sub-Company</a>
        </div> -->

        <div class="panel panel-default scrollable-list horizontal-scroll border-container mb-2"
          scrollingList (scrollEndEvent)="companies.load()"
          rvSpinner="company-licenses-list" [rvShowSpinner]="companies.loadingItems || saving">
          <table class="table dense company-licenses-table">
            <thead class="table-header">
              <tr class="table-header__row">
                <th class="table-header__cell cell-company-tree">&nbsp;</th>
                <th class="table-header__cell u_align-middle u_clickable cell-company-name">
                  Company Name
                </th>
                <th class="table-header__cell u_align-middle u_clickable cell-license-type">
                  License Type
                </th>
                <th class="table-header__cell u_clickable align-center cell-licenses-allocated">
                  Licenses<br>Allocated
                </th>
                <th class="table-header__cell u_clickable align-center cell-display-count">
                  Display<br>Count
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr company-license-item
                class="table-body__row borderless"
                [company]="selectedCompany"
                [licenseAllocationEnabled]="selectedCompany.licenseAllocationEnabled"
                [root]="true">
              </tr>
              <tr company-license-item
                class="table-body__row borderless"
                *ngFor="let company of companyTree; let i = index"
                [company]="company"
                [licenseAllocationEnabled]="selectedCompany.licenseAllocationEnabled"
                [last]="isLast(i)"
                (selectCompany)="setCompany($event)"
                [shown]="company.parent === undefined || isSubShown(company.parent)">
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="license-totals-column">
        <div class="border-container px-4 py-2 scrollable-list horizontal-scroll">
          <company-license-totals></company-license-totals>
        </div>
        <div class="madero-style alert alert-warning mt-4" *ngIf="companyAllocationService.getRequiredLicenses()">
          <i class="fa fa-exclamation-circle icon-left text-warning"></i>
          <strong>Billing Update Required</strong>
          <p>
            This change requires adding
            <strong>{{ companyAllocationService.getRequiredLicensesText('enterprise') }}</strong>
            {{ companyAllocationService.getRequiredLicenses('enterprise') && companyAllocationService.getRequiredLicenses('unlimited') ? ' and ' : '' }}
            <strong>{{ companyAllocationService.getRequiredLicensesText('unlimited') }}</strong>
            to your plan.
          </p>
        </div>
      </div>
    </div>
    <div class="flex-row space-between">
      <div class="button-row">
        <button id="exportButton" type="button" class="btn btn-default btn-toolbar">
          Export
        </button>
      </div>
      <div class="button-row">
        <button id="cancelButton" type="button" class="btn btn-default btn-toolbar" uiSref="apps.billing.home">
          Cancel
        </button>
        <button id="saveButton" type="submit" class="btn btn-primary btn-toolbar" [disabled]="!allocationForm.dirty">
          Save{{ companyAllocationService.getRequiredLicenses() && currentPlanService.isOnTrial() ? ' and Checkout' : '' }}
        </button>
      </div>
    </div>
  </form>
</div>