import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BlueprintService } from '../../services/blueprint.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { PresentationService, ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { PresentationsService } from 'src/app/editor/services/presentations.service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistComponentService {

  public search :any = {
    skipFirstLoad: true,
    sortBy: 'changeDate',
    reverse: true
  };
  public templates = this.ScrollingListService(this.presentation.list, this.search);
  public loading;
  public onAddHandler;

  constructor(
    private ScrollingListService: ScrollingListService,
    private presentation: PresentationService,
    private presentationsService: PresentationsService,
    private templateEditorFactory: TemplateEditorService,
    private blueprintFactory: BlueprintService) { }

    load() {
      this.search.query = '';
      this.search.filter = ' presentationType:"HTML Template"';
      //exclude the template that is being edited
      this.search.filter += ' AND NOT id:' + this.templateEditorFactory.presentation.id;

      this.templates.doSearch();
    }

    loadPresentationNames(presentations) {
      var presentationIds = _.uniq(
        _.map(presentations, (item) => {
          return item.id;
        })
      );

      const lookups = [];
      const found = [];

      this.loading = true;

      for (let presentationId of presentationIds) {
        lookups.push(this.presentationsService.getPresentationCached(presentationId).then(result => {
          for (let presentation of presentations) {
            if (presentation.id === result.id) {
              if (found.indexOf(result.id) < 0) {
                found.push(result.id);
              }
              presentation.name = result.name;
              presentation.revisionStatusName = result.revisionStatusName;
              presentation.removed = false;
            }
          }
        }).catch(() => {}));
      }
      Promise.all(lookups).then(() => {
        for (let presentation of presentations) {
          if (found.indexOf(presentation.id) < 0) {
            presentation.name = 'Unknown';
            presentation.revisionStatusName = 'Presentation not found.';
            presentation.removed = true;
          }
        }
      }).finally(() => {
        this.loading = false;
      });
    }

    addTemplates () {
      var itemsToAdd = this.templates.getSelected();

      //if template supports PUD, then set it to PUD automatically
      var promises = [];
      _.forEach(itemsToAdd, (item) => {
        promises.push(this.blueprintFactory.isPlayUntilDone(item.productCode));
      });

      this.loading = true;

      Promise.all(promises)
        .then( (playUntilDoneValues) => {
          for (var i = 0; i < playUntilDoneValues.length; i++) {
            itemsToAdd[i]['play-until-done'] = playUntilDoneValues[i];
          }

          if (this.onAddHandler) {
            this.onAddHandler(itemsToAdd);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
}
