import { Injectable } from '@angular/core';
import { ApiUtilsService } from 'src/app/shared/services/api-utils.service';
import { UserState, CompanyService } from 'src/app/ajs-upgraded-providers';
import { PresentationsService } from 'src/app/editor/services/presentations.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  savingAlerts = false;
  errorSaving = false;
  missingTemplate = false;
  errorMessage = '';
  apiError = '';
  alertKey: any;
  alertSettings: any;
  changeDate: any;
  changedBy: any;
  private _company: any;

  readonly textFieldsValues = [
    { key: 'headline', label: 'Headline' },
    { key: 'description', label: 'Description' },
    { key: 'instruction', label: 'Instruction' }
  ];

  readonly allowedStatusesValues = [
    { key: 'Actual', label: 'Actual' },
    { key: 'Exercise', label: 'Exercise' },
    { key: 'System', label: 'System' },
    { key: 'Test', label: 'Test' },
    { key: 'Draft', label: 'Draft' }
  ];

  readonly allowedCategoriesValues = [
    { key: 'Geo', label: 'Geophysical' },
    { key: 'Met', label: 'Meteorological' },
    { key: 'Safety', label: 'Safety' },
    { key: 'Security', label: 'Security' },
    { key: 'Rescue', label: 'Rescue' },
    { key: 'Fire', label: 'Fire' },
    { key: 'Health', label: 'Health' },
    { key: 'Env', label: 'Environment' },
    { key: 'Transport', label: 'Transport' },
    { key: 'Infra', label: 'Infrastructure' },
    { key: 'CBRNE', label: 'Chemical, Biological, Radiological, Nuclear and Explosives' },
    { key: 'Other', label: 'Other' }
  ];

  readonly allowedUrgenciesValues = [
    { key: 'Immediate', label: 'Immediate' },
    { key: 'Expected', label: 'Expected' },
    { key: 'Future', label: 'Future' },
    { key: 'Past', label: 'Past' },
    { key: 'Unknown', label: 'Unknown' }
  ];

  readonly allowedSeveritiesValues = [
    { key: 'Extreme', label: 'Extreme' },
    { key: 'Severe', label: 'Severe' },
    { key: 'Moderate', label: 'Moderate' },
    { key: 'Minor', label: 'Minor' },
    { key: 'Unknown', label: 'Unknown' }
  ];

  readonly allowedCertaintiesValues = [
    { key: 'Observed', label: 'Observed' },
    { key: 'Likely', label: 'Likely' },
    { key: 'Possible', label: 'Possible' },
    { key: 'Unlikely', label: 'Unlikely' },
    { key: 'Unknown', label: 'Unknown' }
  ];

  constructor(
    private apiUtilsService: ApiUtilsService,
    private companyService: CompanyService,
    private presentationsService: PresentationsService,
    private userState: UserState
  ) { }

  private _clearMessages() {
    this.errorMessage = '';
    this.apiError = '';
  }

  private _updateSettings(company) {
    this._company = company;
    this.alertKey = company.alertKey;
    this.alertSettings = company.alertSettings;
    this.changeDate = company.changeDate;
    this.changedBy = company.changedBy;

    if (this.alertSettings && this.alertSettings.presentationId && !this.alertSettings.templateProductCode && !this.alertSettings.presentationName) {
      this.presentationsService.getPresentationCached(this.alertSettings.presentationId).then((presentation) => {
        this.alertSettings.presentationName = presentation.name
      });
    }
  }

  loadSettings () {
    this._updateSettings(cloneDeep(this.userState.getCopyOfSelectedCompany(true)));
  }

  changePresentation() {
    this.presentationsService.openPresentationSelector()
      .then((presentationDetails) => {
        this.alertSettings.presentationId = presentationDetails[0];
        this.alertSettings.presentationName = presentationDetails[1];
      });
  }

  save() {
    this._clearMessages();
    this.savingAlerts = true;
    this.errorSaving = false;
    if (this.alertSettings.templateProductCode) {
      this.alertSettings.presentationId = '';
      this.alertSettings.presentationName = '';
    } else if (!this.alertSettings.presentationId) {
      this.missingTemplate = true;
      this.savingAlerts = false;
      return Promise.resolve();
    }
    const presentationName = this.alertSettings.presentationName || '';
    return this.companyService.updateAlerts(this._company.id, this._company).then((result) => {
      this.userState.updateCompanySettings(result.item);
      this._updateSettings(result.item);
      this.alertSettings.presentationName = presentationName;
    }, (error) => {
      this.errorSaving = true;
      this.errorMessage = 'Failed to update Alerts.';
      this.apiError = this.apiUtilsService.humanReadableError(error && error.result || error);
      console.error(this.errorMessage, error);
    }).finally(() => {
      this.savingAlerts = false;
    });
  }

  regenerateAlertKey() {
    this._clearMessages();
    this.savingAlerts = true;

    this.companyService.regenerateField(this._company.id, 'alertKey').then((result) => {
      this.alertKey = result.item;
    }, (error) => {
      this.errorMessage = 'Failed to reset Web Service URL.';
      this.apiError = this.apiUtilsService.humanReadableError(error);
      console.error(this.errorMessage, error);
    }).finally(() => {
      this.savingAlerts = false;
    });
  }
}