<div id="checkout-success" class="row">
  <div class="col-md-8 col-md-offset-2">
    <h4 class="u_margin-md-bottom">A great big thank you!</h4>
    <div class="border-container p-4">
      <p class="u_margin-md-bottom">
        We’ve sent a confirmation email to <b>{{purchase.contact.email}}</b>.
      </p>
      <p>
        <b>Details:</b><br />
        You’ve purchased {{ purchasedMultiple ? '' : 'a' }} subscription{{ purchasedMultiple ? 's' : '' }} to <b *ngIf="purchase.volumePlan?.licenses">{{ purchase.volumePlan.name }}</b> {{ purchasedMultiple ? 'and' : '' }} <b *ngIf="purchase.unlimitedPlan?.licenses">{{ purchase.unlimitedPlan.name }}</b>.<br />
        You will be
        <span [shown]="paymentMethods.paymentMethod === 'invoice'">invoiced</span>
        <span [shown]="paymentMethods.paymentMethod === 'card'">charged</span>
        <b> {{purchase.estimate.total / 100 | currency}}</b>.
      </p>
    </div>
    <div class="button-row u_margin-md-top">
      <button id="doneButton" class="btn btn-primary btn-block" (click)="closeEvent.emit()" aria-label="Done" tabindex="1">Continue</button>
    </div>
  </div>
</div>
