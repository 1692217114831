<div id="company-users">
  <div class="app-header u_margin-md-top mb-5">
    <!-- App Title -->
    <h1 class="app-header-title" id="title">Users</h1>
  </div>

  <div id="errorBox" [shown]="users.apiError" class="alert alert-danger text-danger" role="alert">
    <strong>{{users.errorMessage}}</strong> {{users.apiError}}
  </div>

  <div class="flex-row button-toolbar-md-folded">
    <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="users.doSearch" iconSet="madero"></search-filter>

    <div class="ml-md-3 button-toolbar flex-row" *requireRole="'ua sa'">
      <a href="#" *requireRole="'sa'" (click)="exportUsers()" class="btn btn-default btn-toolbar rise-admin-only">Export</a>
      <a id="userAddButton" class="btn btn-primary btn-toolbar-wide" uiSref="apps.user.add">
        Add User
      </a>
    </div>
  </div>

  <!-- List of Users -->
  <div class="panel panel-default scrollable-list border-container u_margin-sm-top mb-2"
    scrollingList (scrollEndEvent)="users.load()"
    rvSpinner="company-users-list" [rvShowSpinner]="users.loadingItems">
    <table class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th class="table-header__cell u_clickable">
            Name
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr class="table-body__row company-users-list-item" *ngFor="let user of users.items.list" uiSref="apps.user.details" [uiParams]="{username: user.username}">
          <td class="table-body__cell u_clickable">
            <p class="list-group-item-text"><strong>{{user.firstName}} {{user.lastName}}</strong><small class="text-muted ml-2">{{user.email}}</small></p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>