import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollingListService } from 'src/app/ajs-upgraded-providers';

import { ModalComponent } from 'src/app/components/modals/modal.component';
import { ProductsService } from 'src/app/editor/services/products.service';

@Component({
  selector: 'store-content',
  templateUrl: './store-content.component.html',
  styleUrls: ['./store-content.component.scss']
})
export class StoreContentComponent extends ModalComponent implements OnInit {
  constructor(modalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private scrollingListService: ScrollingListService,
    private productsService: ProductsService) {
    super(modalRef);
  }

  _defaultCount = 1000;
  _addWidgetByUrl = null;

  // Provided by users of the component
  addWidgetByUrl = null;

  search = {
    category: 'content',
    count: this._defaultCount,
    query: '',
    doSearch: this.doSearch.bind(this),
    onListUpdate: () => {
      this.filteredProducts = this.products.items.list;

      this.changeDetectorRef.detectChanges();
    }
  };

  products = this.scrollingListService(this.productsService.loadProducts, this.search);
  filteredProducts = [];

  filterConfig = {
    placeholder: "Search for Widget",
    id: 'storeProductsSearchInput'
  };

  ngOnInit(): void {
    if (this.addWidgetByUrl) {
      this._addWidgetByUrl = function () {
        this.close();
        this.addWidgetByUrl();
      };
    }
  }

  slugify(value) {
    return value.toLowerCase().replaceAll(' ', '-');
  }

  doSearch() {
    let filteredProducts = this.products.items.list;

    if (this.search.query) {
      filteredProducts = filteredProducts.filter((item) => {
        return JSON.stringify(item).toLowerCase().indexOf(this.search.query.toLowerCase()) !== -1;
      });
    }

    this.filteredProducts = filteredProducts;
  }

  select(product) {
    super.resolve(product);
  }

  close() {
    super.resolve();
  }
}
