<div class="storage-selector-container" [ngClass]="{ 'no-files': !hasRegularFileItems(), 'folder-upload': isFileType('folder') }">
  <div class="row storage-selector-search-container">
    <div class="col-xs-12">
      <search-filter [filterConfig]="filterConfig" [search]="search" [doSearch]="list.doSearch" iconSet="madero" [localSearch]="true"></search-filter>
    </div>
  </div>

  <div class="storage-selector-component te-scrollable-container"
    [ngClass]="{ 'no-files': !hasRegularFileItems() }"
    rvSpinner="component-storage-selector-spinner" [rvShowSpinner]="storageManagerService.loadingFiles">

    <div class="file-component-list" [shown]="list.folderItems.length > 0 && !isUploading" *ngIf="storageManagerService.isListView">
      <div class="pl-0 file-component-list-header" [hidden]="list.sortedItems.length === 0">
        <div class="file-entry">
          <madero-checkbox *ngIf="!this.storageManagerService.isSingleFileSelector || !storageManagerService.isSingleFileSelector()" (click)="selectAllItems()" [ngValue]="search.selectAll"></madero-checkbox>
          <div class="mr-auto u_clickable" (click)="sortBy(fileNameOrderFunction)">
            File name
            <i *ngIf="search.sortBy === fileNameOrderFunction" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
          </div>
          <div class="u_clickable" (click)="sortBy(dateModifiedOrderFunction)">
            Last modified
            <i *ngIf="search.sortBy === dateModifiedOrderFunction" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
          </div>
        </div>
      </div>

      <div *ngFor="let item of list.sortedItems">
        <div class="pl-0 file-component-list-folder-row u_clickable" [ngClass]="{'active': isSelected(item)}" *ngIf="isFolder(item.name)">
          <div class="file-entry" [ngClass]="{'ml-5': !isFileType('folder')}">
            <madero-checkbox [ngValue]="isSelected(item)" (click)="selectItem(item)" *ngIf="isFileType('folder')"></madero-checkbox>
            <div class="file-text" (click)="loadItems(item.name)">
              <div class="file-name">{{fileNameOf(item.name)}}/</div>
            </div>
          </div>
        </div>

        <div class="pl-0 file-component-list-file-row u_clickable" *ngIf="!isFolder(item.name)" (click)="selectItem(item)">
          <div class="file-entry">
            <madero-checkbox [ngValue]="isSelected(item)"></madero-checkbox>
            <div class="file-text mr-auto">
              <div class="file-name">{{fileNameOf(item.name)}}</div>
            </div>
            <div [hidden]="item.kind === 'folder'">
              {{item.updated.value | date:'MMM d, yyyy'}}
            </div>
          </div>
        </div>
      </div>

      <div id="noSearchResult" class="item-list-empty" *ngIf="list.sortedItems.length === 0">
        No Results Found
      </div>
    </div>

    <div class="storage-selector-grid" [shown]="list.folderItems.length > 0 && !isUploading" *ngIf="!storageManagerService.isListView">
      <div class="storage-row" *ngFor="let item of list.sortedItems">
        <div>
          <div class="folder-entry" *ngIf="isFolder(item.name)" (click)="loadItems(item.name)">
            <a href="#">
              <streamline-icon name="folder" width="38" height="30"></streamline-icon>
              <div class="folder-name">{{ fileNameOf(item.name) }}</div>
            </a>
          </div>
          <div class="file-entry" *ngIf="!isFolder(item.name) && fileType !== 'video'" (click)="selectItem(item)">
            <a href="#">
              <img loading="lazy" [src]="thumbnailFor(item)">
            </a>
          </div>
          <div class="video-entry" *ngIf="!isFolder(item.name) && fileType === 'video'" (click)="selectItem(item)">
            <a href="#">
              <streamline-icon name="video"></streamline-icon>
              <div class="video-name">{{ fileNameOf(item.name) }}</div>
            </a>
          </div>
          <div class="overlay-entry" *ngIf="isSelected(item)" (click)="selectItem(item)">
            <streamline-icon class="overlay-icon" name="checkmark" width="38" height="28"></streamline-icon>
            <p class="overlay-text">{{ fileNameOf(item.name) }}</p>
          </div>
        </div>
      </div>

      <div id="noSearchResult" *ngIf="list.sortedItems.length === 0">
        <p>No Results Found</p>
      </div>
    </div>

    <div class="item-list-empty" [shown]="list.folderItems.length === 0 && !isUploading">
      <div class="row">
        <div class="col-xs-12">
          <h2 [shown]="!currentFolder">You have no {{fileTypeName}} in Storage.</h2>
          <h2 [shown]="currentFolder">You have no {{fileTypeName}} in {{currentFolder}}.</h2>
          <p>Upload {{fileTypeName}} from your device to keep your display interesting!</p>
          <div>
            <img class="img-responsive" src="../images/empty-list.svg">
          </div>
        </div>
      </div>
    </div>

    <basic-uploader [uploaderId]="storageSelectorId + '-folder-uploader'"
                    [uploadManager]="storageUploadManager"
                    [validType]="folderType"
                    [shown]="isUploading">
    </basic-uploader>
    <basic-uploader [uploaderId]="storageSelectorId + '-file-uploader'"
                    [uploadManager]="storageUploadManager"
                    [validType]="fileType"
                    [shown]="isUploading">
    </basic-uploader>
  </div>

  <div class="storage-selector-action-button-bar">
    <div class="pt-4 border-top">
      <button id="upload-file-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
        <strong class="u_capitalize">Upload {{fileTypeName}}</strong>
      </button>
      <label id="{{storageSelectorId}}-file-uploader-label"
        class="btn btn-block aligner"
        [ngClass]="hasRegularFileItems() ? 'btn-default' : 'btn-primary'"
        for="{{storageSelectorId}}-file-uploader"
        [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
      >
        <strong class="u_capitalize">Upload {{fileTypeName}}</strong>
      </label>
    </div>
    <div class="mt-3" *ngIf="isFileType('folder')">
      <button id="upload-folder-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
        <strong class="u_capitalize">Upload Folder</strong>
      </button>
      <label id="{{storageSelectorId}}-folder-uploader-label"
        class="btn btn-block btn-default aligner"
        for="{{storageSelectorId}}-folder-uploader"
        [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
      >
        <strong class="u_capitalize">Upload Folder</strong>
      </label>
    </div>
    <div class="mt-3" [shown]="hasRegularFileItems()">
      <button id="{{storageSelectorId}}-add-selected"
        class="btn btn-primary btn-block"
        (click)="addSelected()"
        [disabled]="isUploading || selectedItems.length === 0"
      >
        <strong>Add Selected</strong>
      </button>
    </div>
  </div>
</div>
