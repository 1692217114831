import { Component, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { UserState, UserService, ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { ModalStackService } from 'src/app/components/modals/modal-stack.service';

@Component({
  selector: 'assign-users-modal',
  templateUrl: './assign-users-modal.component.html',
  styleUrls: ['./assign-users-modal.component.scss']
})
export class AssignUsersModalComponent extends ModalComponent {

  filterConfig = {
    placeholder: 'Search Users'
  };

  search = {
    companyId: this.userState.getSelectedCompanyId(),
    sortBy: 'username',
    filter: 'roles:~\"ap\"',
    reverse: false,
    name: 'Users',
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  }
  users = this.ScrollingListService(this.userService.getUsers, this.search);
  selectedUsers = [];

  constructor(public modalRef: BsModalRef,
    private userState: UserState,
    private ScrollingListService: ScrollingListService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(modalRef);
  }

  toggleUser (userId) {
    const selected = this.selectedUsers.indexOf(userId);
    if (selected < 0) {
      this.selectedUsers.push(userId);
    } else {
      this.selectedUsers.splice(selected, 1);
    }
  }

  userSelected (userId) {
    return this.selectedUsers.indexOf(userId) >= 0;
  }

  dismiss () {
    super.reject();
  }

  closeAllModals() {
    ModalStackService.closeAll();
  }

  apply () {
    super.resolve(this.selectedUsers);
  }

}
