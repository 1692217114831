import { Component, OnInit, Input, ViewChild, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl, FormControlDirective, UntypedFormControl } from '@angular/forms';

import { LazyLoadService } from 'src/app/ajs-upgraded-providers';

@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements ControlValueAccessor, OnInit {
  ZXCVBN_PATH = 'vendor/zxcvbn/zxcvbn.js';

  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective;
  @Input()
  formControl: UntypedFormControl;

  @Input() showPasswordMeter: boolean = false;
  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() minlength = 0;

  feedback;
  scorePercentage;
  strength;
  strengthClass;

  constructor(@Self() @Optional() public control: NgControl,
    private lazyLoadService: LazyLoadService) {
    this.control && (this.control.valueAccessor = this);
  }

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this._validate(value);
    });
  }
  // from ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  // password validation
  _validate(value) {
    if (this.showPasswordMeter) {
      this.lazyLoadService.load(this.ZXCVBN_PATH).then(() => {
        var result = window.zxcvbn(value);
        this.feedback = result.feedback.warning;

        this._validateStrength(result);
        this._updateStrength(this.control.invalid ? 0 : result.score);
      });
    }
  }
  
  _updateStrength(score) {
    // if score is 0, we still want to show a red progress bar, so we set the minimum percentage to 25%
    this.scorePercentage = Math.max(25, score / 4 * 100);
    if (score === 4) {
      this.strength = 'Great';
      this.strengthClass = 'success';
    } else if (score < 4 && score >= 2) {
      this.strength = 'Ok';
      this.strengthClass = 'warning';
    } else {
      this.strength = 'Weak';
      this.strengthClass = 'danger';
    }
  }

  _validateStrength(validation) {
    if (this.showPasswordMeter && !this.control.invalid && validation.score < 2) {
      this.control.control.setErrors({ 'weakPassword': this.feedback || 'Password is too weak' });
    }
  }
}
