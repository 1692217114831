<form id="safeDeleteForm">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismissModal()" data-dismiss="modal" aria-hidden="true">
      <streamline-icon name="close" width="10" height="10"></streamline-icon>
    </button>
    <h4 class="modal-title">Delete Company?</h4>
  </div>
  <div class="modal-body text-left">
    <p>Are you sure you want to delete the company <strong>{{name}}</strong>? This operation cannot be undone! Please type DELETE to confirm.</p>
    <input id="safeDeleteInput" name="safeDeleteInput" type="text" [(ngModel)]="inputText" class="form-control" />
  </div>
  <div class="modal-footer">
    <button class="btn btn-default btn-toolbar" type="button" (click)="dismissModal()">
      Cancel
    </button>
    <button id="deleteForeverButton" class="btn btn-primary btn-toolbar" type="submit" (click)="confirm()" [disabled]="!canConfirm()">
      Delete Forever
    </button>
  </div>
</form>
