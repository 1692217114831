import { Component, ElementRef } from '@angular/core';

import * as _ from 'lodash';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { FileMetadataUtilsService } from '../services/file-metadata-utils.service';
import { FileExistenceCheckService } from '../services/file-existence-check.service';


@Component({
  selector: 'template-component-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {
  private DEFAULT_VIDEO_THUMBNAIL = 'streamline:video';

  public componentId: string;

  uploadManager: any;
  isUploading: boolean;
  values = {
    volume: 0
  };
  isDefaultFileList: boolean;
  selectedFiles;

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private attributeDataFactory: AttributeDataService,
    public templateEditorFactory: TemplateEditorService,
    private templateEditorUtils: TemplateEditorUtilsService,
    private storageManagerFactory: StorageManagerService,
    private fileMetadataUtilsService: FileMetadataUtilsService,
    private fileExistenceCheckService: FileExistenceCheckService,
    public currentPlanFactory: CurrentPlanService) {

    this.componentsFactory.registerDirective({
      type: 'rise-video',
      element: this.elementRef.nativeElement,
      show: () => {
        this._reset();
        this.componentId = componentsFactory.selected.id;

        this.storageManagerFactory.fileType = 'video';
        this.storageManagerFactory.onSelectHandler = (newSelectedItems) => {
          this._addFilesToMetadata(newSelectedItems, true);
        };

        this._loadSelectedFiles();
        this._loadVolume();
      },
      getName: (componentId) => {
        var files = this._getFilesFor(componentId);

        if (files && files.length > 0) {
          return this.templateEditorUtils.fileNameOf(files[0]);
        } else {
          return null;
        }
      }
    });

    this.uploadManager = {
      onUploadStatus: (isUploading) => {
        this.isUploading = isUploading;
      },
      addFile: (file) => {
        this._addFilesToMetadata([file]);
      },
      isSingleFileSelector: false
    };

    this._reset();
  }

  _reset() {
    this._setSelectedFiles([]);
    this.isUploading = false;
  }

  _addFilesToMetadata(files, alwaysAppend?) {
    var selectedFiles = this.isDefaultFileList ? [] : this.selectedFiles;
    var metadata = this.fileMetadataUtilsService.metadataWithFile(selectedFiles,
      this.DEFAULT_VIDEO_THUMBNAIL, files, alwaysAppend);

    this._setMetadata(metadata);
  }

  _loadSelectedFiles() {
    var selectedFiles = this._getAttribute('metadata');

    if (selectedFiles) {
      this._setSelectedFiles(selectedFiles);
    }

    this.templateEditorFactory.loadingPresentation = true;

    this._checkFileExistenceFor(this.componentId)
      .finally(() => {
        setTimeout(() => {
          this.templateEditorFactory.loadingPresentation = false;
        });
      });
  }

  _loadVolume() {
    var volume = this._getAvailableAttribute('volume');

    // default to value 0 if volume not defined
    this.values.volume = this.templateEditorUtils.intValueFor(volume, 0);
  }

  _getAttribute(key) {
    return this.attributeDataFactory.getAttributeData(this.componentId, key);
  }

  _setAttribute(key, value) {
    this.attributeDataFactory.setAttributeData(this.componentId, key, value);
  }

  _getAvailableAttribute(key) {
    return this.attributeDataFactory.getAvailableAttributeData(this.componentId, key);
  }

  _getBlueprintData(key) {
    return this.attributeDataFactory.getBlueprintData(this.componentId, key);
  }

  _getFilesFor(componentId) {
    var metadata = this.attributeDataFactory.getAttributeData(componentId, 'metadata');

    if (!metadata) {
      var files = this.attributeDataFactory.getAvailableAttributeData(componentId, 'files');

      return this.fileMetadataUtilsService.filesAttributeToArray(files);
    }

    return this.fileMetadataUtilsService.extractFileNamesFrom(metadata);
  }

  updateFileMetadata(newMetadata) {
    var currentMetadata = this._getAttribute('metadata');
    var updatedMetadata = this.fileMetadataUtilsService.getUpdatedFileMetadata(currentMetadata, newMetadata);

    if (updatedMetadata) {
      this._setMetadata(updatedMetadata);
    }
  }

  _setMetadata(metadata) {
    var selectedFiles = _.cloneDeep(metadata);

    this._setSelectedFiles(selectedFiles);

    this._setAttribute('metadata', selectedFiles);
  }

  _setSelectedFiles(selectedFiles) {
    var filesAttribute = this.fileMetadataUtilsService.filesAttributeFor(selectedFiles);

    this.selectedFiles = selectedFiles;
    this.isDefaultFileList = filesAttribute === this._getBlueprintData('files');
  }

  saveVolume() {
    this._setAttribute('volume', this.values.volume);
  }

  _checkFileExistenceFor(componentId) {
    var files = this._getFilesFor(componentId);

    return this.fileExistenceCheckService.requestMetadataFor(files, this.DEFAULT_VIDEO_THUMBNAIL)
      .then((metadata) => {
        console.log('received metadata', metadata);

        this.updateFileMetadata(metadata);
      })
      .catch((error) => {
        console.error('Could not check file existence for: ' + componentId, error);
      });
  }

  selectFromStorage() {
    this.componentsFactory.editComponent({
      type: 'rise-storage-selector'
    });
  }

  removeFileFromList(file) {
    var currentMetadata = this.selectedFiles;
    var metadata = this.fileMetadataUtilsService.metadataWithFileRemoved(currentMetadata, file);

    if (metadata) {
      this._setMetadata(metadata);
    }
  }

  showSettingsUI() {
    return this.selectedFiles.length > 0 && !this.isUploading;
  }

  sortItem(evt) {
    var oldIndex = evt.data.oldIndex;
    var newIndex = evt.data.newIndex;

    this.selectedFiles.splice(newIndex, 0, this.selectedFiles.splice(oldIndex, 1)[0]);
  }

}
