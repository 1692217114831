import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderModule } from '../common-header/common-header.module';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';

import { ScheduleSelectorComponent } from './components/schedule-selector/schedule-selector.component';
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { PreviewSelectorComponent } from './components/preview-selector/preview-selector.component';
import { SchedulePickerModalComponent } from './components/schedule-picker-modal/schedule-picker-modal.component';
import { ScheduleTypeSelectorComponent } from './components/schedule-type-selector/schedule-type-selector.component';
import { AssignUsersModalComponent } from './components/assign-users-modal/assign-users-modal.component';
import { ScheduleActionsService } from './services/schedule-actions.service';
import { PlaylistService } from './services/playlist.service';
import { UsernamePipe } from '../shared/pipes/username.pipe';

@NgModule({
  declarations: [
    ScheduleSelectorComponent,
    ScheduleListComponent,
    PreviewSelectorComponent,
    SchedulePickerModalComponent,
    ScheduleTypeSelectorComponent,
    AssignUsersModalComponent
  ],
  imports: [
    CommonModule,
    CommonHeaderModule,
    FormsModule,
    UIRouterModule,
    ComponentsModule,
    SharedModule,
    TooltipModule,
    PopoverModule.forRoot()
  ],
  exports: [
    ScheduleSelectorComponent,
    PreviewSelectorComponent
  ],
  providers: [
    UsernamePipe
  ]
})
export class SchedulesModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399

  static entryComponents = [ ScheduleListComponent, ScheduleTypeSelectorComponent ];
  static providers = [ ScheduleActionsService, PlaylistService ];
}
