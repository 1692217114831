import { Component } from '@angular/core';
import { UserState } from 'src/app/ajs-upgraded-providers';

@Component({
  selector: 'style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss']
})
export class StyleGuideComponent {

  constructor(public userState: UserState) { }
}
