import { Injectable } from '@angular/core';
import { DisplayApiService, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class DisplaySummaryService {

  apiError?: string;
  summary?: any;
  summaryLoading?: boolean;

  constructor(
    private displayApiService: DisplayApiService,
    private processErrorCode: ProcessErrorCode
  ) { }

  loadSummary () {
    this.summary = undefined;
    this.summaryLoading = true;

    return this.displayApiService.summary()
      .then((summary) => {
        this.summary = summary;
      })
      .catch((err) => {
        this.apiError = this.processErrorCode(err);
        console.log('Error requesting display summary', err);
      })
      .finally(() => {
        this.summaryLoading = false;
      });
  }
}
