import { Component } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { UserState } from 'src/app/ajs-upgraded-providers';

@Component({
  selector: 'company-banner',
  templateUrl: './company-banner.component.html',
  styleUrls: ['./company-banner.component.scss']
})
export class CompanyBannerComponent {

  constructor(public userState: UserState) { }

}

angular.module('risevision.common.components')
  .directive(
    'companyBanner', 
    downgradeComponent({
      component: CompanyBannerComponent
    }) as angular.IDirectiveFactory
  );
