<div class="attribute-editor-component">
  <div class="row attribute-editor-row mb-4" [shown]="!sharedTemplatesAvailable">
    <div *ngIf="sharedTemplatesAvailable === null">
      <a href="#" class="madero-link" uiSref="apps.purchase.home">Subscription Required</a>
    </div>
    <div *ngIf="sharedTemplatesAvailable !== null">
      <a href="#" class="madero-link" (click)="showUpgradePlanModal()">Plan Upgrade Required</a>
    </div>
  </div>
  <div class="row attribute-editor-row">
    <div class="col" [ngClass]="{ 'u_link-disabled': !sharedTemplatesAvailable }">
      <label class="text-sm mb-3">
        This company and its sub-companies can use this presentation as a template
      </label>
    </div>
  </div>
  <div class="row attribute-editor-row">
    <div class="madero-radio" id="shareRadio" [ngClass]="{ 'u_link-disabled': !sharedTemplatesAvailable }">
      <input type="radio" [disabled]="userState.isRootCompanySelected() || !sharedTemplatesAvailable" [(ngModel)]="shareTemplate" [value]="true" id="shareTemplateYes" name="shareTemplateRadio" (change)="save()">
      <label for="shareTemplateYes">Yes</label>
      <input type="radio" [disabled]="userState.isRootCompanySelected() || !sharedTemplatesAvailable" [(ngModel)]="shareTemplate" [value]="false" id="shareTemplateNo" name="shareTemplateRadio" (change)="save()">
      <label for="shareTemplateNo">No</label>
   </div>
  </div>
</div>
