import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { CurrentPlanService } from './current-plan.service';
import { PlansService } from './plans.service';
import { UpgradePlanComponent } from './upgrade-plan/upgrade-plan.component';
import { ModalService } from 'src/app/components/modals/modal.service';
import { UserState } from 'src/app/ajs-upgraded-providers';
import { StateService, UrlService } from '@uirouter/angular';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  static readonly FEATURE_MONITORING = 'monitoring';
  static readonly FEATURE_ALERTS = 'alerts';
  static readonly FEATURE_BRANDING = 'branding';
  static readonly FEATURE_OVERRIDE = 'override';
  static readonly FEATURE_TEACHER_ROLE = 'teacher_role';
  static readonly FEATURE_SUBCOMPANY_DISTRIBUTION = 'subcompany_distribution';
  static readonly FEATURE_DISPLAY_CONTROL = 'display_control';
  static readonly FEATURE_SHARED_TEMPLATES = 'shared_templates';
  static readonly FEATURE_CLASSROOM_ALERTS = 'classroom_alerts';
  static readonly BASIC_FEATURES = [];
  advancedFeatures = [
    FeaturesService.FEATURE_MONITORING,
    FeaturesService.FEATURE_ALERTS,
    FeaturesService.FEATURE_BRANDING,
    FeaturesService.FEATURE_OVERRIDE,
    FeaturesService.FEATURE_DISPLAY_CONTROL
  ];

  constructor(
    private currentPlanService: CurrentPlanService,
    private plansService: PlansService,
    private modalService: ModalService,
    private userState: UserState,
    private stateService: StateService,
    private urlService: UrlService
  ) { }

  isFeatureAvailable (feature: string): boolean | null {
    if (this.userState.getCopyOfSelectedCompany().unlockAllFeatures) {
      return true;
    }
    if (!this.currentPlanService.isPlanActive()) {
      return null;
    }
    return this.featureSet.indexOf(feature) >= 0;
  }

  featureRequiresPlan (feature: string): any {
    const plan = this.currentPlanService.currentPlan;
    const discounted = this.plansService.isDiscounted(plan);
    if (this.plansService.isBasicPlan(plan) && this.advancedFeatures.indexOf(feature) >= 0) {
      return this.plansService.getAdvancedPlan(discounted);
    }
    if (this.userState.isK12Customer()) {
      return this.plansService.getUnlimitedPlan();
    }
    return this.plansService.getEnterprisePlan(discounted);
  }

  get featureSet(): string[] {
    return this.userState.getCopyOfSelectedCompany().featureNames || [];
  }

  showUpgradePlanModal(feature: string): Promise<boolean | void> {
    const needsPlan = this.featureRequiresPlan(feature);
    const planExpired = this.isFeatureAvailable(feature) === null;

    return this.modalService.showModal(UpgradePlanComponent, {
      class: 'madero-style',
      initialState: {
        currentPlan: this.currentPlanService.currentPlan.name,
        needsPlan: needsPlan.name,
        planExpired
      }
    }).then(() => {
      if (!planExpired) {
        this.stateService.go('apps.purchase.update-plan', {
          subscriptionId: this.currentPlanService.currentPlan.subscriptionId,
          productCode: needsPlan.productCode,
          redirectTo: this.urlService.url()
        });
      } else {
        this.stateService.go('apps.purchase.home', {
          productCode: needsPlan.productCode
        });
      }
      return true;
    }).catch(() => {});
  }
}

angular.module('risevision.common.components')
  .factory('featuresService', downgradeInjectable(FeaturesService));
