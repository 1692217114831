<section id="TemplateList1" aria-labelledby="FeaturedHeader" class="featured-templates">
  <div class="panel-body container">

    <div id="weekly-templates-notice" *ngIf="!fullView || !this.weeklyTemplates?.items?.list?.length" class="u_clickable" (click)="toggleView()">
      <span id="FeaturedHeader" class="collapsed-label font-weight-medium u_link u_link-no-underline" *requireRole="'!ap'">
        Choose from this week's upcoming events to create a Presentation.
      </span>
      <div class="expand-featured pull-right">
        <span>Show Featured Templates</span>
      </div>
    </div><!-- collapsed view -->

    <div id="weekly-templates-expanded" *ngIf="fullView && this.weeklyTemplates?.items?.list?.length">
      <div id="weekly-templates-close" class="collapse-featured pull-right u_clickable" (click)="toggleView()"><span>Collapse</span></div>
      <div class="row">
        <div id="template-calendar">
          <template-calendar [displayed]="datesAvailable" [(selectedDate)]="selectedDate" (selectedDateChange)="openLibrary()"></template-calendar>
        </div>
        <div class="template-grid">

          <div class="flex-row pl-3">
            <h5 id="FeaturedHeader" class="product-grid-header text-left">Upcoming Events</h5>
          </div>

          <ul class="template-list">
            <li class="template-card" *ngFor="let product of weeklyTemplates.items.list" id="store-product-{{ product.productId }}" (click)="select(product)">
              <!-- Template Image -->
              <div class="template-image">
                <img src="{{ product.imageUrl }}" alt="{{ product.name }}">
                <span class="cta">Use this Template</span>
                <span class="template-fx"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>


    </div><!-- full view -->

  </div>
</section>
