<form id="confirmForm">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()" data-close="modal" aria-hidden="true">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <div class="modal-body">
    <h4>{{ planExpired ? 'Subscription Required' : 'Plan Upgrade Required' }}</h4>
    <div class="plan-features" *ngIf="!planExpired">
      <p>This feature is not available on your current plan{{ currentPlan ? ' (' + currentPlan + ')' : '' }}.
        Upgrade <span [shown]="!!needsPlan">to <strong>{{ needsPlan }}</strong></span> in order to access this feature.
      </p>
    </div>
    <div class="plan-features" *ngIf="planExpired">
      <p>This feature requires an active plan.
        Subscribe <span [shown]="!!needsPlan">to <strong>{{ needsPlan }}</strong></span> in order to access this feature.
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-6">
        <button class="btn btn-default w-100" (click)="close()">
          <span>Cancel</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button id="confirm-primary" class="btn btn-primary w-100" (click)="confirm()">
          <span>{{ planExpired ? 'Subscribe' : 'Upgrade' }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
