import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { COUNTRIES } from 'src/app/ajs-upgraded-providers';
import { GeodataService } from 'src/app/shared/services/geodata.service';

@Component({
  selector: 'display-address',
  templateUrl: './display-address.component.html',
  styleUrls: ['./display-address.component.scss']
})
export class DisplayAddressComponent {

  @Input() display: any;
  @Output() displayChange = new EventEmitter<{}>();
  regionsCA = GeodataService.REGIONS_CA;
  regionsUS = GeodataService.REGIONS_US;

  constructor(
    public countries: COUNTRIES,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  detectChanges () {
    this.changeDetectorRef.detectChanges();
  }
}

angular.module('risevision.displays.directives')
  .directive(
    'displayAddress',
    downgradeComponent({
      component: DisplayAddressComponent
    }) as angular.IDirectiveFactory
  );