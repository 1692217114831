import { Injectable } from '@angular/core';
import { AjsState, AnalyticsFactory, ProcessErrorCode, UserState } from 'src/app/ajs-upgraded-providers';
import { PlansService } from 'src/app/components/plans/plans.service';
import { ReloadSelectedCompanyService } from 'src/app/components/userstate/reload-selected-company.service';
import { PricingService } from './pricing.service';
import { SubscriptionService } from 'src/app/billing/services/subscription.service';
import { BillingApiService } from 'src/app/billing/services/billing-api.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateSubscriptionService {

  public userEmail;
  public loading;
  public errorMessage;
  public apiError;
  public purchase: any = {};
  public estimate: any = {};
  public _purchaseAction;

  constructor(private $state: AjsState,
    userState: UserState,
    private reloadSelectedCompany: ReloadSelectedCompanyService,
    private billingApiService: BillingApiService,
    private analyticsFactory: AnalyticsFactory,
    private pricingFactory: PricingService,
    private subscriptionService: SubscriptionService,
    private processErrorCode: ProcessErrorCode,
    private plansService: PlansService) {

      this.userEmail = userState.getUserEmail();
    }

    _clearMessages() {
      this.loading = false;

      this.errorMessage = '';
      this.apiError = '';
    }

    init(purchaseAction, planCode?, monthly?, downgrade?) {
      this._clearMessages();

      this._purchaseAction = purchaseAction;
      this.purchase = {};
      this.purchase.completed = false;
      this.purchase.licensesToAdd = purchaseAction === 'add' ? this.$state.params.displayCount : 0;
      this.purchase.licensesToRemove = purchaseAction === 'remove' ? this.$state.params.displayCount : 0;
      this.purchase.couponCode = '';

      if (purchaseAction === 'remove' || purchaseAction === 'change' && downgrade) {
        this.purchase.endOfTerm = true;
      }

      this.subscriptionService.getSubscription(this.$state.params.subscriptionId, true).then( () => {
        this.purchase.planId = this.subscriptionService.getItemSubscription().plan_id;

        if (this.purchase.planId && purchaseAction === 'annual') {
          this.purchase.planId = this.purchase.planId.replace('1m', '1y');

        } else if (purchaseAction === 'unlimited') {
          var productCode = this.plansService.getUnlimitedPlan().productCode;
          var currency = this.subscriptionService.getItemSubscription().currency_code.toLowerCase();
          this.purchase.planId = productCode + '-' + currency + '01y';
        } else if (purchaseAction === 'change' && planCode) {
          this.purchase.planId = planCode + '-' + this.subscriptionService.getItemSubscription().currency_code.toLowerCase() + (monthly ? '01m' : '01y');
        }

        this.getEstimate();
      });
    }

    getCurrentDisplayCount() {
      var currentDisplayCount = this.subscriptionService.getItemSubscription().plan_quantity;

      return currentDisplayCount || 0;
    }

    _getChangeInLicenses() {
      if (this._purchaseAction === 'unlimited') {
        return 1;
      }
      var licensesToAdd = this.purchase.licensesToAdd || 0;
      var licensesToRemove = this.purchase.licensesToRemove || 0;

      return licensesToAdd - licensesToRemove;
    }

    getTotalDisplayCount() {
      if (this._purchaseAction === 'unlimited') {
        return 1;
      }
      return this.getCurrentDisplayCount() + this._getChangeInLicenses();
    }

    _getTrackingProperties() {
      return {
        planId: this.purchase.planId,
        currentPlanId: this.subscriptionService.getItemSubscription().plan_id,
        purchaseAction: this._purchaseAction === 'annual' || this._purchaseAction === 'unlimited' ? 'change' : this._purchaseAction,
        subscriptionId: this.subscriptionService.getItemSubscription().id,
        subscriptionPlan: this.plansService.getPlanById(this.purchase.planId).name,
        paymentTerm: this.purchase.planId.endsWith('m') ? 'monthly' : 'yearly',
        changeInLicenses: this._getChangeInLicenses(),
        totalLicenses: this.getTotalDisplayCount(),
        companyId: this.subscriptionService.getItemSubscription().customer_id
      };
    }

    _updatePerDisplayPrice() {
      if (!this.estimate.next_invoice_estimate) {
        return;
      }

      const currentDisplayCount = this.getCurrentDisplayCount();
      const displayCount = this.getTotalDisplayCount();

      const currentPlanId = this.subscriptionService.getItemSubscription().plan_id;

      const lineItem = this.estimate.next_invoice_estimate.line_items[0];
      const isMonthly = lineItem.entity_id.endsWith('m');

      const educationDiscount = !!this.estimate.next_invoice_estimate?.line_item_discounts?.find((item) => item.coupon_id === 'EDUCATION');
      const isEducation = !!educationDiscount;

      this.purchase.currentPricePerDisplay = this.pricingFactory.getPricePerDisplay(isMonthly,
        currentDisplayCount, isEducation, currentPlanId.substring(0, currentPlanId.indexOf('-')));
      this.purchase.newPricePerDisplay = this.pricingFactory.getPricePerDisplay(isMonthly, displayCount,
        isEducation, lineItem.entity_id.substring(0, lineItem.entity_id.indexOf('-')));
    }

    getEstimate() {
      this._clearMessages();

      this.loading = true;

      var couponCode = this.purchase.couponCode;
      var displayCount = this.getTotalDisplayCount();
      var subscriptionId = this.subscriptionService.getItemSubscription().id;
      var companyId = this.subscriptionService.getItemSubscription().customer_id;
      var planId = this.purchase.planId;
      var endOfTerm = this.purchase.endOfTerm;

      return this.billingApiService.estimateSubscriptionUpdate(displayCount, subscriptionId, planId, companyId, couponCode, endOfTerm)
        .then( (result) => {
          this.estimate = result.items[0];

          if (this._purchaseAction !== 'unlimited') {
            this._updatePerDisplayPrice();
          }

          this.analyticsFactory.track('Subscription Update Estimated', this._getTrackingProperties());
        })
        .catch( (e) => {
          this.errorMessage = 'Something went wrong.';
          this.apiError = this.processErrorCode(e);
        })
        .finally( () => {
          this.loading = false;
        });
    }

    getCreditTotal() {
      if (!this.estimate || !this.estimate.credit_note_estimates) {
        return 0;
      }

      var total = this.estimate.credit_note_estimates.reduce( (total, note) => {
        return total + note.total;
      }, 0);

      return total / 100;
    }
    preparePayment() {
      this._clearMessages();

      if (this.subscriptionService.isInvoiced() || true) {
        return Promise.resolve();
      }

      this.loading = true;

      var couponCode = this.purchase.couponCode;
      var displayCount = this.getTotalDisplayCount();
      var subscriptionId = this.subscriptionService.getItemSubscription().id;
      var companyId = this.subscriptionService.getItemSubscription().customer_id;
      var planId = this.purchase.planId;
      var endOfTerm = this.purchase.endOfTerm;

      return this.billingApiService.prepareUpdateSubscription(displayCount, subscriptionId, planId, companyId, couponCode, endOfTerm)
        .then( (response) => {
          this.purchase.intentResponse = response;
        })
        .catch( (e) => {
          this.errorMessage = 'Something went wrong.';
          this.apiError = this.processErrorCode(e);

          this.loading = false;
        });
    }
    completePayment() {
      this._clearMessages();

      this.loading = true;

      var couponCode = this.purchase.couponCode;
      var displayCount = this.getTotalDisplayCount();
      var subscriptionId = this.subscriptionService.getItemSubscription().id;
      var companyId = this.subscriptionService.getItemSubscription().customer_id;
      var planId = this.purchase.planId;
      var endOfTerm = this.purchase.endOfTerm;
      var paymentIntentId = this.purchase.intentResponse ? this.purchase.intentResponse.intentId : null;

      return this.billingApiService.updateSubscription(displayCount, subscriptionId, planId, companyId, couponCode, endOfTerm, paymentIntentId)
        .then( () => {
          this.analyticsFactory.track('Subscription Updated', this._getTrackingProperties());

          if (!endOfTerm) {
            return this.reloadSelectedCompany.reloadPlan(planId, displayCount)
              .catch( (err) => {
                console.debug('Failed to reload company', err);
              });
          }
        })
        .then(() => {
          this.purchase.completed = true;
        })
        .catch( (e) => {
          this.errorMessage = 'Something went wrong.';
          this.apiError = this.processErrorCode(e);
        })
        .finally( () => {
          this.loading = false;
        });
    }
}
