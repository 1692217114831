import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiUtilsService } from './services/api-utils.service';
import { BroadcasterService } from './services/broadcaster.service';
import { PromiseUtilsService } from './services/promise-utils.service';
import { AddressService } from './services/address.service';
import { GeodataService } from './services/geodata.service';
import { ValidationService } from './services/validation.service';
import { ShownDirective } from './directives/shown.directive';
import { DebounceDirective } from './directives/debounce.directive';
import { RvSortableDirective } from './directives/rv-sortable.directive';
import { RequireRoleDirective } from './directives/require-role.directive';
import { DisplayStatusPipe } from './pipes/display-status.pipe';
import { UsernamePipe } from './pipes/username.pipe';
import { RunZoneDirective } from './directives/run-zone.directive';


@NgModule({
  declarations: [
    ShownDirective,
    DebounceDirective,
    RvSortableDirective,
    RequireRoleDirective,
    DisplayStatusPipe,
    UsernamePipe,
    RunZoneDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ShownDirective,
    DebounceDirective,
    RvSortableDirective,
    RequireRoleDirective,
    DisplayStatusPipe,
    UsernamePipe,
    RunZoneDirective
  ]
})
export class SharedModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = []
  static providers = [ ApiUtilsService, BroadcasterService, PromiseUtilsService, AddressService, GeodataService, ValidationService ]
}
