<ng-template #previewSelectorTooltip>
  <span id="preview-selector-tooltip" class="preview-selector-tooltip">
    <div class="row">
      <div class="col-xs-12">
        <search-filter [filterConfig]="filterConfig" [search]="search" [doSearch]="schedules.doSearch" iconSet="madero"></search-filter>
      </div>
    </div>

    <div class="scrollable-list mt-3 mb-3"
      scrollingList="schedules.load()"
      rvSpinner="preview-selector-spinner" [rvShowSpinner]="schedules.loadingItems">
      <div class="flex-row" *ngFor="let schedule of schedules.items.list">
        <div class="row-entry mb-3">
          <a class="madero-link mr-auto u_text-ellipsis" href="#" (click)="select(schedule)">
            {{ schedule.name }}
          </a>
          <a class="madero-link mr-3" uiSref="apps.schedules.details" [uiParams]="{scheduleId: schedule.id}" (click)="closeModal()">
            Edit
          </a>
        </div>
      </div>

      <div id="noItems" class="schedule-list-empty" *ngIf="!schedules.loadingItems && !schedules.search.query && schedules.items.list.length === 0">
        No Schedules
      </div>
      <div id="noSearchResult" class="schedule-list-no-results" *ngIf="!schedules.loadingItems && schedules.search.query && schedules.items.list.length === 0">
        No Results Found
      </div>
    </div>

    <div class="row" *requireRole="'!ap'">
      <div class="col-xs-12">
        <a type="button" class="btn btn-default btn-block" uiSref="apps.schedules.add" (click)="closeModal()">Add Schedule</a>
      </div>
    </div>

  </span>
</ng-template>
<div class="form-group {{ additionalClass }}">
  <label *ngIf="label" class="mr-3">{{ label }}</label>
  <button id="preview-selector" type="button" class="form-control btn-select btn-schedule-selector"
    #tooltipTrigger="bs-tooltip"
    triggers=""
    [tooltip]="previewSelectorTooltip"
    tooltipAnimation="false"
    [adaptivePosition]="false"
    placement="{{ placement || 'bottom left' }}"
    containerClass="madero-style tooltip tooltip-schedule-selector ml-0 {{ additionalTooltipClass }}"
    container="body"
    (onShown)="showPreviewSelector()"
    (onHidden)="hidePreviewSelector()"
    (click)="toggleTooltip()"
  >
    <div class="row-entry">
      <span class="text-muted mr-auto" *ngIf="!selectedItem">Select or add a new schedule</span>
      <span class="u_text-ellipsis mr-auto" *ngIf="selectedItem">{{ selectedItem.name }}</span>
      <span class="text-muted pl-2 pull-right"><i class="fa fa-sort"></i></span>
    </div>
  </button>
</div>
