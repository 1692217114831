import { Injectable } from '@angular/core';
import { UserState } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';
import { NewFolderModalComponent } from '../components/new-folder-modal/new-folder-modal.component';
import { CopyUrlModalComponent } from '../components/copy-url-modal/copy-url-modal.component';
import { RenameFileModalComponent } from '../components/rename-file-modal/rename-file-modal.component';
import { StorageModalComponent } from '../components/storage-modal/storage-modal.component';
import { FolderSelectorModalComponent } from '../components/folder-selector-modal/folder-selector-modal.component';

import { environment } from 'src/environments/environment';

import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

@Injectable({
  providedIn: 'root'
})
export class StorageUtilsService {

  static readonly STORAGE_FILE_URL = 'https://storage.googleapis.com/';
  static readonly STORAGE_CLIENT_API = 'https://www.googleapis.com/storage/v1/b/';
  static readonly SELECTOR_TYPES = {
    SINGLE_FILE: 'single-file',
    MULTIPLE_FILE: 'multiple-file',
    SINGLE_FOLDER: 'single-folder',
    MULTIPLE_FILES_FOLDERS: 'multiple-files-folders'
  };
  static readonly SELECTOR_FILTERS = {
    ALL: {
      name: '',
      extensions: null
    },
    IMAGES: {
      name: 'images',
      extensions: [
        'jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif', 'webp'
      ]
    },
    VIDEOS: {
      name: 'videos',
      extensions: [
        'webm', 'mp4', 'ogg', 'ogv'
      ]
    },
    FOLDERS: {
      name: 'folders',
      extensions: [
        '/'
      ]
    }
  };
  static readonly STORAGE_CLIENT_ERROR = {
    'blocked-file': 'This file is being requested too many times and is blocked.',
    'duplicate-folder': 'A Folder already uses that name, please choose a different one',
    'move': {
      'both-files-or-folders': 'Destination must be a folder.',
      'both-root-or-trash': 'Source and destination must be both in Root or in Trash.',
      'destination-must-not-exist': 'Destination folder already contains a file with that name.',
      'invalid-name': 'The new name cannot have slashes.',
      'must-be-different': 'You cannot move these files or folders into the same folder. Please select a different folder and try again.',
      'no-matches-found': 'No matching files found.',
      'parent-must-exist': 'Destination parent directory must exist.',
      'protected-object-id': 'This file is protected and cannot be moved.',
      'root-not-valid': 'Source and destination cannot point to the root folder.',
      'subfolders-not-valid': 'Source and destination cannot be subfolders of each other.',
      'unknown': 'An error occured while moving. Please try again.'
    },
    'server-error': 'An error has occured.',
    'signed-download-uri-request-failed': 'Download failed. Please try again.',
    'upload-failed': 'Upload failed. Please try again.',
    'upload-file-not-supported': 'File type not supported. File did not upload.',
    'rename': {
      'both-files-or-folders': 'Source and destination must be both files or folders.',
      'both-root-or-trash': 'Source and destination must be both in Root or in Trash.',
      'destination-must-not-exist': 'Name is in use, please choose another.',
      'invalid-name': 'The new name cannot have slashes.',
      'must-be-different': 'The new name must be different.',
      'no-matches-found': 'No matching files found.',
      'parent-must-exist': 'Destination parent directory must exist.',
      'potentially-dangerous-file': 'This does not appear to be a supported media (video, image) file.',
      'protected-object-id': 'This file is protected and cannot be renamed.',
      'root-not-valid': 'Source and destination cannot point to the root folder.',
      'subfolders-not-valid': 'Source and destination cannot be subfolders of each other.',
      'unknown': 'An error occured while renaming. Please try again.'
    }
  };
  static readonly STORAGE_CLIENT_TITLE = {
    'select-file': 'Select a File',
    'select-folder-to-update': 'Select a Folder to show and update Images and Videos as you add or remove them from the Folder.',
    'select-multiple-file': 'Select one or more Files',
    'select-multiple-files-folders': 'Select Files and Folders',
    'select-multiple-files-folders-images': 'Select Images and/or Folders of Images',
    'select-multiple-files-folders-videos': 'Select Videos and/or Folders of Videos',
    'select-single-file': 'Select a File',
    'select-single-file-images': 'Select an Image',
    'select-single-file-videos': 'Select a Video',
    'select-single-folder': 'Select a Folder',
    'select-single-folder-images': 'Select a Folder of Images',
    'select-single-folder-videos': 'Select a Folder of Videos'
  };
  static readonly TRASH_PLACEHOLDER = '--TRASH--';
  static readonly TRASH_FOLDER = '--TRASH--/';
  static readonly TRASH_LABEL = 'Trash';

  constructor(
    private modalService: ModalService,
    private userState: UserState
  ) { }

  fileIsFolder (file: any): boolean {
    return file.name.endsWith('/') || file.name === '';
  }

  fileIsTrash (file: any): boolean {
    return file.name === StorageUtilsService.TRASH_FOLDER;
  }

  isTrashFolder (path: string): boolean {
    return path.lastIndexOf(StorageUtilsService.TRASH_FOLDER, 0) === 0;
  }

  fileName (file: any): string {
    return file.name.substring(this.fileParent(file).length);
  }

  fileParent (file: any): string {
    const idx = file.name.length - (this.fileIsFolder(file) ? 2 : 1);

    return file.name.substring(0, file.name.lastIndexOf('/', idx) + 1);
  }

  getBucketName (): string {
    return 'risemedialibrary-' + this.userState.getSelectedCompanyId();
  }

  getFolderSelfLinkUrl (): string {
    return StorageUtilsService.STORAGE_CLIENT_API + this.getBucketName() + '/o?prefix=';
  }

  isDefaultFileOnTestAppsEnvironment(fileName) {
    if (environment.production) {
      return false;
    }

    // all default files for Rise Vision templates are defined under this GCS bucket
    const regex = /^risemedialibrary-7fa5ee92-7deb-450b-a8d5-e5ed648c575f[/]Template Library[/].+/;

    return regex.test(fileName);
  }

  parseFilePath(fileName: string): string[]|null {
    const regex = /risemedialibrary-([0-9a-f-]{36})[/](.+)/g;
    const match = regex.exec(fileName);

    if (!match) {
      console.error('Filename is not a valid Rise Storage path: ' + fileName);

      return null;
    }

    return match;
  }

  fileSortValue (file: any, field: string): string|number {
    switch (field) {
      case 'name':
        return file.name
          .replace(StorageUtilsService.TRASH_FOLDER, StorageUtilsService.TRASH_LABEL)
          .toLowerCase()
          .split(' (')
          .join('/(');
      case 'dateModified':
        return file.updated ? file.updated.value : '';
      case 'extension':
        if (file.name.endsWith('/')) {
          return 'Folder';
        }
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        return ext === file.name ? '' : ext;
      case 'size':
        return file.size ? Number(file.size) : 0;
      default:
        return '';
    }
  }

  filterFiles (files: any[], search: any, storageFull: boolean): any[] {
    const filtered = files.filter((file) => {
      return (!search.query || file.name.toLowerCase().indexOf(search.query.toLowerCase()) >= 0) &&
        (storageFull || !this.fileIsTrash(file));
    });
    filtered.sort((a, b) => {
      const term1 = this.fileSortValue(a, search.sortBy);
      const term2 = this.fileSortValue(b, search.sortBy);
      const order = term1 < term2 ? -1 : term1 > term2 ? 1 : 0;
      return search.reverse ? -order : order;
    });
    return filtered;
  }

  private _getFileUrl (file: any): string {
    const fileUrl = file.kind === 'folder' ?
      this.getFolderSelfLinkUrl() +
      encodeURIComponent(file.name) :
      StorageUtilsService.STORAGE_FILE_URL + this.getBucketName() + '/' +
      encodeURIComponent(file.name);

    return fileUrl;
  }

  getFileUrls (files: any[]): string[] {
    const fileUrls = [];

    if (files) {
      files.forEach((file) => {
        var copyUrl = this._getFileUrl(file);
        fileUrls.push(copyUrl);
      });
    }

    return fileUrls;
  }

  storageTitle (selectorType: string, filterName: string): string {
    const key = 'select-' + selectorType + (filterName ? '-' + filterName : '');
    return StorageUtilsService.STORAGE_CLIENT_TITLE[key] || '';
  }

  showAddFolderModal (folderPath: string, existingFiles: any[]): Promise<any> {
    return this.modalService.showMediumModal(NewFolderModalComponent, {
      initialState: {
        folderPath,
        existingFiles
      }
    });
  }

  showCopyUrlModal (copyFile: any): Promise<any> {
    return this.modalService.showLargeModal(CopyUrlModalComponent, {
      initialState: {
        copyFile
      }
    });
  }

  showRenameFileModal (selectedFile: any, renameObject: Function): Promise<any> {
    return this.modalService.showMediumModal(RenameFileModalComponent, {
      initialState: {
        selectedFile,
        renameObject
      }
    });
  }

  showStorageModal (type: string = 'single-file', filter: string = '', enableByURL: boolean = false): Promise<any> {
    return this.modalService.showLargeModal(StorageModalComponent, {
      initialState: {
        selectorType: type,
        selectorFilter: filter,
        enableByURL
      }
    });
  }

  showFolderSelectorModal (folderPath: string, excludedFiles: string[]): Promise<any> {
    return this.modalService.showMediumModal(FolderSelectorModalComponent, {
      initialState: {
        folderPath: folderPath,
        isTrashFolder: this.isTrashFolder(folderPath),
        excludedFiles: excludedFiles
      }
    });
  }
}

angular.module('risevision.storage.services')
  .constant('STORAGE_FILE_URL', StorageUtilsService.STORAGE_FILE_URL)
  .constant('STORAGE_CLIENT_API', StorageUtilsService.STORAGE_CLIENT_API)
  .constant('SELECTOR_TYPES', StorageUtilsService.SELECTOR_TYPES)
  .constant('SELECTOR_FILTERS', StorageUtilsService.SELECTOR_FILTERS)
  .constant('STORAGE_CLIENT_ERROR', StorageUtilsService.STORAGE_CLIENT_ERROR)
  .factory('storageUtilsService', downgradeInjectable(StorageUtilsService));
