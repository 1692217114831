import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { CommonHeaderModule } from '../common-header/common-header.module';
import { SchedulesModule } from '../schedules/schedules.module';
import { routes } from './displays.routes';

import { DisplayAddComponent } from './components/display-add/display-add.component';
import { DisplayLicenseCountsComponent } from './components/display-license-counts/display-license-counts.component';
import { DisplayControlComponent } from './components/display-control/display-control.component';
import { DisplayListOperationsService } from './services/display-list-operations.service';
import { DisplayListComponent } from './components/display-list/display-list.component';
import { DisplayAddressComponent } from './components/display-address/display-address.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { DisplayFieldsComponent } from './components/display-fields/display-fields.component';
import { ScreenshotComponent } from './components/screenshot/screenshot.component';
import { ResolutionPipe } from './pipes/resolution.pipe';
import { DisplayDetailsComponent } from './components/display-details/display-details.component';
import { ViewportComponent } from './viewport/viewport.component';
import { ActivationNoticeComponent } from './components/activation-notice/activation-notice.component';
import { ScreenshotRequesterService } from './services/screenshot-requester.service';
import { PlayerLicenseService } from './services/player-license.service';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { EditRebootTimeComponent } from './components/edit-reboot-time/edit-reboot-time.component';
import { EditMonitoringComponent } from './components/edit-monitoring/edit-monitoring.component';

@NgModule({
  declarations: [
    DisplayAddComponent,
    DisplayLicenseCountsComponent,
    DisplayControlComponent,
    DisplayListComponent,
    DisplayAddressComponent,
    AlertsComponent,
    DisplayFieldsComponent,
    ScreenshotComponent,
    ResolutionPipe,
    DisplayDetailsComponent,
    ViewportComponent,
    ActivationNoticeComponent,
    EditAddressComponent,
    EditRebootTimeComponent,
    EditMonitoringComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild(routes),
    ComponentsModule,
    SchedulesModule,
    SharedModule,
    CommonHeaderModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    MatExpansionModule,
    MatSlideToggleModule,
    MatCheckboxModule
  ]
})
export class DisplaysModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [ DisplayControlComponent, DisplayAddressComponent ];
  static providers = [ DisplayListOperationsService, ScreenshotRequesterService, PlayerLicenseService ];
}
