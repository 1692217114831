<div rvSpinner="auth-buttons-login" [rvShowSpinner]="loading">
  <h1 *ngIf="!joinAccount">Ready to join 9,000 organizations and start communicating better?</h1>
  <h1 *ngIf="joinAccount">Ready to<span [shown]="companyName"> join {{companyName}} and</span> start communicating better?</h1>

  <div class="feature-sideways-panels">
    <div class="mb-5 mt-lg-5">
      <div class="brandscript-clients">
        <strong>Top Organizations Trust Rise Vision</strong>
        <div class="flex-row w-100 mt-4 mb-5">
          <img class="" src="../images/sign-up/marriott-logo.png" alt="Marriott Hotels" height="42">
          <img class=" mx-4" src="../images/sign-up/cps-logo.png" alt="Chicago Public Schools" height="42">
          <img class="" src="../images/sign-up/eagles-logo.png" alt="Philadelphia Eagles" height="42">
        </div>
        <ul class="features-checklist">
          <li><streamline-icon name="checkmark"></streamline-icon>Fraction of the cost of competitors.</li>
          <li><streamline-icon name="checkmark"></streamline-icon>Hundreds of professionally-designed templates.</li>
          <li><streamline-icon name="checkmark"></streamline-icon>Unlimited, premium support.</li>
        </ul>
      </div>
    </div>
    <div>
      <div class="border-container mx-auto">
        <div class="panel-body">

          <div *ngIf="!joinAccount">
            <h4>Start your FREE 14-day trial.</h4>
          </div>

          <div *ngIf="joinAccount">
            <h4 *ngIf="companyName">Join {{companyName}}</h4>
            <h4 *ngIf="!companyName">Complete your account registration</h4>
          </div>

          <!-- CUSTOM LOGIN -->
          <div>
            <!-- Error Message -->
            <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.message">
              <p><strong>{{errors.messageTitle}}</strong></p>
              <p class="u_remove-bottom" [innerHTML]="errors.message"></p>
            </div>

            <!-- Generic Error -->
            <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.genericError">
              <p class="u_remove-bottom">
                <i class="fa fa-warning icon-left"></i>
                <span id="signup-failure-error">Registration failed. <br/>If you are having problems registering, please check this <a href="https://help.risevision.com/hc/en-us/articles/115001402743-I-am-having-trouble-logging-in-what-can-I-do-" target="_blank">Help Center article</a>.</span>
              </p>
            </div>

            <!-- Duplicate Account Error -->
            <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.duplicateError">
              <p class="u_remove-bottom">
                <i class="fa fa-warning icon-left"></i>
                <span id="already-registered-warning">This email address is already registered. You can <a uiSref="common.auth.unauthorized">sign in</a> with this address.</span>
              </p>
            </div>

            <!-- SSO Login Required Error -->
            <div class="panel-body bg-danger u_margin-sm-top" [shown]="errors.ssoRequired">
              <p class="u_remove-bottom">
                <i class="fa fa-warning icon-left"></i>
                <span id="sso-required-warning">Your account has been activated, but SSO is required for this company. Please <a uiSref="common.auth.unauthorized">sign in with SSO</a>.</span>
              </p>
            </div>

            <!-- ERROR PANEL 6 -->
            <div class="panel-body bg-danger u_margin-sm-top" [shown]="invalidRequest && messages.inviteResent === undefined">
              <p class="u_remove-bottom">
                <i class="fa fa-warning icon-left"></i>
                <span id="invalid-request-url" *ngIf="joinAccount">
                  The activation link is not valid. Use the button below to send a new activation email.
                </span>
                <span id="invited-account-signup" *ngIf="!joinAccount">
                  This email address has been invited to join a company. Please activate your account using the link in the invitation email.
                </span>
              </p>
            </div>

            <!-- MESSAGE PANEL 3 -->
            <div class="panel-body bg-info u_margin-sm-top" *ngIf="messages.inviteResent !== undefined">
              <p class="u_remove-bottom">
                <i class="fa fa-warning icon-left"></i>
                <span [shown]="messages.inviteResent">The invitation email has been resent. Please use the link in the email to activate your account.</span>
                <span [shown]="!messages.inviteResent">The invitation email could not be sent. If the problem persists, please <a target="_blank" href="mailto:support@risevision.com">contact support</a>.</span>
              </p>
            </div>

            <auth-form
              [credentials]="credentials"
              [isSignUp]="true"
              [joinAccount]="joinAccount"
              [shown]="!invalidRequest && !errors.ssoRequired"
              [ssoChecked]="true"
              [ssoEnabled]="ssoEnabled"
              [(usePassword)]="usePassword"
              (googleLogin)="googleLogin()"
              (submitForm)="createAccount($event)"
              (loading)="loading = $event">
            </auth-form>

          </div>

        </div>
      </div><!--panel-->

      <div class="align-center u_margin-md-top">
        <p>Already have an account? <a class="madero-link" id="sign-in-link" uiSref="common.auth.unauthorized">Sign in</a>.</p>
      </div>

    </div>
  </div><!--feature-sideways-panels-->
</div>