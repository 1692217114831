<div class="attribute-editor-component">
  <basic-uploader [uploaderId]="folderUploaderId + '-uploader'"
                  [uploadManager]="storageUploadManager"
                  [validType]="folderType"
                  [shown]="isUploading">
  </basic-uploader>
  <basic-uploader [uploaderId]="fileUploaderId + '-uploader'"
                  [uploadManager]="storageUploadManager"
                  [validType]="fileType"
                  [fileList]="canvaUploadList"
                  [shown]="isUploading">
  </basic-uploader>

  <!-- default view -->
  <div class="attribute-editor-component" >
    <div class="attribute-editor-row">
      <div>
        <button id="upload-file-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
          Upload Files
        </button>
        <label id="{{fileUploaderId}}-uploader-label"
          class="btn btn-default btn-block aligner"
          for="{{fileUploaderId}}-uploader"
          [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
        >
          Upload Files
        </label>
      </div>
      <div *ngIf="canUploadFolder()" class="mt-3">
        <button id="upload-folder-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
          Upload Folder
        </button>
        <label id="{{folderUploaderId}}-uploader-label"
          class="btn btn-default btn-block aligner"
          for="{{folderUploaderId}}-uploader"
          [attr.disabled]="isUploading ? true : null" [shown]="currentPlanService.isPlanActive()"
        >
          Upload Folder
        </label>
      </div>
      <button id="te-media-select" class="btn btn-default btn-block mt-4" [disabled]="isUploading" (click)="selectFromStorage()">
        Select from Storage
      </button>
      <div class="edit-in-canva mt-4" *ngIf="!isDocument">
        <button id="canva-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
          <strong>Design with Canva</strong>
        </button>
        <canva-button (designPublished)="onDesignPublished($event)" [disabled]="isUploading" [shown]="currentPlanService.isPlanActive()"></canva-button>
      </div>
      <button *ngIf="canSelectGooglePhotos()" id="te-google-photos" class="btn btn-default btn-block mt-4" [disabled]="isUploading" (click)="selectGooglePhotos()">
        Google Photos
      </button>
    </div>
  </div>
</div>
