<div class="financial-container">
<div class="instrument-list te-scrollable-container"
    [ngClass]="{ 'instrument-list-show': enteringInstrumentSelector, 'instrument-list-cover': enteringSymbolSelector, 'instrument-list-uncover': exitingSymbolSelector }"
    [shown]="showInstrumentList || enteringInstrumentSelector || exitingSymbolSelector"
    rvSpinner="template-editor-loader" [rvShowSpinner]="templateEditorFactory.loadingPresentation">
  <div class="row instrument-row" *ngFor="let instr of instruments">
    <div class="col-xs-10 pl-0">
      <div class="instrument-name instrument-name-ellipsis">{{ instr.name | uppercase }}</div>
      <div class="instrument-rate">{{ instr.symbol | uppercase }}</div>
    </div>
    <div class="col-xs-2 pr-0 instrument-delete">
      <a href="#" (click)="removeInstrument(instr.symbol)">
        <streamline-icon class="ml-3 streamline-component-icon" name="trash" width="16" height="18"></streamline-icon>
      </a>
    </div>
  </div>
  <div class="row instrument-list-empty" [hidden]="instruments.length !== 0 || templateEditorFactory.loadingPresentation">
    <div class="col-xs-12">
      <h2>There are no symbols in this list</h2>
      <p>{{ getEmptyListSuggestionText() }}</p>
      <div>
        <img class="img-responsive" src="../images/financial-empty-list.svg">
      </div>
    </div>
  </div>
</div>

<div class="financial-action-button-bar" [shown]="showInstrumentList">
  <div>
    <button id="symbolSelectorButton" class="btn btn-primary btn-block" (click)="showSymbolSearch()">
      <strong>{{ getOpenSymbolSelectorButtonLabel() }}</strong>
    </button>
  </div>
</div>

<div
    [ngClass]="{ 'instrument-selector-show': enteringSymbolSelector, 'instrument-selector-hide': exitingSymbolSelector }"
    [shown]="showSymbolSelector || enteringSymbolSelector">
  <div class="input-group instrument-selector-search-box">
    <span class="input-group-addon" (click)="searchInstruments()">
      <streamline-icon name="magnifier" width="16" height="16"></streamline-icon>
    </span>
    <input type="text" class="form-control"
           [(ngModel)]="searchKeyword" [disabled]="searching" (keydown.enter)="searchKeyPressed()"
           placeholder="{{ getSearchPlaceholder() }}">
    <span class="input-group-addon" (click)="resetSearch()">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </span>
  </div>

  <div class="instrument-selector te-scrollable-container">
  <div class="instrument-selector-no-results" [shown]="!instrumentSearch.length || instrumentSearch.length === 0">
    <h2>No Results Found</h2>
  </div>

  <div class="instrument-selector-popular" [shown]="popularResults">{{ getPopularTitle() }}</div>

  <div class="row instrument-row" [shown]="instrumentSearch.length > 0"
       *ngFor="let instrument of  instrumentSearch; let key = index"
       (click)="selectInstrument(key)"
       [ngClass]="{'instrument-row--is-selected' : instrument.isSelected}">
    <div class="col-xs-12 pl-0 pr-0">
      <span class="instrument-selector-checkbox">
        <input id="{{instrument.symbol}}" type="checkbox"
               (click)="selectInstrument(key)"
               [checked]="instrument.isSelected">
        <label for="{{instrument.symbol}}"></label>
      </span>
      <span class="instrument-selector-name">
        <div class="instrument-name">{{ instrument.name | uppercase }}</div>
        <div class="instrument-rate">{{ instrument.symbol | uppercase }}</div>
      </span>
    </div>
  </div>
  </div>
</div>

<div class="financial-action-button-bar" [shown]="showSymbolSelector">
  <div>
    <button id="addSelectedInstrumentsButton" class="btn btn-primary btn-block" [disabled]="!canAddInstrument" (click)="addInstrument()">
      <strong>Add Selected</strong>
    </button>
  </div>
</div>
</div>
