<div rvSpinner="registration-loader" [rvShowSpinner]="registrationService.loading">
  <div *ngIf="unexpectedGoogleAccount">
    <div class="border-container mx-auto">
      <div class="panel-body">
        <h4>Oops! We don’t recognize your Google Account.</h4>
        <p>You can only activate your account using the email address that was invited. Please go back and activate your account using <strong>{{joinaccountState.email}}</strong>.</p>
        <div class="row mt-5">
          <div class="col-xs-12">
            <button class="btn btn-primary w-100" id="change-account-link" sign-out-button>Change Account</button>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="unconfirmedGoogleAccount && !isSignUp">
    <div class="border-container mx-auto">
      <div class="panel-body">
        <h4>Oops! We don’t recognize your Google Account.</h4>
        <p><strong>{{ username }}</strong> does not currently have a Rise Vision account. Would you like to create one?</p>
        <div class="row mt-5">
          <div class="col-xs-6">
            <button class="btn btn-default w-100" (click)="confirmGoogleAccount()">Create a New Account</button>
          </div>
          <div class="col-xs-6">
            <button class="btn btn-primary w-100" id="try-again-link" sign-out-button>Change Account</button>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(!unconfirmedGoogleAccount || isSignUp) && !unexpectedGoogleAccount">
    <h1>One last step!</h1>

    <div class="border-container mx-auto">
      <div class="panel-body">

        <h4>Help us personalize your experience.</h4>

        <div id="registration-modal">

          <form #registrationForm="ngForm" id="forms.registrationForm" role="form" name="forms.registrationForm" autocomplete="on" novalidate>
            <div class="row u_margin-md-top">
              <div class="col-xs-12">
                <!-- First Name -->
                <div class="form-group" [ngClass]="{ 'has-error' : firstName.invalid && !firstName.pristine }">
                  <label for="firstName">First Name: *</label>
                  <input #firstName="ngModel" type="text" class="form-control firstName" name="firstName" id="firstName" autocomplete="fname" aria-required="true" tabindex="1" [(ngModel)]="registrationService.profile.firstName" autofocus required>
                  <p [shown]="firstName.invalid && !firstName.pristine"
                    class="help-block validation-error-message-first-name">Enter a First Name.</p>
                </div>

                <!-- Last Name -->
                <div class="form-group" [ngClass]="{ 'has-error' : lastName.invalid && !lastName.pristine }">
                  <label for="lastName">Last Name: *</label>
                  <input #lastName="ngModel" type="text" class="form-control lastName" name="lastName" id="lastName" autocomplete="lname" aria-required="true" tabindex="1" [(ngModel)]="registrationService.profile.lastName" required>
                  <p [shown]="lastName.invalid && !lastName.pristine"
                    class="help-block validation-error-message-last-name">Enter a Last Name.</p>
                </div>

                <!-- Organization -->
                <div class="form-group" [ngClass]="{'has-error': companyName.invalid && !companyName.pristine}" [shown]="registrationService.newUser">
                  <label for="companyName">Organization: *</label>
                  <input #companyName="ngModel" type="text" class="form-control companyName" name="companyName" id="companyName" tabindex="1" aria-required="true" [(ngModel)]="registrationService.company.name" [required]="registrationService.newUser">
                  <p [shown]="companyName.invalid && !companyName.pristine"
                    class="help-block validation-error-message-company-name">Enter an Organization.</p>
                </div>

                <!-- Industry -->
                <div class="form-group" [ngClass]="{'has-error': companyIndustry.invalid && !companyIndustry.pristine}" [shown]="registrationService.newUser">
                  <label for="companyIndustry">Tell us your Industry to help us make your displays look great: *</label>
                  <select #companyIndustry="ngModel" class="form-control selectpicker companyIndustry" name="companyIndustry" id="companyIndustry" tabindex="1" aria-required="true" [(ngModel)]="registrationService.company.companyIndustry" (ngModelChange)="onChange()" [required]="registrationService.newUser">
                    <option [value]="undefined" hidden>&lt; Select Industry &gt;</option>
                    <option class="companyIndustryOption"
                      *ngFor="let industry of COMPANY_INDUSTRY_FIELDS" value="{{industry[1]}}">{{industry[0]}}
                    </option>
                  </select>
                  <p [shown]="companyIndustry.invalid && !companyIndustry.pristine"
                    class="help-block validation-error-message-company-industry">Select an industry.</p>
                </div>

                <div class="madero-style alert alert-info u_margin-sm-top" [shown]="registrationService.company.companyIndustry === 'PHILANTHROPY'">
                  <p class="u_remove-bottom">
                    <i class="fa fa-exclamation-circle icon-left"></i>
                    <span>Special pricing is available to organizations that are registered as 501(c)(3) in the US or
                      Canadian organizations that file a T3010.</span>
                  </p>
                </div>

                <!-- How did you hear about Rise Vision? -->
                <div class="form-group" [shown]="newUserK12Selected()">
                  <label for="refSource">How did you hear about Rise Vision?</label>
                  <input type="text" class="form-control refSource" name="refSource" id="refSource" tabindex="1" aria-required="false" [(ngModel)]="registrationService.profile.refSource">
                </div>

                <!-- Terms of Service and Privacy -->
                <div class="form-group">
                  <div class="checkbox" [ngClass]="{ 'has-error' : accepted.invalid && !accepted.pristine }">
                    <label for="accepted">
                      <input #accepted="ngModel" type="checkbox" id="accepted" name="accepted" class="accept-terms-checkbox" [(ngModel)]="registrationService.profile.accepted" tabindex="1" required />
                      <span>
                        I accept the
                        <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360000924446-Terms-of-Service" target="_blank" tabindex="1">Terms of Service</a>
                        and
                        <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360000915023-Privacy-Policy" target="_blank" tabindex="1">Privacy Policy</a>. *
                      </span>
                    </label>

                    <p class="help-block validation-error-message-accepted" [shown]="accepted.invalid && !accepted.pristine">
                      You must accept the Terms and Conditions.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </div>

        <button id="createAccount" type="submit" aria-label="Create Account" tabindex="1" form="registrationForm" class="btn btn-primary btn-hg btn-block registration-save-button" (click)="save()" [disabled]="registrationService.loading">
          Create Account
        </button>

      </div>
    </div><!--panel-->
    <div class="align-center u_margin-md-top">
      <a class="madero-link" id="sign-out-link" href="#" sign-out-button>Sign Out</a>
    </div>
  </div>
</div>
