<upload *ngIf="allowUpload" [folderPath]="folderPath" (addFile)="addFile($event)" (queueLengthChange)="uploadQueueLength = $event"></upload>

<div class="flex-row button-toolbar-md-folded">
  <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="search.doSearch" [localSearch]="true" [shown]="isFileListVisible()" iconSet="madero"></search-filter>

  <div *ngIf="allowUpload" class="ml-auto button-toolbar flex-row">
    <div class="search-buttons" [ngClass]="{'disabled': isTrashFolder}">
      <button id="newFolderButton" class="btn btn-default" (click)="addFolder()" [disabled]="isTrashFolder">
        Create Folder
      </button>

      <label id="uploadButtonUnsubscribed" class="btn btn-primary" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
        Upload Files
      </label>
      <label id="uploadButton" class="btn btn-primary" for="upload-files" [shown]="currentPlanService.isPlanActive()">
        Upload Files
      </label>

      <label id="uploadFolderButtonUnsubscribed" class="btn btn-primary" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
        Upload Folder
      </label>
      <label id="uploadFolderButton" class="btn btn-primary" for="upload-folder" [shown]="currentPlanService.isPlanActive()">
        Upload Folder
      </label>
    </div>
  </div>
</div>

<div class="flex-row u_margin-md-top" [shown]="isFileListVisible()">
  <div class="flex-row-entry flex-grow">
    <storage-breadcrumb [folderPath]="folderPath" (folderClicked)="changeFolder({name: $event})"></storage-breadcrumb>
  </div>
  <div class="flex-row-entry pr-4" *ngIf="storageService.isMultipleSelector() && !allowBatchOperations && filesDetails.checkedItemsCount > 0">
    {{ filesDetails.checkedItemsCount }} Selected
  </div>
  <div class="flex-row-entry" [shown]="filesDetails.bucketExists && allowUpload">
    <list-grid-toggle [(isListView)]="isListView"></list-grid-toggle>
  </div>
</div>
<div [shown]="files.apiError && filesDetails.checkedItemsCount > 0" class="madero-style alert alert-danger text-center u_margin-md-top mb-0" role="alert">
  <p><strong>{{files.errorMessage}}</strong><br>{{files.apiError}}</p>
</div>
<div id="files-toolbar" class="flex-row" [hidden]="fileListStatus.code === 404">
  <batch-operations class="w-100" [listObject]="files" *ngIf="allowBatchOperations"></batch-operations>
</div>

<div>
  <div class="scrollable-list horizontal-scroll border-container u_margin-md-top u_margin-md-bottom grid-selector" rvSpinner="storage-selector-loader" [rvShowSpinner]="files.loadingItems">
    <storage-list-view *ngIf="isListView"
      [files]="files.items.list"
      [folderPath]="folderPath"
      [filesDetails]="filesDetails"
      [fileListVisible]="isFileListVisible()"
      [search]="search"
      [selectAll]="files.search.selectAll"
      (selectAllCheckboxes)="files.selectAll()"
      (fileClick)="fileClick($event)">
    </storage-list-view>
    <storage-grid-view *ngIf="!isListView"
      [files]="files.items.list"
      [folderPath]="folderPath"
      [filesDetails]="filesDetails"
      [fileListVisible]="isFileListVisible()"
      [search]="search"
      (fileClick)="fileClick($event)">
    </storage-grid-view>
    <empty-state [shown]="!files.loadingItems && isEmptyState()" [isMultipleFilesFoldersSelector]="storageService.isMultipleFilesFoldersSelector()"></empty-state>
  </div>
</div>