import { Injectable } from '@angular/core';
import { UserState, CoreAPILoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class TemplateApiService {

  static readonly TEMPLATE_SEARCH_FIELDS = [ 'name', 'id' ];

  constructor(private userState: UserState,
    private coreAPILoader: CoreAPILoader) { }

  private _createSearchQuery (fields, search) {
    var query = '';
    for (var i in fields) {
      query += 'OR ' + fields[i] + ':~\"' + search + '\" ';
    }
    query = query.substring(3);
    return query.trim();
  }

  listPresentationTemplates (search, cursor?) {
    var query = search.query ?
      this._createSearchQuery(TemplateApiService.TEMPLATE_SEARCH_FIELDS, search.query) :
      '';
    var obj = {
      'companyId': this.userState.getSelectedCompanyId(),
      'search': query,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };
    console.debug('list presentation templates called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.presentation.listTemplates(obj);
      })
      .then((resp) => {
        console.debug('list presentation templates resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get list of presentation templates.', e);
        throw e;
      });
  }

  listTemplates (search, cursor?) {
    var companyId = this.userState.getSelectedCompanyId();

    var filterString = 'visibleTo:ALL';

    if (companyId) {
      filterString += ' OR visibleTo:' + companyId;
    }

    filterString = '(' + filterString + ')';

    if (search.rvaEntityId) {
      filterString += ' AND (rvaEntityId:' + search.rvaEntityId + ')';
    }

    if (search.query && search.query.length) {
      filterString += ' AND \"' + search.query + '\"';
    }

    if (search.filter && search.filter.length) {
      filterString += ' AND ' + search.filter;
    }

    var obj = {
      'companyId': this.userState.getSelectedCompanyId(),
      'search': filterString,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sort || 'defaultOrderWeight ASC'
    };

    console.debug('list templates called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.template.list(obj);
      })
      .then((resp) => {
        console.debug('list templates resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get list of templates.', e);
        throw e;
      });
  }

  getTemplate (productId) {
    var obj = {
      'productId': productId
    };

    console.debug('get template called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.template.getByProductId(obj);
      })
      .then((resp) => {
        console.debug('get template resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get get template.', e);
        throw e;
      });
  }
}