<form id="alertForm">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="mb-5">Failed to delete Presentation.</h4>

    <div *ngIf="embeddingSchedules.length > 0">
      <p class="text-left">The following schedules have <b>{{presentation.name}}</b> included. Remove it from those schedules and try again.</p>

      <div *ngFor="let embedding of embeddingSchedules">
        <a uiSref="apps.schedules.details" [uiParams]="{ scheduleId: embedding.id, cid: embedding.companyId }"
          class="madero-link u_ellipsis-lg" target="_blank">
          <strong>{{embedding.name}}</strong>
        </a>
      </div>
    </div>

    <hr *ngIf="embeddingSchedules.length > 0 && embeddingPresentations.length > 0">

    <div *ngIf="embeddingPresentations.length > 0">
      <p class="text-left">The following presentations have <b>{{presentation.name}}</b> included in their playlists. Remove it from those presentations and try again.</p>

      <div *ngFor="let embedding of embeddingPresentations">
        <a *ngIf="!isHtmlPresentation(embedding)" uiSref="apps.editor.workspace.artboard" [uiParams]="{presentationId: embedding.id}"
           class="madero-link u_ellipsis-lg" target="_blank">
          <strong>{{embedding.name}}</strong>
        </a>
        <a *ngIf="isHtmlPresentation(embedding)" uiSref="apps.editor.templates.edit" [uiParams]="{presentationId: embedding.id}"
           class="madero-link u_ellipsis-lg" target="_blank">
          <strong>{{embedding.name}}</strong>
        </a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-12">
        <button type="button" class="btn btn-default w-100" (click)="close()">
          <span>Close</span>
        </button>
      </div>
    </div>
  </div>
</form>
