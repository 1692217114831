import { Component, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UIRouterGlobals } from '@uirouter/angular';

import { DisplayFactory, ScheduleFactory } from 'src/app/ajs-upgraded-providers';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';

@Component({
  selector: 'display-add',
  templateUrl: './display-add.component.html',
  styleUrls: ['./display-add.component.scss']
})
export class DisplayAddComponent implements OnInit {

  selectedSchedule = null;

  @ViewChild('displayForm')
  public displayForm: NgForm;

  get hasActivationKey(): boolean {
    return !!this.uiRouterGlobals.params.activationKey;
  }

  get isActivating(): boolean {
    return this.uiRouterGlobals.current.name === 'apps.displays.activate';
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private uiRouterGlobals: UIRouterGlobals,
    public displayFactory: DisplayFactory,
    public playerLicenseService: PlayerLicenseService,
    private scheduleFactory: ScheduleFactory,
    private currentPlanFactory: CurrentPlanService) {

    this.scheduleFactory.getAllDisplaysSchedule()
      .then((result) => {
        if (result) {
          this.selectedSchedule = result;
        }
      });
  }

  ngOnInit() {
    if (!this.playerLicenseService.isProAvailable(this.displayFactory.display)) {
      this.currentPlanFactory.confirmAndPurchase();
    }
  }

  save() {
    if (!this.displayForm.valid) {
      console.info('form not valid');
      return;
    }

    if (this.displayFactory.display.activationKey) {
      this.displayFactory.display.activationKey = this.displayFactory.display.activationKey.toUpperCase();
    }

    this.displayFactory.addDisplay(this.selectedSchedule);
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

}