<div class="wh-100"
[style.background] = "editorService.presentation.backgroundStyle"
[style.backgroundSize] = "editorService.presentation.backgroundScaleToFit ? 'contain' : ''">
  <img id="artboardEmptyState" *ngIf="showEmptyState()" src="../images/empty_state.png" />
  <artboard-placeholder *ngFor="let placeholder of editorService.presentation.placeholders" id="{{placeholder.id}}" 
    [placeholder]="placeholder" placeholder-drag placeholder-resize
    (mousedown)="placeholderService.setPlaceholder(placeholder); $event.stopPropagation()"
    class="ph-block" [ngClass]="{ 'edit-mode': isPlaceholderSelected(placeholder) }"
    [style.top] = "placeholder.top + placeholder.topUnits"
    [style.left] = "placeholder.left + placeholder.leftUnits"
    [style.width] = "placeholder.width + placeholder.widthUnits"
    [style.height] = "placeholder.height + placeholder.heightUnits"
    [style.background] = "placeholder.backgroundStyle"
    [style.backgroundSize] = "placeholder.backgroundScaleToFit ? 'contain' : ''"
    [style.z-index] = "isPlaceholderSelected(placeholder) ? 100 : placeholder.zIndex"
    >
  </artboard-placeholder>
</div>
