<div rvSpinner="google-photos-loader" [rvShowSpinner]="spinner">

  <div class="attribute-editor-row" [shown]="!userAccount">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label>Account:</label><br>
          <div class="mb-4" [shown]="componentAccount">Google Photos is connected to {{componentAccountUsername}}</div>
          <div class="text-sm">
            Connect to Google Photos to access your photo albums
          </div>
          <button class="btn btn-google-auth btn-hg btn-block mt-2 folder-component-photos" (click)="confirmConnect()">
            <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg">
            <div class="mx-auto">Sign in with Google</div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="attribute-editor-row" [shown]="revokeFailed">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <div class="text-sm">
            We could not revoke your access with Google at this time. We strongly recommend you manually revoke access in your Google account.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [shown]="userAccount">
    <div class="attribute-editor-row">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label>Account:</label><br>
            Google Photos is connected to {{componentAccountUsername}}<br>
            <button class="btn btn-default btn-block mt-2 folder-component-photos" [shown]="sameAccount" (click)="confirmDisconnect()">Disconnect from Google Photos</button>
            <button class="btn btn-default btn-block mt-3 folder-component-photos" [shown]="!sameAccount" (click)="confirmChange()">Change Google Photos Account</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="attribute-editor-row" [shown]="!sameAccount && componentAccount">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label>Album:</label><br>
          {{ albumName }}
        </div>
      </div>
    </div>
  </div>
  <div class="attribute-editor-row" [shown]="sameAccount">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label>Album:</label><br>
          <select name="source" class="form-control folder-component-google-photos-album" [(ngModel)]="values.source" (change)="saveSource()">
            <option value="" [shown]="false">&lt; Select Album  &gt;</option>
            <option value="all-photos">All Photos</option>
            <option *ngFor="let a of albums" [value]="a.id">{{a.title}} ({{ a.mediaItemsCount }})</option>
          </select>
        </div>
      </div>
    </div>
  </div>

</div>
