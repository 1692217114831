<ng-template #overridesPopover><div [innerHtml]="scheduleOverrides"></div></ng-template>
<div>
  <div class="flex-row layout-md-folded">
    <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="displays.doSearch" iconSet="madero"></search-filter>
    <div class="flex-row row-wrap-xs ml-md-3 mt-3 mt-md-0" *ngIf="enableSubcompanies">
      <madero-checkbox checkboxId="allCompaniesCheckbox" ngDefaultControl (ngModelChange)="toggleAllCompanies($event)" [ngModel]="distributeToSubcompanies" [disabled]="!subcompanyDistributionAvailable && !distributeToSubcompanies"></madero-checkbox>
      <label class="font-weight-normal u_nowrap mb-0" [ngClass]="{ 'u_clickable': subcompanyDistributionAvailable || distributeToSubcompanies }" (click)="toggleAllCompanies(!distributeToSubcompanies)">Select displays from all companies</label>
      <a href="#" uiSref="apps.purchase.home" (click)="closeEvent.emit()" class="madero-link u_nowrap u_inline-block ml-5 ml-sm-3" [shown]="subcompanyDistributionAvailable === null">Subscription Required</a>
      <a href="#" (click)="showUpgradePlanModal()" class="madero-link u_nowrap u_inline-block ml-5 ml-sm-3" [shown]="subcompanyDistributionAvailable !== null && !subcompanyDistributionAvailable">Plan Upgrade Required</a>
    </div>
  </div>
  <div class="scrollable-list horizontal-scroll border-container u_margin-sm-top"
        scrollingList (scrollEndEvent)="displays.load()" (scroll)="refresh()"
        rvSpinner="display-list-loader" [rvShowSpinner]="displays.loadingItems"
        [shown]="!(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)">

    <table id="displayListTable" class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th id="tableHeaderName" (click)="displays.sortBy('name')" class="table-header__cell u_clickable">
            Name
            <i *ngIf="search.sortBy === 'name'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
          </th>
          <th id="tableHeaderCompanyName" class="table-header__cell" *ngIf="enableSubcompanies">
            Company
          </th>
          <th id="tableHeaderLicensed" class="table-header__cell">
            Licensed
          </th>
          <th id="tableHeaderLicensed" class="table-header__cell">
            Status
          </th>
          <th id="tableHeaderLastConnection" class="table-header__cell">
            Last Connection
          </th>
          <th id="tableHeaderSchedule" class="table-header__cell">
            Schedule
          </th>
        </tr>
      </thead>

      <tbody class="table-body">
        <tr class="table-body__row display" *ngFor="let display of displays.items.list">
          <td id="displayName-{{display.id}}" class="table-body__cell display-name">
            <div class="flex-row">
              <madero-checkbox (click)="toggleDisplay(display.id, display.companyId);" [ngValue]="isSelected(display.id)"></madero-checkbox>
              <a class="madero-link u_clickable u_ellipsis-lg" (click)="toggleDisplay(display.id, display.companyId);"><strong>{{display.name}}</strong></a>
            </div>
          </td>
          <td id="companyName-{{display.id}}" class="table-body__cell" *ngIf="enableSubcompanies"><span>{{display.companyName}}</span></td>
          <td id="licensed-{{display.id}}" class="table-body__cell"><span>{{display.playerProAuthorized ? 'Yes' : 'No'}}</span></td>
          <td id="status-{{display.id}}" class="table-body__cell">
            <span *ngIf="displayFactory.showLicenseRequired(display) && display.onlineStatus !== 'not_activated'">
              License Required
            </span>
            <span [ngClass]="{'text-success': display.onlineStatus === 'online', 'text-danger': display.onlineStatus === 'offline'}" *ngIf="!(displayFactory.showLicenseRequired(display) && display.onlineStatus !== 'not_activated')">
              {{display.onlineStatus | displayStatus}}
            </span>
          </td>
          <td id="lastConnection-{{display.id}}" class="table-body__cell display-address">
            <span *ngIf="displayFactory.showLicenseRequired(display)">
              License Required
            </span>
            <span *ngIf="!displayFactory.showLicenseRequired(display)">
              {{display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
            </span>
          </td>
          <td id="schedule-{{display.id}}" class="table-body__cell display-schedule">
            <div class="u_nowrap flex-row">
              <span class="u_ellipsis-md schedule-view" *ngIf="displayFactory.hasSchedule(display)">
                {{display.scheduleName}}
              </span>
              <span class="u_ellipsis-md" *ngIf="!displayFactory.hasSchedule(display)">
                {{ display.overrideScheduleNames ? 'No Default Schedule' : 'No Schedule Selected' }}
              </span>
              <span class="badge badge-primary u_clickable ml-2"
                *ngIf="display.overrideScheduleNames"
                [popover]="overridesPopover"
                (onShown)="showPopover(display.overrideScheduleNames)"
                containerClass="madero-style"
                outsideClick="true"
                placement="bottom right">

                +{{display.overrideScheduleNames.length}}
              </span>
            </div>
          </td>
        </tr>

        <!-- If no search results -->
        <tr class="table-body__row" *ngIf="!displays.loadingItems && displays.items.list.length === 0 && search.query !== undefined">
          <td colspan="3" class="text-center">Your search didn't return any results.</td>
        </tr>
      </tbody>

    </table>
  </div><!--panel-->
</div>

<div class="border-container text-center u_margin-md-top u_margin-md-bottom" [shown]="!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined">
  <div class="panel-body">
    <div class="form-group my-4">
      Add a display to start communicating better with your audience!
    </div>
    <a id="displayAddButton" class="btn btn-primary btn-toolbar-wide mb-2" (click)="closeEvent.emit()" uiSref="apps.displays.add">
      Add Display
    </a>

  </div>
</div>