<div id="playlistItemModal">
  <form #playlistItemForm="ngForm" id="playlistItemForm" role="form" name="forms.playlistItemForm" (keyup.enter)="save()" novalidate>
    <div class="modal-header">
      <button type="button" class="close" (click)="dismiss()">
        <streamline-icon name="close" width="12" height="12"></streamline-icon>
      </button>
      <h4 class="modal-title">Edit Playlist Item</h4>
    </div>
    <div class="modal-body align-left">
      <div class="panel" [shown]="isDeprecatedItem">
        <div class="panel-body bg-warning">
          <p class="u_remove-bottom">
            This item is deprecated and you must <a href="https://help.risevision.com/hc/en-us/articles/360001275603-How-do-I-update-the-deprecated-content-items-in-my-presentation-" target="_blank" class="madero-link">replace it</a> with its corresponding Widget as soon as possible. If you need access to this item, please use the <a href="{{PREVIOUS_EDITOR_URL}}" class="madero-link">previous version</a> of the Editor.
          </p>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">Name</label>
        <input type="text" name="name" class="form-control" [(ngModel)]="_item.name">
      </div>

      <div class="form-group" [shown]="widgetName">
        <label class="control-label">Widget:</label>
        <br>
        <a id="widgetName" class="link" href="#" (click)="showSettingsModal()">{{widgetName}}<i class="fa fa-cog icon-right"></i></a>
      </div>
      <hr>

      <div class="form-group u_margin-sm-top">
        <label class="control-label" for="playlist-item-pud">Duration:</label>

        <div class="flex-row">
          <madero-checkbox name="playUntilDone" [(ngModel)]="_item.playUntilDone" ngDefaultControl></madero-checkbox>
          <span class="control-label mr-3">Play Until Done</span>
        </div>

      </div>

      <div *ngIf="!_item.playUntilDone">
        <div class="input-group">
          <input #duration="ngModel" type="number" id="duration" name="duration" class="form-control" [(ngModel)]="_item.duration" required min="1" placeholder="Enter duration">
          <span class="input-group-addon">Seconds</span>
        </div>
        <span class="text-danger" [shown]="duration.invalid">
          Required
        </span>
      </div>

      <div class="attribute-editor-row mt-4">
        <label class="control-label">Timeline:</label>

        <timeline-textbox [timelineObject]="_item"></timeline-textbox>
      </div>

      <div class="attribute-editor-row mt-4">
        <label class="control-label">Distribution:</label>

        <distribution-selector [(distribution)]="_item.distribution" [(distributeToAll)]="_item.distributeToAll"></distribution-selector>
      </div>
    </div><!--modal-body-->

    <div class="modal-footer">

      <button class="btn btn-default btn-toolbar" (click)="dismiss()">Cancel</button>
      <button id="playlistItemSave" class="btn btn-primary btn-toolbar" (click)="save()" [disabled]="playlistItemForm.invalid">Apply</button>
    </div>
  </form>
</div>
