import { Component, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'bulk-edit-modal',
  templateUrl: './bulk-edit-modal.component.html',
  styleUrls: ['./bulk-edit-modal.component.scss']
})
export class BulkEditModalComponent extends ModalComponent implements AfterViewInit {

  title: string;
  baseModel: any;
  component: any;

  @ViewChild('bulkEditForm')
  public bulkEditForm: NgForm;

  @ViewChild('target', { read: ViewContainerRef })
  private target: ViewContainerRef;

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  ngAfterViewInit(): void {
    const instance = this.target.createComponent(this.component).instance as any;
    instance.model = this.baseModel;
  }

  save() {
    super.resolve(this.baseModel);
  }

  cancel() {
    super.reject('cancel')
  }

  dismiss() {
    super.reject();
  }
}
