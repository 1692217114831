<h4 class="mb-1">Total Licenses</h4>
<table class="table license-totals-table">
  <thead class="table-header">
    <tr>
      <th class="table-header__cell u_align-middle align-center">License<wbr> Type</th>
      <th class="table-header__cell u_align-middle align-center">Purchased</th>
      <th class="table-header__cell u_align-middle align-center">Allocated</th>
      <th class="table-header__cell u_align-middle align-center">Remaining</th>
    </tr>
  </thead>
  <tbody class="table-body">
    <tr>
      <td class="table-body__cell u_nowrap">Per-Display <i class="fa fa-exclamation-circle text-warning" *ngIf="companyAllocationService.getRequiredLicenses('enterprise')"></i></td>
      <td class="table-body__cell align-center">{{ companyAllocationService.getPurchasedLicenses('enterprise') }}</td>
      <td class="table-body__cell align-center">{{ companyAllocationService.getAllocatedLicenses('enterprise') }}</td>
      <td class="table-body__cell align-center">{{ companyAllocationService.getRemainingLicenses('enterprise') }}</td>
    </tr>
    <tr>
      <td class="table-body__cell">Unlimited <i class="fa fa-exclamation-circle text-warning" *ngIf="companyAllocationService.getRequiredLicenses('unlimited')"></i></td>
      <td class="table-body__cell align-center">{{ companyAllocationService.getPurchasedLicenses('unlimited') }}</td>
      <td class="table-body__cell align-center">{{ companyAllocationService.getAllocatedLicenses('unlimited') }}</td>
      <td class="table-body__cell align-center">{{ companyAllocationService.getRemainingLicenses('unlimited') }}</td>
    </tr>
  </tbody>
</table>
<button class="btn btn-default btn-block my-3" uiSref="apps.purchase.home" [uiParams]="{ enterprise: companyAllocationService.getRequiredLicenses('enterprise'), unlimited: companyAllocationService.getRequiredLicenses('unlimited') }">Purchase Licenses</button>