import { Component } from '@angular/core';

import { UserState, UserService, ScrollingListService, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {

  filterConfig = {
    placeholder: 'Search Users'
  };

  search = {
    companyId: this.userState.getSelectedCompanyId(),
    sortBy: 'username',
    reverse: false,
    name: 'Users'
  }
  users = this.ScrollingListService(this.userService.getUsers, this.search);

  constructor(private userState: UserState,
    private ScrollingListService: ScrollingListService,
    private userService: UserService,
    private modalService: ModalService,
    private processErrorCode: ProcessErrorCode
  ) { }

  exportUsers () {
    this.users.loadingItems = false;
    this.users.errorMessage = '';
    this.users.apiError = '';
    this.users.errorCodes = [];

    this.modalService.confirm('Export users?',
      'An export file will be prepared and emailed to you at <b>' + this.userState.getUserEmail() +
      '</b> once ready.<br/> Please ensure your email is configured to accept emails from <b>no-reply@risevision.com</b>.',
      'Export', 'Cancel').then(() => {

      this.users.loadingItems = true;
      this.userService.exportCompanyUsers(this.userState.getSelectedCompanyId()).then(() => { })
      .catch((error) => {
        this.users.errorMessage = 'Failed to export users.';
        this.users.apiError = this.processErrorCode(error);
      })
      .finally(() => {
        this.users.loadingItems = false;
      });
    });
  }

}
