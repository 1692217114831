<div id="productDetailsModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()">
      <streamline-icon name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">{{product.name}}</h4>
  </div>

  <div class="row modal-body u_padding-lg">
    <div class="row">
      <div class="col-md-8 col-xs-12">
        <img class="img-responsive" [src]="product.imageUrl">
        <p class="u_margin-md-top" [innerHtml]="product.descriptionShort"></p>
        <p><a id="previewTemplate" class="madero-link" *ngIf="showPreviewLink" href="http://widgets.risevision.com/viewer/?type=presentation&id={{product.rvaEntityId}}&showui=false" target="_blank">Preview this Template</a></p>
      </div>
      <div class="col-md-4 col-xs-12">
        <button id="useProductButton" class="btn btn-primary btn-lg btn-block" (click)="select()">
          Start with this Template
        </button>
      </div>
    </div>
  </div>
</div>
