import { Component, Input } from '@angular/core';

@Component({
  selector: 'beta-badge',
  templateUrl: './beta-badge.component.html',
  styleUrls: ['./beta-badge.component.scss']
})
export class BetaBadgeComponent {
  @Input() tooltipPlacement?: string = 'above';
}
