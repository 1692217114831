import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyStateService {
  static EDUCATION_INDUSTRIES = [
    'PRIMARY_SECONDARY_EDUCATION',
    'HIGHER_EDUCATION'
  ];
  static DISCOUNT_INDUSTRIES = [
    'PRIMARY_SECONDARY_EDUCATION',
    'HIGHER_EDUCATION',
    'LIBRARIES',
    'PHILANTHROPY',
    'NON_PROFIT_ORGANIZATION_MANAGEMENT',
    'RELIGIOUS_INSTITUTIONS'
  ];

  constructor() { }
}
