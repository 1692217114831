import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { UserState, DisplayFactory, ScheduleFactory } from 'src/app/ajs-upgraded-providers';
import { PlayerProService } from 'src/app/displays/services/player-pro.service';
import { PlayerActionsService } from 'src/app/displays/services/player-actions.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { DisplayControlService } from 'src/app/displays/services/display-control.service';
import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'display-fields',
  templateUrl: './display-fields.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  styleUrls: ['./display-fields.component.scss']
})
export class DisplayFieldsComponent implements OnInit {

  @Input() selectedSchedule: any = {};
  @Output() selectedScheduleChange: EventEmitter<any> = new EventEmitter<any>();

  isMobile = this.presentationUtilsService.isMobileBrowser();
  isMonitoringAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_MONITORING);
  isDisplayControlAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_DISPLAY_CONTROL);
  isClassroomAlertsAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_CLASSROOM_ALERTS);
  showWebPlayer = false;
  showInstallPanel = false;
  timePickerKey = false;
  displayForm: NgForm;

  _sanitizedPreviewUrl;
  _previewUrl;
  get santitizedPreviewUrl() {
    let previewUrl = '';
    if (this.selectedSchedule.id) {
      previewUrl = environment.SHARED_SCHEDULE_URL.replace('SCHEDULE_ID', this.selectedSchedule.id) + '&env=apps_display';
    }

    if (previewUrl !== this._previewUrl || !this._sanitizedPreviewUrl) {
      this._previewUrl = previewUrl;
      this._sanitizedPreviewUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(previewUrl);
    }

    return this._sanitizedPreviewUrl;
  }

  constructor(
    public userState: UserState,
    public playerActionsService: PlayerActionsService,
    public playerLicenseService: PlayerLicenseService,
    public playerProService: PlayerProService,
    public displayFactory: DisplayFactory,
    private displayControlService: DisplayControlService,
    private scheduleFactory: ScheduleFactory,
    private currentPlanService: CurrentPlanService,
    private presentationUtilsService: PresentationUtilsService,
    private featuresService: FeaturesService,
    private modalService: ModalService,
    broadcasterService: BroadcasterService,
    private trackerService: TrackerService,
    public domSanitizer: DomSanitizer,
    private controlContainer: ControlContainer
  ) {
    broadcasterService.on('risevision.company.updated', () => {
      const company = userState.getCopyOfSelectedCompany(true);
      displayFactory.display.playerProAuthorized = displayFactory.display.playerProAuthorized ||
        company.playerProAvailableLicenseCount > 0 && displayFactory.display.playerProAssigned;
    });
  }

  ngOnInit(): void {
    this.displayForm = <NgForm>this.controlContainer;
  }

  toggleProAuthorized () {
    if (!this.playerLicenseService.isProAvailable(this.displayFactory.display) &&
        !this.displayFactory.display.originalPlayerProAuthorized) {
      this.displayFactory.display.playerProAuthorized = false;
      this.currentPlanService.confirmAndPurchase();
    } else {
      this.playerLicenseService.updateDisplayLicenseLocal(this.displayFactory.display);
    }
  }

  scheduleSelected () {
    this.selectedScheduleChange.emit(this.selectedSchedule);

    if (this.scheduleFactory.requiresLicense(this.selectedSchedule) &&
        !this.displayFactory.display.playerProAuthorized) {
      this.modalService.confirm('Assign license?',
          'You\'ve selected a schedule that contains presentations. In order to show this schedule on this display, you need to license it. Assign license now?',
          'Yes', 'No')
        .then(() => {
          // Toggle license as if they clicked the checkbox
          this.displayFactory.display.playerProAuthorized = true;

          this.toggleProAuthorized();
        });
    }
  }

  confirmLicensing () {
    return this.modalService.confirm('Assign license?',
        'Do you want to assign one of your licenses to this display?',
        'Yes', 'No')
      .then(() => {
        // Toggle license as if they clicked the checkbox
        this.displayFactory.display.playerProAuthorized = true;

        this.toggleProAuthorized();
      });
  }

  confirmDelete () {
    this.modalService.confirmDanger('Delete Display?',
        'Are you sure you want to delete this Display?',
        'Yes', 'No')
      .then(this.displayFactory.deleteDisplay);
  }

  getWebPlayerUrl () {
    if (!this.displayFactory.display) {
      return '';
    }
    return environment.VIEWER_URL + '/?type=webplayer&id=' + this.displayFactory.display.id;
  }

  copyToClipboard (text) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text);
    }
  }

  openTimePicker ($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  configureDisplayControl () {
    this.displayControlService.openDisplayControlModal(false);
  }

  trackDisplayMonitoring () {
    this.trackerService.displayEvent('Display Monitoring Updated',
      this.displayFactory.display.id,
      this.displayFactory.display.name,
      {
        monitoringEnabled: this.displayFactory.display.monitoringEnabled
      }
    );
  }

  promptUpgrade (feature) {
    this.featuresService.showUpgradePlanModal(feature);
  }

}
