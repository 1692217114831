<div>
    <div id="upload-panel-{{uploaderId}}" class="storage-upload-panel" [shown]="activeUploadCount() > 0">
      <div class="storage-upload-body te-scrollable-container">
        <div *ngFor="let item of uploader.queue">
          <div class="storage-upload-file-row">
            <div class="file-entry" [ngClass]="{'is-error': item.isError}">
              <div class="file-text">
                  <div class="file-name">{{ fileNameOf(item.file.name) }}</div>
                  <div class="file-error" [shown]="item.isError">
                    Your file couldn't be uploaded.
                    <a href="#" (click)="retryFailedUpload(item)">Try again?</a>
                  </div>
              </div>
  
              <div class="file-remove">
                <a href="#" (click)="removeItem(item)">
                  <span>Remove</span>
                </a>
              </div>
            </div>
  
            <div class="progress inline-progress" [shown]="!item.isError">
              <div class="progress-bar progress-bar-striped progress-bar-success active" role="progressbar"
                    [ngStyle]="{ 'width': item.progress + '%' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="modal fade" tabindex="-1">
      <form id="upload-form-{{uploaderId}}">
        <input #inputField 
          type="file" 
          id="{{uploaderId}}"
          [attr.multiple]="uploadManager && uploadManager.isSingleFileSelector && uploadManager.isSingleFileSelector() ? null : 'true'" 
          [attr.webkitdirectory]="validType === 'folder' ? 'true' : null"
          [attr.accept]="getAcceptedTypes()" 
          (change)="fileInputChangeEvent($event.target.files)">
      </form>
    </div>
  </div>
  
