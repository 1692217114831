import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserauthApiService } from '../../services/userauth-api.service';
import { AjsState } from 'src/app/ajs-upgraded-providers';

@Component({
  selector: 'reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss']
})
export class ResetPasswordConfirmComponent {

  credentials: any = {};
  errors = {};

  emailResetSent = false;
  invalidToken = false;
  loading;

  @ViewChild('resetPasswordForm')
  public resetPasswordForm: NgForm;

  constructor(private $state: AjsState,
    private userauth: UserauthApiService) {}

  _resetErrorStates() {
    this.emailResetSent = false;
    this.invalidToken = false;
  }

  resetPassword() {
    this._resetErrorStates();

    if (this.resetPasswordForm.valid) {
      this.loading = true;
      this.userauth.resetPassword(this.$state.params.user, this.$state.params.token, this.credentials.newPassword)
        .then(() => {
          console.log('Password updated');
          this.$state.go('common.auth.unauthorized', {
            passwordReset: true
          });
        })
        .catch((err) => {
          var error = err.result && err.result.error && err.result.error.message;

          if (error === 'Password reset token does not match') {
            this.invalidToken = true;
          } else {
            console.error(err);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  requestPasswordReset() {
    this._resetErrorStates();

    this.loading = true;
    this.userauth.requestPasswordReset(this.$state.params.user)
      .then(() => {
        console.log('Email sent');
        this.emailResetSent = true;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

}