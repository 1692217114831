import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AjsState, UserState } from 'src/app/ajs-upgraded-providers';

import { CreditCardService } from 'src/app/purchase/services/credit-card.service';
import { AddPaymentSourceService } from '../../services/add-payment-source.service';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'add-payment-source',
  templateUrl: './add-payment-source.component.html',
  styleUrls: ['./add-payment-source.component.scss']
})
export class AddPaymentSourceComponent {

  contactEmail = this.userState.getUserEmail();

  @ViewChild('paymentMethodsForm')
  public paymentMethodsForm: NgForm;

  constructor(private $state: AjsState,
    private userState: UserState,
    public subscriptionService: SubscriptionService,
    public addPaymentSourceService: AddPaymentSourceService,
    private creditCardService: CreditCardService) {
      this.addPaymentSourceService.init();
    }

  _goToSubscriptionPage(subscriptionId) {
    this.$state.go('apps.billing.subscription', {
      subscriptionId: subscriptionId
    });
  }

  addPaymentMethod(subscriptionId) {
    if (this.creditCardService.paymentMethods.paymentMethod === 'invoice') {
      var purchaseOrderNumber = this.creditCardService.paymentMethods.purchaseOrderNumber;

      this.addPaymentSourceService.changePaymentToInvoice(subscriptionId, purchaseOrderNumber)
        .then(() => {
          this._goToSubscriptionPage(subscriptionId);
        });
    } else {
      if (!this.paymentMethodsForm.valid) {
        return;
      }

      this.addPaymentSourceService.changePaymentSource(subscriptionId)
        .then(() => {
          this._goToSubscriptionPage(subscriptionId);
        });
    }
  }
}
