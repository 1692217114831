<div>
  <span *ngIf="playerLicenseService.hasProfessionalLicenses()">
    {{ playerLicenseService.getUsedLicenseString() }}
  </span>
  <span *ngIf="!currentPlanFactory.isUnlimitedPlan() && displaySummaryService.summary && displaySummaryService.summary.scheduledUnlicensed">
    <span *ngIf="playerLicenseService.hasProfessionalLicenses()"> / </span>
    <span class="danger">
      {{ displaySummaryService.summary.scheduledUnlicensed }}
      Scheduled Un-Licensed Display{{ displaySummaryService.summary.scheduledUnlicensed > 1 ? 's' : '' }}{{ requiredLicenses > 0 ? '.' : '' }}
    </span>
    <a *ngIf="requiredLicenses > 0" class="madero-link" href="#" (click)="purchaseLicenses()">
      Purchase License{{ displaySummaryService.summary.scheduledUnlicensed > 1 ? 's' : '' }}?
    </a>
  </span>
  <div *ngIf="showOnlineCount && displaySummaryService.summary">
    {{ displaySummaryService.summary.online }} Online Displays / {{ displaySummaryService.summary.offline }} Offline Displays
  </div>
</div>