<div class="flex-row multi-actions-panel u_padding-20-horizontal py-2 u_margin-md-top" [shown]="listObject && listObject.getSelected().length > 0 && !listObject.batchOperations.activeOperation">
  <div class="flex-row button-toolbar-md-folded w-100">
    <div class="col-sm-5 pl-0">
      <strong>{{listObject && listObject.getSelected().length}}</strong>
      <span class="text-gray">
        {{listObject?.batchOperations.name}}<span [shown]="listObject && listObject.getSelected().length > 1">s</span> selected.
        <span [hidden]="listObject?.search.selectAll"><a class="madero-link u_clickable" (click)="listObject.selectAll()"> Select all</a>.</span>
        <span [shown]="listObject?.search.selectAll"><a class="madero-link u_clickable" (click)="listObject.deselectAll()"> Select none</a>.</span>
      </span>
    </div>
    <div class="col-sm-7 pl-0">
      <div>
        <label class="mr-2">Actions:</label>
        <div class="btn-group dropdown" dropdown>
          <button id="listActionsButton" type="button" dropdownToggle class="form-control btn-select btn-toolbar-wide flex-row mt-0">
            <div class="row-entry">
              <span class="text-gray mr-auto">Choose an action</span>
              <span class="text-gray pl-2"><i class="fa fa-sort"></i></span>
            </div>
          </button>
          <div *dropdownMenu class="dropdown-menu playlist-menu" role="menu">
            <ul>
              <li *ngIf="!listObject?.batchOperations.operations.length">
                <div class="btn-dropdown">
                  <span class="text-gray">None</span>
                </div>
              </li>
              <li *ngFor="let operation of listObject?.batchOperations.operations" [hidden]="operation.hidden && operation.hidden()">
                <button type="button" id="{{ 'action' + operation.name.replaceAll(' ', '') }}" class="btn-dropdown u_clickable" [ngClass]="{'rise-admin-only': operation.requireRole === 'sa'}" (click)="operation.onClick()">
                  <span>{{operation.name}}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="close" (click)="listObject.deselectAll()" data-dismiss="modal" aria-hidden="true">
    <streamline-icon name="close" width="15" height="15"></streamline-icon>
  </button>
</div>

<div id="batchOperationsProgress" class="border-container text-center u_padding-20 u_margin-md-top" [shown]="listObject?.batchOperations.activeOperation">
  <div class="multi-actions-progress-panel">
    <p class="mb-0"><strong>Bulk {{listObject?.batchOperations.activeOperation?.name | lowercase}} in progress.</strong>&nbsp;<a class="madero-link u_clickable" (click)="listObject?.batchOperations.cancel()">Cancel</a></p>
    <p>Please don’t navigate away from this page.</p>
    <div class="progress my-4">
      <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{listObject?.batchOperations.progress}}.0"
      attr.aria-valuemin="0" attr.aria-valuemax="100" [style.width]="listObject?.batchOperations.progress + '%'"></div>
    </div>
    <p class="mb-0">
      {{listObject?.batchOperations.completedItemCount}} out of {{listObject?.batchOperations.totalItemCount}}
      operations completed.
      <!--
      {{listObject?.batchOperations.name | lowercase}}<span [shown]="listObject?.batchOperations.totalItemCount > 1">s</span>
      {{listObject?.batchOperations.activeOperation.name | lowercase}}d.
      -->
    </p>
  </div>
</div>
