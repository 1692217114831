import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { UserState } from 'src/app/ajs-upgraded-providers';

@Directive({
  selector: '[requireRole]'
})
export class RequireRoleDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private userState: UserState
  ) { }

  @Input() set requireRole(roles: string) {
    if (this.userState.hasPermission(roles)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

}
