import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { WidgetModalFactory } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';
import { PresentationItemComponent } from '../components/presentation-item/presentation-item.component';
import { PlaceholderPlaylistService } from './placeholder-playlist.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private modalService: ModalService,
    private widgetModalFactory: WidgetModalFactory,
    private placeholderPlaylistService: PlaceholderPlaylistService) { }

  _showPresentationSettingsModal(item) {
    return this.modalService.showMediumModal(PresentationItemComponent, {
      backdrop: true,
      initialState: {
        item
      }
    });
  }

  showSettingsModal(item, softUpdate?) {
    var deferred;

    if (item && item.type === 'widget') {
      deferred = this.widgetModalFactory.showSettingsModal(item);
    } else if (item && item.type === 'presentation') {
      deferred = this._showPresentationSettingsModal(item);
    }

    if (!deferred) {
      return;
    }

    deferred.then(() => {
      if (!softUpdate) {
        this.placeholderPlaylistService.updateItem(item);
      }
    });

  }
}

angular.module('risevision.editor.services')
  .factory('settingsService', downgradeInjectable(SettingsService));
