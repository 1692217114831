import { Injectable } from '@angular/core';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebPageUrlValidationService {

  private static readonly PROXY_URL = 'https://services2.risevision.com/proxy/';

  constructor(
    private promiseUtils: PromiseUtilsService,
    private httpClient: HttpClient
  ) { }

  validate(url) {
    if (!url) {
      return Promise.resolve('VALID');
    }

    let value = url;

    // Add https:// if no protocol parameter exists
    if (value.indexOf('://') === -1 || value.indexOf('://') > 5) {
      value = 'https://' + value;
    }

    var deferred = this.promiseUtils.generateDeferredPromise();

    this.httpClient.get( WebPageUrlValidationService.PROXY_URL + value, {
      responseType: 'text',
      observe: 'response'})
    .toPromise()
    .then((response: any) => {
      let restricted = false;
      let header;

      header = response.headers.get( 'X-Frame-Options' );
      if ( header ) {
        restricted = true;
      }

      header = response.headers.get( "content-security-policy" );
      if ( header && header.includes( "frame-ancestors" )) {
        restricted = true;
      }

      if (restricted) {
        return deferred.resolve('FRAME_RESTRICTED');
      }

      return deferred.resolve('VALID');
    }, () => {
      return deferred.resolve('LOAD_FAILURE');
    })
    .catch( (err) => {
      deferred.reject(err);
    });

    return deferred.promise;
  }

}
