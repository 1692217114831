import { Injectable } from '@angular/core';
import { UserState, CoreAPILoader, ExceptionHandler } from 'src/app/ajs-upgraded-providers';
import { TimelineDescriptionService } from 'src/app/components/timeline/timeline-description.service';

import { pick } from 'lodash';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import type { ApiSearch } from 'src/app/shared/services/api-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleApiService {

  static readonly SCHEDULE_WRITABLE_FIELDS = [
    'name', 'content', 'distribution', 'distributeToAll',
    'timeDefined', 'startDate', 'endDate', 'startTime', 'endTime',
    'recurrenceType', 'recurrenceFrequency', 'recurrenceAbsolute',
    'recurrenceDayOfWeek', 'recurrenceDayOfMonth', 'recurrenceWeekOfMonth',
    'recurrenceMonthOfYear', 'recurrenceDaysOfWeek', 'scheduleType', 'playOnce',
    'subcompanyDistribution', 'distributeToSubcompanies', 'assignedUsers'
  ];
  static readonly SCHEDULE_AP_WRITABLE_FIELDS = ['name', 'content'];
  static readonly SCHEDULE_SEARCH_FIELDS = ['name', 'id'];

  constructor(
    private timelineDescriptionService: TimelineDescriptionService,
    private coreAPILoader: CoreAPILoader,
    private userState: UserState,
    private exceptionHandler: ExceptionHandler
  ) { }

  private createSearchQuery (fields: string[], search: string): string {
    let query = '';

    for (let i in fields) {
      query += 'OR ' + fields[i] + ':~\"' + search + '\" ';
    }

    query = query.substring(3);

    return query.trim();
  }

  private writeScheduleTypeName (schedule) {
    switch (schedule.scheduleType) {
      case 'override_replace':
        schedule.scheduleTypeName = 'Replace';
      break;
      case 'override_insert':
        schedule.scheduleTypeName = 'Include';
      break;
      default:
        schedule.scheduleTypeName = '';
    }
  }

  list (search: ApiSearch, cursor?: string): Promise<any> {
    let query = search.query ?
      this.createSearchQuery(ScheduleApiService.SCHEDULE_SEARCH_FIELDS, search.query) : '';
    query += search.filter ? (search.query ? ' AND ' : '') + search.filter : '';

    const obj = {
      'companyId': this.userState.getSelectedCompanyId(),
      'search': query,
      'cursor': cursor,
      'count': search.count ? search.count.toString() : '',
      'sort': search.sortBy ? search.sortBy + (search.reverse ? ' desc' : ' asc') : ''
    };
    console.debug('list schedules called with', obj);
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.schedule.list(obj);
    })
      .then((resp) => {
        try {
          if (resp.result.items) {
            for (var item of resp.result.items) {
              this.writeScheduleTypeName(item);
              item.useLocaldate = true;
              item.timeline = this.timelineDescriptionService.updateLabel(item);
            }
          }
        } catch(e) {
          this.exceptionHandler(e, 'Failed process schedules.', true);
        }

        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to get list of schedules.', e);
        throw e;
      });
  }

  get (scheduleId?: string): Promise<any> {
    const obj = {
      'id': scheduleId
    };

    console.debug('get schedule called with', scheduleId);
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.schedule.get(obj);
    })
      .then((resp) => {
        console.debug('get schedule resp', resp);
        this.writeScheduleTypeName(resp.result.item);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to get schedule.', e);
        throw e;
      });
  }

  add (schedule: any, forceDistribution?: boolean): Promise<any> {
    const fields = pick(schedule, ScheduleApiService.SCHEDULE_WRITABLE_FIELDS);
    fields.forceDistribution = forceDistribution;

    const obj = {
      'companyId': this.userState.getSelectedCompanyId(),
      'data': fields
    };
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.schedule.add(obj);
    })
      .then((resp) => {
        console.debug('added schedule', resp);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to add schedule.', e);
        throw e;
      });
  }

  update (scheduleId: string, schedule: any, forceDistribution?: boolean): Promise<any> {
    const fields = pick(schedule, this.userState.isAssignedPublisher() ?
      ScheduleApiService.SCHEDULE_AP_WRITABLE_FIELDS :
      ScheduleApiService.SCHEDULE_WRITABLE_FIELDS
    );
    if (!this.userState.isAssignedPublisher()) {
      fields.forceDistribution = forceDistribution;
    }

    const obj = {
      'id': scheduleId,
      'data': fields
    };

    console.debug('update schedule called with', scheduleId);
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.schedule.patch(obj);
    })
      .then((resp) => {
        console.debug('update schedule resp', resp);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to update schedule.', e);
        throw e;
      });
  }

  copy (scheduleId?: string, companyId?: string): Promise<any> {
    const obj = {
      'id': scheduleId,
      'companyId': companyId
    };

    console.debug('copy schedule called with', scheduleId, companyId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.copy(obj);
      })
      .then((resp) => {
        console.debug('copy schedule resp', resp);
        return resp;
      })
      .then(null, (e) => {
        console.error('Failed to copy schedule.', e);
        throw e;
      });
  }

  delete (scheduleId?: string): Promise<any> {
    const obj = {
      'id': scheduleId
    };

    console.debug('delete schedule called with', scheduleId);
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.schedule.delete(obj);
    })
      .then((resp) => {
        console.debug('delete schedule resp', resp);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to delete schedule.', e);
        throw e;
      });
  }

  export () {
    const companyId = this.userState.getSelectedCompanyId();

    console.debug('export schedules called with', companyId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.export({ companyId: companyId });
      })
      .then((resp) => {
        console.debug('export schedules resp', resp);
        return resp;
      })
      .then(null, (e) => {
        console.error('Failed to export schedules.', e);
        throw e;
      });
  }

  addPresentation (scheduleIds?: string[], playlistItem?: any): Promise<any> {
    const obj = {
      'scheduleIds': scheduleIds,
      'playlistItem': playlistItem
    };

    console.debug('addPresentation to schedule called with', scheduleIds);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.addPresentation(obj);
      })
      .then((resp) => {
        console.debug('addPresentation to schedule resp', resp);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to add presentation to schedule.', e);
        throw e;
      });
  }

  removePresentation (scheduleIds?: string[], presentationId?: string): Promise<any> {
    const obj = {
      'scheduleIds': scheduleIds,
      'presentationId': presentationId
    };

    console.debug('removePresentation from schedule called with', scheduleIds);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.removePresentation(obj);
      })
      .then((resp) => {
        console.debug('removePresentation from schedule resp', resp);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to remove presentation from schedule.', e);
        throw e;
      });
  }
}

angular.module('risevision.schedules.services')
  .factory('schedule', downgradeInjectable(ScheduleApiService));