<div class="list-group-item">

  <div class="flex-row form-group">
    <div class="row-entry">

      <label class="control-label pull-left mb-0">Screenshot</label>
      <span class="mr-auto" [shown]="screenshotState() === 'no-license'">
        <a href="" (click)="confirmLicensing()" [shown]="userState.hasRole('da')">License Required</a>
        <span [shown]="!userState.hasRole('da')">License Required</span>
      </span>
      <span id="notActivated" [shown]="screenshotState() !== 'no-license' && displayFactory.display.onlineStatus === 'not_activated'">
        Not Activated
      </span>
      <span class="mr-auto" [shown]="reloadScreenshotEnabled() && displayFactory.display.onlineStatus !== 'not_activated'">
        <!-- online -->
        <a id="btnUpdateScreenshot" class="u_icon-hover u_clickable" (click)="screenshotService.requestScreenshot()">
          Update
        </a>
      </span>
      <!-- offline, unsupported, out of date, not-installed? -->
      <!-- show nothing -->
    </div>
  </div>

  <div class="display-screenshot" [shown]="!screenshotState()">
    <div class="display-screenshot-info">
      <p class="text-gray">Screenshot not available.</p>
    </div>
  </div>
  <div class="display-screenshot" [shown]="screenshotState() === 'no-license'">
    <div class="display-screenshot-info">
      <p class="text-gray">License Required</p>
    </div>
  </div>
  <div class="display-screenshot" [shown]="screenshotState() === 'screenshot-error'">
    <div class="display-screenshot-info">
      <p class="text-gray">There was a problem requesting the screenshot.</p>
    </div>
  </div>
  <div class="display-screenshot" [shown]="screenshotState() === 'loading'">
    <div class="display-screenshot-info">
      <div class="app-loading-dots">
        <i class="fa fa-3x fa-spinner fa-spin fa-fw"></i>
      </div>
    </div>
  </div>
  <img class="img-fluid" [src]="screenshotUrl" [shown]="screenshotState() === 'screenshot-loaded'">

  <div class="text-gray align-right mt-2" [shown]="screenshotState() === 'screenshot-loaded'">
    <small>Updated {{ lastModified | date:'d-MMM-yyyy h:mm a' }}</small>
  </div>

</div>
