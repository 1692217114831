import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ModalService } from 'src/app/components/modals/modal.service';
import { PresentationSelectorComponent } from '../components/presentation-selector/presentation-selector.component';
import { PresentationMultiSelectorComponent } from '../components/presentation-multi-selector/presentation-multi-selector.component';
import { PresentationService } from 'src/app/ajs-upgraded-providers';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PresentationsService {

  constructor(
    private presentation: PresentationService,
    private modalService: ModalService
  ) { }

  private _presentations = [];
  loadingPresentation = false;
  apiError = '';

  private _find (presentationId) {
    return _.find(this._presentations, {
      id: presentationId
    });
  }

  setPresentation (presentation) {
    if (presentation.id && !this._find(presentation.id)) {
      this._presentations.push(presentation);
    }
  }

  resetPresentation (presentation) {
    const removeIndex = _.findIndex(this._presentations, {
      id: presentation.id
    });

    if (removeIndex >= 0) {
      this._presentations.splice(removeIndex, 1);
    }
  }

  getPresentationCached (presentationId?) {
    var presentation = this._find(presentationId);

    if (presentation) {
      return Promise.resolve(presentation);
    } else {
      return this._getPresentation(presentationId);
    }
  }

  private _getPresentation (presentationId?) {

    //show loading spinner
    this.loadingPresentation = true;

    var deferred = new Promise((resolve, reject) => {
      this.presentation.get(presentationId)
        .then((result) => {
          this.setPresentation(result.item);

          resolve(result.item);
        })
        .then(null, (e) => {
          this.apiError = e.message ? e.message : e.toString();

          reject();
        })
        .finally(() => {
          this.loadingPresentation = false;
        });
    });

    return deferred;
  }

  selectPresentation () {
    PresentationSelectorComponent.PRESENTATION_SEARCH.filter = ' NOT presentationType:\"HTML Template\"';

    setTimeout(function () {
      PresentationSelectorComponent.PRESENTATION_SEARCH.filter = '';
    });

    return this.openPresentationSelector();
  }

  openPresentationSelector() {
    return this.modalService.showMediumModal(PresentationSelectorComponent);
  }

  openPresentationMultiSelector() {
    return this.modalService.showMediumModal(PresentationMultiSelectorComponent);
  }
}

angular.module('risevision.editor.services')
  .factory('presentationFactory', downgradeInjectable(PresentationsService));