import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { EditorService } from '../../services/editor.service';
import { TemplateApiService } from '../../services/template-api.service';
import { TemplatesService } from '../../services/templates.service';

@Component({
  selector: 'weekly-templates',
  templateUrl: './weekly-templates.component.html',
  styleUrls: ['./weekly-templates.component.scss']
})
export class WeeklyTemplatesComponent implements OnInit {

  static readonly WEEKLY_TEMPLATES_FULL_VIEW = 'RiseVision-weeklyTemplatesFullView';

  weeklyTemplates: any;
  fullView: boolean;
  selectedDate: Date | null;
  datesAvailable: boolean = false;

  search = {
    count: 250,
    sort: 'templateCalendarStartDate asc',
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  };

  constructor(
    public templatesService: TemplatesService,
    private ScrollingListService: ScrollingListService,
    private templateApiService: TemplateApiService,
    private editorService: EditorService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const sessionFullView = sessionStorage.getItem(WeeklyTemplatesComponent.WEEKLY_TEMPLATES_FULL_VIEW);
    if (sessionFullView === null) {
      sessionStorage.setItem(WeeklyTemplatesComponent.WEEKLY_TEMPLATES_FULL_VIEW, 'true');
      this.fullView = true;
    } else {
      this.fullView = sessionFullView === 'true';
    }

    this.weeklyTemplates = this.ScrollingListService(this.loadTemplates.bind(this), this.search);
  }

  loadTemplates(search, cursor) {
    if (this.templatesService.upcomingEvents.length) {
      this.datesAvailable = true;
      return Promise.resolve({
        items: this.templatesService.upcomingEvents.slice(0, 3)
      });
    } else {
      return this.templateApiService.listTemplates(search, cursor).then((result) => {
        this.datesAvailable = this.templatesService.loadDates(result.items);
        this.templatesService.loadUpcomingEvents(4);
        return {
          items: this.templatesService.upcomingEvents.slice(0, 3)
        };
      });
    }
  }

  toggleView () {
    this.fullView = !this.fullView;
    sessionStorage.setItem(WeeklyTemplatesComponent.WEEKLY_TEMPLATES_FULL_VIEW, this.fullView.toString());
  }

  select (product: any) {
    this.editorService.addFromProduct(product, { addedFrom: 'Events Calendar' });
  }

  openLibrary () {
    this.editorService.addPresentationModal(this.selectedDate);
    this.selectedDate = null;
  }

}
