import { Injectable } from '@angular/core';

import { ProcessErrorCode, UserState } from 'src/app/ajs-upgraded-providers';

import { CreditCardService } from 'src/app/purchase/services/credit-card.service';
import { AddressUtilsService } from 'src/app/shared/services/address-utils.service';
import { BillingApiService } from './billing-api.service';

@Injectable({
  providedIn: 'root'
})
export class AddPaymentSourceService {

  apiError;
  loading;

  constructor(private processErrorCode: ProcessErrorCode,
    private userState: UserState,
    private creditCardService: CreditCardService,
    private billingApiService: BillingApiService,
    private addressUtilsService: AddressUtilsService) { }

  _clearMessages() {
    this.loading = false;

    this.apiError = '';
  }

  init() {
    this._clearMessages();

    this.creditCardService.initPaymentMethods(false)
      .finally(() => {
        this.creditCardService.paymentMethods.paymentMethod = 'card';
        this.creditCardService.paymentMethods.newCreditCard.billingAddress = this.addressUtilsService.copyAddress(this.userState
          .getCopyOfSelectedCompany());
      });
  }

  changePaymentToInvoice(subscriptionId, poNumber) {
    this._clearMessages();

    this.loading = true;

    return this.billingApiService.changePaymentToInvoice(subscriptionId, poNumber)
      .catch((e) => {
        this._showErrorMessage(e);

        return Promise.reject(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  _preparePaymentSource() {
    return this.billingApiService.preparePaymentSource(this.creditCardService.getPaymentMethodId())
      .then((response) => {
        this.creditCardService.paymentMethods.intentResponse = response;

        if (response.authenticationRequired) {
          return this.creditCardService.confirmCardSetup(response.intentSecret);
        } else {
          return Promise.resolve();
        }
      });
  }

  _addPaymentSource() {
    var intentId = this.creditCardService.paymentMethods.intentResponse ?
      this.creditCardService.paymentMethods.intentResponse.intentId : null;

    return this.billingApiService.addPaymentSource(intentId)
      .then((response) => {
        return response && response.item && response.item.payment_source &&
          response.item.payment_source.id;
      });
  }

  _changePaymentSource(subscriptionId, paymentSourceId) {
    return this.billingApiService.changePaymentSource(subscriptionId, paymentSourceId);
  }

  changePaymentSource(subscriptionId) {
    this._clearMessages();

    this.loading = true;

    return this.creditCardService.validatePaymentMethod()
      .then(this._preparePaymentSource.bind(this))
      .then(this._addPaymentSource.bind(this))
      .then((paymentSourceId) => {
        return this._changePaymentSource(subscriptionId, paymentSourceId);
      })
      .catch((e) => {
        this._showErrorMessage(e);

        return Promise.reject(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  _showErrorMessage(e) {
    this.apiError = this.processErrorCode(e);

    console.error(this.apiError, e);
  }

}
