import { Component, Injector, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserState } from 'src/app/ajs-upgraded-providers';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { PlaceholdersService } from '../../services/placeholders.service';
import { FeaturesService } from 'src/app/components/plans/features.service';

import { PresentationPropertiesService } from '../../services/presentation-properties.service';

@Component({
  selector: 'presentation-properties',
  templateUrl: './presentation-properties.component.html',
  styleUrls: ['./presentation-properties.component.scss'],
  providers: [
    {
      deps: ['$injector'],
      provide: '$scope',
      useFactory: ($injector: Injector) => $injector.get('$rootScope').$new()
    }
  ]
})
export class PresentationPropertiesComponent extends ModalComponent {

  @ViewChild('presentationPropertiesForm')
  public presentationPropertiesForm: NgForm;

  presentationProperties = this.presentationPropertiesService.getPresentationProperties();
  companyId = this.userState.getSelectedCompanyId();
  placeholders = this.placeholdersService.getPlaceholders();

  constructor(public modalRef: BsModalRef,
    private broadcaster: BroadcasterService,
    private userState: UserState,
    private presentationPropertiesService: PresentationPropertiesService,
    private placeholdersService: PlaceholdersService,
    private featuresService: FeaturesService) {
    super(modalRef);
  }

  apply() {
    if (!this.presentationPropertiesForm.invalid) {
      this.presentationPropertiesService.setPresentationProperties(this.presentationProperties);
      this.dismiss();
    }
  }

  dismiss() {
    this.broadcaster.emit('presentationPropertiesDismissed');
    super.resolve();
  }

  get sharedTemplatesAvailable(): boolean | null {
    return this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SHARED_TEMPLATES);
  }

  showUpgradePlanModal() {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SHARED_TEMPLATES).then((result) => {
      if (result) {
        this.dismiss();
      }
    });
  }

}
