import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TimeParserService } from '../time-parser.service';

@Component({
  selector: 'popup-time-picker',
  templateUrl: './popup-time-picker.component.html',
  styleUrls: ['./popup-time-picker.component.scss']
})
export class PopupTimePickerComponent implements OnChanges {
  displayTime: string

  private _time: Date;
  get time(): Date {
    return this._time;
  }
  set time(value: Date) {
    this._time = value;
    this.displayTime = this.timeParserService.parseDate(value);
    this.ngModelChange.emit(this.timeParserService.parseDateMilitary(value));
  }

  @Input() ngModel!: string;
  @Output() ngModelChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private timeParserService: TimeParserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ngModel) {
      const time = this.timeParserService.parseMilitary(changes.ngModel.currentValue);
      this.time = time ? time : this.timeParserService.midnight();
    }
  }
}
