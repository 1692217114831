<div class="component-container">
  <div class="attribute-editor-component">
    <button id="addPlaceholderButton" class="btn btn-default btn-block mb-2" (click)="placeholdersService.addNewPlaceholder()">
      Add
    </button>

    <div class="editor-playlist workspace-sidebar-body">
      <div class="editor-playlist-item attribute-row attribute-row-hover" *ngFor="let placeholder of placeholdersService.getPlaceholders()">
        <div class="attribute-desc mr-auto">
          <streamline-icon class="streamline-component-icon" name="playlist" width="24" height="18"></streamline-icon>
          <a id="manageLink" class="madero-link" (click)="manage(placeholder)">{{placeholder.id}}</a>
        </div>

        <a href="#" id="duplicateButton" (click)="placeholdersService.duplicatePlaceholder(placeholder)">
          <streamline-icon class="ml-3 mr-0 streamline-component-icon" name="copy" width="16" height="18"></streamline-icon>
        </a>
        <a href="#" id="removeButton" (click)="remove(placeholder)">
          <streamline-icon class="ml-3 streamline-component-icon" name="trash" width="16" height="18"></streamline-icon>
        </a>
      </div><!--editor-playlist-item-->

      <div [hidden]="placeholdersService.getPlaceholders().length">
        <div class="panel-body">
          <p class="text-muted text-center u_remove-bottom">You haven't added any Placeholders to your Presentation yet.</p>
        </div>
      </div>
    </div>
  </div>
</div>
