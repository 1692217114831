import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'safe-delete-modal',
  templateUrl: './safe-delete-modal.component.html',
  styleUrls: ['./safe-delete-modal.component.scss']
})
export class SafeDeleteModalComponent extends ModalComponent {

  name;
  inputText = null;

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  dismissModal() {
    super.reject();
  }

  canConfirm() {
    return this.inputText === 'DELETE';
  }

  confirm() {
    if (this.canConfirm()) {
      super.resolve();
    }
  }

}
