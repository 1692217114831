import { Component, ChangeDetectorRef, OnInit, NgZone } from '@angular/core';
import { ModalComponent } from '../../modals/modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TimelineService } from '../timeline.service';

@Component({
  selector: 'timeline-modal',
  templateUrl: './timeline-modal.component.html',
  styleUrls: ['./timeline-modal.component.scss']
})
export class TimelineModalComponent extends ModalComponent implements OnInit {

  timeline: any;
  recurrence: any;

  processing = false;
  today = new Date();
  options = {
    dateInputFormat: 'DD-MMM-YYYY',
    showWeekNumbers: false,
    showTodayButton: true,
    showClearButton: true,
    todayPosition: 'left',
    clearPosition: 'left',
    initCurrentTime: false
  };

  constructor(public modalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private timelineService: TimelineService,
    private ngZone: NgZone) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.timelineService.timeline = this.timeline;
    this.recurrence = this.timelineService.recurrence;

    if (this.timeline.startDate && this.timeline.startDate.getTime() < this.today.getTime()) {
      this.today = new Date(this.timeline.startDate.getTime());
    }
  }

  // This may no longer be needed once all schedule field components are migrated to Angular
  // Fix for https://github.com/Rise-Vision/rise-vision-apps/issues/3563
  datepickerShown () {
    // There will usually only be one datepicker on screen, but select all just in case
    Array.from(document.getElementsByClassName('bs-datepicker-head')).forEach((header) => {
      (header as HTMLElement).onclick = () => {
        this.ngZone.run(() => {});
      }
    });
  }

  detectChanges () {
    this.changeDetectorRef.detectChanges();
  }

  apply () {
    this.processing = true;
    this.timelineService.save();
    super.resolve(this.timeline);
  }

  dismiss () {
    super.reject();
  }

}