<div class="madero-style alert alert-info flex-row" role="alert">
  <streamline-icon name="info" width="16" height="16"></streamline-icon>
  <p><strong>
    <span *ngIf="subscriptionService.getItemSubscription().has_scheduled_changes">
      Pending change:
      <span *ngIf="subscriptionService.getItemSubscription().changed_plan_quantity">
        remove {{ subscriptionService.getItemSubscription().changed_plan_quantity }}
        of {{ subscriptionService.getItemSubscription().plan_quantity }} display licenses
      </span>
      <span *ngIf="getPlanName(this.subscriptionService.getItemSubscription().changed_plan_id)">
        <span *ngIf="subscriptionService.getItemSubscription().changed_plan_quantity">and change plan</span>
        from {{ getPlanName(this.subscriptionService.getItemSubscription().plan_id) }}
        to {{ getPlanName(this.subscriptionService.getItemSubscription().changed_plan_id) }}
      </span>
      will take effect on
      {{ subscriptionService.getItemSubscription().next_billing_at * 1000 | date:'d-MMM-yyyy' }}.
    </span>
    <span *ngIf="!subscriptionService.getItemSubscription().has_scheduled_changes">
      <span *ngIf="subscriptionService.getItemSubscription().cancelled_at">
        This subscription is scheduled to cancel on
        {{ subscriptionService.getItemSubscription().cancelled_at * 1000 | date:'d-MMM-yyyy' }}.
      </span>
      <span *ngIf="!subscriptionService.getItemSubscription().cancelled_at">
        Changes to your subscription will update on your next billing cycle on
        {{ subscriptionService.getItemSubscription().next_billing_at * 1000 | date:'d-MMM-yyyy' }}.
      </span>
    </span>

    <div class="pt-3" *ngIf="isUpdate && subscriptionService.getItemSubscription().has_scheduled_changes">

      The following change will cancel <span *ngIf="!upgrade">and replace</span> your previous pending changes.
      <a class="madero-link" href="#" (click)="helpWidgetFactory.showContactUs()">Contact Us</a>
      if you need help updating this subscription.
    </div>
  </strong></p>
</div>