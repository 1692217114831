import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FileUploaderFactory, StorageApiService, UploadOverwriteWarning, UploadURIService } from 'src/app/ajs-upgraded-providers';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

  static readonly STORAGE_UPLOAD_CHUNK_SIZE = 256 * 1024 * 100; // Multiple of Google required chunk size (256 * 1024)
  static readonly videoTypesNotSupported = ['mov', 'wmv', 'm4v', 'flv', 'avi', 'ogg', 'ogv'];
  static readonly imageTypesNotSupported = ['tiff', 'tif'];

  @Input() folderPath: string = '';
  @Output() addFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() queueLengthChange: EventEmitter<number> = new EventEmitter<number>();

  warnings: any[] = [];
  uploader: any;
  status: any = {};
  isCollapsed: boolean = false;

  constructor (
    private fileUploaderFactory: FileUploaderFactory,
    private storageApiService: StorageApiService,
    private uploadOverwriteWarning: UploadOverwriteWarning,
    private uploadUriService: UploadURIService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.uploader = this.fileUploaderFactory();

    this.uploader.onBeforeUploadItem = (item) => {
      this.status.message = 'Uploading ' + item.file.name;
    };

    this.uploader.onCancelItem = (item) => {
      this.uploader.removeFromQueue(item);
      this.queueLengthChange.emit(this.uploader.queue.length);
    };

    this.uploader.onCompleteItem = (item) => {
      this.dismissWarning(item);

      if (item.isCancel) {
        return;
      }

      if (!item.isSuccess) {
        this.status.message = 'Upload failed. Please try again.';

        return;
      }

      const baseFile = {
        'name': item.encodingFileName || item.file.name,
        'updated': {
          'value': new Date().valueOf().toString()
        },
        'size': item.file.size,
        'type': item.file.type
      };

      //retrieve to generate thumbnail
      this.storageApiService.refreshFileMetadata(item.encodingFileName || item.file.name)
        .then((file) => {
          console.debug('Add file to list of available files', file);
          this.addFile.emit(file);
        }, (err) => {
          console.debug('Error refreshing metadata', item.file.name, err);
          this.addFile.emit(baseFile);
        })
        .finally(() => {
          this.uploader.removeFromQueue(item);
          this.queueLengthChange.emit(this.uploader.queue.length);
          this.changeDetectorRef.detectChanges();
        });
    };

    this.uploader.onAddingFiles = () => {
      uploadOverwriteWarning.resetConfirmation();
    };

    this.uploader.currentFilePath = () => {
      return this.folderPath;
    };

    this.uploader.onAfterAddingFile = (fileItem) => {
      console.info('onAfterAddingFile', fileItem.file.name);

      if (!fileItem.isRetrying) {
        fileItem.file.name = (this.folderPath || '') + fileItem.file.name;
      }

      this.status.message = 'Uploading ' + fileItem.file.name;

      const uploadFile = (resp) => {
        fileItem.url = resp.message;
        fileItem.taskToken = resp.taskToken;
        fileItem.encodingFileName = resp.newFileName;
        fileItem.chunkSize = UploadComponent.STORAGE_UPLOAD_CHUNK_SIZE;

        this.checkFileType(fileItem);

        return this.uploadOverwriteWarning.checkOverwrite(resp).then(() => {
          this.uploader.uploadItem(fileItem, () => { this.changeDetectorRef.detectChanges(); });
        }).catch(() => {
          this.uploader.removeFromQueue(fileItem);
          this.queueLengthChange.emit(this.uploader.queue.length);
        });
      };

      return this.uploadUriService.getURI(fileItem.file)
        .then(uploadFile)
        .then(null, (resp) => {
          console.log('getURI error', resp);

          if (resp.message === 'Unencodable overwrite') {
            return this.uploadUriService.getURI(fileItem.file, true)
              .then(uploadFile);
          }

          this.uploader.notifyErrorItem(fileItem, resp.status);
          this.status.message = resp.message;
        });
    };
  }

  removeItem (item) {
    this.uploader.removeFromQueue(item);
    this.queueLengthChange.emit(this.uploader.queue.length);
  }

  someEncoding () {
    return this.uploader.someEncoding();
  }

  activeUploadCount () {
    return this.uploader.queue.filter((file) => {
      return !file.isUploaded || file.isError;
    }).length;
  }

  getErrorCount () {
    return this.uploader.getErrorCount();
  }

  getNotErrorCount () {
    return this.uploader.getNotErrorCount();
  }

  retryFailedUpload (file) {
    if (file.isError) {
      this.uploader.retryItem(file);
    }
  }

  retryFailedUploads () {
    this.uploader.queue.forEach((f) => {
      if (f.isError) {
        this.uploader.retryItem(f);
      }
    });
  }

  cancelAllUploads () {
    this.uploader.removeAll();
    this.queueLengthChange.emit(this.uploader.queue.length);
  }

  private checkFileType (fileItem) {
    var fileName = fileItem.file.name;
    var extension = fileName && fileName.split('.').pop();
    if (UploadComponent.videoTypesNotSupported.indexOf(extension) !== -1) {
      if (fileItem.encodingFileName) {
        return;
      }
      this.warnings.push({
        message: 'File <b>' + fileName + '</b> is not supported by Video Widget.'
      });
    } else if (UploadComponent.imageTypesNotSupported.indexOf(extension) !== -1) {
      this.warnings.push({
        message: 'File <b>' + fileName + '</b> is not supported by Image Widget.'
      });
    }
  }

  private dismissWarning (fileItem) {
    const warningIndex = this.warnings.findIndex((warning) => {
      return warning.filename === fileItem.file.name;
    });
    if (warningIndex !== -1) {
      setTimeout(() => {
        this.warnings.splice(warningIndex);
      }, 5000);
    }
  }

  storageFileSelect (event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    this.uploader.compress(element.files)
      .then((fileItems) => {
        return this.uploader.addToQueue(fileItems).then(() => {
          this.queueLengthChange.emit(this.uploader.queue.length);
        });
      })
      .then(() => {
        element.value = '';
      });
  }

}

angular.module('risevision.storage.directives')
  .directive(
    'upload',
    downgradeComponent({
      component: UploadComponent
    }) as angular.IDirectiveFactory
  );
