import { Injectable } from '@angular/core';
import { EnableCompanyProduct, ProcessErrorCode, UserState } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class PlayerLicenseService {

  static readonly PLAYER_PRO_PRODUCT_CODE = 'c4b368be86245bf9501baaa6e0b00df9719869fd';

  apiError = '';
  updatingLicense = false;

  constructor(
    private currentPlanService: CurrentPlanService,
    private userState: UserState,
    private enableCompanyProduct: EnableCompanyProduct,
    private processErrorCode: ProcessErrorCode,
    private modalService: ModalService
  ) { }

  getUsedLicenseString (): string {
    let text = this.getProUsedLicenseCount() + ' Licensed Display' +
      (this.getProUsedLicenseCount() !== 1 ? 's' : '') + ' / ';
    if (this.currentPlanService.isUnlimitedPlan()) {
      text += 'Unlimited Licenses';
    } else {
      text += this.getProAvailableLicenseCount() +
        ' Available License' + (this.getProAvailableLicenseCount() !== 1 ? 's' : '');
    }
    return text;
  }

  isProAvailable (display: any): boolean {
    return this.hasProfessionalLicenses() && this.getProLicenseCount() > 0 &&
      !this.areAllProLicensesUsed(display);
  }

  hasProfessionalLicenses (): boolean {
    return this.currentPlanService.currentPlan.playerProTotalLicenseCount > 0;
  }

  getProLicenseCount (): number {
    return this.currentPlanService.currentPlan.playerProTotalLicenseCount || 0;
  }

  getProAvailableLicenseCount (): number {
    return this.currentPlanService.currentPlan.playerProAvailableLicenseCount || 0;
  }

  getProUsedLicenseCount (): number {
    return this.getProLicenseCount() - this.getProAvailableLicenseCount();
  }

  isProToggleEnabled (display: any): boolean {
    return !this.currentPlanService.isUnlimitedPlan() && this.userState.hasRole('da') && ((display && display.playerProAuthorized) ||
      (this.areAllProLicensesUsed(display) ? !this.currentPlanService.currentPlan.isPurchasedByParent : true));
  }

  areAllProLicensesUsed (display: any): boolean {
    const allLicensesUsed = !this.getProAvailableLicenseCount();
    const allProLicensesUsed = allLicensesUsed && !(display && display.playerProAssigned);

    return this.getProLicenseCount() > 0 && allProLicensesUsed;
  }

  updateDisplayLicenseLocal (display: any): void {
    const playerProAuthorized = display.playerProAuthorized;
    const company = this.userState.getCopyOfSelectedCompany(true);

    display.playerProAssigned = playerProAuthorized;
    display.playerProAuthorized = (display.originalPlayerProAuthorized ||
      company.playerProAvailableLicenseCount > 0) && playerProAuthorized;
  }

  updateDisplayLicense (display: any): Promise<any> {
    this.apiError = '';
    this.updatingLicense = true;

    const apiParams = {};
    const playerProAuthorized = display.playerProAuthorized;
    apiParams[display.id] = playerProAuthorized;

    return this.enableCompanyProduct(display.companyId, PlayerLicenseService.PLAYER_PRO_PRODUCT_CODE, apiParams)
      .then((resp) => {
        const resultDisplays = resp && resp.item && resp.item.displays;
        if (resultDisplays && resultDisplays[display.id] === apiParams[display.id]) {
          this.updateDisplayLicenseLocal(display);
          this.toggleDisplayLicenseLocal(display.playerProAuthorized);
        } else {
          throw new Error('License could not be updated. Please try again.');
        }
      })
      .catch((e) => {
        this.apiError = this.processErrorCode(e);
        console.error(this.apiError, e);
        return Promise.reject(e);
      })
      .finally(() => {
        this.updatingLicense = false;
        if (!playerProAuthorized) {
          display.monitoringEnabled = false;
        }
      });
  }

  toggleDisplayLicenseLocal (playerProAuthorized: boolean, displaysCount?: number): void {
    const count = displaysCount || 1;
    const company = this.userState.getCopyOfSelectedCompany(true);
    let availableLicenseCount = company.playerProAvailableLicenseCount || 0;

    if (playerProAuthorized) {
      availableLicenseCount = availableLicenseCount - count;
      availableLicenseCount = availableLicenseCount < 0 ? 0 : availableLicenseCount;
    } else {
      availableLicenseCount = availableLicenseCount + count;
    }

    company.playerProAvailableLicenseCount = availableLicenseCount;
    this.userState.updateCompanySettings(company);
  }

  confirmAndLicense (displayIds: string[]): Promise<any> {
    const countText = displayIds.length + ' display' + (displayIds.length > 1 ? 's' : '');
    return this.modalService.confirm('License ' + countText + '?',
        'You are about to assign licenses to ' + countText + '. Would you like to proceed?',
        'Yes', 'No')
      .then(() => {
        if (this.getProAvailableLicenseCount() >= displayIds.length) {
          return this._licenseDisplays(displayIds);
        } else {
          this.currentPlanService.confirmAndPurchase(displayIds.length - this.getProAvailableLicenseCount());
          return Promise.reject();
        }
      });
  }

  licenseDisplaysByCompanyId (companyId: string, displayIds: string[]): Promise<any> {
    const apiParams = {};
    for (let i = 0; i < displayIds.length; i++) {
      apiParams[displayIds[i]] = true;
    }

    return this.enableCompanyProduct(companyId, PlayerLicenseService.PLAYER_PRO_PRODUCT_CODE, apiParams)
      .then((resp) => {
        const resultDisplays = resp && resp.item && resp.item.displays;
        let licensedCount = 0;
        for (let i = 0; i < displayIds.length; i++) {
          if (resultDisplays && resultDisplays[displayIds[i]] === true) {
            licensedCount++;
          }
        }

        this.toggleDisplayLicenseLocal(true, licensedCount);

        if (licensedCount !== displayIds.length) {
          throw new Error('License could not be updated. Please try again.');
        }
      });
  }

  private _licenseDisplays (displayIds: string[]): Promise<any> {
    this.apiError = '';
    this.updatingLicense = true;

    return this.licenseDisplaysByCompanyId(this.userState.getSelectedCompanyId(), displayIds)
      .catch((e) => {
        this.apiError = this.processErrorCode(e);
        console.error(this.apiError, e);
        return Promise.reject(e);
      })
      .finally(() => {
        this.updatingLicense = false;
      });
  }
}

angular.module('risevision.displays.services')
  .factory('playerLicenseFactory', downgradeInjectable(PlayerLicenseService));
