import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeParserService {
  static readonly regexMilitary = /^(\d{1,2}):(\d{1,2})/;

  private _timeString (hours: number, minutes: number): string {
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
  }

  parseMilitary (timeString: string): Date | null {
    if (!TimeParserService.regexMilitary.test(timeString)) {
      return null;
    }

    const parts = TimeParserService.regexMilitary.exec(timeString);
    let hours = Number(parts[1]);
    const minutes = Number(parts[2]);

    const time = new Date();
    time.setHours(hours, minutes, 0, 0);

    return time;
  }

  midnight (): Date {
    const time = new Date();
    time.setHours(0, 0, 0, 0);
    return time;
  }

  parseDate (date: Date): string {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const meridian = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    return this._timeString(hours, minutes) + ' ' + meridian;
  }

  parseDateMilitary (date: Date): string {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    return this._timeString(hours, minutes)
  }
}
