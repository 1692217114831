<!-- Step 4 -->
<div class="purchase-sideways-panels">
    <div id="checkout-payment-methods">
      <h4 class="u_margin-md-bottom">Payment Method</h4>

      <form #paymentMethodsForm="ngForm" id="forms.paymentMethodsForm" role="form" name="forms.paymentMethodsForm" novalidate>
        <payment-methods-form [showInvoiceOption]="!purchase.volumePlan.isMonthly" [contactEmail]="purchase.contact.email"></payment-methods-form>
      </form>

    </div>
    <purchase-summary id="checkout-purchase-summary" *ngIf="purchaseStepsService.currentStep === 2"></purchase-summary>
  </div>

  <div id="errorBox" class="madero-style alert alert-danger u_margin-md-top" role="alert" [shown]="paymentMethods.tokenError">
    <strong>Card Processing Error</strong> {{paymentMethods.tokenError}}
  </div>

  <div id="errorBox" class="madero-style alert alert-danger u_margin-md-top" role="alert" [shown]="purchase.checkoutError">
    <strong>Payment Error</strong> {{purchase.checkoutError}}
  </div>

  <div class="button-row text-right mt-5">
    <button id="backButton" type="button" aria-label="Go back to Billing Address" class="btn btn-default btn-toolbar pull-left" (click)="purchaseStepsService.setPreviousStep()">Back</button>
    <button id="payButton" type="submit" class="btn btn-primary btn-toolbar-wide" form="form.paymentMethodsForm" (click)="completeCardPayment()" tabindex="1" aria-label="Complete Payment" *ngIf="paymentMethods.paymentMethod === 'card'">
      <span id="payLabel">Pay {{purchase.estimate.total / 100 | currency}} Now</span>
    </button>
    <button id="invoiceButton" type="submit" class="btn btn-primary btn-toolbar-wide" form="form.paymentMethodsForm" (click)="completePayment()" tabindex="1" aria-label="Complete Payment" *ngIf="paymentMethods.paymentMethod === 'invoice'">
      <span id="invoiceLabel">Invoice Me {{purchase.estimate.total / 100 | currency}} Now</span>
    </button>
  </div>
