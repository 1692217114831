import { ElementRef, Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';
import { FileMetadataUtilsService } from '../services/file-metadata-utils.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import * as csv from 'jquery-csv';

@Component({
  selector: 'template-component-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent {

  componentId;
  fields = [];
  values = [];
  invalidUrls = new Map();

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private elementRef: ElementRef,
    private componentsService: ComponentsService,
    private attributeDataService: AttributeDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService,
    private storageManagerService: StorageManagerService,
    private fileMetadataUtilsService: FileMetadataUtilsService
  ) {
    componentsService.registerDirective({
      type: 'rise-data-table',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsService.selected.id;
        this._load();
      }
    });
  }

  _load() {
    const values = this.attributeDataService.getAvailableAttributeData(this.componentId, 'values');
    const fields = this.attributeDataService.getAvailableAttributeData(this.componentId, 'fields');

    this.values = values ? JSON.parse(values) : [];
    this.fields = fields ? JSON.parse(fields) : [];
    this.replaceUnsupportedFieldTypes();
    this.validate();
  }

  refreshUI() {
    this.changeDetectorRef.detectChanges();
  }

  save() {
    if (!this.validate()) {
      return;
    }

    this.attributeDataService.setAttributeData(this.componentId, 'values', JSON.stringify(this.values));

    this.refreshUI();
  }

  addRow() {
    this.values.push([]);
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    });
  }

  sortItem(evt) {
    this.moveItem(evt.data.oldIndex, evt.data.newIndex);

    this.save();
  }

  moveItem(oldIndex, newIndex) {
    this.values.splice(newIndex, 0, this.values.splice(oldIndex, 1)[0]);
  }

  removeItem(key) {
    this.values.splice(key, 1);

    this.save();
  }

  replaceUnsupportedFieldTypes() {
    //replace unrecognised field types with "text"
    const validFieldTypes = ["text", "multiline", "image"];
    this.fields.forEach(item => {
      if (!validFieldTypes.includes(item.type)) {
        item.type = "text"
      }
    })
  }

  validate() {
    this.invalidUrls.clear();

    this.values.forEach(row => {
      row.forEach((value, index) => {
        if (this.fields[index]?.type === 'image') {
          this.validateImagelUrl(value);
        }
      })
    })
    return this.invalidUrls.size === 0;
  }

  validateImagelUrl(url) {
    //check if url is null, undefuned, or empty
    if (!url?.length) {
      return true;
    }

    //check protocol
    if (!url.toLowerCase().startsWith('https://')) {
      this.invalidUrls.set(url, 'INVALID_PROTOCOL');
      return false;
    }

    //check protocl
    if (!new RegExp(/^.+\.(JPG|JPEG|PNG|BMP|SVG|GIF|WEBP)$/,'i').test(url)) {
      this.invalidUrls.set(url, 'INVALID_EXTENSION');
      return false;
    }

    return true;
  }

  prepareCsv() {
    const columnNames = this.fields.map((item) => item.name);
    const headers = csv.fromArrays([columnNames]);
    const body = csv.fromArrays(this.values);
    return headers + body;
  }

  getCsvDownloadUrl() {
    const text = this.prepareCsv();
    const data = new Blob([text], {type: 'text/plain'});

    return window.URL.createObjectURL(data);
  }

  exportCsv() {
    const url = this.getCsvDownloadUrl();
    const link = window.document.getElementById('te-data-table-export');
    if (link instanceof HTMLAnchorElement ) {
      link.href = url;
    }
  }

  importCsv(e) {

    if (!e.srcElement.value) {
      return;
    }

    this.readFileIntoMemory(e.target.files[0], res => {
      let newValues;

      try {
        if (!res) {
          throw new Error('Error loading file');
        }

        newValues = this.parseCsv(res.content, this.fields.length);

      } catch (error) {
        console.error(error);
        this.modalService.showMessage(error.message, null);
        return;

      } finally {
        //reset input value in order for onChange to trigger when the same file is selected again
        e.srcElement.value = '';
      }

      this.values = newValues;

      this.save();
    })
  }

  parseCsv(data, numberOfColumns) {

    let result;

    try {
      result = csv.toArrays(data);
    } catch (error) {
      throw new Error('Error parsing file content. Make sure you selected the valid CSV file.');
    }

    if (!result.length) {
      throw new Error('CVS file is empty.');
    }

    if (result.length > 500) {
      throw new Error('Too many rows. The maximum is 500.');
    }

    result.forEach((row, rowIndex) => {
      if (row.length != numberOfColumns) {
        throw new Error(`The number of columns in the row ${rowIndex + 1} does not match the number of columns in the table component.`);
      }
    });

    //remove headers
    result.shift();

    return result;
  }

  readFileIntoMemory (file, callback) {
    var reader = new FileReader();
    reader.onload = () => {
      callback({
        name: file.name,
        size: file.size,
        type: file.type,
        content: reader.result
      });
    };
    reader.onerror = () => {
      callback(null);
    };
    reader.readAsText(file);
  }

  selectFromStorage (item: any, key: string) {
    this.storageManagerService.fileType = StorageManagerService.FILE_TYPE.IMAGE;
    this.storageManagerService.isSingleFileSelector = () => { return true; }
    this.storageManagerService.onSelectHandler = (result) => {
      item[key] = StorageUtilsService.STORAGE_FILE_URL + this.fileMetadataUtilsService.getFilePath(result[0]);
      this.save();
    }
    this.componentsService.editComponent({
      type: 'rise-storage-selector'
    });
  }

}
