<div [shown]="userState.isTestCompanySelected()"
  class="sub-company-alert test-company-alert">
  This is a Test Company
</div>
<div [shown]="userState.isSubcompanySelected()"
  class="common-header-alert sub-company-alert hidden-xs">
  You're in Sub-Company {{userState.getSelectedCompanyName()}}&nbsp;
  <a href="#" (click)="userState.resetCompany()" class="link-button">Switch to My Company</a>
</div>
<div [shown]="userState.isSubcompanySelected()"
  class="common-header-alert sub-company-alert visible-xs">
  <a href="#" (click)="userState.resetCompany()" class="link-button">My Company</a>
  <span class="sub-company-name">Sub-Company {{userState.getSelectedCompanyName()}}</span>
  <span>&nbsp;</span>
</div>
