import { Injectable } from '@angular/core';
import { DisplayFactory, DisplayApiService } from 'src/app/ajs-upgraded-providers';
import { ResourceLoaderService } from 'src/app/shared/services/resource-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {

  screenshot: any = {};
  screenshotLoading?: boolean;

  constructor(
    private displayFactory: DisplayFactory,
    private displayApiService: DisplayApiService,
    private resourceLoaderService: ResourceLoaderService
  ) { }

  requestScreenshot () {
    if (this.displayFactory.showLicenseRequired()) {
      return;
    }

    this.screenshotLoading = true;

    return this.displayApiService.requestScreenshot(this.displayFactory.display.id)
      .then(() => {
        return this.loadScreenshot();
      })
      .catch((err) => {
        this.screenshotLoading = false;

        this.screenshot = {
          error: 'requesting'
        };
        console.log('Error requesting screenshot', err);
      });
  }

  loadScreenshot () {
    this.screenshotLoading = true;

    const url =
      'https://storage.googleapis.com/risevision-display-screenshots/' +
      this.displayFactory.display.id + '.jpg';

    return this.resourceLoaderService.imageBlobLoader(url)
      .then((resp) => {
        this.screenshot = resp;
      })
      .catch((err) => {
        this.screenshot = {
          error: 'loading'
        };
        console.log('Error loading screenshot', err);
      })
      .finally(() => {
        this.screenshotLoading = false;
      });
  }

}
