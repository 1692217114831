<div  rvSpinner="update-subscription-loader" [rvShowSpinner]="updateSubscriptionService.loading || subscriptionService.loading">
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">

    <h1 class="app-header-title" [shown]="purchaseAction === 'add'">Add Display Licenses</h1>
    <h1 class="app-header-title" [shown]="purchaseAction === 'remove'">Remove Display Licenses</h1>
    <h1 class="app-header-title" [shown]="purchaseAction === 'annual'">Adjust Billing Frequency</h1>
    <h1 class="app-header-title" [shown]="purchaseAction === 'unlimited'">Upgrade Subscription</h1>
    <h1 class="app-header-title" [shown]="purchaseAction === 'type' || purchaseAction === 'change'">Change Subscription Type</h1>

  </div>

  <plan-tier-picker *ngIf="purchaseAction === 'type'"></plan-tier-picker>

  <div *ngIf="purchaseAction !== 'type'">
    <div rv-spinner rv-spinner-key="update-subscription-loader"></div>

    <div [shown]="updateSubscriptionService.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
      <strong>{{updateSubscriptionService.apiError}}</strong>
      <br/>If you need help please email us at support@risevision.com.
    </div>

    <div id="purchase-licenses-panel" class="row">
      <div class="col-md-8 col-md-offset-2" [hidden]="updateSubscriptionService.purchase?.completed">
        <form #purchaseLicensesForm="ngForm" id="forms.purchaseLicensesForm" role="form" name="forms.purchaseLicensesForm" novalidate (ngSubmit)="completePayment()">

          <h4 class="u_margin-md-bottom">Subscription Details</h4>

          <pending-change-alert *ngIf="purchaseAction === 'remove' || billingUtils.hasPendingChange(subscriptionService.getItemSubscription())" [isUpdate]="true"></pending-change-alert>

          <div class="border-container p-4">

            <div class="left-right-aligner" [ngClass]="{'has-error': licensesToAdd.invalid }" *ngIf="purchaseAction === 'add'">
              <label for="licensesToAdd" class="control-label">Number of display licenses you want to add:</label>
              <input #licensesToAdd="ngModel" class="display-count-input text-md mt-0 pull-right" type="number" name="licensesToAdd" [(ngModel)]="updateSubscriptionService.purchase.licensesToAdd" min="1" max="10000" ng-pattern="/^[0-9]+$/" required (change)="detectChanges()" (debounceEvent)="getEstimate()" autofocus />
            </div>

            <div class="left-right-aligner" [ngClass]="{'has-error': licensesToRemove.invalid }" *ngIf="purchaseAction === 'remove'">
              <label for="licensesToRemove" class="control-label">Number of display licenses you want to remove:</label>
              <input #licensesToRemove="ngModel" class="display-count-input text-md mt-0 pull-right" type="number" name="licensesToRemove" [(ngModel)]="updateSubscriptionService.purchase.licensesToRemove" min="1" max="{{updateSubscriptionService.getCurrentDisplayCount()-1}}" ng-pattern="/^[0-9]+$/" required (change)="detectChanges()" (debounceEvent)="getEstimate()" autofocus />
            </div>

            <div class="border-bottom mb-4" [shown]="purchaseAction === 'unlimited'">
              <p class="font-weight-bold">
                Upgrade to an Unlimited License subscription
              </p>
              <p class="left-right-aligner">
                <span class="font-weight-bold">Total number of display licenses for this subscription:</span>
                <span>Unlimited</span>
              </p>
              <p class="mb-4">
                <span class="font-weight-bold">Note:</span> An Unlimited License is a site license per school building. If purchasing for a school district or network of schools, you need to purchase a separate license for each school. If you have any questions, our <a href="https://www.risevision.com/contact-us?form_selected=sales&content_hide=true" target="_blank">Sales Team</a> is happy to help!
              </p>
            </div>

            <div class="border-bottom py-4 mb-4" [shown]="purchaseAction !== 'annual' && purchaseAction !== 'unlimited'">
              <p class="left-right-aligner mb-0">
                <span class="font-weight-bold">Total number of display licenses for this subscription:</span>
                <span>
                  {{updateSubscriptionService.getTotalDisplayCount()}}
                </span>
              </p>

              <p class="mb-0" [shown]="updateSubscriptionService.purchase.currentPricePerDisplay && updateSubscriptionService.purchase.currentPricePerDisplay === updateSubscriptionService.purchase.newPricePerDisplay">{{updateSubscriptionService.purchase.currentPricePerDisplay | currency }} per display license, per month.</p>
              <div class="madero-style alert alert-success price-update mt-3 mb-0" [shown]="updateSubscriptionService.purchase.currentPricePerDisplay > updateSubscriptionService.purchase.newPricePerDisplay">
                <p class="mb-0 text-success"><strong>Cost will decrease to {{updateSubscriptionService.purchase.newPricePerDisplay | currency }} per display license, per month.</strong></p>
              </div>

              <div class="madero-style alert alert-danger price-update mt-3 mb-0" [shown]="updateSubscriptionService.purchase.currentPricePerDisplay < updateSubscriptionService.purchase.newPricePerDisplay">
                <p class="mb-0 text-success"><strong>Cost will increase to {{updateSubscriptionService.purchase.newPricePerDisplay | currency }} per display license, per month.</strong></p>
              </div>
            </div>

            <div class="border-bottom stack-sm-sideways-panels pt-0 pb-4 mb-4" [shown]="purchaseAction === 'annual'">
              <div class="mb-2 mb-md-0">
                <span class="font-weight-bold">Choose payment frequency:</span><br />
                Save 10% by paying annually!
              </div>
              <div class="btn-group btn-group-justified billing-period-select ml-auto" (click)="billingPeriod = (billingPeriod === 'monthly' ? 'annually' : 'monthly')">
                <button type="button" class="btn"
                  [ngClass]="{'btn-toggle-blue-off' : billingPeriod !== 'monthly', 'btn-toggle-blue-on' : billingPeriod === 'monthly'}">
                  Pay Monthly
                  <streamline-icon name="checkmark" [shown]="billingPeriod === 'monthly'"></streamline-icon>
                </button>
                <button type="button" class="btn"
                  [ngClass]="{'btn-toggle-blue-off' : billingPeriod !== 'annually', 'btn-toggle-blue-on' : billingPeriod === 'annually'}">
                  Pay Annually
                  <streamline-icon name="checkmark" [shown]="billingPeriod === 'annually'"></streamline-icon>
                </button>
              </div>
            </div>

            <p class="mb-0" [shown]="!addCoupon && !couponCode && purchaseAction !== 'remove'">
              &nbsp;
              <span class="pull-right">
                <a aria-label="Add Coupon Code" class="madero-link u_clickable" (click)="addCoupon = true" tabindex="1">Add A Coupon Code</a>
              </span>
            </p>
            <div class="row" [shown]="addCoupon">
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <label for="coupon-code" class="control-label">Coupon Code:</label>
                  <span class="pull-right">
                    <a aria-label="Cancel Coupon Code" class="madero-link u_clickable" (click)="clearCouponCode()" tabindex="1">Cancel</a>
                  </span>
                  <div class="flex-row">
                    <input id="coupon-code" aria-required="false" type="text" class="form-control mr-3" name="couponCode" [(ngModel)]="couponCode" ng-enter="applyCouponCode()">
                    <button id="apply-coupon-code" type="button" aria-label="Apply Coupon Code" class="btn btn-default" (click)="applyCouponCode()">Apply</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="border-bottom pb-4" *ngIf="purchaseAction !== 'remove'">
              <p class="coupon-row mb-0" *ngFor="let coupon of updateSubscriptionService.estimate.invoice_estimate?.discounts">
                <span class="font-weight-bold">{{coupon.description}}</span>
                <span class="pull-right">-{{coupon.amount/100 | currency }}</span>
              </p>
            </div>

            <div class="pt-4">
              <p id="prorated-credit-row" class="left-right-aligner mb-4" *ngIf="updateSubscriptionService.getCreditTotal()">
                <span class="font-weight-bold">Prorated credit added to your account:</span>
                <span>
                  <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimate.credit_note_estimates[0].currency_code}}</span>
                  <span class="purchase-total">{{updateSubscriptionService.getCreditTotal() | currency }}</span>
                </span>
              </p>
              <p id="prorated-amount-row" class="left-right-aligner mb-4" *ngIf="updateSubscriptionService.estimate.invoice_estimate">
                <span class="font-weight-bold">Prorated amount, due now:</span>
                <span>
                  <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimate.invoice_estimate?.currency_code}}</span>
                  <span class="purchase-total">{{updateSubscriptionService.estimate.invoice_estimate?.amount_due/100 | currency }}</span>
                </span>
              </p>
              <p id="next-invoice-row" class="left-right-aligner mb-0" *ngIf="updateSubscriptionService.estimate.next_invoice_estimate">
                <span class="font-weight-bold">Next invoice on {{updateSubscriptionService.estimate.subscription_estimate?.next_billing_at * 1000 | date:'d-MMM-yyyy'}}:</span>
                <span>
                  <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimate.next_invoice_estimate?.currency_code}}</span>
                  <span class="purchase-total">{{updateSubscriptionService.estimate.next_invoice_estimate?.total/100 | currency }}</span>
                </span>
              </p>
            </div>

          </div>

          <div class="row u_margin-md-top mb-5">
            <div class="col-md-4 mb-2">
              <button id="backButton" type="button" aria-label="Go back" class="btn btn-default btn-hg btn-block" (click)="close()">Cancel</button>
            </div>

            <div class="col-md-8">
              <button id="payButton" type="submit" class="btn btn-primary btn-hg btn-block" tabindex="1" aria-label="Complete Payment" [disabled]="purchaseLicensesForm.invalid || billingPeriod === 'monthly'" *ngIf="updateSubscriptionService.estimate.invoice_estimate?.amount_due > 0">
                <span id="invoiceLabel">Pay {{updateSubscriptionService.estimate.invoice_estimate?.amount_due/100 | currency }} Now</span>
              </button>
              <button id="updateButton" type="submit" class="btn btn-primary btn-hg btn-block" tabindex="1" aria-label="Update Subscription" [disabled]="purchaseLicensesForm.invalid || billingPeriod === 'monthly'" *ngIf="!updateSubscriptionService.estimate.invoice_estimate?.amount_due">
                <span id="invoiceLabel">Update Subscription</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <update-subscription-success [purchaseAction]="purchaseAction" (closeEvent)="close()" [shown]="updateSubscriptionService.purchase?.completed"></update-subscription-success>
  </div>
  <div class="mt-5 border-top">
    <purchase-footer></purchase-footer>
  </div>

</div>
