import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { TagInputModule } from 'ngx-chips';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UIRouterModule } from '@uirouter/angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { SharedModule } from '../shared/shared.module';

import { ModalService } from './modals/modal.service';
import { MessageBoxComponent } from './modals/message-box/message-box.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { LastModifiedComponent } from './last-modified/last-modified/last-modified.component';
import { LastRevisedComponent } from './last-modified/last-revised/last-revised.component';
import { StreamlineIconComponent } from './streamline-icon/streamline-icon.component';
import { StretchyInputComponent } from './stretchy-input/stretchy-input.component';
import { RvSpinnerDirective } from './loading/rv-spinner.directive';
import { LoadingService } from './loading/loading.service';
import { TrackerService } from './logging/tracker.service';
import { PopupTimePickerComponent } from './time-date/popup-time-picker/popup-time-picker.component';
import { PopupDatePickerComponent } from './time-date/popup-date-picker/popup-date-picker.component';
import { MaderoCheckboxComponent } from './madero-checkbox/madero-checkbox.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { ScrollingListDirective } from './scrolling-list/scrolling-list.directive';
import { BatchOperationsComponent } from './scrolling-list/batch-operations/batch-operations.component';
import { FocusMeDirective } from './focus-me/focus-me.directive';
import { TimelineTextboxComponent } from './timeline/timeline-textbox/timeline-textbox.component';
import { SuggestGeneralDeliveryComponent } from './suggest-general-delivery/suggest-general-delivery.component';
import { EmailsFieldComponent } from './emails-field/emails-field.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { BackgroundImageComponent } from './background-image/background-image.component';
import { DistributionSelectorComponent } from './distribution/distribution-selector/distribution-selector.component';
import { BetaBadgeComponent } from './beta-badge/beta-badge.component';
import { CompanyIdModalComponent } from './scrolling-list/company-id-modal/company-id-modal.component';
import { BulkDeleteConfirmationComponent } from './scrolling-list/bulk-delete-confirmation/bulk-delete-confirmation.component';
import { DistributionListComponent } from './distribution/distribution-list/distribution-list.component';
import { DistributionModalComponent } from './distribution/distribution-modal/distribution-modal.component';
import { TimelineModalComponent } from './timeline/timeline-modal/timeline-modal.component';
import { MonthDropdownComponent } from './timeline/month-dropdown/month-dropdown.component';
import { WeekdayDropdownComponent } from './timeline/weekday-dropdown/weekday-dropdown.component';
import { WeekDropdownComponent } from './timeline/week-dropdown/week-dropdown.component';
import { TimelineService } from './timeline/timeline.service';
import { DateEndAfterStartDirective } from './timeline/date-end-after-start.directive';
import { TimelineDescriptionService } from './timeline/timeline-description.service';
import { UnlockFeatureComponent } from './plans/unlock-feature/unlock-feature.component';
import { CurrentPlanService } from './plans/current-plan.service';
import { FeaturesService } from './plans/features.service';
import { UpgradePlanComponent } from './plans/upgrade-plan/upgrade-plan.component';
import { TimelineBasicModalComponent } from './timeline/timeline-basic-modal/timeline-basic-modal.component';
import { PickTimeRangeComponent } from './timeline/pick-time-range/pick-time-range.component';
import { WeekdayCheckboxesComponent } from './timeline/weekday-checkboxes/weekday-checkboxes.component';
import { TimelineBasicTextboxComponent } from './timeline/timeline-basic-textbox/timeline-basic-textbox.component';
import { YesNoToggleComponent } from './yes-no-toggle/yes-no-toggle.component';
import { CalendarMonthYearHeaderComponent } from './calendar-month-year-header/calendar-month-year-header.component';
import { BulkEditModalComponent } from './modals/bulk-edit-modal/bulk-edit-modal.component';
import { NumberInputComponent } from './number-input/number-input.component';

@NgModule({
  declarations: [
    MessageBoxComponent,
    ColorPickerComponent,
    ConfirmModalComponent,
    LastModifiedComponent,
    LastRevisedComponent,
    StreamlineIconComponent,
    StretchyInputComponent,
    RvSpinnerDirective,
    PopupTimePickerComponent,
    PopupDatePickerComponent,
    MaderoCheckboxComponent,
    SearchFilterComponent,
    ScrollingListDirective,
    BatchOperationsComponent,
    FocusMeDirective,
    TimelineTextboxComponent,
    SuggestGeneralDeliveryComponent,
    EmailsFieldComponent,
    PasswordInputComponent,
    SvgIconComponent,
    BackgroundImageComponent,
    DistributionSelectorComponent,
    BetaBadgeComponent,
    CompanyIdModalComponent,
    BulkDeleteConfirmationComponent,
    DistributionListComponent,
    DistributionModalComponent,
    TimelineModalComponent,
    MonthDropdownComponent,
    WeekdayDropdownComponent,
    WeekDropdownComponent,
    DateEndAfterStartDirective,
    UnlockFeatureComponent,
    UpgradePlanComponent,
    TimelineBasicModalComponent,
    PickTimeRangeComponent,
    WeekdayCheckboxesComponent,
    TimelineBasicTextboxComponent,
    YesNoToggleComponent,
    CalendarMonthYearHeaderComponent,
    BulkEditModalComponent,
    NumberInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    ProgressbarModule,
    TooltipModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    SharedModule,
    UIRouterModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    ColorPickerComponent,
    LastModifiedComponent,
    LastRevisedComponent,
    StreamlineIconComponent,
    StretchyInputComponent,
    RvSpinnerDirective,
    PopupTimePickerComponent,
    PopupDatePickerComponent,
    MaderoCheckboxComponent,
    SearchFilterComponent,
    ScrollingListDirective,
    BatchOperationsComponent,
    FocusMeDirective,
    TimelineTextboxComponent,
    SuggestGeneralDeliveryComponent,
    EmailsFieldComponent,
    PasswordInputComponent,
    SvgIconComponent,
    BackgroundImageComponent,
    DistributionSelectorComponent,
    BetaBadgeComponent,
    DistributionSelectorComponent,
    YesNoToggleComponent,
    TimelineBasicTextboxComponent,
    NumberInputComponent
  ]
})
export class ComponentsModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [ MessageBoxComponent, ConfirmModalComponent, LastModifiedComponent, StreamlineIconComponent, SearchFilterComponent, SuggestGeneralDeliveryComponent, SvgIconComponent, DistributionSelectorComponent ]
  static providers = [ ModalService, LoadingService, TrackerService, TimelineService, TimelineDescriptionService, CurrentPlanService, FeaturesService ]
}
