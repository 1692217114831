import { Injectable } from '@angular/core';
import { UserState, CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { DisplayActivationService } from 'src/app/displays/services/display-activation.service';

@Injectable({
  providedIn: 'root'
})
export class DisplayApiService {

  static readonly DISPLAY_SEARCH_FIELDS = ['name', 'id'];

  constructor(
    private broadcaster: BroadcasterService,
    private coreAPILoader: CoreAPILoader,
    private userState: UserState,
    private displayActivationService: DisplayActivationService) { }

  list (search, cursor?) {
    const obj = {
      'companyId': this.userState.getSelectedCompanyId(),
      'search': search.query || '*',
      'filter': '',
      'includeSubcompanies': search.includeSubcompanies || false,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };
    console.debug('list displays called with', obj);

    return this.coreAPILoader().then(coreApi => {
      return coreApi.display.list(obj);
    })
    .then(resp => {
      let result = resp.result;
      result.items && result.items.forEach(function (item) {
        item.lastActivityDate = item.onlineStatus === 'online' ? new Date() : (item
          .lastActivityDate ? new Date(item.lastActivityDate) : '');
      });

      this.broadcaster.emitWithParams('displaysLoaded', result.items);
      this.displayActivationService.track(result.items);

      return resp.result;
    })
    .catch(e => {
      console.error('Failed to get list of displays.', e);
      throw e;
    });
  }
}