import { Injectable } from '@angular/core';

import { UserState, StoreAuthorizationService } from 'src/app/ajs-upgraded-providers';

import { BlueprintService } from './blueprint.service';
import { ModalService } from 'src/app/components/modals/modal.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FinancialLicenseService {
  private NEED_FINANCIAL_DATA_LICENSE = ['rise-data-financial'];
  private CONTACT_US_URL = 'https://www.risevision.com/contact-us?form_selected=sales&content_hide=true';

  private FINANCIAL_DATA_LICENSE_PLAN = '356ab5e0541a41e96e4ef0b45ecac9f72af454ac';

  private isSubscribedPromise;
  private isSubscribedCompany;

  constructor(private modalService: ModalService,
    private blueprintFactory: BlueprintService,
    private userState: UserState,
    private storeAuthorization: StoreAuthorizationService) { }

  needsFinancialDataLicense() {
    if (!this.blueprintFactory.blueprintData) {
      return false;
    }

    return _.some(this.blueprintFactory.blueprintData.components, component => {
      return _.includes(this.NEED_FINANCIAL_DATA_LICENSE, component.type);
    });
  }

  hasFinancialDataLicense() {
    if (this.isSubscribedPromise && this.isSubscribedCompany === this.userState.getSelectedCompanyId()) {
      return this.isSubscribedPromise;
    }

    this.isSubscribedCompany = this.userState.getSelectedCompanyId();
    this.isSubscribedPromise = this.storeAuthorization.check(this.FINANCIAL_DATA_LICENSE_PLAN)
      .then(() => {
        return true;
      })
      .catch((result) => {
        if (result !== false) {
          // Failed to retrieve results; invalidate promise
          this.isSubscribedCompany = null;
        }
        return false;
      })

    return this.isSubscribedPromise;
  }

  showFinancialDataLicenseRequiredMessage() {
    this.modalService.confirm('Financial Data License Required',
      'This Presentation will only show on Display(s) that are assigned a Financial Data License. Please contact <a href="mailto:sales@risevision.com">sales@risevision.com</a> to enquire about our 30 day free trial.',
      'Get a 30 Day Free Trial',
      'Close'
    ).then(() => {
      window.open(this.CONTACT_US_URL, '_blank');
    });
  }

  show() {
    if (this.needsFinancialDataLicense()) {
      this.hasFinancialDataLicense()
        .then((result) => {
          if (!result) {
            this.showFinancialDataLicenseRequiredMessage();
          }
        });
    }
  }

}