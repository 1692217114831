import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';

import { UserState, CompanyService, ProcessErrorCode, AjsState } from 'src/app/ajs-upgraded-providers';

import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { MoveCompanyComponent } from '../move-company/move-company.component';

@Component({
  selector: 'company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.scss']
})
export class CompanyAddComponent {

  company = {};
  loading = false;
  formError = null;
  apiError = null;
  isAddressError = false;

  @ViewChild('companyForm')
  public companyForm: NgForm;

  constructor(private _location: Location,
    private $state: AjsState,
    private modalService: ModalService,
    private userState: UserState,
    private companyService: CompanyService,
    private addressFactory: AddressService,
    private tracker: TrackerService,
    private processErrorCode: ProcessErrorCode
  ) { }

  save() {
    this._clearErrorMessages();

    if (!this.companyForm.valid) {
      console.log('Form not valid');
    } else {
      this.loading = true;

      this.addressFactory.validateAddressIfChanged(this.companyForm.form.controls, this.company)
        .then(() => {
          this.companyService.createCompany(this.userState.getSelectedCompanyId(), this.company)
            .then((company) => {
              this.tracker.companyEvent('Company Created', company.id, company.name);

              this.userState.switchCompany(company.id).then(() => {
                this.$state.go('apps.company.details', {cid: company.id});
              });

            })
            .catch((err) => {
              this.formError = 'Failed to Add Sub-Company.';
              this.apiError = this.processErrorCode(err);
            })
            .finally(() => {
              this.loading = false;
            });

        })
        .catch((error) => {
          this.loading = false;

          this.formError = 'We couldn\'t update your address.';
          this.apiError = this.processErrorCode(error);
          this.isAddressError = true;
        });
    }
  }

  // Show Move Company Modal
  moveCompany() {
    return this.modalService.showMediumModal(MoveCompanyComponent);
  }

  _clearErrorMessages() {
    this.formError = null;
    this.apiError = null;
    this.isAddressError = false;
  }

  cancel() {
    this._location.back();
  }

}
