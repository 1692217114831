import { Injectable } from '@angular/core';
import { ComponentsService, PlaylistComponentInfo, PlaylistComponentItem } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { MediaSelectorService } from './media-selector.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { AnalyticsFactory, UserState } from 'src/app/ajs-upgraded-providers';

export type BasePlaylistTemplateItem = {
  id?: string;
  label?: string;
  tagName: string;
};

@Injectable({
  providedIn: 'root'
})
export class BasePlaylistService {
  playlistComponents: PlaylistComponentItem[] = ComponentsService.PLAYLIST_COMPONENTS;
  playlistItems: any[] = [];
  componentId: string;

  constructor(
    protected componentsService: ComponentsService,
    protected mediaSelectorService: MediaSelectorService,
    protected templateEditorService: TemplateEditorService,
    protected attributeDataService: AttributeDataService,
    protected analyticsFactory: AnalyticsFactory,
    protected userState: UserState
  ) { }

  protected getComponentObject(item: BasePlaylistTemplateItem): PlaylistComponentInfo {
    return {
      type: item.tagName,
      label: item.label,
      id: this.componentId + ' ' + this.playlistItems.indexOf(item)
    };
  }

  protected safeParseJson(string: string): any {
    try {
      return JSON.parse(string);
    } catch (err) {
      console.error(err, string);
      return null;
    }
  }

  addItem(type: string, options?: any): void {}

  getComponentName(item: BasePlaylistTemplateItem) {
    return this.componentsService.getComponentName(this.getComponentObject(item));
  }

  getComponentIcon(item: BasePlaylistTemplateItem) {
    return this.componentsService.getComponentIcon(this.getComponentObject(item));
  }

  getComponentByType(type: string): PlaylistComponentItem {
    return ComponentsService.COMPONENTS_ARRAY.find((item) => {
      return item.type === type;
    }) || {} as PlaylistComponentItem;
  }

  editComponent(item: BasePlaylistTemplateItem, playlist: boolean = false) {
    this.componentsService.editComponent(this.getComponentObject(item), { playlist });
  }

  reset() {
    this.componentId = '';
    this.playlistItems = [];
    this.playlistComponents = ComponentsService.PLAYLIST_COMPONENTS;
  }

  showMediaSelector(options?: any) {
    this.mediaSelectorService.onMediaUpdate = null;
    this.mediaSelectorService.onUserComponentAdd = null;

    var fileType = (options && 'fileType' in options) ? options.fileType : null;
    var singleFile = (options && 'singleFile' in options) ? options.singleFile : false;

    this.componentsService.editComponent({
      type: 'rise-media-selector'
    }, {
      fileType,
      singleFile
    });
  }
}
