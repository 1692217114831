import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { StoreAPILoader, UserState } from 'src/app/ajs-upgraded-providers';
import { AddressUtilsService } from 'src/app/shared/services/address-utils.service';

@Injectable({
  providedIn: 'root'
})
export class BillingApiService {

  private INVOICE_WRITABLE_FIELDS = [
    'poNumber'
  ];

  constructor(
    private userState: UserState,
    private addressUtils: AddressUtilsService,
    private storeApiLoader: StoreAPILoader) { }

  getSubscriptions(search, cursor?) {
    var params = {
      'companyId': this.userState.getSelectedCompanyId(),
      'cursor': cursor,
      'count': search.count
    };

    console.debug('Store integrations.subscription.list called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.subscription.list(params);
      })
      .then((resp) => {
        console.debug('integrations.subscription.list resp', resp);

        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get company\'s subscriptions.', e);
        return Promise.reject(e);
      });
  }
  getSubscription(subscriptionId, withScheduledChanges?) {
    var params = {
      'subscriptionId': subscriptionId,
      'companyId': this.userState.getSelectedCompanyId(),
      'withScheduledChanges': !!withScheduledChanges
    };

    console.debug('Store integrations.subscription.get called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.subscription.get(params);
      })
      .then((resp) => {
        console.debug('integrations.subscription.get resp', resp);

        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get subscription.', e);
        return Promise.reject(e);
      });
  }
  estimateSubscriptionUpdate(displayCount, subscriptionId, planId, companyId, couponCode, endOfTerm) {
    let billingAddress = this.addressUtils.copyAddress(this.userState.getCopyOfSelectedCompany());
    let items = [{
      subscriptionId: subscriptionId,
      id: planId,
      qty: displayCount
    }];
    var jsonData = {
      billTo: billingAddress,
      shipTo: billingAddress,
      couponCode: couponCode,
      endOfTerm: endOfTerm,
      items
    };
    let obj = {
      jsonData: JSON.stringify(jsonData)
    };

    console.debug('Store purchase.estimate called with', jsonData);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.purchase.estimate(obj);
      })
      .then((resp) => {
        console.debug('purchase.estimate resp', resp);
        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to retrieve subscription estimate.', e);
        return Promise.reject(e);
      });
  }
  prepareUpdateSubscription(displayCount, subscriptionId, planId, companyId, couponCode, endOfTerm) {
    let billingAddress = this.addressUtils.copyAddress(this.userState.getCopyOfSelectedCompany());
    let items = [{
      subscriptionId: subscriptionId,
      companyId: companyId,
      id: planId,
      qty: displayCount
    }];
    var jsonData = {
      billTo: billingAddress,
      shipTo: billingAddress,
      couponCode: couponCode,
      endOfTerm: endOfTerm,
      items
    };
    let obj = {
      jsonData: JSON.stringify(jsonData)
    };

    console.debug('Store purchase.prepare called with', obj);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.purchase.prepare(obj);
      })
      .then((resp) => {
        console.debug('purchase.prepare resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to retrieve subscription prepare.', e);
        return Promise.reject(e);
      });
  }
  updateSubscription(displayCount, subscriptionId, planId, companyId, couponCode, endOfTerm, paymentIntentId) {
    let billingAddress = this.addressUtils.copyAddress(this.userState.getCopyOfSelectedCompany());
    let items = [{
      subscriptionId: subscriptionId,
      companyId: companyId,
      id: planId,
      qty: displayCount
    }];
    var jsonData = {
      billTo: billingAddress,
      shipTo: billingAddress,
      couponCode: couponCode,
      endOfTerm: endOfTerm,
      paymentIntentId: paymentIntentId,
      items
    };
    let obj = {
      jsonData: JSON.stringify(jsonData)
    };

    console.debug('Store purchase.update called with', obj);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.purchase.put(obj);
      })
      .then((resp) => {
        console.debug('purchase.put resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to retrieve subscription update.', e);
        return Promise.reject(e);
      });
  }
  changePoNumber(subscriptionId, poNumber) {
    var params = {
      'subscriptionId': subscriptionId,
      'poNumber': poNumber,
      'companyId': this.userState.getSelectedCompanyId()
    };

    console.debug('Store integrations.subscription.changePoNumber called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.subscription.changePoNumber(params);
      })
      .then((resp) => {
        console.debug('integrations.subscription.changePoNumber resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to change po number for the subscription.', e);
        return Promise.reject(e);
      });
  }
  changePaymentSource(subscriptionId, paymentSourceId) {
    var params = {
      'subscriptionId': subscriptionId,
      'paymentSourceId': paymentSourceId,
      'companyId': this.userState.getSelectedCompanyId()
    };

    console.debug('Store integrations.subscription.changePaymentSource called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.subscription.changePaymentSource(params);
      })
      .then((resp) => {
        console.debug('integrations.subscription.changePaymentSource resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to change payment source for the subscription.', e);
        return Promise.reject(e);
      });
  }
  changePaymentToInvoice(subscriptionId, poNumber) {
    var params = {
      'subscriptionId': subscriptionId,
      'poNumber': poNumber,
      'companyId': this.userState.getSelectedCompanyId()
    };

    console.debug('Store integrations.subscription.changePaymentToInvoice called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.subscription.changePaymentToInvoice(params);
      })
      .then((resp) => {
        console.debug('integrations.subscription.changePaymentToInvoice resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to change payment to invoice for the subscription.', e);
        return Promise.reject(e);
      });
  }
  getInvoices(search, cursor?) {
    var params = {
      'companyId': this.userState.getSelectedCompanyId(),
      'cursor': cursor,
      'count': search.count
    };

    console.debug('Store integrations.invoice.list called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.invoice.list(params);
      })
      .then((resp) => {
        console.debug('integrations.invoice.list resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to get company\'s invoices.', e);
        return Promise.reject(e);
      });
  }
  getUnpaidInvoices(search, cursor?) {
    var params = {
      'companyId': search.companyId,
      'token': search.token,
      'cursor': cursor,
      'count': search.count
    };

    console.debug('Store integrations.invoice.listUnpaid called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.invoice.listUnpaid(params);
      })
      .then((resp) => {
        console.debug('integrations.invoice.listUnpaid resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to get company\'s unpaid invoices.', e);
        return Promise.reject(e);
      });
  }
  getInvoice(invoiceId, companyId, token) {
    var params = {
      'companyId': companyId,
      'invoiceId': invoiceId,
      'token': token
    };

    console.debug('Store integrations.invoice.get called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.invoice.get(params);
      })
      .then((resp) => {
        console.debug('integrations.invoice.get resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to get invoice.', e);
        return Promise.reject(e);
      });
  }
  updateInvoice(invoice, companyId, token) {
    var fields = _.pick(invoice, this.INVOICE_WRITABLE_FIELDS);
    var params = {
      'companyId': companyId,
      'invoiceId': invoice.id,
      'token': token,
      'data': fields
    };

    console.debug('Store integrations.invoice.put called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.invoice.put(params);
      })
      .then((resp) => {
        console.debug('integrations.invoice.put resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to update invoice.', e);
        return Promise.reject(e);
      });
  }
  getInvoicePdf(invoiceId, companyId, token) {
    var params = {
      'companyId': companyId,
      'invoiceId': invoiceId,
      'token': token
    };

    console.debug('Store integrations.invoice.getPdf called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.invoice.getPdf(params);
      })
      .then((resp) => {
        console.debug('integrations.invoice.getPdf resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to get invoice PDF.', e);
        return Promise.reject(e);
      });
  }
  getCreditCards(search, cursor?) {
    var params = {
      'companyId': this.userState.getSelectedCompanyId(),
      'cursor': cursor,
      'count': search.count
    };

    console.debug('Store integrations.card.list called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.card.list(params);
      })
      .then((resp) => {
        console.debug('integrations.card.list resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to get company\'s cards.', e);
        return Promise.reject(e);
      });
  }
  preparePaymentSource(paymentMethodId) {
    var params = {
      'paymentMethodId': paymentMethodId,
      'companyId': this.userState.getSelectedCompanyId()
    };

    console.debug('Store integrations.paymentSource.prepare called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.paymentSource.prepare(params);
      })
      .then((resp) => {
        console.debug('integrations.paymentSource.prepare resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to prepare payment source.', e);
        return Promise.reject(e);
      });
  }
  addPaymentSource(setupIntentId) {
    var params = {
      'setupIntentId': setupIntentId,
      'companyId': this.userState.getSelectedCompanyId()
    };

    console.debug('Store integrations.paymentSource.add called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.paymentSource.add(params);
      })
      .then((resp) => {
        console.debug('integrations.paymentSource.add resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to add payment source.', e);
        return Promise.reject(e);
      });
  }
  deletePaymentSource(paymentSourceId) {
    var params = {
      'paymentSourceId': paymentSourceId,
      'companyId': this.userState.getSelectedCompanyId()
    };

    console.debug('Store integrations.paymentSource.delete called with', params);

    return this.storeApiLoader()
      .then(function (storeApi) {
        return storeApi.integrations.paymentSource.delete(params);
      })
      .then((resp) => {
        console.debug('integrations.paymentSource.delete resp', resp);

        return Promise.resolve(resp.result);
      })
      .catch((e) => {
        console.error('Failed to delete payment source.', e);
        return Promise.reject(e);
      });
  }

}
