import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Transition } from '@uirouter/angular';

import { ScrollingListService, CompanyService, UserState } from 'src/app/ajs-upgraded-providers';
import { CompanyManagementService } from '../../services/company-management.service';
import type { CompanyTreeItem } from '../../services/company';
import { CompanyAllocationService } from '../../services/company-allocation.service';
import { NgForm } from '@angular/forms';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

@Component({
  selector: 'company-licenses',
  templateUrl: './company-licenses.component.html',
  styleUrls: ['./company-licenses.component.scss']
})
export class CompanyLicensesComponent implements OnInit {

  filterConfig = {
    placeholder: 'Search Companies'
  };

  search = {
    companyId: this.userState.getSelectedCompanyId(),
    sortBy: 'name',
    reverse: false,
    name: 'Companies',
    onListUpdate: (newItems) => {
      this.companyManagementService.createCompanyTree(this.companyTree, newItems);
      this.companyManagementService.loadSubcompanyTree(this.search.companyId, newItems.map((item) => item.id).join(','), this.companyTree);
    }
  };

  companies = this.ScrollingListService(this.companyService.getCompanies, this.search);
  selectedCompany = this.userState.getCopyOfSelectedCompany();
  companyTree: CompanyTreeItem[] = [];
  boundSearch: Function;
  saving: boolean;

  constructor(
    private companyManagementService: CompanyManagementService,
    public companyAllocationService: CompanyAllocationService,
    public currentPlanService: CurrentPlanService,
    private ScrollingListService: ScrollingListService,
    private companyService: CompanyService,
    private userState: UserState,
    public location: Location,
    private transition: Transition
  ) { }

  ngOnInit(): void {
    this.boundSearch = this.doSearch.bind(this);
    this.companyAllocationService.init();
  }

  setCompany(companyId: string): void {
    this.companyTree = [];
    this.companies.loadingItems = true;
    this.userState.switchCompany(companyId);
  }

  isSubShown(parent: CompanyTreeItem): boolean {
    while(parent) {
      if (!parent.showSubs) {
        return false;
      }
      parent = parent.parent;
    }
    return true;
  }

  isLast(companyIndex: number): boolean {
    for (let i = companyIndex + 1; i < this.companyTree.length; i++) {
      if (this.companyTree[i].level === 0) {
        return false;
      }
    }
    return true;
  }

  doSearch() {
    this.companyTree = [];
    this.companies.doSearch();
  }

  save(ngForm: NgForm) {
    this.saving = true;
    this.companyAllocationService.saveAllocations(this.selectedCompany.licenseAllocationEnabled)
      .then(() => {
        const requiredEnterprise = this.companyAllocationService.getRequiredLicenses('enterprise');
        const requiredUnlimited = this.companyAllocationService.getRequiredLicenses('unlimited');
        if (requiredEnterprise + requiredUnlimited > 0) {
          if (this.currentPlanService.isOnTrial()) {
            this.transition.router.stateService.go('apps.purchase.home', {
              enterprise: requiredEnterprise,
              unlimited: requiredUnlimited
            });
          }
        } else {
          ngForm.form.markAsPristine();
        }
      })
      .finally(() => {
        this.saving = false;
      });
  }

}
