import { Component, ViewChildren, ElementRef, NgZone, Input, OnInit } from '@angular/core';

import { TemplateEditorService } from '../../services/template-editor.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { BlueprintService } from '../../services/blueprint.service';
import { ComponentsService } from '../../services/components.service';
import { MediaSelectorService } from '../../template-components/services/media-selector.service';
import { UserComponentsService } from '../../template-components/services/user-components.service';
import { FileExistenceCheckService } from '../../template-components/services/file-existence-check.service';
import { LogoImageService } from '../../template-components/services/logo-image.service';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'template-attribute-components',
  templateUrl: './template-attribute-components.component.html',
  styleUrls: ['./template-attribute-components.component.scss']
})
export class TemplateAttributeComponentsComponent implements OnInit {
  private _baseComponents = [];

  @Input() isUserComponents: boolean = false;

  get components() {
    if (this.isUserComponents) {
      return this.userComponentsService.playlistItems;
    }

    return this._baseComponents;
  }

  @ViewChildren('ComponentName') componentNameInputs: ElementRef[];

  constructor(public templateEditorFactory: TemplateEditorService,
    public userComponentsService: UserComponentsService,
    public componentsService: ComponentsService,
    private blueprintService: BlueprintService,
    private attributeDataService: AttributeDataService,
    private analyticsFactory: AnalyticsFactory,
    private fileExistenceCheckService: FileExistenceCheckService,
    private ngZone: NgZone)
  { }

  ngOnInit(): void {
    this._baseComponents = this.blueprintService.blueprintData.components
      .filter(c => {
        return !c.nonEditable;
      });

    if (this.isUserComponents) {
      this._loadUserComponents();
    }
  }

  private _loadUserComponents() {
    this.userComponentsService.playlistItems = cloneDeep(this.attributeDataService.getUserComponents());

    const fileNames = this.userComponentsService.playlistItems
      .filter((item) => item?.type === MediaSelectorService.FOLDER_TYPE.STORAGE_FILE)
      .map((file) => String(file.source));

    this.fileExistenceCheckService.requestMetadataFor(fileNames, LogoImageService.DEFAULT_IMAGE_THUMBNAIL).then((metadata) => {
      metadata.forEach((meta) => {
        if (!meta.exists) {
          this._setFileMissing(meta.file, true);
        }
      });
    });
  }

  private _findComponentNameInput(key: string): ElementRef {
    return this.componentNameInputs.find((el) => el.nativeElement.id === `input-component-name-${key}`);
  }

  private _setFileMissing (source, value) {
    const file = this.userComponentsService.playlistItems.find((item) => item?.source === source);
    if (file) {
      file.fileMissing = value;
    }
  }

  getDisplayName(component: any): string {
    if (this.isUserComponents) {
      return this.attributeDataService.getAttributeData(component.id, 'editorLabel') || this.userComponentsService.getComponentName(component);
    }

    return component ? this.attributeDataService.getAvailableAttributeData(component.id, 'editorLabel') || component.label : '';
  }

  getComponentIcon(component: any) {
    if (this.isUserComponents) {
      return this.userComponentsService.getComponentIcon(component);
    }

    return this.componentsService.getComponentIcon(component);
  }

  getComponentVisibility(component: any): boolean {
    const visibility = this.attributeDataService.getCustomization(component.id, 'visibility');
    const hidden = visibility === false;
    return !hidden;
  }

  setComponentVisibility(component: any, visible: boolean): void {
    this.attributeDataService.setCustomization(component.id, 'visibility', visible);
  }

  editComponent(comp, key) {
    if (!this.isUserComponents) {
      this.componentsService.editComponent(comp);
      return;
    }

    const item = this.userComponentsService.playlistItems[key];

    this.userComponentsService.editComponent(item);
  }

  isDataComponent(component: any): boolean {
    return component.type && component.type.toLowerCase().includes('rise-data-');
  }

  itemNameKeyup(key: string, event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      const input = this._findComponentNameInput(key).nativeElement;
      const comp = this.isUserComponents ? this.userComponentsService.playlistItems[key] : this.components[key];
      input.value = this.getDisplayName(comp);
      input.blur();
      this.ngZone.run(() => {});
    } else if (event.key === 'Enter') {
      this._findComponentNameInput(key).nativeElement.blur();
      this.ngZone.run(() => {});
    }
  }

  saveItemName(key: string, event: Event): void {
    const item = this.isUserComponents ? this.userComponentsService.playlistItems[key] : this.components[key];
    const value = (<HTMLInputElement>event.target).value.trim();

    if (value !== this.getDisplayName(item)) {
      item.editorLabel = value;
      this.attributeDataService.setAttributeData(item.id, 'editorLabel', value);
    }
    item.editing = false;
  }

  renameComponent(key: string): void {
    if (this.isUserComponents) {
      this.userComponentsService.playlistItems[key].editing = true;
    } else {
      this.components[key].editing = true;
    }

    const input = this._findComponentNameInput(key);
    setTimeout(() => {
      input.nativeElement.focus();
    });
  }

  removeComponent(key) {
    if (!this.isUserComponents) {
      return;
    }

    const item = this.userComponentsService.playlistItems[key];
    const type = item.tagName;

    this.userComponentsService.playlistItems.splice(key, 1);

    this.attributeDataService.removeUserComponent(item);

    this.analyticsFactory.track('User Component Removed', {
      componentType: type,
    });
  }

  sortItem(evt) {
    if (!this.isUserComponents) {
      return;
    }

    this.userComponentsService.playlistItems.splice(evt.data.newIndex, 0, this.userComponentsService.playlistItems.splice(evt.data.oldIndex, 1)[0]);

    this.attributeDataService.reorderUserComponents(this.userComponentsService.playlistItems.map((item) => item.id));
  }
}
