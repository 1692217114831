import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { UserState, AnalyticsFactory, BigQueryLogging } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {
  constructor(
    private userState: UserState,
    private analyticsFactory: AnalyticsFactory,
    private bigQueryLogging: BigQueryLogging,
  ) { }

  // companyTracker
  COMPANY_EVENTS_TO_BQ = [
    'Company Created'
  ];

  companyEvent(eventName?, companyId?, companyName?, isUserCompany?) {
    if (eventName) {
      this.analyticsFactory.track(eventName, {
        companyId: companyId,
        companyName: companyName,
        isUserCompany: isUserCompany
      });
      if (this.COMPANY_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, companyName, null,
          this.userState.getUsername(), companyId);
      }
    }
  }

  // userTracker
  USER_EVENTS_TO_BQ = [];

  userEvent(eventName?, userId?, isSelf?, extraProperties?) {
    if (eventName) {
      var properties = extraProperties || {};
      properties.userId = userId;
      properties.companyId = this.userState.getSelectedCompanyId();
      properties.isSelf = isSelf;

      this.analyticsFactory.track(eventName, properties);
      if (this.USER_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName);
      }
    }
  }

  // presentationTracker
  PRESENTATION_EVENTS_TO_BQ = [
    'Presentation Created',
    'New Presentation',
    'Template Copied',
    'HTML Template Copied'
  ];

  presentationEvent(eventName?, presentationId?, presentationName?, extraProperties?) {
    if (eventName) {
      var properties = extraProperties || {};
      properties.presentationId = presentationId;
      properties.presentationName = presentationName;
      properties.companyId = this.userState.getSelectedCompanyId();

      this.analyticsFactory.track(eventName, properties);
      if (this.PRESENTATION_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, presentationId);
      }
    }
  }

  // scheduleTracker
  SCHEDULE_EVENTS_TO_BQ = [
    'Schedule Created'
  ];

  scheduleEvent(eventName?, scheduleId?, scheduleName?, extraProperties?) {
    if (eventName) {
      var properties = extraProperties || {};
      properties.scheduleId = scheduleId;
      properties.scheduleName = scheduleName;
      properties.companyId = this.userState.getSelectedCompanyId();

      this.analyticsFactory.track(eventName, properties);

      if (this.SCHEDULE_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, scheduleId);
      }
    }
  }

  // displayTracker
  DISPLAY_EVENTS_TO_BQ = [
    'Display Created'
  ];

  displayEvent(eventName?, displayId?, displayName?, eventProperties?) {
    if (eventName) {
      eventProperties = eventProperties || {};
      angular.extend(eventProperties, {
        displayId: displayId,
        displayName: displayName,
        userId: this.userState.getUsername(),
        email: this.userState.getUserEmail(),
        companyId: this.userState.getSelectedCompanyId(),
        companyName: this.userState.getSelectedCompanyName()
      });

      this.analyticsFactory.track(eventName, eventProperties);

      if (this.DISPLAY_EVENTS_TO_BQ.indexOf(eventName) !== -1) {
        this.bigQueryLogging.logEvent(eventName, displayId);
      }
    }
  }

}

angular.module('risevision.common.components')
  .factory('trackerFactory', downgradeInjectable(TrackerService));
