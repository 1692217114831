import { Injectable } from '@angular/core';
import { UserState, CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import type { CompanyLicenseInfo } from './company-license-info';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {

  constructor(private coreAPILoader: CoreAPILoader,
    private userState: UserState)
  {
  }

  getLicenseAllocationInfo (): Promise<{ item: { licenseInfo: CompanyLicenseInfo } }> {
    const obj = {
      'id': this.userState.getSelectedCompanyId()
    };

    console.debug('getLicenseAllocationInfo called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.company.getLicenseAllocationInfo(obj);
      })
      .then((resp) => {
        console.debug('getLicenseAllocationInfo resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to getLicenseAllocationInfo.', e);
        throw e;
      });
  }

  allocateLicenses (companyLicenses: { [key: string]: any }, licenseAllocationEnabled?: boolean): Promise<any> {
    const allocations = {};
    for (const [key, value] of Object.entries(companyLicenses)) {
      allocations[key] = {
        allocatedProductCode: value.allocatedProductCode,
        allocatedLicenseCount: value.allocatedLicenseCount
      };
    }

    const obj = {
      id: this.userState.getSelectedCompanyId(),
      licenseAllocationEnabled,
      data: {
        allocationData: JSON.stringify({
          data: allocations
        })
      }
    };

    console.debug('allocateLicenses called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.company.allocateLicenses(obj);
      })
      .then((resp) => {
        console.debug('allocateLicenses resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to allocateLicenses.', e);
        throw e;
      });
  }

}