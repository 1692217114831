<div class="attribute-editor-component youtube-component-container">

  <div class="attribute-editor-row">
    <form>
      <div class="form-group has-feedback" [ngClass]="{ 'has-error': validationResult && validationResult !== 'VALID', 'has-success': validationResult === 'VALID' && source !== '' }">
        <label class="control-label" for="te-youtube-src">Source:</label>
        <input type="text" id="te-youtube-src" name="te-youtube-src" class="form-control u_ellipsis" [(ngModel)]="source" placeholder="Enter URL" (debounceEvent)="saveSource()" >
        <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{validationResult && validationResult !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
        <p class="help-block" [ngSwitch]="validationResult">
          <span *ngSwitchCase="'INVALID'">Please provide a valid YouTube URL.</span>
        </p>
      </div>
    </form>
  </div>

  <div class="attribute-editor-row">
    <div id="youtube-component-volume">
      <label>Volume:</label>
      <div class="row">
        <div class="col-xs-12">
          <input min="0" max="100" type="range"
            [(ngModel)]="volume"
            (change)="saveVolume()"
          >
        </div>
      </div>
      <p class="range-value">{{ volume }}</p>
    </div>

    <p class="text-sm">
      Your video(s) won't be shown in preview but will play on your Display.
    </p>
  </div>

</div>
