<div class="attribute-editor-component rise-text-container te-scrollable-container">
    <div class="attribute-editor-row" [hidden]="isTextVisible">
      <div class="madero-style alert alert-info u_margin-sm-top">
        <p class="u_remove-bottom">
          <i class="fa fa-exclamation-circle icon-left"></i>
          <span><b>New Feature:</b> Edit text directly on your template.</span>
          <span> If you are having trouble, <a class="madero-link u_clickable" (click)="isTextVisible=true">click here</a>.</span>
        </p>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="isTextVisible">
      <label>Text:</label>
      <input [hidden]="isMultiline" id="text-component-input" type="text" class="form-control" [(ngModel)]="value" placeholder="Enter your text" (ngModelChange)="save('value')">
      <p [shown]="isMultiline" class="text-sm">Hit enter to add a line break.</p>
      <div [shown]="isMultiline" class="row">
        <div class="col-xs-12">
          ​<textarea id="text-component-input-multiline" class="form-control resize-vertical" rows="2" [(ngModel)]="value" placeholder="Enter your text" (ngModelChange)="save('value')"></textarea>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row">
      <div class="form-group">
        <label class="control-label" for="te-tc-font">Font</label>
        <div class="form-control font-picker" #fontPicker tabindex="0" (click)="onFontPickerClick()" (keydown)="onFontPickerKeyDown($event)">
          <span class="font-picker-selected" [style.fontFamily]="font">
            {{ fontName }}
          </span>
          <span class="pull-right text-muted"><i class="fa fa-chevron-down font-picker-icon"></i></span>
          <div class="font-picker-dropdown" *ngIf="isFontPickerOpen" [style.maxHeight.px]="fontPickerMaxHeight">
            <div class="font-picker-option" *ngFor="let fontFamily of fonts" [style.fontFamily]="fontFamily.font" [class.selected]="font === fontFamily.font" (click)="onFontPickerOptionClick(fontFamily)">
              {{ fontFamily.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Text Size:</label>
            <div class="has-feedback" [ngClass]="{ 'has-error': !isValidFontSize(), 'has-success': isValidFontSize() }">
              <input type="number" class="form-control" id="te-tc-size" name="te-tc-size" placeholder="{{ fontsizePlaceholder }}"
              [(ngModel)]="fontsize"
              [attr.min]="minfontsize"
              [attr.max]="maxfontsize"
              (ngModelChange)="save('fontsize')">
              <p class="help-block" *ngIf="!isValidFontSize()">
                <span>Please provide a number between {{minfontsize}} and {{maxfontsize}}.</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Text Color:</label>
            <color-picker pickerId="te-tc-color" placeholderText="Default" [(ngModel)]="color" (ngModelChange)="onColorChange()" ngDefaultControl></color-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Line Height:</label>
            <select id="te-tc-line-height" class="form-control" [(ngModel)]="lineheight" (ngModelChange)="save('lineheight')">
              <option value="">Default</option>
              <option value="1">Single</option>
              <option value="1.15">1.15</option>
              <option value="1.5">1.5</option>
              <option value="2">Double</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Letter Spacing:</label>
            <input id="te-tc-letter-spacing" type="number" class="form-control" [(ngModel)]="letterspacing" placeholder="Default" (ngModelChange)="save('letterspacing')">
          </div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row">
      <div class="form-group">
        <label class="control-label" for="te-tc-formatting">Options</label>
        <div class="formatting-options">
          <div class="formatting-option" *ngFor="let fo of formattingOptions; index as foIndex" [class.selected]="isFormatSelected(fo)" [class.middle]="foIndex === 1" (click)="onFormattingClick(fo)"><i class="fa fa-{{fo}}"></i></div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row">
      <div class="form-group">
        <label class="control-label" for="te-tc-alignment">Alignment</label>
        <div class="formatting-options">
          <div class="formatting-option" *ngFor="let ao of alignmentOptions; index as aoIndex" [class.selected]="isAlignmentSelected(ao)" [class.middle]="aoIndex === 1" (click)="onAlignmentClick(ao)"><i class="fa fa-align-{{ao}}"></i></div>
        </div>
      </div>
    </div>

    <div class="attribute-editor-row" [shown]="showPosition">
      <div class="form-group">
        <label class="control-label u_margin-sm-top" for="te-tc-position">Position</label>
        <select id="te-tc-position"
                class="form-control"
                [(ngModel)]="position"
                (ngModelChange)="save('position')">
            <option [ngValue]="po" *ngFor="let po of positionOptions">{{po.name}}</option>
        </select>
      </div>
    </div>

  </div>
