import { AjsTransitions } from 'src/app/ajs-upgraded-providers';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderService {

  placeholder;

  constructor (
    private $transitions: AjsTransitions,
    private broadcaster: BroadcasterService) {

    this.broadcaster.on('presentationUpdated', () => {
      this.clearPlaceholder();
    });

    // This replaces $stateChangeSuccess
    this.$transitions.onSuccess({
      to: (state: any) => state.name !== 'apps.editor.workspace.artboard'
    }, () => {
      this.clearPlaceholder();
    });
  }

  setPlaceholder (placeholder) {
    this.placeholder = placeholder;
  }

  clearPlaceholder () {
    this.placeholder = undefined;
  }
}

angular.module('risevision.editor.services')
  .factory('placeholderFactory', downgradeInjectable(PlaceholderService));